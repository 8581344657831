import styled from 'styled-components';

import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';

export type DropTargetProps = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  label?: string;
};

const StyledDropTarget = styled.div`
  max-width: ${({ theme }) => theme.pxToRem(158)};
  text-align: center;
  text-transform: capitalize;
  & .cta {
    width: ${({ theme }) => theme.pxToRem(92)};
    height: ${({ theme }) => theme.pxToRem(92)};
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    border: 1px dashed ${({ theme }) => theme.colors.primary['BLUE']};
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
      width: ${({ theme }) => theme.pxToRem(40)};
      height: ${({ theme }) => theme.pxToRem(40)};

      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 2s infinite;

      & > svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        border-radius: ${({ theme }) => theme.pxToRem(16)};
        box-shadow: none;
      }
    }
  }

  /* Animations */
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 1);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px rgba(96, 172, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 0);
    }
  }
`;

const DropTarget: React.FC<DropTargetProps> = ({
  children,
  onClick,
  label,
}) => {
  return (
    <StyledDropTarget>
      <div className="cta">
        <Button
          appearance="primary"
          className="cta__button"
          aria-label="Activate Node"
          onClick={onClick}
        >
          <Icon icon="PlusIcon" />
        </Button>
        {children}
      </div>
      {label && <p>{label}</p>}
    </StyledDropTarget>
  );
};

export default DropTarget;
