import { useState } from 'react';
import styled from 'styled-components';

import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';

import { motion, AnimatePresence } from 'framer-motion';

import { useCanvas } from 'pages/business-configuration/nested-routes/business-blueprint/context';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import BusinessModelDraggableItem from 'components/molecules/business-model-draggable-item';
import BusinessModelHandle from 'components/atoms/business-model-handle/';
import Tooltip from 'components/atoms/tooltip';

import { BusinessModelOptionType } from 'pages/business-configuration/nested-routes/business-blueprint/config';

import { Position } from 'react-flow-renderer';

export type BusinessModelDroppableNodeProps = {
  id: string;
  children?: React.ReactNode;
  data: {
    label: string;
    handlePosition: string;
    labelPosition?: 'top' | 'bottom' | 'left' | 'right';
  };
  onClick?: any;
  onDroppableItemClose?: (item: BusinessModelOptionType) => void;
  droppableItems: BusinessModelOptionType[] | [];
  xPos: number;
  yPos: number;
};

const StyledBusinessModelDroppableNode = styled.div<{
  labelposition: BusinessModelDroppableNodeProps['data']['labelPosition'];
}>`
  min-width: ${({ theme }) => theme.pxToRem(220)};
  cursor: default;

  & .cta {
    min-width: ${({ theme }) => theme.pxToRem(220)};
    min-height: 112;
    display: flex;
    flex-direction: ${({ labelposition }) => {
      if (labelposition === 'top') {
        return 'column-reverse';
      }
      if (labelposition === 'bottom') {
        return 'column';
      }
      if (labelposition === 'right') {
        return 'row-reverse';
      }
      return 'row';
    }};

    align-items: center;

    & > div {
      margin-left: ${({ theme, labelposition }) =>
        labelposition === 'top' ? theme.pxToRem(24) : 0};
    }

    &__button {
      width: ${({ theme }) => theme.pxToRem(40)};
      height: ${({ theme }) => theme.pxToRem(40)};
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1);
      animation: pulse 2s infinite;

      & > svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        border-radius: ${({ theme }) => theme.pxToRem(16)};
        box-shadow: none;
      }
    }

    &__label {
      padding: ${({ theme }) => theme.pxToRem(8)};
      color: ${({ theme }) => theme.colors.neutral[600]};
      text-transform: capitalize;
    }
  }

  & .droppable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ theme }) => theme.pxToRem(220)};
    padding: ${({ theme }) => theme.pxToRem(16)};
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    border: 1px solid ${({ theme }) => theme.colors.neutral[200]};

    &__label {
      color: ${({ theme }) => theme.colors.neutral[600]};
    }

    &__target {
      width: 100%;
      height: ${({ theme }) => theme.pxToRem(40)};
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      cursor: pointer;

      border-radius: ${({ theme }) => theme.pxToRem(8)};
      border: 1px dashed ${({ theme }) => theme.colors.primary['BLUE']};

      & > p {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.primary['BLUE']};

        & > svg {
          width: ${({ theme }) => theme.pxToRem(18)};
          height: ${({ theme }) => theme.pxToRem(18)};
          padding-right: ${({ theme }) => theme.pxToRem(8)};

          & path {
            color: ${({ theme }) => theme.colors.primary['BLUE']};
          }
        }
      }

      &.selected {
        background-color: ${({ theme }) => theme.colors.tertiary['B50']};
        border: 1px solid ${({ theme }) => theme.colors.primary['BLUE']};
        cursor: default;
      }

      &:disabled {
        background-color: transparent;
        border: 1px dashed ${({ theme }) => theme.colors.neutral[500]};
        cursor: not-allowed;

        & > p {
          color: ${({ theme }) => theme.colors.neutral[500]};
        }

        & svg > {
          & path {
            color: ${({ theme }) => theme.colors.neutral[500]};
          }
        }
      }
    }
  }

  /* Animations */
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 1);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px rgba(96, 172, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 0);
    }
  }
`;

const DroppableContainer = styled.div<{ isDraggingOver?: boolean }>`
  width: 100%;
  min-height: ${({ theme }) => theme.pxToRem(8)};
  margin-bottom: ${({ theme }) => theme.pxToRem(8)};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.neutral[50] : 'transparent'};
  cursor: ${({ isDraggingOver }) => (isDraggingOver ? 'grabbing' : 'default')};
`;

const droppableVariants = {
  active: {
    transform: 'scale(1)',
  },
  nonActive: {
    transform: 'scale(0)',
  },
};

const BusinessModelDroppableNode: React.FC<BusinessModelDroppableNodeProps> = ({
  id,
  children,
  data,
  droppableItems,
  onClick,
  onDroppableItemClose,
}) => {
  const { label, labelPosition, handlePosition } = data;
  const { businessModel } = useCanvas();
  const [active, setActive] = useState(droppableItems?.length > 0);

  return (
    <>
      {active && (
        <>
          {handlePosition === 'bottom' && (
            <BusinessModelHandle
              type="target"
              id="bottom"
              position={Position.Bottom}
            />
          )}
          {handlePosition === 'left' && (
            <BusinessModelHandle
              type="target"
              id="left"
              position={Position.Left}
            />
          )}
          {handlePosition === 'right' && (
            <BusinessModelHandle
              type="target"
              id="right"
              position={Position.Right}
            />
          )}
          {handlePosition === 'top' && (
            <BusinessModelHandle
              type="target"
              id="top"
              position={Position.Top}
            />
          )}
        </>
      )}

      <StyledBusinessModelDroppableNode labelposition={labelPosition || 'top'}>
        <AnimatePresence initial={false} exitBeforeEnter>
          {active ? (
            <Tooltip
              content="Please finish editing the currently selected option by closing the side panel"
              disable={
                businessModel?.selectedNodeId === null ||
                businessModel?.selectedNodeId === id
                  ? true
                  : false
              }
            >
              <motion.div
                animate={active ? 'active' : 'nonActive'}
                variants={droppableVariants}
                initial="nonActive"
                exit="nonActive"
                transition={{
                  type: 'spring',
                  stiffness: 1000,
                  damping: 50,
                  mass: 0.5,
                  delay: 0,
                }}
                className="droppable"
              >
                <p className="droppable__label">{label}</p>
                <Droppable droppableId={`drop-${id}`}>
                  {(provided, snapshot) => {
                    return (
                      <DroppableContainer
                        isDraggingOver={snapshot.isDraggingOver}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {droppableItems.map(
                          ({ id, label, icon }: any, index: number) => (
                            <Draggable
                              isDragDisabled
                              key={`${label}=${index}`}
                              index={index}
                              draggableId={`drop-${index}-${label}`}
                            >
                              {(provided) => (
                                <BusinessModelDraggableItem
                                  label={label}
                                  icon={icon}
                                  innerRef={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClose={() => {
                                    onDroppableItemClose?.({ id, label, icon });
                                  }}
                                />
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </DroppableContainer>
                    );
                  }}
                </Droppable>

                <button
                  className={`droppable__target ${
                    businessModel?.selectedNodeId === id ? 'selected' : ''
                  }`}
                  aria-label="Select Node"
                  disabled={
                    businessModel?.selectedNodeId
                      ? businessModel?.selectedNodeId !== id
                      : false
                  }
                  onClick={() => {
                    onClick?.();
                  }}
                >
                  <p>
                    <Icon icon="PlusIcon" />{' '}
                    {businessModel?.selectedNodeId === id
                      ? 'Drag Card'
                      : 'Select'}
                  </p>
                </button>
              </motion.div>
            </Tooltip>
          ) : (
            <div className="cta">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Button
                  appearance="primary"
                  className="cta__button"
                  aria-label="Activate Node"
                  onClick={() => {
                    setActive(true);
                  }}
                >
                  <Icon icon="PlusIcon" />
                </Button>
              </motion.div>
              <motion.div
                key="cta"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <p className="cta__label">{label}</p>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        {children}
      </StyledBusinessModelDroppableNode>
    </>
  );
};

export default BusinessModelDroppableNode;
