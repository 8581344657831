import styled from 'styled-components';

import SalesFunnelCell from 'components/molecules/sales-funnel-cell/SalesFunnelCell';

export type SalesFunnelProps = {
  children?: React.ReactNode;
  loading?: boolean;
  funnelCells?: {
    key: string;
    name: string;
    value: number;
    simulated_value: number;
    is_percentage: boolean;
  }[];
  vertical?: boolean;
};

const StyledSalesFunnel = styled.div`
  & > div {
    margin-bottom: ${({ theme }) => theme.pxToRem(32)};
  }

  & div:last-child {
    border-bottom: none;
  }
`;

const StyledVerticalSalesFunnel = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  & div:last-child {
    border-right: none;
  }
`;

const SalesFunnel: React.FC<SalesFunnelProps> = ({
  funnelCells,
  vertical,
  loading,
}) => {
  const websiteVisits =
    funnelCells?.find((cell) => cell.key === 'WEBSITE-VISITS')?.value || 0;
  const simulatedWebsiteVisits =
    funnelCells?.find((cell) => cell.key === 'WEBSITE-VISITS')
      ?.simulated_value || 0;

  if (vertical) {
    return (
      <StyledVerticalSalesFunnel>
        {Array.isArray(funnelCells)
          ? funnelCells.map(({ key, name, value, simulated_value }) => {
              return (
                <SalesFunnelCell
                  loading={loading}
                  key={key}
                  title={name}
                  value={value}
                  simulatedvalue={
                    simulated_value === value ? undefined : simulated_value
                  }
                  current={Math.ceil((value / websiteVisits) * 100)}
                  forecast={
                    simulated_value
                      ? Math.ceil(
                          (simulated_value / simulatedWebsiteVisits) * 100
                        )
                      : undefined
                  }
                  vertical={vertical}
                />
              );
            })
          : []}
      </StyledVerticalSalesFunnel>
    );
  }
  return (
    <StyledSalesFunnel>
      {Array.isArray(funnelCells)
        ? funnelCells.map(({ key, name, value, simulated_value }) => {
            return (
              <SalesFunnelCell
                key={key}
                title={name}
                value={value}
                current={Math.ceil((value / websiteVisits) * 100)}
                forecast={
                  simulated_value
                    ? Math.ceil(simulated_value / simulatedWebsiteVisits) * 100
                    : undefined
                }
              />
            );
          })
        : []}
    </StyledSalesFunnel>
  );
};

export default SalesFunnel;
