import { useState } from 'react';
import styled from 'styled-components';

import SoftwareCard from 'components/atoms/software-card';
import AddSoftwareCard from 'components/atoms/add-software-card';
import SoftwarePickerPopover from 'components/molecules/software-picker-popover';
import OnboardingNav from 'components/molecules/onboarding-nav';

import { useNavigate } from 'react-router-dom';
import { SoftwareKeys, softwareMap } from '../../softwareData';

const initialData: {
  accountingSoftwares: SoftwareKeys[];
  bankingSoftwares: SoftwareKeys[];
} = {
  accountingSoftwares: ['xero', 'quickbooks'],
  bankingSoftwares: ['barclays', 'lloyds', 'hsbc'],
};

const FinancialPlatforms = styled.div`
  & .container {
    position: absolute;
    top: ${({ theme }) => theme.pxToRem(156)};
    left: 50%;
    transform: translateX(-50%);

    & h2 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(18)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[900]};
      margin-bottom: ${({ theme }) => theme.pxToRem(24)};
    }

    & .softwareCardContainer {
      display: flex;
      & > * {
        margin-right: ${({ theme }) => theme.pxToRem(24)};
      }
      margin-bottom: ${({ theme }) => theme.pxToRem(18)};
    }
  }

  & .templateCard {
    margin: ${({ theme }) => theme.pxToRem(12)};
    & .templateOption {
      margin: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Financial = () => {
  const [accountingData, setAccountingData] = useState<SoftwareKeys[]>([]);
  const [bankingData, setBankingData] = useState<SoftwareKeys[]>([]);
  const navigate = useNavigate();

  return (
    <FinancialPlatforms>
      <div className="container">
        <h2>Accounting Software</h2>
        <div className="softwareCardContainer">
          {accountingData.map((id) => (
            <SoftwareCard
              key={id}
              data-testid="software-card"
              logo={softwareMap[id].logo}
              title={softwareMap[id].title}
              onClickX={() =>
                setAccountingData((prev) =>
                  prev.filter((currentId) => currentId !== id)
                )
              }
            />
          ))}
          <SoftwarePickerPopover
            availableSoftwares={initialData.accountingSoftwares
              .filter((key) => !accountingData.includes(key))
              .map((id) => softwareMap[id])}
            selectedSoftwares={accountingData.map((id) => softwareMap[id])}
            onSelect={(id) => setAccountingData((prev) => [...prev, id])}
            onRemove={(id) =>
              setAccountingData((prev) =>
                prev.filter((currentId) => currentId !== id)
              )
            }
          >
            <AddSoftwareCard />
          </SoftwarePickerPopover>
        </div>

        <h2>Banking Software</h2>
        <div className="softwareCardContainer">
          {bankingData.map((id) => (
            <SoftwareCard
              key={id}
              logo={softwareMap[id].logo}
              title={softwareMap[id].title}
              onClickX={() =>
                setBankingData((prev) =>
                  prev.filter((currentId) => currentId !== id)
                )
              }
            />
          ))}
          <SoftwarePickerPopover
            availableSoftwares={initialData.bankingSoftwares
              .filter((key) => !bankingData.includes(key))
              .map((id) => softwareMap[id])}
            selectedSoftwares={bankingData.map((id) => softwareMap[id])}
            onSelect={(id) => setBankingData((prev) => [...prev, id])}
            onRemove={(id) =>
              setBankingData((prev) =>
                prev.filter((currentId) => currentId !== id)
              )
            }
          >
            <AddSoftwareCard />
          </SoftwarePickerPopover>
        </div>
      </div>
      <OnboardingNav
        className="bottomNavigation"
        timeSinceLastSave={600000}
        isNextDisabled={accountingData.length === 0 || bankingData.length === 0}
        onClickNext={() => {
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/financial-info'
          );
          navigate('/account-settings/financial-info');
        }}
        onClickBack={() => {
          navigate('/business-configuration/customer-journey');
        }}
      />
    </FinancialPlatforms>
  );
};

export default Financial;
