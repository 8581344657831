import { pxToRem } from 'theme/helpers';
import colors from '../colors';
import typography from 'theme/typography';

export const table: any = {
  Table: {
    baseStyle: {
      taleLayout: 'fixed',
      borderRadius: pxToRem(10),
      border: `1px solid ${colors.neutral[300]}`,
      background: colors.neutral[0],
    },
    appearances: {},
    sizes: {},
  },
  TableCell: {
    baseStyle: {
      paddingX: 12,
      fontSize: pxToRem(12),
      lineHeight: pxToRem(16),
    },
    appearances: {
      default: {
        _focus: {
          outline: 'none',
          background: colors.neutral[75],
        },
      },
    },
    sizes: {},
  },
  TableRow: {
    baseStyle: {
      outline: 'none',
      textDecoration: 'none',
      height: pxToRem(64),
      _isSelectable: {
        cursor: 'pointer',
      },
    },
    appearances: {
      default: {
        _hover: {
          background: colors.neutral[75],
        },
        _focus: {},
        _active: {},
        _current: {},
      },
    },
  },
  TableHead: {
    baseStyle: {
      borderBottom: 'default',
      background: colors.neutral[50],
      borderTopLeftRadius: `${pxToRem(10)}`,
      borderTopRightRadius: `${pxToRem(10)}`,
      height: pxToRem(48),
      fontSize: pxToRem(10),
      fontWeight: 'fontWeights.semibold',
      lineHeight: pxToRem(16),
      letterSpacing: 'letterSpacings.normal',
      fontFamily: typography.fontFamilies.display,
      color: colors.neutral[700],
      textTransform: 'uppercase',
    },
    appearances: {},
    sizes: {},
  },
};
