import { IconButton } from 'evergreen-ui';
import { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import Button from '../button';
import Icon from '../icon';
import SoftwareIcon from '../software-icon';
import logos from './logos';

const config = {
  idle: {
    cornerButton: { icon: 'XIcon', disabled: false },
    actionButton: { title: 'Connect', disabled: false },
  },
  connecting: {
    cornerButton: { icon: 'RefreshIcon', disabled: true },
    actionButton: { title: 'Connecting', disabled: true },
  },
  connected: {
    cornerButton: { icon: 'CheckIcon', disabled: true },
    actionButton: null,
  },
} as const;
export type ConnectionStatus = keyof typeof config;

export type SoftwareCardProps = {
  logo: keyof typeof logos;
  title: string;
  status?: ConnectionStatus;
  onClickX?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  connectButton?: boolean;
  cornerButton?: boolean;
  onClickConnect?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledSoftwareCard = styled.div`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(120)};
  height: ${({ theme }) => theme.pxToRem(144)};
  padding: ${({ theme }) => theme.pxToRem(16)};
  border: ${({ theme }) =>
    `${theme.pxToRem(1)} solid ${theme.colors.neutral[200]}`};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  margin: ${({ theme }) =>
    theme.pxToRem({ top: 0, right: 16, bottom: 0, left: 16 })};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .corner-button {
    width: ${({ theme }) => theme.pxToRem(24)};
    height: ${({ theme }) => theme.pxToRem(24)};
    background: transparent;

    position: absolute;
    right: 0;
    top: 0;
    transform: translate(40%, -60%);

    border: ${({ theme }) =>
      `${theme.pxToRem(2)} solid ${theme.colors.neutral[200]}`};
    box-sizing: border-box;
    border-radius: 50%;

    padding: ${({ theme }) => theme.pxToRem(4)};

    &,
    &:hover,
    &:focus {
      & svg {
        width: 100%;
        height: auto;
      }
    }

    &.CheckIcon svg path {
      fill: ${({ theme }) => theme.colors.secondary['G400']};
    }
    &.RefreshIcon svg path {
      fill: ${({ theme }) => theme.colors.secondary['Y400']};
    }
    &.XIcon svg path {
      fill: ${({ theme }) => theme.colors.secondary['R400']};
    }
  }

  & > h3 {
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(12)};
    line-height: ${({ theme }) => theme.pxToRem(16)};
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin: ${({ theme }) =>
      theme.pxToRem({ top: 8, right: 0, bottom: 8, left: 0 })};
  }

  & > button {
    margin-top: ${({ theme }) => theme.pxToRem(8)};
    &.action-connecting {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.secondary['Y400']};
      background: ${({ theme }) => theme.colors.neutral[100]};
    }
  }
`;

const SoftwareCard: React.FC<SoftwareCardProps> = ({
  logo,
  title,
  status = 'idle',
  onClickX,
  connectButton = false,
  cornerButton = true,
  onClickConnect,
  ...props
}) => {
  return (
    <StyledSoftwareCard {...props}>
      {cornerButton && (
        <IconButton
          icon={<Icon icon={config[status].cornerButton.icon} />}
          className={`corner-button ${config[status].cornerButton.icon}`}
          onClick={onClickX}
          aria-label={status}
          disabled={config[status].cornerButton.disabled}
        />
      )}
      <SoftwareIcon logo={logo} sizePx={45} />
      {connectButton && config[status].actionButton ? (
        <Button
          appearance="primary"
          className={`action-${status}`}
          onClick={onClickConnect}
          disabled={config[status].actionButton?.disabled}
        >
          {config[status].actionButton?.title}
        </Button>
      ) : (
        <h3>{title}</h3>
      )}
    </StyledSoftwareCard>
  );
};

export default SoftwareCard;
