import Icon from 'components/atoms/icon';
import styled from 'styled-components';

export type ActionCardProps = {
  title?: string;
  timeWeight?: number;
  budgetWeight?: number;
  innerRef?: any;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledActionCard = styled.div`
  width: ${({ theme }) => theme.pxToRem(350)};
  min-height: ${({ theme }) => theme.pxToRem(88)};
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  display: grid;
  grid-template-columns: ${({ theme }) =>
    `${theme.pxToRem(60)} ${theme.pxToRem(230)} ${theme.pxToRem(60)}`};
  justify-content: center;
  align-items: center;
  justify-items: center;
  cursor: move;

  & div:nth-child(1) {
    & > span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: ${({ theme }) => theme.pxToRem(32)};
      height: ${({ theme }) => theme.pxToRem(32)};
      background-color: ${({ theme }) => theme.colors.secondary['Y400']};
      border-radius: 50%;

      & > svg {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        fill: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }

  & div:nth-child(2) {
    width: 100%;
    & .scale {
      display: flex;
      margin-top: ${({ theme }) => theme.pxToRem(4)};

      & svg {
        width: ${({ theme }) => theme.pxToRem(20)};
        height: ${({ theme }) => theme.pxToRem(20)};
        fill: ${({ theme }) => theme.colors.neutral[500]};
      }

      &__time {
        display: flex;
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[200]};
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 0,
            left: 0,
            right: 8,
            bottom: 0,
          })};

        & > svg {
          &.active {
            fill: ${({ theme }) => theme.colors.secondary['G400']};
          }
        }
      }

      &__money {
        display: flex;
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 0,
            left: 8,
            right: 8,
            bottom: 0,
          })};

        & > svg {
          &.active {
            fill: ${({ theme }) => theme.colors.tertiary['T400']};
          }
        }
      }
    }
  }

  & div:nth-child(3) {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: ${({ theme }) => theme.pxToRem(24)};
      height: ${({ theme }) => theme.pxToRem(24)};
      fill: ${({ theme }) => theme.colors.neutral[500]};
    }
  }
`;

const ActionCard: React.FC<ActionCardProps> = ({
  title,
  timeWeight,
  budgetWeight,
  innerRef,
  ...props
}) => {
  const time = new Array(3).fill(undefined).map((undefined, index) => {
    if (timeWeight && index + 1 <= timeWeight) {
      return <Icon icon="ClockIcon" key={index} className="active" />;
    }
    return <Icon icon="ClockIcon" key={index} />;
  });
  const budget = new Array(3).fill(undefined).map((undefined, index) => {
    if (budgetWeight && index + 1 <= budgetWeight) {
      return <Icon icon="CurrencyDollarIcon" key={index} className="active" />;
    }
    return <Icon icon="CurrencyDollarIcon" key={index} />;
  });

  return (
    <StyledActionCard {...props} ref={innerRef}>
      <div>
        <span>
          <Icon icon="LightningBoltIcon" />
        </span>
      </div>
      <div>
        <h5>{title}</h5>
        <div className="scale">
          <div className="scale__time">{time}</div>
          <div className="scale__money">{budget}</div>
        </div>
      </div>
      <div>
        <Icon icon="ViewListIcon" />
      </div>
    </StyledActionCard>
  );
};

export default ActionCard;
