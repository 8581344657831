import { RefObject, HTMLAttributes } from 'react';
import styled from 'styled-components';

export const ITEM_HEIGHT = 40;

export type SelectMenuItemProps = {
  children?: React.ReactNode;
  selected?: boolean;
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | RefObject<HTMLButtonElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
>;

const StyledSelectMenuItem = styled.button<
  Pick<SelectMenuItemProps, 'selected'>
>`
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(ITEM_HEIGHT)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  font-weight: 400;
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(20)};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.neutral[900] : theme.colors.neutral[600]};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.neutral[100] : 'transparent'};
  border: none;

  padding: ${({ theme }) =>
    theme.pxToRem({ top: 0, right: 18, bottom: 0, left: 18 })};

  & svg {
    height: 16px;
    color: inherit;
    margin-left: ${({ theme }) => theme.pxToRem(9)};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

const SelectMenuItem: React.FC<SelectMenuItemProps> = ({
  children,
  ...props
}) => {
  return <StyledSelectMenuItem {...props}>{children}</StyledSelectMenuItem>;
};

export default SelectMenuItem;
