import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';

export type FinancingType = {
  id: string;
  provider: string;
  type: string;
  amount_min: number;
  amount_max: number;
  duration_min: number;
  duration_max: number;
  interest: number;
  repayment: number;
  speed: number;
  is_eligible: boolean;
};

export const GET_FINANCIAL_OPTIONS_QUERY = 'GET_FINANCIAL_OPTIONS_QUERY';

export const getFinancialOptions = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
      }
    ]
  >;
}): Promise<FinancingType[]> => {
  const { getAccessTokenSilently } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/financing`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (!response.ok) {
    throw Error('Network Error');
  }

  const {
    data: { collection: financingOptions },
  } = await response.json();

  return financingOptions;
};
