import styled from 'styled-components';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { updateWorkingCapitalInfo } from 'pages/account-settings/actions';
import {
  inventorySize,
  payableDaysOutstanding,
  WorkingCapitalInfoState,
} from 'pages/account-settings/StateMachine.types';
import { useNavigate } from 'react-router-dom';

import InputWrapper from 'components/molecules/input-wrapper';
import Select from 'components/molecules/select';
import TextInput from 'components/molecules/text-input';
import OnboardingNav from 'components/molecules/onboarding-nav';

const WorkingCapitalInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & h2 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.pxToRem(18)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
    color: ${({ theme }) => theme.colors.neutral[800]};
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 24, right: 0, bottom: 24, left: 0 })};
  }

  & .workingCapitalForm {
    width: ${({ theme }) => theme.pxToRem(561)};
  }

  & .input {
    width: 100%;
  }

  & .shortInput {
    width: 50%;
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const workingCapitalInfoSchema = yup.object({
  payableDaysOutstanding: yup.string().oneOf(
    payableDaysOutstanding.map((cost) => cost.value),
    'Please select one'
  ),
  inventorySize: yup.string().oneOf(
    inventorySize.map((cost) => cost.value),
    'Please select an inventory size'
  ),
  payableDays: yup
    .string()
    .required('Payable days is required.')
    .matches(/^\d+$/, 'Paybale days can only contain numbers'),
  inventoryDays: yup
    .string()
    .required('Inventory days is required.')
    .matches(/^(\s*|\d+)$/, 'Inventory days can only contain numbers'),
  receivableDays: yup
    .string()
    .required('Receivable days is required.')
    .matches(/^(\s*|\d+)$/, 'Receivable days can only contain numbers'),
  processingDays: yup
    .string()
    .required('Processing days is required.')
    .matches(/^(\s*|\d+)$/, 'Processing days can only contain numbers'),
  fullfilmentDays: yup
    .string()
    .required('Fullfilment days is required.')
    .matches(/^(\s*|\d+)$/, 'Fullfilment days can only contain numbers'),
});

const WorkingCapitalInfo = () => {
  const navigate = useNavigate();
  const {
    state: { workingCapitalInfoStore },
    actions,
  } = useStateMachine({ updateWorkingCapitalInfo });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkingCapitalInfoState>({
    resolver: yupResolver<yup.AnyObjectSchema>(workingCapitalInfoSchema) as any,
    defaultValues: {
      ...workingCapitalInfoStore,
    },
  });

  return (
    <WorkingCapitalInfoContainer>
      <form
        className="workingCapitalForm"
        onSubmit={handleSubmit((data) => {
          actions.updateWorkingCapitalInfo(data);
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/acuity-info'
          );
          navigate('/account-settings/acuity-info');
        })}
      >
        <InputWrapper label="Payable days outstanding">
          <Select
            data-testid="payableDaysOutstanding-input"
            className="input"
            placeholder="Select One"
            name="payableDaysOutstanding"
            control={control}
            error={errors['payableDaysOutstanding']}
            errorMessage={errors['payableDaysOutstanding']?.message}
            values={payableDaysOutstanding}
          />
        </InputWrapper>

        <InputWrapper label="Inventory size">
          <Select
            data-testid="inventorySize-input"
            className="input"
            placeholder="Select One"
            name="inventorySize"
            control={control}
            error={errors['inventorySize']}
            errorMessage={errors['inventorySize']?.message}
            values={inventorySize}
          />
        </InputWrapper>

        <h2>Cash Cycle</h2>

        <InputWrapper label="Payable Days">
          <TextInput
            data-testid="payableDays-input"
            className="shortInput"
            placeholder="Enter amount"
            name="payableDays"
            control={control}
            error={errors['payableDays']}
          />
        </InputWrapper>

        <InputWrapper label="Inventory Days">
          <TextInput
            data-testid="inventoryDays-input"
            className="shortInput"
            placeholder="Enter amount"
            name="inventoryDays"
            control={control}
            error={errors['inventoryDays']}
          />
        </InputWrapper>

        <InputWrapper label="Receivable Days">
          <TextInput
            data-testid="receivableDays-input"
            className="shortInput"
            placeholder="Enter amount"
            name="receivableDays"
            control={control}
            error={errors['receivableDays']}
          />
        </InputWrapper>

        <InputWrapper label="Processing Days">
          <TextInput
            data-testid="processingDays-input"
            className="shortInput"
            placeholder="Enter amount"
            name="processingDays"
            control={control}
            error={errors['processingDays']}
          />
        </InputWrapper>

        <InputWrapper label="Fullfilment Days">
          <TextInput
            data-testid="fullfilmentDays-input"
            className="shortInput"
            placeholder="Enter amount"
            name="fullfilmentDays"
            control={control}
            error={errors['fullfilmentDays']}
          />
        </InputWrapper>

        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/account-settings/financial-info');
          }}
        />
      </form>
    </WorkingCapitalInfoContainer>
  );
};

export default WorkingCapitalInfo;
