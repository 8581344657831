import Icon, { IconType } from '@icon-park/react/lib/all';
import styled from 'styled-components';

export type IconParkIconProps = {
  icon: IconType;
  variant?: 'outline' | 'filled' | 'two-tone' | 'multi-color';
  className?: string;
};

const StyledIconWrap = styled.div`
  span {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconParkIcon: React.FC<IconParkIconProps> = ({
  icon,
  variant = 'outline',
  ...props
}) => {
  return (
    <StyledIconWrap {...props}>
      <Icon type={icon} theme={variant} />
    </StyledIconWrap>
  );
};

export default IconParkIcon;
