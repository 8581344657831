import { motion } from 'framer-motion';
import Card from 'components/atoms/card';
import styled from 'styled-components';
import Icon, { IconProps } from '../icon/Icon';
import { HTMLAttributes, RefObject } from 'react';

export type TemplateCardProps = {
  icon: IconProps['icon'];
  title: string;
  description: string;
  active?: boolean;
  disabled?: boolean;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledTemplateCard = styled(Card)<
  Pick<TemplateCardProps, 'active' | 'disabled'>
>`
  width: ${({ theme }) => theme.pxToRem(207)};
  height: ${({ theme }) => theme.pxToRem(240)};
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  background: ${({ theme, active }) =>
    active ? '#60ACFF' : theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${({ theme }) =>
    theme.pxToRem({ top: 35, right: 16, bottom: 29, left: 16 })};

  &:hover {
    box-shadow: ${({ theme, disabled }) =>
      disabled
        ? 'none'
        : `0px ${theme.pxToRem(4)} ${theme.pxToRem(50)} ${theme.pxToRem(
            -10
          )} rgba(193, 196, 214, 0.4)`};
  }

  & .headerIcon {
    height: ${({ theme }) => theme.pxToRem(26)};
    margin-bottom: ${({ theme }) => theme.pxToRem(27)};
    color: ${({ theme, active }) =>
      active ? theme.colors.neutral[0] : '#60acff'};
    & path {
      stroke: ${({ theme, active }) =>
        active ? theme.colors.neutral[0] : '#60acff'};
    }
  }

  & h2 {
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.pxToRem(18)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
    color: ${({ theme, active }) => theme.colors.neutral[active ? 0 : 900]};

    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(12)};
    color: ${({ theme, active }) => theme.colors.neutral[active ? 0 : 500]};
    line-height: ${({ theme }) => theme.pxToRem(16)};
    text-align: center;

    margin-bottom: ${({ theme }) => theme.pxToRem(21)};
  }

  & div {
    text-align: center;
  }

  & .expandIcon {
    height: ${({ theme }) => theme.pxToRem(14)};
    color: ${({ theme, active }) => theme.colors.neutral[active ? 0 : 600]};
    & path {
      stroke: ${({ theme, active }) => theme.colors.neutral[active ? 0 : 600]};
    }
  }
`;

const TemplateCard: React.FC<TemplateCardProps> = ({
  title,
  description,
  icon,
  onClick,
  ...props
}) => {
  return (
    <StyledTemplateCard
      {...props}
      onClick={(event) => {
        if (props.disabled) {
          return;
        }

        onClick?.(event);
      }}
    >
      <Icon icon={icon} className="headerIcon" />
      <h2>{title}</h2>
      <p>{description}</p>
      <motion.div>
        <Icon
          icon={props.active ? 'ChevronDownIcon' : 'ChevronRightIcon'}
          className="expandIcon"
        />
      </motion.div>
    </StyledTemplateCard>
  );
};

export default TemplateCard;
