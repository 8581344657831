import styled from 'styled-components';

import Card from 'components/atoms/card';
import SliderInput from 'components/molecules/slider-input';
import Loading from 'components/atoms/loading';

export type KpiSimulationCardProps = {
  children?: React.ReactNode;
  onChange?: (value: number) => void;
  onFinalChange?: (value: number[]) => void;
  disabled?: boolean;
  min: number;
  max: number;
  control: any;
  name: string;
  sliderDefaultValue?: number;
  loading?: boolean;
  simulationMode?: boolean;
  unit?: string;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref' | 'onChange'
>;

const StyledKpiSimulationCard = styled.div<{ simulationMode?: boolean }>`
  height: 100%;

  & > div {
    background-image: ${({ theme }) => theme.colors.primary['N800']};
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;

    & > div {
      & > .content {
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 24,
            left: 32,
            right: 32,
            bottom: 48,
          })};

        & > h5 {
          color: ${({ theme }) => theme.colors.neutral[0]};
          margin-bottom: ${({ theme }) => theme.pxToRem(12)};

          & > svg {
            margin-right: ${({ theme }) => theme.pxToRem(8)};
          }
        }

        & > p {
          color: ${({ theme }) => theme.colors.neutral[0]};
        }
      }

      & > .loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 0,
            left: 0,
            right: 0,
            bottom: 24,
          })};

        & svg {
          & > circle {
            stroke: white;
          }
        }
      }

      & > .slider {
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 0,
            left: 32,
            right: 32,
            bottom: 56,
          })};

        &.is-loading {
          display: none;
        }

        & > div {
          margin: ${({ theme }) => theme.pxToRem(0)};
          & > .sliderTrack {
            border-radius: 22px;

            & > .sliderThumb {
              background: ${({ theme }) => theme.colors.neutral[0]};

              & > .sliderMark {
                display: none;
              }

              & > .sliderLabel {
                display: inline-flex;
                background: ${({ theme, simulationMode }) =>
                  simulationMode
                    ? theme.colors.secondary['G400']
                    : theme.colors.neutral[0]};
                width: 48px;
                color: ${({ theme, simulationMode }) =>
                  simulationMode
                    ? theme.colors.neutral[0]
                    : theme.colors.neutral[600]};
                border-radius: ${({ theme }) => theme.pxToRem(14)};
                font-weight: 900;
                & > span {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const KpiSimulationCard: React.FC<KpiSimulationCardProps> = ({
  children,
  onChange,
  disabled,
  min,
  max,
  control,
  name,
  loading,
  onFinalChange,
  simulationMode,
  unit,
  ...props
}) => {
  return (
    <StyledKpiSimulationCard {...props} simulationMode={simulationMode}>
      <Card>
        <div>
          <div className="content">
            <h5 className="H-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.988"
                height="21.195"
                viewBox="0 0 24.988 21.195"
              >
                <g
                  id="Group_4258"
                  data-name="Group 4258"
                  transform="translate(1218.172 -1049.217)"
                >
                  <path
                    id="Path_11697"
                    data-name="Path 11697"
                    d="M129.987,314.5a1.562,1.562,0,0,1-1.562,1.562h-6.972a1.176,1.176,0,0,1-1.153-.952l-.779-4.064a2.066,2.066,0,0,0-4.058,0l-.779,4.064a1.175,1.175,0,0,1-1.153.953H106.56a1.559,1.559,0,0,1-1.406-.892h2.075v-.892H105v-.892h2.231v-.892H105V311.6h2.231v-.892H105v-.892h2.677v-.892H105v-.892h4.462v-.892h-4.306a1.559,1.559,0,0,1,1.406-.892h21.865a1.562,1.562,0,0,1,1.562,1.562Z"
                    transform="translate(-1323.171 754.35)"
                    fill="#fff"
                  />
                  <path
                    id="Path_11698"
                    data-name="Path 11698"
                    d="M163.475,86.115v3.818a.446.446,0,0,0,.482.446c5.319-.427,5.319-1.237,5.319-1.626V84.935c0-1.68-7.853-1.81-10.263-1.81s-10.263.13-10.263,1.81v3.817c0,1.587,7.013,1.791,9.817,1.808v1.192a.446.446,0,0,0,.785.29l3.273-3.817a.446.446,0,0,0-.023-.606l-3.273-3.273a.446.446,0,0,0-.762.316v1.189c-4.761-.028-7.922-.509-8.77-.916.872-.42,4.207-.918,9.217-.918s8.323.495,9.209.914a15.535,15.535,0,0,1-4.336.74h0a.446.446,0,0,0-.411.444Z"
                    transform="translate(-1364.691 966.092)"
                    fill="#fff"
                  />
                  <path
                    id="Path_11699"
                    data-name="Path 11699"
                    d="M169.276,84.932c0-1.68-7.853-1.81-10.263-1.81s-10.263.13-10.263,1.81H149.8c.872-.419,4.207-.917,9.217-.917s8.323.495,9.209.914Z"
                    transform="translate(-1364.691 966.095)"
                    fill="#fff"
                  />
                </g>
              </svg>
              Simulation Mode
            </h5>
            <p className="large-text">
              Use the scale to simulate improvement of the KPI below and see how
              it affects the rest of the KPIs
            </p>
          </div>
          {loading && <Loading className="loading" />}
          <div className={`slider ${loading ? 'is-loading' : ''}`}>
            <SliderInput
              disabled={disabled}
              min={min}
              max={max}
              step={1}
              name={name}
              control={control}
              marks={false}
              unit={unit}
              onChange={(value) => {
                if (typeof value === 'number') {
                  onChange?.(value);
                }
              }}
              onFinalChange={(value) => {
                onFinalChange?.(value);
              }}
            />
          </div>
          {children}
        </div>
      </Card>
    </StyledKpiSimulationCard>
  );
};

export default KpiSimulationCard;
