import styled from 'styled-components';

import {
  Tooltip as EuiTooltip,
  TooltipProps as EuiTooltipProps,
} from 'evergreen-ui';

export type TooltipProps = {
  disable?: boolean;
} & EuiTooltipProps;
const StyledTooltip = styled(EuiTooltip)``;

const Tooltip: React.FC<TooltipProps> = ({ ...props }) => {
  if (props?.disable) {
    return <>{props?.children}</>;
  }

  return <StyledTooltip {...props} />;
};

export default Tooltip;
