import styled from 'styled-components';

import { Handle, HandleProps } from 'react-flow-renderer';

export type SupplierManufacturerHandleProps = {
  children?: React.ReactNode;
} & HandleProps;

const StyledSupplierManufacturerHandle = styled(Handle)`
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-color: ${({ theme }) => theme.colors.secondary['B400']};
  z-index: 10;
`;

const SupplierManufacturerHandle: React.FC<SupplierManufacturerHandleProps> = ({
  ...props
}) => {
  return <StyledSupplierManufacturerHandle {...props} />;
};

export default SupplierManufacturerHandle;
