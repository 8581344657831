import { useMutation, UseMutationOptions } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const useUpdateAction = (
  options?:
    | Omit<
        UseMutationOptions<
          string,
          unknown,
          { actionId: number; status: string },
          unknown
        >,
        'mutationFn'
      >
    | undefined
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  return useMutation(
    async ({ actionId, status }: { actionId: number; status: string }) => {
      const token = window.Cypress
        ? 'cypress-mock-token'
        : await getAccessTokenSilently();

      if (!isAuthenticated && !window.Cypress) {
        throw new Error(`You need to login to create a user.`);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/actions/${actionId}/status`,
        {
          method: 'PATCH',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            status,
          }),
        }
      );

      if (response.status !== 204) {
        throw new Error(`Failed to update the action state.`);
      }

      return 'success';
    },
    { ...(options ? { ...options } : {}) }
  );
};
