import { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import Icon from '../icon';
import logos, { SoftwareCardLogoType } from '../software-card/logos';

export type SoftwareConnectedIconProps = {
  logo: SoftwareCardLogoType;
  sizePx: number;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledSoftwareConnectedIcon = styled.div<
  Pick<SoftwareConnectedIconProps, 'sizePx'>
>`
  position: relative;
  width: ${({ theme, sizePx }) => theme.pxToRem(sizePx)};
  height: ${({ theme, sizePx }) => theme.pxToRem(sizePx)};
  background: ${({ theme }) => theme.colors.blue50};
  padding: ${({ theme, sizePx }) => theme.pxToRem(sizePx * 0.1)};
  border-radius: 50%;

  & .inner {
    box-shadow: ${({ theme }) =>
      `0 ${theme.pxToRem(4)} ${theme.pxToRem(50)} ${theme.pxToRem(
        -10
      )} rgba(193, 196, 214, 0.3)`};
    border: ${({ theme }) =>
      `${theme.pxToRem(3)} solid ${theme.colors.neutral[0]}`};
    padding: ${({ theme }) => theme.pxToRem(5)};
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & .corner {
    position: absolute;
    right: 0;
    top: 0;
    transform: ${({ sizePx }) =>
      `translate(${5 - sizePx * 0.1}%, ${-1 * (5 - sizePx * 0.1)}%)`};

    width: ${({ theme }) => theme.pxToRem(24)};
    height: ${({ theme }) => theme.pxToRem(24)};
    padding: ${({ theme }) => theme.pxToRem(3)};
    background: ${({ theme }) => theme.colors.neutral[0]};
    border: ${({ theme }) =>
      `${theme.pxToRem(2)} solid ${theme.colors.neutral[100]}`};
    box-sizing: border-box;
    border-radius: 50%;

    & svg {
      color: ${({ theme }) => theme.colors.secondary['Y400']};
      width: 100%;
      height: 100%;
    }
  }
`;

const SoftwareConnectedIcon: React.FC<SoftwareConnectedIconProps> = ({
  logo,
  sizePx,
  ...props
}) => {
  const Logo = logos[logo];
  return (
    <StyledSoftwareConnectedIcon sizePx={sizePx} {...props}>
      <div className="inner">
        <Logo />
      </div>
      <div className="corner">
        <Icon icon="RefreshIcon" />
      </div>
    </StyledSoftwareConnectedIcon>
  );
};

export default SoftwareConnectedIcon;
