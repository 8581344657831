import {
  Tablist as EuiTablist,
  TablistProps as EuiTablistProps,
} from 'evergreen-ui';
import React from 'react';
import styled from 'styled-components';

export type TablistProps = EuiTablistProps;

const StyledTablist = styled(EuiTablist)`
  display: inline-block;
  height: ${({ theme }) => theme.pxToRem(40)};
  background: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(40)};
  padding: ${({ theme }) => theme.pxToRem(4)};

  & > span {
    height: auto;
    font-weight: 500;
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(32)};
    color: ${({ theme }) => theme.colors.neutral[600]};
    border-radius: ${({ theme }) => theme.pxToRem(40)};
    box-sizing: content-box;
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 0, right: 24, bottom: 0, left: 24 })};

    &[aria-selected='true'] {
      background: ${({ theme }) => theme.colors.primary['B400']};
      font-weight: 600;
      color: ${({ theme }) => theme.colors.neutral[0]};
    }
  }
`;

/**
 * Tablist is using the **Evergreen UI native TabList** component
 * It expects a Tab component to be passed as a child.
 *
 */

const Tablist: React.FC<TablistProps> = ({ ...props }) => {
  return <StyledTablist {...props} />;
};

export default Tablist;
