import styled from 'styled-components';

import Lottie, { LottieComponentProps } from 'lottie-react';

import shieldCreation from './data.json';

export type ShieldCreationLoaderProps = {} & Omit<
  LottieComponentProps,
  'ref' | 'as' | 'animationData'
>;

const StyledShieldCreationLoader = styled(Lottie)`
  width: 100%;
  height: 100%;
  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors.neutral[600]};
      stroke: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const ShieldCreationLoader: React.FC<ShieldCreationLoaderProps> = ({
  ...props
}) => {
  return (
    <StyledShieldCreationLoader {...props} animationData={shieldCreation} />
  );
};

export default ShieldCreationLoader;
