import React from 'react';
import styled from 'styled-components';

import { NestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { updateAcuityInfo } from 'pages/account-settings/actions';
import {
  acuityFunctions,
  AcuityInfoState,
  acuityMetrics,
  financingProducts,
  missingRoles,
} from 'pages/account-settings/StateMachine.types';
import { useNavigate } from 'react-router-dom';

import InputWrapper from 'components/molecules/input-wrapper';
import TagInput from 'components/molecules/tag-input';
import OnboardingNav from 'components/molecules/onboarding-nav';
import SliderInput from 'components/molecules/slider-input';

const AcuityInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & .form {
    width: ${({ theme }) => theme.pxToRem(561)};
  }

  & .input {
    width: 100%;
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const acuityInfoSchema = yup.object({
  metrics: yup.array().min(1, 'This field is required'),
  financialKnowledgeRate: yup
    .number()
    .min(1, 'Please set a value')
    .max(10, 'Please set a value'),
  marketingKnowledgeRate: yup
    .number()
    .min(1, 'Please set a value')
    .max(10, 'Please set a value'),
  operationalManagementKnowledgeRate: yup
    .number()
    .min(1, 'Please set a value')
    .max(10, 'Please set a value'),
  functions: yup.array().min(1, 'This field is required'),
  missingRoles: yup.array().min(1, 'This field is required'),
  financingProducts: yup.array().min(1, 'This field is required'),
});

const FinancialInfo = () => {
  const {
    state: { acuityInfoStore },
    actions,
  } = useStateMachine({ updateAcuityInfo });
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<
    Omit<
      AcuityInfoState,
      'metrics' | 'functions' | 'missingRoles' | 'financingProducts'
    > & {
      metrics: NestedValue<AcuityInfoState['metrics']>;
      functions: NestedValue<AcuityInfoState['functions']>;
      missingRoles: NestedValue<AcuityInfoState['missingRoles']>;
      financingProducts: NestedValue<AcuityInfoState['financingProducts']>;
    }
  >({
    resolver: yupResolver<yup.AnyObjectSchema>(acuityInfoSchema) as any,
    defaultValues: {
      ...acuityInfoStore,
    },
  });

  const selectedMetrics = watch('metrics');
  const autoCompleteMetrics = React.useMemo(
    () => acuityMetrics.filter((source) => !selectedMetrics.includes(source)),
    [selectedMetrics]
  );

  const selectedFunctions = watch('functions');
  const autoCompleteFunctions = React.useMemo(
    () =>
      acuityFunctions.filter((source) => !selectedFunctions.includes(source)),
    [selectedFunctions]
  );

  const selectedMissingRoles = watch('missingRoles');
  const autoCompleteMissingRoles = React.useMemo(
    () =>
      missingRoles.filter((source) => !selectedMissingRoles.includes(source)),
    [selectedMissingRoles]
  );

  const selectedFinancingProducts = watch('financingProducts');
  const autoCompleteFinancingProducts = React.useMemo(
    () =>
      financingProducts.filter(
        (source) => !selectedFinancingProducts.includes(source)
      ),
    [selectedFinancingProducts]
  );

  return (
    <AcuityInfoContainer>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          actions.updateAcuityInfo(data);
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/goals-info'
          );
          navigate('/account-settings/goals-info');
        })}
      >
        <InputWrapper
          label="What metrics are you currently tracking?"
          helpText="Segment syncs a list of users to these destinations and keeps it up to date."
        >
          <TagInput
            data-testid="metrics-input"
            className="input"
            inputProps={{
              placeholder: 'Please select',
            }}
            name="metrics"
            control={control}
            error={errors['metrics']}
            errorMessage={errors['metrics']?.message}
            autocompleteItems={autoCompleteMetrics}
          />
        </InputWrapper>

        <InputWrapper label="How would you rate your financial knowledge with respect to managing your business?">
          <SliderInput
            data-testid="financialKnowledgeRate-input"
            className="input"
            name="financialKnowledgeRate"
            control={control}
            error={errors['financialKnowledgeRate']}
            min={0}
            max={10}
            step={1}
          />
        </InputWrapper>

        <InputWrapper label="How would you rate your marketing knowledge with respect to managing your business?">
          <SliderInput
            data-testid="marketingKnowledgeRate-input"
            className="input"
            name="marketingKnowledgeRate"
            control={control}
            error={errors['marketingKnowledgeRate']}
            min={0}
            max={10}
            step={1}
          />
        </InputWrapper>

        <InputWrapper label="How would you rate your operational management knowledge with respect to managing your business?">
          <SliderInput
            data-testid="operationalManagementKnowledgeRate-input"
            className="input"
            name="operationalManagementKnowledgeRate"
            control={control}
            error={errors['operationalManagementKnowledgeRate']}
            min={0}
            max={10}
            step={1}
          />
        </InputWrapper>

        <InputWrapper label="What functions do you feel that you are on top of?">
          <TagInput
            data-testid="functions-input"
            className="input"
            inputProps={{
              placeholder: 'Please select',
            }}
            name="functions"
            control={control}
            error={errors['functions']}
            errorMessage={errors['functions']?.message}
            autocompleteItems={autoCompleteFunctions}
          />
        </InputWrapper>

        <InputWrapper label="What roles do you feel are missing in your company?">
          <TagInput
            data-testid="missingRoles-input"
            className="input"
            inputProps={{
              placeholder: 'Please select',
            }}
            name="missingRoles"
            control={control}
            error={errors['missingRoles']}
            errorMessage={errors['missingRoles']?.message}
            autocompleteItems={autoCompleteMissingRoles}
          />
        </InputWrapper>

        <InputWrapper label="Are you familiar with financing products?">
          <TagInput
            data-testid="financingProducts-input"
            className="input"
            inputProps={{
              placeholder: 'Please select',
            }}
            name="financingProducts"
            control={control}
            error={errors['financingProducts']}
            errorMessage={errors['financingProducts']?.message}
            autocompleteItems={autoCompleteFinancingProducts}
          />
        </InputWrapper>

        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/account-settings/working-capital-info');
          }}
        />
      </form>
    </AcuityInfoContainer>
  );
};

export default FinancialInfo;
