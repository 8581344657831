import styled, { createGlobalStyle } from 'styled-components';

import Logo from 'components/atoms/logo';

export type AccountSettingsPageTemplateProps = {
  children?: React.ReactNode;
  title?: string;
};

const GlobalStyle = createGlobalStyle`
body {
  background-color: ${({ theme }) => theme.colors.neutral[0]};
}
`;

const StyledAccountSettingsPageTemplate = styled.div`
  margin-bottom: ${({ theme }) => theme.pxToRem(32)};

  & .header {
    display: flex;
    align-items: center;
    padding: ${({ theme }) =>
      theme.pxToRem({ left: 16, top: 8, bottom: 8, right: 16 })};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['400']};
    margin-bottom: ${({ theme }) => theme.pxToRem(32)};

    & > svg {
      margin-right: ${({ theme }) => theme.pxToRem(16)};
    }

    & > h2 {
      font-weight: bold;
      font-size: ${({ theme }) => theme.pxToRem(18)};
      line-height: ${({ theme }) => theme.pxToRem(24)};

      padding-left: ${({ theme }) => theme.pxToRem(15)};
      margin-left: ${({ theme }) => theme.pxToRem(15)};
      border-left: ${({ theme }) => `1px solid ${theme.colors.neutral['400']}`};
    }
  }

  & .content {
    width: ${({ theme }) => theme.pxToRem(561)};
    margin: 0 auto;

    & h3 {
      font-weight: bold;
      font-size: ${({ theme }) => theme.pxToRem(24)};
      line-height: ${({ theme }) => theme.pxToRem(36)};
      color: ${({ theme }) => theme.colors.neutral[900]};
      margin-bottom: ${({ theme }) => theme.pxToRem(32)};
    }

    & form {
      width: 100%;
    }
  }

  & .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const AccountSettingsPageTemplate: React.FC<
  AccountSettingsPageTemplateProps
> = ({ children, title }) => {
  return (
    <>
      <GlobalStyle />
      <StyledAccountSettingsPageTemplate>
        <div className="header">
          <Logo to="/" />
          {title && <h2>{title}</h2>}
        </div>
        <div className="content">{children}</div>
      </StyledAccountSettingsPageTemplate>
    </>
  );
};

export default AccountSettingsPageTemplate;
