import styled from 'styled-components';
import {
  SelectMenu as EuiSelectMenu,
  SelectMenuProps as EuiSelectMenuProps,
} from 'evergreen-ui';
import { useMemo } from 'react';
import Icon, { IconProps } from 'components/atoms/icon/Icon';
import SelectMenuItem, { ITEM_HEIGHT } from 'components/atoms/select-menu-item';

export type SelectMenuProps = {
  iconStart?: IconProps['icon'];
  options: (Exclude<EuiSelectMenuProps['options'], undefined>[number] & {
    icon?: IconProps['icon'];
  })[];
  placeholder?: string;
} & Omit<EuiSelectMenuProps, 'children' | 'options'>;

const StyledSelectMenu = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.neutral[600]};
  border: none;
  background: transparent;

  & .start-icon {
    width: ${({ theme }) => theme.pxToRem(16)};
    height: ${({ theme }) => theme.pxToRem(16)};
    color: inherit;
    margin-right: ${({ theme }) => theme.pxToRem(6)};
  }

  & > p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(20)};
    color: inherit;
  }

  & p + svg {
    height: ${({ theme }) => theme.pxToRem(16)};
    color: inherit;
    margin-left: ${({ theme }) => theme.pxToRem(9)};
  }
`;

const SelectMenu: React.FC<SelectMenuProps> = ({
  iconStart,
  placeholder,
  ...props
}) => {
  const currentValue = useMemo(
    () => props.options?.find((option) => option.value === props.selected),
    [props.options, props.selected]
  );

  return (
    <EuiSelectMenu
      {...props}
      width="auto"
      height={props.options.length * ITEM_HEIGHT}
      hasFilter={false}
      hasTitle={false}
      itemRenderer={({ isSelected, onSelect, key, item }) => {
        return (
          <SelectMenuItem onClick={onSelect} selected={isSelected} key={key}>
            {item.label}
            {item.icon && <Icon icon={item.icon as IconProps['icon']} />}
          </SelectMenuItem>
        );
      }}
    >
      <StyledSelectMenu>
        {iconStart && (
          <Icon className="start-icon" icon={iconStart} variant="outline" />
        )}
        <p>{currentValue?.label || placeholder || 'Please select'}</p>
        <Icon icon={currentValue?.icon || 'ChevronDownIcon'} />
      </StyledSelectMenu>
    </EuiSelectMenu>
  );
};

export default SelectMenu;
