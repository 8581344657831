import { useGlobalPageTemplate } from 'context';
import { motion, MotionProps } from 'framer-motion';
import styled from 'styled-components';

export type PageContentContainerProps = {
  children?: React.ReactNode;
  minimizeNavbar?: boolean;
  sidebarOpen?: boolean;
  className?: string;
  wrapper?: boolean;
} & MotionProps;

const StyledPageContentContainer = styled(motion.div)<{
  fixContentContainer?: number;
  wrapper?: boolean;
}>`
  min-width: ${({ theme, fixContentContainer }) =>
    fixContentContainer ? theme.pxToRem(fixContentContainer) : 'auto'};
  min-height: ${({ theme }) => `calc(100vh - ${theme.pxToRem(88)})`};
  margin-top: ${({ theme }) => theme.pxToRem(20)};
  margin-bottom: ${({ theme }) => theme.pxToRem(20)};
  padding: ${({ theme }) =>
    theme.pxToRem({
      top: 32,
      left: 24,
      right: 24,
      bottom: 32,
    })};
  ${({ wrapper, theme }) =>
    wrapper
      ? ``
      : `
        background-color: ${
          theme.mode === 'light'
            ? theme.colors.neutral[50]
            : theme.colors.neutral[950]
        };
        border: ${`${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};
        border-radius: ${theme.pxToRem(16)};
    `}
`;

const PageContentContainer: React.FC<PageContentContainerProps> = ({
  children,
  minimizeNavbar,
  sidebarOpen,
  wrapper,
  ...props
}) => {
  const { fixContentContainer } = useGlobalPageTemplate();
  return (
    <StyledPageContentContainer
      animate={{
        marginLeft: (minimizeNavbar ? 100 : 200) + 32,
        marginRight: sidebarOpen ? 397 + 16 : 24,
      }}
      initial={false}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 60,
        mass: 0.1,
        delay: 0,
      }}
      fixContentContainer={fixContentContainer}
      wrapper={wrapper}
      {...props}
    >
      {children}
    </StyledPageContentContainer>
  );
};

export default PageContentContainer;
