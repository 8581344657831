import { GlobalState } from 'little-state-machine';
import {
  AcuityInfoState,
  BusinessSophisticationInfoState,
  GoalsInfoState,
  RiskInfoState,
} from './StateMachine.types';

import {
  PersonalState,
  BusinessState,
  FinancialInfoState,
  WorkingCapitalInfoState,
} from './StateMachine.types';

export const updatePersonalDetails = (
  state: GlobalState,
  payload: PersonalState
) => ({
  ...state,
  personalStore: {
    ...state.personalStore,
    ...payload,
  },
});

export const updateBusinessDetails = (
  state: GlobalState,
  payload: BusinessState
) => ({
  ...state,
  businessStore: {
    ...state.businessStore,
    ...payload,
  },
});

export const updateFinancialInfo = (
  state: GlobalState,
  payload: FinancialInfoState
) => ({
  ...state,
  financialInfoStore: {
    ...state.financialInfoStore,
    ...payload,
  },
});

export const updateAcuityInfo = (
  state: GlobalState,
  payload: AcuityInfoState
) => ({
  ...state,
  acuityInfoStore: {
    ...state.acuityInfoStore,
    ...payload,
  },
});

export const updateGoalsInfo = (
  state: GlobalState,
  payload: GoalsInfoState
) => ({
  ...state,
  goalsInfoStore: {
    ...state.goalsInfoStore,
    ...payload,
  },
});

export const updateRiskInfo = (state: GlobalState, payload: RiskInfoState) => ({
  ...state,
  riskInfoStore: {
    ...state.riskInfoStore,
    ...payload,
  },
});

export const updateBusinessSophistication = (
  state: GlobalState,
  payload: BusinessSophisticationInfoState
) => ({
  ...state,
  businessSophisticationInfoStore: {
    ...state.businessSophisticationInfoStore,
    ...payload,
  },
});

export const updateWorkingCapitalInfo = (
  state: GlobalState,
  payload: WorkingCapitalInfoState
) => ({
  ...state,
  workingCapitalInfoStore: {
    ...state.workingCapitalInfoStore,
    ...payload,
  },
});
