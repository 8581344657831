import styled from 'styled-components';

export type TabButtonProps = {
  children?: React.ReactNode;
  active: boolean;
} & Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'ref'
>;

const StyledTabButton = styled.button<Pick<TabButtonProps, 'active'>>`
  width: auto;
  margin-right: 24px;
  margin-bottom: ${({ theme }) => theme.pxToRem(12)};
  padding: ${({ theme }) =>
    theme.pxToRem({
      top: 8,
      left: 24,
      right: 24,
      bottom: 8,
    })};
  border: none;
  background-color: transparent;
  font-size: ${({ theme }) => theme.pxToRem(14)};
  color: ${({ theme, active }) =>
    active ? theme.colors.neutral[0] : theme.colors.neutral[600]};
  border-radius: ${({ theme }) => theme.pxToRem(40)};
  cursor: pointer;
  background-image: ${({ active, theme }) =>
    active ? theme.colors.primary['B400'] : 'none'};

  :hover {
    background-image: ${({ theme }) => theme.colors.primary['B400']};
    color: ${({ theme }) => theme.colors.neutral[0]};
  }
`;

const TabButton: React.FC<TabButtonProps> = ({
  children,
  active,
  ...props
}) => {
  return (
    <StyledTabButton {...props} active={active}>
      {children}
    </StyledTabButton>
  );
};

export default TabButton;
