import { useState, useEffect } from 'react';
import styled from 'styled-components';

import DotsScaleLoader from '../dots-scale-loader';
import ShieldTransformationLoader from '../shield-transformation-loader';

import { Popover, Position } from 'evergreen-ui';

export type HonuSuggestionsIndicatorProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  description?: string;
  localStorageFlag?: string;
};

const StyledHonuSuggestionsIndicator = styled.div<
  HonuSuggestionsIndicatorProps & { showPopUp?: boolean }
>`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(72)};
  height: ${({ theme }) => theme.pxToRem(72)};
  display: flex;
  align-items: center;
  justify-content: center;

  & > .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme, disabled }) =>
      disabled ? theme.pxToRem(58) : theme.pxToRem(72)};
    height: ${({ theme, disabled }) =>
      disabled ? theme.pxToRem(58) : theme.pxToRem(72)};
    border: none;
    outline: none;
    background: ${({ disabled }) =>
      disabled
        ? `transparent
    linear-gradient(180deg, #f8f7f8 0%, #ececef 30%, #8f95b2 100%) 0% 0%
    no-repeat padding-box`
        : `transparent
      linear-gradient(180deg, #f8f7f8 0%, #ececef 30%, #5386f1 100%) 0% 0%
      no-repeat padding-box`};
    border-radius: 50%;
    cursor: pointer;
    animation: ${({ showPopUp }) => (showPopUp ? `pulse 2s infinite` : 'none')};

    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ${({ theme, disabled }) =>
        disabled ? theme.pxToRem(48) : theme.pxToRem(62)};
      height: ${({ theme, disabled }) =>
        disabled ? theme.pxToRem(48) : theme.pxToRem(62)};
      border-radius: 50%;
      ${({ theme, disabled }) =>
        disabled
          ? `
      background: ${theme.colors.neutral[600]}
      `
          : `
       background-image: ${theme.colors.primary['N800']};
      `};

      & .close-icon {
        display: none;
        width: ${({ theme }) => theme.pxToRem(48)};
        height: ${({ theme }) => theme.pxToRem(48)};
        & svg {
          color: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }
  }

  & .shield-loader {
    position: absolute;
    top: 3px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 19;

    & > svg {
      & path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
        stroke: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }

  & .turtle-loader {
    position: absolute;
    width: ${({ theme }) => theme.pxToRem(64)};
    height: ${({ theme }) => theme.pxToRem(64)};
    z-index: 19;

    & > svg {
      & path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
        stroke: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }

  /* Animations */
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 1);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 16px rgba(96, 172, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 0);
    }
  }
`;

const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.pxToRem(290)};
  height: 100%;
  padding: ${({ theme }) => theme.pxToRem(32)};

  background: transparent
    linear-gradient(
      270deg,
      rgba(114, 196, 227, 1) 0%,
      rgba(103, 138, 245, 1) 100%
    )
    0% 0% no-repeat padding-box;

  & > p {
    color: ${({ theme }) => theme.colors.neutral[0]};
    margin-bottom: ${({ theme }) => theme.pxToRem(18)};
  }

  & .button-container {
    & > button {
      width: fit-content;
      border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
      outline: none;
      background-color: transparent;
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      color: ${({ theme }) => theme.colors.neutral[0]};
      cursor: pointer;
      margin-right: ${({ theme }) => theme.pxToRem(16)};
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 12,
          left: 24,
          right: 24,
          bottom: 12,
        })};
    }
  }
`;

const HonuSuggestionsIndicator: React.FC<HonuSuggestionsIndicatorProps> = ({
  disabled,
  description,
  localStorageFlag,
  ...props
}) => {
  const showIntelligenceIndicator = localStorageFlag
    ? window.localStorage.getItem(localStorageFlag)
    : 'off';
  const [showPopUp, setShowPopUp] = useState(
    showIntelligenceIndicator === 'off' ? false : !disabled
  );

  useEffect(() => {
    if (showIntelligenceIndicator !== 'off') {
      setShowPopUp(!disabled);
    }
  }, [disabled, setShowPopUp, showIntelligenceIndicator]);

  return (
    <Popover
      display="false"
      statelessProps={{
        borderRadius: 16,
        boxShadow: `0px 3px 6px #00000029`,
        'aria-label': 'Honu Intelligence Popup',
      }}
      position={Position.BOTTOM_RIGHT}
      isShown={showPopUp}
      content={
        <PopUpCard>
          <p className="large-text">{description}</p>
          <div className="button-container">
            <button
              onClick={() => {
                setShowPopUp(false);
              }}
            >
              Close
            </button>
            <button
              onClick={() => {
                window.localStorage.setItem(
                  'financeIntelligenceIndicator',
                  'off'
                );
                setShowPopUp(false);
              }}
            >
              Don't Show
            </button>
          </div>
        </PopUpCard>
      }
    >
      <StyledHonuSuggestionsIndicator
        {...props}
        disabled={disabled}
        showPopUp={showPopUp}
        aria-label="Honu Intelligence Popup"
      >
        {disabled ? (
          <ShieldTransformationLoader className="shield-loader" />
        ) : (
          <DotsScaleLoader className="turtle-loader" />
        )}
        <div className="container">
          <span />
        </div>
      </StyledHonuSuggestionsIndicator>
    </Popover>
  );
};

export default HonuSuggestionsIndicator;
