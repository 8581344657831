import styled from 'styled-components';

import { NavLink, NavLinkProps } from 'react-router-dom';

import Icon from 'components/atoms/icon';
import { IconProps } from 'components/atoms/icon/Icon';
export type NavBarLinkProps = {
  to?: string;
  icon?: IconProps['icon'];
} & NavLinkProps;

const StyledNavBarLink = styled(NavLink)`
  text-decoration: none;
  display: inline-block;
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(40)};
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }): string => theme.pxToRem(40)};
  font-weight: 400;
  color: ${({ theme }) =>
    theme.mode === 'light'
      ? theme.colors.neutral[600]
      : theme.colors.neutral[0]};
  text-indent: ${({ theme }): string => theme.pxToRem(8)};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary['B400']};
  }

  &.active {
    color: ${({ theme }) => theme.colors.secondary['B400']};

    &:after {
      content: '';
      width: ${({ theme }) => theme.pxToRem(6)};
      height: 100%;
      position: absolute;
      background-color: ${({ theme }) => theme.colors.secondary['B400']};
      border-radius: ${({ theme }) =>
        theme.pxToRem({
          top: 99,
          left: 99,
          right: 0,
          bottom: 0,
        })};
      top: 0;
      right: ${({ theme }) => theme.pxToRem(8)};
    }
  }

  & > .icon {
    width: ${({ theme }) => theme.pxToRem(24)};
    height: ${({ theme }) => theme.pxToRem(24)};
  }

  & .indicator {
    height: 100%;
    width: 6px;
    background-color: blue;
    position: absolute;
    right: 0;
    border-radius: 4px 0 0 4px;
  }
`;

/**
 * `NavBarLink` component should be used within `NavBarContainer`
 * to link between internal app pages. The component implements
 * React Router `NavLink` component for the routing functionality.
 */

const NavBarLink: React.FC<NavBarLinkProps> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <StyledNavBarLink {...props}>
      {icon && <Icon icon={icon} className="icon" />}
      {children}
    </StyledNavBarLink>
  );
};

export default NavBarLink;
