import React from 'react';

import styled from 'styled-components';

import { IconParkIcon } from 'components/atoms/icon';

export type FundingOptionsDroppableProps = {
  children?: React.ReactNode;
  innerRef?: any;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledFundingOptionsDroppable = styled.div`
  width: 100%;
  height: 100%;

  & .droppable-container {
    position: relative;
    width: 100%;

    &__heading {
      height: ${({ theme }) => theme.pxToRem(40)};
      background-color: ${({ theme }) => theme.colors.secondary['B400']};
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      box-shadow: 0px 0px 12px #559ff97c;
      display: flex;
      justify-content: center;
      align-items: center;

      & > h5 {
        color: ${({ theme }) => theme.colors.neutral[0]};
      }
    }

    &__drop-icon {
      padding: ${({ theme }) => theme.pxToRem(10)};
      width: ${({ theme }) => theme.pxToRem(44)};
      height: ${({ theme }) => theme.pxToRem(44)};
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

      & .icon {
        width: ${({ theme }) => theme.pxToRem(44)};
        height: ${({ theme }) => theme.pxToRem(44)};
        & > span {
          & > svg {
            color: ${({ theme }) => theme.colors.tertiary['B200']};
          }
        }
      }
    }

    &__drop-area {
      width: 100%;
      height: 100%;
      min-height: ${({ theme }) => theme.pxToRem(100)};
      border: 1px solid ${({ theme }) => theme.colors.neutral[400]};
      border-style: dashed;
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      margin-top: ${({ theme }) => theme.pxToRem(8)};
      padding-bottom: ${({ theme }) => theme.pxToRem(32)};
    }
  }
`;

const FundingOptionsDroppable: React.FC<FundingOptionsDroppableProps> = ({
  children,
  innerRef,
  ...props
}) => {
  return (
    <StyledFundingOptionsDroppable {...props} ref={innerRef}>
      <div className="droppable-container">
        <div className="droppable-container__heading">
          <h5 className="H-500">Funding Options</h5>
        </div>
        <div className="droppable-container__drop-area">
          {React.Children.count(children) === 0 && (
            <div className="droppable-container__drop-icon">
              <IconParkIcon icon="MoveInOne" className="icon" />
            </div>
          )}
          {children}
        </div>
      </div>
    </StyledFundingOptionsDroppable>
  );
};

export default FundingOptionsDroppable;
