import { useState, useEffect } from 'react';

import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';

import Icon from 'components/atoms/icon';
import OnboardingNav from 'components/molecules/onboarding-nav';
import BusinessGoalsAndObjectives from 'components/molecules/business-goals-and-objectives';
import BusinessGoalCard from 'components/atoms/business-goal-card';
import PageContentContainer from 'components/atoms/page-content-container';
import ShieldTransformationLoader from 'components/atoms/shield-transformation-loader';
import HonuSuggestionsCta from 'components/atoms/honu-suggestions-cta/HonuSuggestionsCta';
import IntelligenceLoadingOverlay from 'components/template/intelligence-loading-overlay';
import PageHeader from 'components/molecules/page-header';

import {
  getBusinessObjectives,
  GET_BUSINESS_OBJECTIVES_QUERY,
  BusinessOptionType,
} from 'api-client/queries';

import { useSetUserObjectives } from 'api-client/mutations';

import { useQuery } from 'react-query';

import { useNavigate } from 'react-router-dom';

import { useGlobalPageTemplate } from 'context';

const PageContainer = styled.div`
  & > .page-header {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-content: flex-end;
    min-height: auto;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 15,
        left: 24,
        right: 24,
        bottom: 15,
      })};

    & p {
      color: ${({ theme }) => theme.colors.neutral[600]};
    }

    & div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .resources {
    min-height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.pxToRem(24)};

    & > div {
      flex: 1;
      & h1 {
        display: flex;
        align-items: center;
        font-size: ${({ theme }) => theme.pxToRem(21)};

        & > span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: ${({ theme }) => theme.pxToRem(24)};
          height: ${({ theme }) => theme.pxToRem(24)};
          border-radius: 50%;
          margin-right: ${({ theme }) => theme.pxToRem(4)};

          & > svg {
            width: ${({ theme }) => theme.pxToRem(14)};
            height: ${({ theme }) => theme.pxToRem(14)};
          }
        }

        & > p {
          font-size: ${({ theme }) => theme.pxToRem(15)};
          font-weight: 600;
          color: ${({ theme }) => theme.colors.neutral[800]};
        }
      }

      &.time {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
        & > div {
          margin-left: ${({ theme }) => theme.pxToRem(32)};

          & > p {
            color: ${({ theme }) => theme.colors.neutral[600]};
          }

          & span {
            background-color: ${({ theme }) => theme.colors.secondary['G400']};
            & > svg {
              fill: ${({ theme }) => theme.colors.neutral[0]};
            }
          }
        }
      }

      &.budget {
        & > div {
          margin-left: ${({ theme }) => theme.pxToRem(32)};

          & > p {
            color: ${({ theme }) => theme.colors.neutral[600]};
          }

          & span {
            background-color: ${({ theme }) => theme.colors.tertiary['T400']};
            & > svg {
              fill: ${({ theme }) => theme.colors.neutral[0]};
            }
          }
        }
      }
    }
  }

  & .goals-container {
    min-height: auto;
    position: relative;
    padding-bottom: ${({ theme }) => theme.pxToRem(65)};

    & .content {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      & > div {
        flex: 1;
        margin-left: 24px;
        max-width: ${({ theme }) => theme.pxToRem(394)};
        margin-bottom: ${({ theme }) => theme.pxToRem(24)};
      }
    }
  }

  & .navigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(44)};
    left: 50%;
    transform: translateX(-40%);

    & .selected-goals-list {
      display: flex;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[600]};

      & > svg {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(44)};
      }
      & > p {
        max-width: ${({ theme }) => theme.pxToRem(650)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  & .loading-overlay {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & > div {
      width: ${({ theme }) => theme.pxToRem(400)};
      height: ${({ theme }) => theme.pxToRem(400)};
    }
  }
`;

const Objectives = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { minimizeNavbar } = useGlobalPageTemplate();
  const [intelligenceActive, setIntelligenceActive] = useState(true);

  // Those values will come from API
  const time = 80;
  const budget = 2500;
  const [selectedObjectives, setSelectedObjectives] = useState<
    BusinessOptionType[] | []
  >([]);
  const { data: objectivesData, isLoading: isObjectivesLoading } = useQuery(
    [GET_BUSINESS_OBJECTIVES_QUERY, { getAccessTokenSilently }] as const,
    getBusinessObjectives
  );
  const { isLoading: isSettingObjectives, mutate } = useSetUserObjectives({
    onSuccess: () => {
      navigate('/choose-actions', {
        state: {
          fromObjectives: true,
        },
      });
    },
  });

  useEffect(() => {
    if (objectivesData) {
      setSelectedObjectives((prevState) => {
        return [
          ...prevState,
          ...objectivesData.revenues.filter(
            (objective) => objective.is_suggested
          ),
          ...objectivesData.profitability.filter(
            (objective) => objective.is_suggested
          ),
          ...objectivesData.solvency.filter(
            (objective) => objective.is_suggested
          ),
        ];
      });
    }
  }, [objectivesData, setSelectedObjectives]);

  return (
    <>
      <IntelligenceLoadingOverlay
        show={isSettingObjectives}
        delay={2}
        notifications={[
          'Analyzing your objectives',
          'Understanding your aspirations',
          'Assessing your risk appetite',
          'Running multiple simulations',
          'Ranking simulation outcomes',
          'Surfacing optimal result',
        ]}
      />
      <PageContainer>
        <PageHeader
          title="Set your goals"
          description="   We’ve chosen the best Objectives for you based on how your
              business is currently performing"
        >
          <HonuSuggestionsCta
            className="suggestions-cta"
            active={objectivesData && intelligenceActive}
            popUpInfo="Your Honu Suggestions are on! You can always turn them off by clicking this button and do it manually."
            onClick={() => {
              setSelectedObjectives((prevState) => {
                if (objectivesData) {
                  if (intelligenceActive) {
                    return [
                      ...objectivesData.revenues.filter(
                        (objective) => objective.is_locked
                      ),
                      ...objectivesData.profitability.filter(
                        (objective) => objective.is_locked
                      ),
                      ...objectivesData.solvency.filter(
                        (objective) => objective.is_locked
                      ),
                    ];
                  }
                  return [
                    ...objectivesData.revenues.filter(
                      (objective) => objective.is_suggested
                    ),
                    ...objectivesData.profitability.filter(
                      (objective) => objective.is_suggested
                    ),
                    ...objectivesData.solvency.filter(
                      (objective) => objective.is_suggested
                    ),
                  ];
                }
                return prevState;
              });
              setIntelligenceActive((prevState) => !prevState);
            }}
          />
        </PageHeader>
        <PageContentContainer className="resources">
          <div className="time">
            <div>
              <h1>
                <span>
                  <Icon icon="ClockIcon" />
                </span>
                {time}
                <p>h/week</p>
              </h1>
              <p>Available Time</p>
            </div>
          </div>
          <div className="budget">
            <div>
              <h1>
                <span>
                  <Icon icon="CurrencyDollarIcon" />
                </span>
                {budget >= 1000 ? budget / 1000 : budget}
                <p>k/month</p>
              </h1>
              <p>Available Budget</p>
            </div>
          </div>
        </PageContentContainer>
        <PageContentContainer
          className="goals-container"
          minimizeNavbar={minimizeNavbar}
        >
          {isObjectivesLoading ? (
            <div className="loading-overlay">
              <ShieldTransformationLoader />
            </div>
          ) : (
            <>
              <div className="content">
                <BusinessGoalsAndObjectives variant="revenue">
                  {objectivesData?.revenues?.map(
                    ({ id, name, is_suggested, is_locked }) => {
                      return (
                        <BusinessGoalCard
                          key={id}
                          title={name}
                          selected={
                            selectedObjectives.find(
                              (objective) => objective.id === id
                            ) !== undefined
                          }
                          locked={is_locked}
                          onClick={() => {
                            if (
                              selectedObjectives.find(
                                (objective) => objective.id === id
                              )
                            ) {
                              setSelectedObjectives((prevState) =>
                                prevState.filter(
                                  (objective) => objective.id !== id
                                )
                              );
                              return;
                            }
                            setSelectedObjectives((prevState) => [
                              ...prevState,
                              { id, name, is_suggested, is_locked },
                            ]);
                          }}
                        />
                      );
                    }
                  )}
                </BusinessGoalsAndObjectives>
                <BusinessGoalsAndObjectives variant="profitability">
                  {objectivesData?.profitability?.map(
                    ({ id, name, is_suggested, is_locked }) => {
                      return (
                        <BusinessGoalCard
                          key={id}
                          title={name}
                          selected={
                            selectedObjectives.find(
                              (objective) => objective.id === id
                            ) !== undefined
                          }
                          locked={is_locked}
                          onClick={() => {
                            if (
                              selectedObjectives.find(
                                (objective) => objective.id === id
                              )
                            ) {
                              setSelectedObjectives((prevState) =>
                                prevState.filter(
                                  (objective) => objective.id !== id
                                )
                              );
                              return;
                            }
                            setSelectedObjectives((prevState) => [
                              ...prevState,
                              { id, name, is_suggested, is_locked },
                            ]);
                          }}
                        />
                      );
                    }
                  )}
                </BusinessGoalsAndObjectives>
                <BusinessGoalsAndObjectives variant="solvency">
                  {objectivesData?.solvency?.map(
                    ({ id, name, is_suggested, is_locked }) => {
                      return (
                        <BusinessGoalCard
                          key={id}
                          title={name}
                          selected={
                            selectedObjectives.find(
                              (objective) => objective.id === id
                            ) !== undefined
                          }
                          locked={is_locked}
                          onClick={() => {
                            if (
                              selectedObjectives.find(
                                (objective) => objective.id === id
                              )
                            ) {
                              setSelectedObjectives((prevState) =>
                                prevState.filter(
                                  (objective) => objective.id !== id
                                )
                              );
                              return;
                            }
                            setSelectedObjectives((prevState) => [
                              ...prevState,
                              { id, name, is_suggested, is_locked },
                            ]);
                          }}
                        />
                      );
                    }
                  )}
                </BusinessGoalsAndObjectives>
              </div>
              <div className="navigation">
                <OnboardingNav
                  backButtonLabel="Back"
                  nextButtonLabel="Confirm & Continue"
                  onClickNext={() => {
                    const objectivesIds = selectedObjectives.map(
                      ({ id }) => id
                    );
                    mutate({ objectivesIds, delay: 13000 });
                  }}
                  onClickBack={() => {
                    navigate('/');
                  }}
                >
                  <div className="selected-goals-list">
                    <Icon icon="CheckCircleIcon" variant="outline" />
                    <p className="large-text">
                      {selectedObjectives.map(({ name }) => name).join(', ')}
                    </p>
                  </div>
                </OnboardingNav>
              </div>
            </>
          )}
        </PageContentContainer>
      </PageContainer>
    </>
  );
};

export default Objectives;
