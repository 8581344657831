import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';

import { FinancialForecast } from '../shared-types';
export const GET_FINANCING_SIMULATION_QUERY = 'GET_FINANCING_SIMULATION_QUERY';

export const getFinancingSimulation = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
        provider?: string;
        delay?: number;
      }
    ]
  >;
}): Promise<FinancialForecast> => {
  const { getAccessTokenSilently, provider, delay } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();

  return new Promise((resolve) => setTimeout(resolve, delay || 0)).then(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/${
          process.env.REACT_APP_API_GATEWAY_VERSION
        }/reasoning/api/kpis/simulation/financing-options${
          provider ? `?financing-option=${provider}` : ''
        }`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        }
      );

      if (!response.ok) {
        throw Error('Network Error');
      }

      const { data } = await response.json();
      setTimeout(() => {}, 3000);

      return data;
    }
  );
};
