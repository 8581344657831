import { defaultTheme } from 'evergreen-ui';

/**
 * Sets the default Evergreen UI theme
 *
 * @version ^6.6.1
 * @see https://evergreen.segment.com/introduction/theming
 */

const evergreenTheme = {
  ...defaultTheme,
};

export default evergreenTheme;

/**
 * Reference to the default evergreen theme properties
 */

// {
//   tokens: {
//     colors: {
//       gray900: '#101840',
//       gray800: '#474d66',
//       gray700: '#696f8c',
//       gray600: '#8f95b2',
//       gray500: '#c1c4d6',
//       gray400: '#d8dae5',
//       gray300: '#E6E8F0',
//       gray200: '#edeff5',
//       gray100: '#F4F5F9',
//       gray90: '#F4F6FA',
//       gray75: '#F9FAFC',
//       gray50: '#FAFBFF',
//       blue900: '#0A1433',
//       blue800: '#142966',
//       blue700: '#1F3D99',
//       blue600: '#2952CC',
//       blue500: '#3366FF',
//       blue400: '#5C85FF',
//       blue300: '#85A3FF',
//       blue200: '#ADC2FF',
//       blue100: '#D6E0FF',
//       blue50: '#EBF0FF',
//       blue25: '#F3F6FF',
//       red700: '#7D2828',
//       red600: '#A73636',
//       red500: '#D14343',
//       red100: '#F9DADA',
//       red25: '#FDF4F4',
//       green900: '#10261E',
//       green800: '#214C3C',
//       green700: '#317159',
//       green600: '#429777',
//       green500: '#52BD95',
//       green400: '#75CAAA',
//       green300: '#97D7BF',
//       green200: '#BAE5D5',
//       green100: '#DCF2EA',
//       green25: '#F5FBF8',
//       orange700: '#996A13',
//       orange500: '#FFB020',
//       orange100: '#F8E3DA',
//       orange25: '#FFFAF2',
//       purple600: '#6E62B6',
//       purple100: '#E7E4F9',
//       teal800: '#0F5156',
//       teal100: '#D3F5F7',
//       yellow800: '#66460D',
//       yellow100: '#FFEFD2',
//       muted: '#696f8c',
//       default: '#474d66',
//     },
//     fontFamilies: {
//       display:
//         '"SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//       ui: '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//       mono: '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace',
//     },
//     text: {
//       '300': {
//         fontSize: '12px',
//         fontWeight: 400,
//         lineHeight: '16px',
//         letterSpacing: '0',
//       },
//       '400': {
//         fontSize: '14px',
//         fontWeight: 400,
//         lineHeight: '20px',
//         letterSpacing: '-0.05px',
//       },
//       '500': {
//         fontSize: '16px',
//         fontWeight: 400,
//         lineHeight: '20px',
//         letterSpacing: '-0.05px',
//       },
//       '600': {
//         fontSize: '20px',
//         fontWeight: 400,
//         lineHeight: '24px',
//         letterSpacing: '-0.07px',
//       },
//     },
//     paragraph: {
//       '300': {
//         fontSize: '12px',
//         fontWeight: 400,
//         lineHeight: '18px',
//         letterSpacing: '0',
//       },
//       '400': {
//         fontSize: '14px',
//         fontWeight: 400,
//         lineHeight: '21px',
//         letterSpacing: '-0.05px',
//       },
//       '500': {
//         fontSize: '16px',
//         fontWeight: 400,
//         lineHeight: '24px',
//         letterSpacing: '-0.05px',
//       },
//     },
//     overlayBackgroundColor: 'rgba(67, 90, 111, 0.7)',
//     codeBackgroundColor: 'rgba(16, 112, 202, 0.06)',
//     codeBorderColor: 'rgba(16, 112, 202, 0.14)',
//     fills: {
//       neutral: {
//         color: '#474d66',
//         backgroundColor: '#edeff5',
//       },
//       blue: {
//         color: '#2952CC',
//         backgroundColor: '#D6E0FF',
//       },
//       red: {
//         color: '#7D2828',
//         backgroundColor: '#F9DADA',
//       },
//       orange: {
//         color: '#996A13',
//         backgroundColor: '#F8E3DA',
//       },
//       yellow: {
//         color: '#66460D',
//         backgroundColor: '#FFEFD2',
//       },
//       green: {
//         color: '#317159',
//         backgroundColor: '#DCF2EA',
//       },
//       teal: {
//         color: '#0F5156',
//         backgroundColor: '#D3F5F7',
//       },
//       purple: {
//         color: '#6E62B6',
//         backgroundColor: '#E7E4F9',
//       },
//     },
//     selectedOptionColor: '#3366FF',
//     borderRadius: 4,
//     primary: {
//       base: '#3366FF',
//       hover: '#2952CC',
//       active: '#1F3D99',
//       disabled: '#D6E0FF',
//     },
//     intents: {
//       info: {
//         background: '#F3F6FF',
//         border: '#3366FF',
//         text: '#2952CC',
//         icon: '#3366FF',
//       },
//       success: {
//         background: '#F5FBF8',
//         border: '#52BD95',
//         text: '#317159',
//         icon: '#52BD95',
//       },
//       warning: {
//         background: '#FFFAF2',
//         border: '#FFB020',
//         text: '#996A13',
//         icon: '#FFB020',
//       },
//       danger: {
//         background: '#FDF4F4',
//         border: '#D14343',
//         text: '#A73636',
//         icon: '#D14343',
//       },
//     },
//     states: {
//       default: {
//         icon: '#474d66',
//       },
//       muted: {
//         icon: '#696f8c',
//       },
//       dark: {
//         icon: '#101840',
//       },
//       disabled: {
//         icon: '#c1c4d6',
//       },
//       selected: {
//         icon: '#3366FF',
//       },
//     },
//   },
//   colors: {
//     gray900: '#101840',
//     gray800: '#474d66',
//     gray700: '#696f8c',
//     gray600: '#8f95b2',
//     gray500: '#c1c4d6',
//     gray400: '#d8dae5',
//     gray300: '#E6E8F0',
//     gray200: '#edeff5',
//     gray100: '#F4F5F9',
//     gray90: '#F4F6FA',
//     gray75: '#F9FAFC',
//     gray50: '#FAFBFF',
//     blue900: '#0A1433',
//     blue800: '#142966',
//     blue700: '#1F3D99',
//     blue600: '#2952CC',
//     blue500: '#3366FF',
//     blue400: '#5C85FF',
//     blue300: '#85A3FF',
//     blue200: '#ADC2FF',
//     blue100: '#D6E0FF',
//     blue50: '#EBF0FF',
//     blue25: '#F3F6FF',
//     red700: '#7D2828',
//     red600: '#A73636',
//     red500: '#D14343',
//     red300: '#EE9191',
//     red100: '#F9DADA',
//     red25: '#FDF4F4',
//     green900: '#10261E',
//     green800: '#214C3C',
//     green700: '#317159',
//     green600: '#429777',
//     green500: '#52BD95',
//     green400: '#75CAAA',
//     green300: '#97D7BF',
//     green200: '#BAE5D5',
//     green100: '#DCF2EA',
//     green25: '#F5FBF8',
//     orange700: '#996A13',
//     orange500: '#FFB020',
//     orange100: '#F8E3DA',
//     orange25: '#FFFAF2',
//     purple600: '#6E62B6',
//     purple100: '#E7E4F9',
//     teal800: '#0F5156',
//     teal100: '#D3F5F7',
//     yellow800: '#66460D',
//     yellow100: '#FFEFD2',
//     muted: '#696f8c',
//     default: '#474d66',
//     dark: '#101840',
//     selected: '#3366FF',
//     tint1: '#FAFBFF',
//     tint2: '#F9FAFC',
//     overlay: 'rgba(67, 90, 111, 0.7)',
//     yellowTint: '#FFEFD2',
//     greenTint: '#F5FBF8',
//     orangeTint: '#FFFAF2',
//     redTint: '#FDF4F4',
//     blueTint: '#F3F6FF',
//     purpleTint: '#E7E4F9',
//     tealTint: '#D3F5F7',
//     border: {
//       default: '#E6E8F0',
//       muted: '#edeff5',
//     },
//     icon: {
//       default: '#696f8c',
//       muted: '#8f95b2',
//       disabled: '#d8dae5',
//       selected: '#3366FF',
//     },
//     text: {
//       danger: '#D14343',
//       success: '#52BD95',
//       info: '#3366FF',
//     },
//   },
//   fills: {
//     neutral: {
//       color: '#474d66',
//       backgroundColor: '#edeff5',
//     },
//     blue: {
//       color: '#2952CC',
//       backgroundColor: '#D6E0FF',
//     },
//     red: {
//       color: '#7D2828',
//       backgroundColor: '#F9DADA',
//     },
//     orange: {
//       color: '#996A13',
//       backgroundColor: '#F8E3DA',
//     },
//     yellow: {
//       color: '#66460D',
//       backgroundColor: '#FFEFD2',
//     },
//     green: {
//       color: '#317159',
//       backgroundColor: '#DCF2EA',
//     },
//     teal: {
//       color: '#0F5156',
//       backgroundColor: '#D3F5F7',
//     },
//     purple: {
//       color: '#6E62B6',
//       backgroundColor: '#E7E4F9',
//     },
//   },
//   intents: {
//     info: {
//       background: '#F3F6FF',
//       border: '#3366FF',
//       text: '#2952CC',
//       icon: '#3366FF',
//     },
//     success: {
//       background: '#F5FBF8',
//       border: '#52BD95',
//       text: '#317159',
//       icon: '#52BD95',
//     },
//     warning: {
//       background: '#FFFAF2',
//       border: '#FFB020',
//       text: '#996A13',
//       icon: '#FFB020',
//     },
//     danger: {
//       background: '#FDF4F4',
//       border: '#D14343',
//       text: '#A73636',
//       icon: '#D14343',
//     },
//   },
//   radii: ['0px', '4px', '8px'],
//   shadows: [
//     '0 0 1px rgba(67, 90, 111, 0.3)',
//     '0 0 1px rgba(67, 90, 111, 0.3), 0 2px 4px -2px rgba(67, 90, 111, 0.47)',
//     '0 0 1px rgba(67, 90, 111, 0.3), 0 5px 8px -4px rgba(67, 90, 111, 0.47)',
//     '0 0 1px rgba(67, 90, 111, 0.3), 0 8px 10px -4px rgba(67, 90, 111, 0.47)',
//     '0 0 1px rgba(67, 90, 111, 0.3), 0 16px 24px -8px rgba(67, 90, 111, 0.47)',
//   ],
//   fontFamilies: {
//     display:
//       '"SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//     ui: '"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
//     mono: '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace',
//   },
//   fontSizes: ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px'],
//   fontWeights: {
//     light: 300,
//     normal: 400,
//     semibold: 500,
//     bold: 600,
//   },
//   letterSpacings: {
//     tightest: '-0.2px',
//     tighter: '-0.07px',
//     tight: '-0.05px',
//     normal: '0',
//     wide: '0.6px',
//   },
//   lineHeights: ['16px', '18px', '20px', '24px', '28px', '32px', '40px'],
//   zIndices: {
//     focused: 2,
//     stack: 5,
//     positioner: 10,
//     overlay: 20,
//     toaster: 30,
//   },
//   components: {
//     Alert: {
//       appearances: {},
//       sizes: {},
//     },
//     Avatar: {
//       appearances: {},
//       sizes: {},
//     },
//     Badge: {
//       baseStyle: {
//         height: 16,
//         paddingY: 0,
//         paddingX: 6,
//         borderRadius: 'radii.1',
//         fontSize: '11.5px',
//         textAlign: 'center',
//         textDecoration: 'none',
//         textTransform: 'uppercase',
//       },
//       appearances: {},
//       sizes: {},
//     },
//     Button: {
//       baseStyle: {
//         fontFamily: 'fontFamilies.ui',
//         border: '1px solid transparent',
//         borderRadius: 'radii.1',
//         transition: 'box-shadow 80ms ease-in-out',
//         _focus: {
//           boxShadow: 'shadows.focusRing',
//         },
//         _disabled: {
//           cursor: 'not-allowed',
//           pointerEvents: 'none',
//         },
//       },
//       appearances: {
//         default: {
//           backgroundColor: 'white',
//           _disabled: {
//             color: 'colors.gray500',
//             borderColor: 'colors.gray300',
//           },
//           _hover: {
//             backgroundColor: 'colors.gray50',
//           },
//           _active: {
//             backgroundColor: 'colors.gray100',
//           },
//         },
//         minimal: {
//           backgroundColor: 'transparent',
//           _disabled: {
//             color: 'colors.gray500',
//             opacity: 0.6,
//           },
//           _hover: {
//             backgroundColor: 'colors.gray100',
//           },
//           _active: {
//             backgroundColor: 'colors.gray200',
//           },
//         },
//         destructive: {
//           backgroundColor: 'colors.red500',
//           borderColor: 'colors.red500',
//           color: 'white',
//           _hover: {
//             backgroundColor: 'colors.red600',
//             borderColor: 'colors.red600',
//           },
//           _disabled: {
//             backgroundColor: 'colors.red100',
//             borderColor: 'colors.red100',
//           },
//           _focus: {
//             backgroundColor: 'colors.red500',
//             boxShadow: '0 0 0 2px undefined',
//             borderColor: 'colors.red500',
//           },
//           _active: {
//             backgroundColor: 'colors.red700',
//             borderColor: 'colors.red700',
//           },
//         },
//       },
//       sizes: {
//         small: {
//           height: 24,
//           minWidth: 24,
//           fontSize: 'fontSizes.1',
//           lineHeight: '24px',
//           paddingLeft: 12,
//           paddingRight: 12,
//         },
//         medium: {
//           height: 32,
//           minWidth: 32,
//           fontSize: 'fontSizes.1',
//           lineHeight: '32px',
//           paddingLeft: 16,
//           paddingRight: 16,
//         },
//         large: {
//           height: 40,
//           minWidth: 40,
//           fontSize: 'fontSizes.2',
//           lineHeight: '40px',
//           paddingLeft: 20,
//           paddingRight: 20,
//         },
//       },
//     },
//     Card: {
//       baseStyle: {
//         borderRadius: 'radii.1',
//       },
//       appearances: {},
//       sizes: {},
//     },
//     Checkbox: {
//       baseStyle: {},
//       appearances: {
//         default: {
//           _base: {
//             color: 'white',
//             background: 'white',
//           },
//           _disabled: {
//             cursor: 'not-allowed',
//             background: 'colors.gray100',
//             color: 'colors.gray100',
//           },
//           _hover: {},
//           _focus: {},
//           _active: {
//             background: 'colors.gray100',
//           },
//           _checked: {
//             color: 'white',
//             background: 'colors.blue500',
//           },
//           _checkedHover: {
//             color: 'white',
//             background: 'colors.blue600',
//           },
//           _checkedActive: {
//             color: 'white',
//             background: 'colors.blue700',
//           },
//           _checkedDisabled: {
//             color: 'colors.gray600',
//             background: 'colors.gray100',
//           },
//         },
//       },
//       sizes: {},
//     },
//     Code: {
//       baseStyle: {},
//       appearances: {
//         default: {
//           backgroundColor: 'rgba(16, 112, 202, 0.06)',
//           boxShadow: 'inset 0 0 0 1px rgba(16, 112, 202, 0.14)',
//           paddingX: 6,
//           paddingY: 3,
//           borderRadius: 'radii.1',
//         },
//       },
//       sizes: {},
//     },
//     DialogBody: {
//       baseStyle: {
//         paddingY: 8,
//         paddingX: 32,
//       },
//     },
//     DialogFooter: {
//       baseStyle: {
//         paddingX: 32,
//         paddingBottom: 32,
//         paddingTop: 24,
//       },
//     },
//     DialogHeader: {
//       baseStyle: {
//         paddingX: 32,
//         paddingTop: 32,
//         paddingBottom: 24,
//       },
//     },
//     Group: {
//       baseStyle: {
//         _child: {
//           '&:focus': {
//             zIndex: 'zIndices.focused',
//           },
//           '&:active': {
//             zIndex: 'zIndices.focused',
//           },
//         },
//         _firstChild: {
//           borderTopRightRadius: 0,
//           borderBottomRightRadius: 0,
//         },
//         _middleChild: {
//           borderRadius: 0,
//           marginLeft: '-1px',
//         },
//         _lastChild: {
//           borderTopLeftRadius: 0,
//           borderBottomLeftRadius: 0,
//           marginLeft: '-1px',
//         },
//       },
//       appearances: {},
//       sizes: {},
//     },
//     Heading: {
//       baseStyle: {
//         color: 'colors.dark',
//         fontFamily: 'fontFamilies.display',
//         fontWeight: 'fontWeights.semibold',
//       },
//       appearances: {},
//       sizes: {
//         '100': {
//           fontSize: 'fontSizes.0',
//           textTransform: 'uppercase',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.wide',
//           fontFamily: 'fontFamilies.ui',
//           color: 'colors.muted',
//         },
//         '200': {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//           fontFamily: 'fontFamilies.ui',
//           color: 'colors.muted',
//         },
//         '300': {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//           fontFamily: 'fontFamilies.ui',
//         },
//         '400': {
//           fontSize: 'fontSizes.2',
//           lineHeight: 'lineHeights.1',
//           letterSpacing: 'letterSpacings.tight',
//           fontFamily: 'fontFamilies.ui',
//         },
//         '500': {
//           fontFamily: 'fontFamilies.ui',
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tight',
//           lineHeight: 'lineHeights.2',
//         },
//         '600': {
//           fontSize: 'fontSizes.4',
//           lineHeight: 'lineHeights.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         '700': {
//           fontSize: 'fontSizes.5',
//           lineHeight: 'lineHeights.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         '800': {
//           fontSize: 'fontSizes.6',
//           lineHeight: 'lineHeights.5',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tightest',
//         },
//         '900': {
//           fontSize: 'fontSizes.7',
//           lineHeight: 'lineHeights.6',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tightest',
//         },
//       },
//     },
//     Icon: {
//       appearances: {},
//       sizes: {},
//     },
//     InlineAlert: {
//       appearances: {},
//       sizes: {},
//     },
//     Input: {
//       baseStyle: {
//         borderRadius: 'radii.1',
//         fontFamily: 'fontFamilies.ui',
//         lineHeight: 'lineHeights.0',
//         fontSize: 'fontSizes.1',
//         border: '1px solid transparent',
//         color: 'colors.default',
//         paddingX: 12,
//         transition: 'box-shadow 80ms ease-in-out',
//         _placeholder: {
//           color: 'colors.gray600',
//         },
//         _disabled: {
//           cursor: 'not-allowed',
//           backgroundColor: 'colors.gray100',
//           color: 'colors.muted',
//         },
//       },
//       appearances: {
//         default: {
//           backgroundColor: 'white',
//           borderColor: 'colors.gray400',
//           _focus: {
//             zIndex: 'zIndices.focused',
//             boxShadow: 'shadows.focusRing',
//             borderColor: 'colors.blue200',
//           },
//           _invalid: {
//             borderColor: 'colors.red500',
//           },
//         },
//         none: {
//           backgroundColor: 'transparent',
//         },
//       },
//       sizes: {
//         small: {
//           height: 24,
//         },
//         medium: {
//           height: 32,
//         },
//         large: {
//           height: 40,
//           lineHeight: 'lineHeights.1',
//         },
//       },
//     },
//     Label: {
//       baseStyle: {
//         color: 'colors.dark',
//         fontFamily: 'fontFamilies.display',
//         fontWeight: 'fontWeights.semibold',
//       },
//       appearances: {},
//       sizes: {
//         '100': {
//           fontSize: 'fontSizes.0',
//           textTransform: 'uppercase',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.wide',
//           fontFamily: 'fontFamilies.ui',
//           color: 'colors.muted',
//         },
//         '200': {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//           fontFamily: 'fontFamilies.ui',
//           color: 'colors.muted',
//         },
//         '300': {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//           fontFamily: 'fontFamilies.ui',
//         },
//         '400': {
//           fontSize: 'fontSizes.2',
//           lineHeight: 'lineHeights.1',
//           letterSpacing: 'letterSpacings.tight',
//           fontFamily: 'fontFamilies.ui',
//         },
//         '500': {
//           fontFamily: 'fontFamilies.ui',
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tight',
//           lineHeight: 'lineHeights.2',
//         },
//         '600': {
//           fontSize: 'fontSizes.4',
//           lineHeight: 'lineHeights.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         '700': {
//           fontSize: 'fontSizes.5',
//           lineHeight: 'lineHeights.3',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         '800': {
//           fontSize: 'fontSizes.6',
//           lineHeight: 'lineHeights.5',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tightest',
//         },
//         '900': {
//           fontSize: 'fontSizes.7',
//           lineHeight: 'lineHeights.6',
//           fontWeight: 'fontWeights.bold',
//           letterSpacing: 'letterSpacings.tightest',
//         },
//       },
//     },
//     List: {
//       baseStyle: {},
//       appearances: {},
//       sizes: {},
//     },
//     Link: {
//       baseStyle: {
//         borderRadius: 'radii.1',
//         transition: '120ms all ease-in-out',
//         textDecoration: 'none',
//         _hover: {},
//         _active: {},
//         _focus: {},
//       },
//       appearances: {},
//       sizes: {},
//     },
//     MenuItem: {
//       baseStyle: {
//         outline: 'none',
//         textDecoration: 'none',
//         display: 'flex',
//         position: 'relative',
//         paddingX: 12,
//         _isSelectable: {
//           cursor: 'pointer',
//         },
//         _disabled: {
//           cursor: 'not-allowed',
//           pointerEvents: 'none',
//         },
//       },
//       appearances: {
//         default: {
//           backgroundColor: 'white',
//           '&:before': {
//             content: '""',
//             position: 'absolute',
//             left: 0,
//             top: 0,
//             bottom: 0,
//             width: 2,
//             borderRadius: '0px 2px 2px 0px',
//             backgroundColor: 'colors.blue500',
//             transition: '0.25s',
//             transformOrigin: 'left center',
//             transform: 'scaleX(0)',
//           },
//           _hover: {
//             backgroundColor: 'colors.gray75',
//           },
//           _focus: {
//             backgroundColor: 'colors.gray75',
//           },
//           _active: {
//             backgroundColor: 'intents.info.background',
//             '&:before': {
//               transform: 'scaleX(1)',
//             },
//           },
//           _current: {
//             backgroundColor: 'intents.info.background',
//             '&:before': {
//               transform: 'scaleX(1)',
//             },
//           },
//         },
//       },
//     },
//     Option: {
//       baseStyle: {
//         outline: 'none',
//         textDecoration: 'none',
//         display: 'flex',
//         position: 'relative',
//         backgroundColor: 'white',
//         _before: {
//           content: '""',
//           position: 'absolute',
//           left: 0,
//           top: 0,
//           bottom: 0,
//           width: 2,
//           borderRadius: '0px 2px 2px 0px',
//           backgroundColor: 'colors.blue500',
//           transition: '0.25s',
//           transformOrigin: 'left center',
//           transform: 'scaleX(0)',
//         },
//         _isSelectable: {
//           cursor: 'pointer',
//         },
//         _hover: {
//           backgroundColor: 'colors.gray75',
//         },
//         _focus: {
//           backgroundColor: 'colors.gray75',
//         },
//         _active: {
//           backgroundColor: 'intents.info.background',
//         },
//         _selected: {
//           backgroundColor: 'intents.info.background',
//           ' span': {
//             color: 'intents.info.text',
//           },
//           '&:before': {
//             transform: 'scaleX(1)',
//           },
//         },
//         _disabled: {
//           opacity: 0.5,
//           pointerEvents: 'none',
//           cursor: 'not-allowed',
//         },
//       },
//     },
//     Pane: {
//       appearances: {},
//       sizes: {},
//     },
//     Paragraph: {
//       baseStyle: {
//         marginTop: 0,
//         marginBottom: 0,
//       },
//       appearances: {},
//       sizes: {
//         '300': {
//           fontSize: 'fontSizes.1',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.1',
//           letterSpacing: 'letterSpacings.normal',
//         },
//         '400': {
//           fontSize: 'fontSizes.2',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.3',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         '500': {
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.3',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         '600': {
//           fontSize: 'fontSizes.4',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.4',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         small: {
//           fontSize: 'fontSizes.1',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.1',
//           letterSpacing: 'letterSpacings.normal',
//         },
//         medium: {
//           fontSize: 'fontSizes.2',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.3',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         large: {
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.3',
//           letterSpacing: 'letterSpacings.tight',
//         },
//       },
//     },
//     Radio: {
//       baseStyle: {},
//       appearances: {
//         default: {
//           _base: {
//             color: 'white',
//             background: 'white',
//           },
//           _disabled: {
//             cursor: 'not-allowed',
//             background: 'colors.gray100',
//             color: 'colors.gray100',
//           },
//           _hover: {},
//           _focus: {},
//           _active: {
//             background: 'colors.gray100',
//           },
//           _checked: {
//             color: 'white',
//             background: 'colors.blue500',
//           },
//           _checkedHover: {
//             color: 'white',
//             background: 'colors.blue600',
//           },
//           _checkedActive: {
//             color: 'white',
//             background: 'colors.blue700',
//           },
//           _checkedDisabled: {
//             color: 'colors.gray600',
//             background: 'colors.gray100',
//           },
//         },
//       },
//       sizes: {},
//     },
//     Select: {
//       baseStyle: {
//         fontFamily: 'fontFamilies.ui',
//         borderRadius: 'radii.1',
//         border: 0,
//       },
//       appearances: {
//         default: {
//           backgroundColor: 'white',
//           color: 'colors.gray800',
//           _disabled: {
//             cursor: 'not-allowed',
//             color: 'colors.gray500',
//             borderColor: 'colors.gray300',
//           },
//           _hover: {
//             borderColor: 'colors.gray600',
//             backgroundColor: 'colors.gray50',
//           },
//           _focus: {
//             boxShadow: 'shadows.focusRing',
//           },
//           _active: {
//             backgroundColor: 'colors.gray100',
//           },
//         },
//       },
//       sizes: {
//         small: {
//           height: 24,
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//         },
//         medium: {
//           height: 32,
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//         },
//         large: {
//           height: 40,
//           fontSize: 'fontSizes.2',
//           lineHeight: 'lineHeights.2',
//         },
//       },
//     },
//     Spinner: {
//       baseStyle: {
//         color: 'rgba(67, 90, 111, 0.47)',
//       },
//       appearances: {},
//       sizes: {
//         small: {
//           width: 32,
//           height: 32,
//         },
//         medium: {
//           width: 40,
//           height: 40,
//         },
//         large: {
//           width: 48,
//           height: 48,
//         },
//       },
//     },
//     Switch: {
//       baseStyle: {},
//       appearances: {
//         default: {
//           _base: {
//             color: 'white',
//             backgroundColor: 'colors.gray400',
//           },
//           _disabled: {
//             cursor: 'not-allowed',
//             opacity: 0.5,
//           },
//           _hover: {
//             backgroundColor: 'colors.gray500',
//           },
//           _focus: {},
//           _active: {
//             backgroundColor: 'colors.gray600',
//           },
//           _checked: {
//             backgroundColor: 'colors.blue500',
//             color: 'white',
//           },
//           _checkedHover: {
//             backgroundColor: 'colors.blue600',
//             color: 'white',
//           },
//           _checkedActive: {
//             backgroundColor: 'colors.blue700',
//             color: 'white',
//           },
//           _checkedDisabled: {},
//         },
//       },
//       sizes: {},
//     },
//     Tab: {
//       baseStyle: {
//         fontFamily: 'fontFamilies.ui',
//         fontWeight: 500,
//       },
//       appearances: {
//         primary: {
//           color: 'colors.muted',
//           paddingTop: '6px',
//           paddingBottom: '6px',
//           paddingLeft: '2px',
//           paddingRight: '2px',
//           position: 'relative',
//           ':not(:last-child)': {},
//           _before: {
//             content: '""',
//             position: 'absolute',
//             bottom: 0,
//             right: 0,
//             height: '2px',
//             borderRadius: '2px 2px 0px 0px',
//             backgroundColor: 'colors.blue500',
//             width: '100%',
//             transition: '0.25s',
//             transform: 'scaleY(0)',
//             transformOrigin: 'bottom center',
//           },
//           _hover: {
//             color: 'colors.default',
//           },
//           _current: {
//             color: 'colors.blue500',
//             '&:before': {
//               transform: 'scaleY(1)',
//             },
//             '&:focus': {
//               color: 'colors.blue600',
//             },
//           },
//           _focus: {
//             boxShadow: 'shadows.focusRing',
//             color: 'colors.default',
//           },
//           _disabled: {
//             pointerEvents: 'none',
//             cursor: 'not-allowed',
//             color: 'colors.gray500',
//             '&:before': {
//               backgroundColor: 'colors.gray500',
//             },
//           },
//         },
//         secondary: {
//           paddingX: '16px',
//           paddingY: '8px',
//           borderRadius: 'radii.1',
//           color: 'colors.default',
//           ':not(:last-child)': {},
//           _hover: {
//             backgroundColor: 'colors.gray100',
//             color: 'colors.gray800',
//           },
//           _active: {
//             backgroundColor: 'colors.gray200',
//           },
//           _current: {
//             backgroundColor: 'colors.blue50',
//             color: 'colors.blue500',
//           },
//           _focus: {
//             boxShadow: 'shadows.focusRing',
//           },
//           _disabled: {
//             pointerEvents: 'none',
//             cursor: 'not-allowed',
//             color: 'colors.gray500',
//             '&[aria-current="page"], &[aria-selected="true"]': {
//               backgroundColor: 'colors.gray100',
//             },
//           },
//         },
//       },
//     },
//     Table: {
//       baseStyle: {
//         borderRadius: 'radii.1',
//         border: 'muted',
//       },
//       appearances: {},
//       sizes: {},
//     },
//     TableCell: {
//       baseStyle: {
//         paddingX: 12,
//       },
//       appearances: {
//         default: {
//           _focus: {
//             outline: 'none',
//             background: 'colors.blue50',
//           },
//         },
//       },
//       sizes: {},
//     },
//     TableHead: {
//       baseStyle: {
//         borderBottom: 'default',
//         background: 'colors.tint2',
//         height: '56px',
//         fontSize: 'fontSizes.1',
//         fontWeight: 'fontWeights.bold',
//         lineHeight: 'lineHeights.0',
//         letterSpacing: 'letterSpacings.normal',
//         fontFamily: 'fontFamilies.ui',
//         color: 'colors.muted',
//         textTransform: 'uppercase',
//       },
//       appearances: {},
//       sizes: {},
//     },
//     TableRow: {
//       baseStyle: {
//         outline: 'none',
//         textDecoration: 'none',
//         height: 64,
//         _isSelectable: {
//           cursor: 'pointer',
//         },
//       },
//       appearances: {
//         default: {
//           _hover: {},
//           _focus: {},
//           _active: {},
//           _current: {},
//         },
//       },
//     },
//     TagInput: {
//       baseStyle: {
//         paddingY: '2px',
//         backgroundColor: 'white',
//         borderRadius: 'radii.1',
//       },
//       appearances: {
//         default: {
//           _focused: {
//             outline: 'none',
//             zIndex: 'zIndices.focused',
//             transition: 'box-shadow 80ms ease-in-out',
//             boxShadow: 'shadows.focusRing',
//           },
//           _disabled: {
//             cursor: 'not-allowed',
//             backgroundColor: 'colors.gray100',
//           },
//         },
//       },
//       sizes: {},
//     },
//     Text: {
//       baseStyle: {},
//       appearances: {},
//       sizes: {
//         '300': {
//           fontSize: 'fontSizes.1',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//         },
//         '400': {
//           fontSize: 'fontSizes.2',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.2',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         '500': {
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.2',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         '600': {
//           fontSize: 'fontSizes.4',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.3',
//           letterSpacing: 'letterSpacings.tighter',
//         },
//         small: {
//           fontSize: 'fontSizes.1',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.0',
//           letterSpacing: 'letterSpacings.normal',
//         },
//         medium: {
//           fontSize: 'fontSizes.2',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.2',
//           letterSpacing: 'letterSpacings.tight',
//         },
//         large: {
//           fontSize: 'fontSizes.3',
//           fontWeight: 'fontWeights.normal',
//           lineHeight: 'lineHeights.2',
//           letterSpacing: 'letterSpacings.tight',
//         },
//       },
//     },
//     TextDropdownButton: {
//       baseStyle: {
//         fontFamily: 'fontFamilies.ui',
//         backgroundColor: 'transparent',
//         borderRadius: 'radii.1',
//         paddingX: 4,
//         marginX: -4,
//         paddingY: 2,
//         marginY: -2,
//         color: 'colors.default',
//         _disabled: {
//           cursor: 'not-allowed',
//           pointerEvents: 'none',
//         },
//         _focus: {
//           boxShadow: 'shadows.focusRing',
//         },
//       },
//       appearances: {},
//       sizes: {
//         small: {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//         },
//         medium: {
//           fontSize: 'fontSizes.1',
//           lineHeight: 'lineHeights.0',
//         },
//         large: {
//           fontSize: 'fontSizes.2',
//           lineHeight: 'lineHeights.2',
//         },
//       },
//     },
//     Tooltip: {
//       baseStyle: {
//         paddingY: 8,
//         paddingX: 16,
//         maxWidth: 240,
//         borderRadius: 'radii.2',
//         elevation: 'shadows.3',
//       },
//       appearances: {
//         card: {
//           backgroundColor: 'white',
//         },
//         default: {
//           color: 'white',
//           backgroundColor: 'colors.gray800',
//         },
//       },
//       sizes: {},
//     },
//   },
// };
