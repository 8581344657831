import styled from 'styled-components';

import { motion, AnimatePresence, MotionProps } from 'framer-motion';

import { pxToRem } from 'theme/helpers';

import Card from 'components/atoms/card';
import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';
import { Portal } from 'evergreen-ui';

export type CanvasSidePanelProps = {
  children?: React.ReactNode;
  active?: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  subTitle?: string;
  className?: string;
} & MotionProps;

const StyledCanvasSidePanel = styled(motion.div)<{ active?: boolean }>`
  width: ${({ theme }) => theme.pxToRem(360)};
  position: fixed;
  top: ${({ theme }) => theme.pxToRem(32)};
  right: ${({ theme, active }) =>
    active ? theme.pxToRem(24) : theme.pxToRem(-400)};
  bottom: ${({ theme }) => theme.pxToRem(32)};
  z-index: 999;

  & .card {
    width: 100%;
    height: 100%;
    box-shadow: 0px 4px 10px -10px rgba(193, 196, 214, 0.3);

    & .close-button {
      position: absolute;
      top: ${({ theme }) => theme.pxToRem(20)};
      right: ${({ theme }) => theme.pxToRem(12)};
      width: ${({ theme }) => theme.pxToRem(32)};
      height: ${({ theme }) => theme.pxToRem(32)};
      border-radius: 50%;
      & svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(20)};
        height: ${({ theme }) => theme.pxToRem(20)};
        & path {
          fill: ${({ theme }) => theme.colors.neutral[500]};
        }
      }
    }

    & .content {
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 24,
          right: 24,
          bottom: 32,
        })};

      & > .title {
        padding-right: ${({ theme }) => theme.pxToRem(20)};
        & > p {
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }
    }
  }
`;

const variants = {
  open: {
    right: pxToRem(32),
  },
  closed: {
    right: pxToRem(-400),
  },
};

const CanvasSidePanel: React.FC<CanvasSidePanelProps> = ({
  children,
  active,
  onClose,
  title,
  subTitle,
  ...props
}) => {
  return (
    <Portal>
      <AnimatePresence>
        <div>Test</div>
        {active && (
          <StyledCanvasSidePanel
            variants={variants}
            initial="closed"
            animate={active ? 'open' : 'closed'}
            exit="closed"
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 60,
              mass: 0.5,
              delay: 0,
            }}
            {...props}
          >
            <Card className="card">
              <Button
                aria-label="Close Side Panel Button"
                button-name="close-side-panel-button"
                className="close-button"
                appearance="minimal"
                onClick={(event) => {
                  onClose?.(event);
                }}
              >
                <Icon icon="XIcon" />
              </Button>
              <div className="content">
                <div className="title">
                  <h2 className="H-600">{title}</h2>
                  <p>{subTitle}</p>
                </div>
                {children}
              </div>
            </Card>
          </StyledCanvasSidePanel>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default CanvasSidePanel;
