import styled from 'styled-components';

import Card from 'components/atoms/card';
import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';

import Loading from 'components/atoms/loading/Loading';

export type ConnectDataWidgetProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  connected?: 'true';
  title: string;
  loading?: boolean;
};

const StyledConnectDataWidget = styled(Card)<
  Omit<ConnectDataWidgetProps, 'title'>
>`
  max-width: ${({ theme }) => theme.pxToRem(250)};
  /* TODO use theme to set colour */
  border: ${({ connected, theme }) =>
    connected === 'true'
      ? `2px solid ${theme.colors.secondary['G400']}`
      : `2px solid ${theme.colors.neutral[300]}`};
  background-color: ${({ connected }) =>
    connected === 'true' ? `#F5FBF8` : 'none'};
  padding: ${({ theme }) =>
    theme.pxToRem({
      top: 16,
      left: 16,
      right: 16,
      bottom: 24,
    })};

  & > p {
    color: ${({ theme }) => theme.colors.secondary['G400']};
    margin-bottom: 0;
  }

  & > .heading {
    display: flex;
    line-height: ${({ theme }) => theme.pxToRem(24)};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
    & > .check-circle-icon {
      margin-right: ${({ theme }) => theme.pxToRem(8)};
      color: ${({ theme }) => theme.colors.secondary['G400']};
      width: ${({ theme }) => theme.pxToRem(24)};
      height: ${({ theme }) => theme.pxToRem(24)};
    }
  }
`;

const ConnectDataWidget: React.FC<ConnectDataWidgetProps> = ({
  onClick,
  connected,
  title,
  loading,
  ...props
}) => {
  return (
    <StyledConnectDataWidget connected={connected} {...props}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="heading">
            {connected === 'true' && (
              <Icon icon="CheckCircleIcon" className="check-circle-icon" />
            )}
            <h5 className="H-500">{title}</h5>
          </div>
          {connected === 'true' && <p>Connected</p>}
          {connected !== 'true' && (
            <Button
              onClick={(event) => {
                onClick?.(event);
              }}
              appearance="primary"
            >
              Connect
            </Button>
          )}
        </>
      )}
    </StyledConnectDataWidget>
  );
};

export default ConnectDataWidget;
