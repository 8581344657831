import styled from 'styled-components';

import { Select as EuiSelect, SelectFieldProps } from 'evergreen-ui';

import { FieldError, UseFormRegister, Controller } from 'react-hook-form';

import InputError from 'components/atoms/input-error';

export type SelectProps = {
  values: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
  register?: UseFormRegister<any>;
  name: string;
  required?: boolean;
  error?: FieldError | undefined;
  errorMessage?: FieldError['message'];
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isPlaceholder?: 'true';
  control: any;
} & SelectFieldProps;

const StyledSelect = styled(EuiSelect)`
  margin-bottom: ${({ theme, error }) =>
    error === 'true' ? theme.pxToRem(0) : theme.pxToRem(16)};
  & select {
    background: ${({ theme }) => theme.colors.neutral[0]};
    ${({ theme, isplaceholder, error }) =>
      isplaceholder === 'true'
        ? `
      color: ${theme.colors.neutral[600]};
      border: 1px solid ${
        error === 'true'
          ? theme.colors.secondary['R400']
          : theme.colors.neutral[600]
      };
      `
        : `
      color: ${theme.colors.neutral[800]};
      border: 1px solid ${
        error === 'true'
          ? theme.colors.secondary['R400']
          : theme.colors.neutral[400]
      };
      `}
  }
  & > svg {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    border-radius: ${({ theme }) => theme.pxToRem(4)};
  }
`;

const Select: React.FC<SelectProps> = ({
  values,
  placeholder,
  name,
  onChange,
  error,
  errorMessage,
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ ...field }) => {
        // Remove fieldState and formState from props to avoid React warnings
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fieldState, formState, ...fieldProps } = field;
        return (
          <>
            <StyledSelect
              {...props}
              {...fieldProps}
              value={field.field.value}
              error={error ? 'true' : 'false'}
              isplaceholder={
                field.field.value === 'placeholder' ? 'true' : 'false'
              }
              onChange={(event: React.ChangeEvent<HTMLSelectElement>): void => {
                field.field.onChange(event);
                onChange?.(event);
              }}
            >
              {placeholder && (
                <option value="placeholder">{placeholder}</option>
              )}
              {values?.map(({ value, label }, index) => {
                return (
                  <option
                    key={`${index}-${value}`}
                    data-testid={`${index}-${value}`}
                    value={value}
                  >
                    {label}
                  </option>
                );
              })}
            </StyledSelect>
            {error && <InputError>{errorMessage}</InputError>}
          </>
        );
      }}
    />
  );
};

export default Select;
