import React, { HTMLAttributes, useState, useEffect } from 'react';

import styled from 'styled-components';

import { IconParkIcon } from 'components/atoms/icon';
import DotsScaleLoader from '../dots-scale-loader';
import ShieldTransformationLoader from '../shield-transformation-loader';

import { Popover, Position } from 'evergreen-ui';

export type HonuSuggestionsCtaProps = {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  popUpInfo?: string;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledHonuSuggestionsCta = styled.div`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(72)};
  height: ${({ theme }) => theme.pxToRem(72)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & .default-button-span {
      background: linear-gradient(
        90.92deg,
        #4762b5 0.79%,
        #688bf6 47.65%,
        #1a7ae6 99.24%
      );
      box-shadow: 0px 2px 0px 0px #4762b5;
    }

    & .turtle-loader {
      display: none;
    }
  }
  .spinner {
    border-top: 3px solid ${({ theme }) => theme.colors.primary['BLUE']};
    border-right: 3px solid transparent;
    border-radius: 50%;
    animation: rotation 0.8s linear infinite;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  & .shield-loader {
    position: absolute;
    top: 3px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    cursor: pointer;

    & > svg {
      & path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
        stroke: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }

  & .turtle-loader {
    position: absolute;
    width: ${({ theme }) => theme.pxToRem(64)};
    height: ${({ theme }) => theme.pxToRem(64)};
    z-index: 19;
    cursor: pointer;

    & > svg {
      & path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
        stroke: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }
`;

const DefaultButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.pxToRem(58)};
  height: ${({ theme }) => theme.pxToRem(58)};
  border: none;
  outline: none;
  background: transparent
    linear-gradient(180deg, #f8f7f8 0%, #ececef 30%, #5386f1 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 50%;
  background-color: #8f95b2;
  cursor: pointer;

  &:hover {
    background: transparent
      linear-gradient(180deg, #f8f7f8 0%, #ececef 30%, #5386f1 100%) 0% 0%
      no-repeat padding-box;
  }

  & > span {
    display: block;
    width: ${({ theme }) => theme.pxToRem(48)};
    height: ${({ theme }) => theme.pxToRem(48)};
    border-radius: 50%;
    background-image: ${({ theme }) => theme.colors.primary['N800']};
    box-shadow: 0px 2px 0px 0px #678af5;
  }
`;

const ActiveButton = styled.button<{ showPopUp?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.pxToRem(72)};
  height: ${({ theme }) => theme.pxToRem(72)};
  border: none;
  outline: none;
  background: transparent
    linear-gradient(180deg, #f8f7f8 0%, #ececef 30%, #5386f1 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 50%;
  cursor: pointer;
  animation: ${({ showPopUp }) => (showPopUp ? `pulse 2s infinite` : 'none')};

  &:hover {
    & > span {
      background: linear-gradient(
        90.92deg,
        #4762b5 0.79%,
        #688bf6 47.65%,
        #1a7ae6 99.24%
      );
      box-shadow: 0px 2px 0px 0px #4762b5;

      & .close-icon {
        display: block;
      }
    }
  }

  & > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.pxToRem(62)};
    height: ${({ theme }) => theme.pxToRem(62)};
    border-radius: 50%;
    background-image: ${({ theme }) => theme.colors.primary['N800']};
    box-shadow: 0px 2px 0px 0px #678af5;
    & > .close-icon {
      display: none;
      width: ${({ theme }) => theme.pxToRem(38)};
      height: ${({ theme }) => theme.pxToRem(38)};
      & svg {
        color: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }

  /* Animations */
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 1);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 16px rgba(96, 172, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(96, 172, 255, 0);
    }
  }
`;

const PopUpCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.pxToRem(290)};
  height: 100%;
  padding: ${({ theme }) => theme.pxToRem(32)};

  background: transparent
    linear-gradient(
      270deg,
      rgba(114, 196, 227, 1) 0%,
      rgba(103, 138, 245, 1) 100%
    )
    0% 0% no-repeat padding-box;

  & > p {
    color: ${({ theme }) => theme.colors.neutral[0]};
    margin-bottom: ${({ theme }) => theme.pxToRem(18)};
  }

  & .button-container {
    & > button {
      width: fit-content;
      border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
      outline: none;
      background-color: transparent;
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      color: ${({ theme }) => theme.colors.neutral[0]};
      cursor: pointer;
      margin-right: ${({ theme }) => theme.pxToRem(16)};
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 12,
          left: 24,
          right: 24,
          bottom: 12,
        })};
    }
  }
`;

const HonuSuggestionsCta: React.FC<HonuSuggestionsCtaProps> = ({
  disabled,
  active,
  onClick,
  popUpInfo,
  ...props
}) => {
  const objectivesIntelligenceIndicator = window.localStorage.getItem(
    'objectivesIntelligenceIndicator'
  );
  const [showPopUp, setShowPopUp] = useState(
    objectivesIntelligenceIndicator === 'off' ? false : active
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (active) {
      setLoading(false);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [active]);

  useEffect(() => {
    if (objectivesIntelligenceIndicator !== 'off') {
      setShowPopUp(active);
    }
  }, [active, setShowPopUp, objectivesIntelligenceIndicator]);

  return (
    <Popover
      position={Position.BOTTOM_RIGHT}
      display="false"
      statelessProps={{
        borderRadius: 16,
        boxShadow: `0px 3px 6px #00000029`,
        'aria-label': 'Honu Intelligence Popup',
      }}
      isShown={showPopUp}
      content={
        <PopUpCard>
          <p className="large-text">{popUpInfo}</p>
          <div className="button-container">
            <button
              onClick={() => {
                setShowPopUp(false);
              }}
            >
              Close
            </button>
            <button
              onClick={() => {
                window.localStorage.setItem(
                  'objectivesIntelligenceIndicator',
                  'off'
                );
                setShowPopUp(false);
              }}
            >
              Don't Show
            </button>
          </div>
        </PopUpCard>
      }
    >
      <StyledHonuSuggestionsCta {...props}>
        {active ? (
          <DotsScaleLoader className="turtle-loader" onClick={onClick} />
        ) : (
          <ShieldTransformationLoader
            className="shield-loader"
            onClick={onClick}
          />
        )}
        {loading && <div className="spinner" />}
        {active ? (
          <ActiveButton
            disabled={disabled}
            aria-label="Honu Suggestions Button"
            onClick={onClick}
            showPopUp={showPopUp}
          >
            <span>
              <IconParkIcon
                icon="Close"
                className="close-icon"
                variant="outline"
              />
            </span>
          </ActiveButton>
        ) : (
          <DefaultButton
            disabled={disabled || loading}
            aria-label="Honu Suggestions Button"
            onClick={onClick}
          >
            <span className="default-button-span"></span>
          </DefaultButton>
        )}
      </StyledHonuSuggestionsCta>
    </Popover>
  );
};

export default HonuSuggestionsCta;
