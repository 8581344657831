import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';
import { KpiType } from '../shared-types';

type BusinessState = {
  current: number;
  forecasted: number;
  is_percentage: boolean;
};

export const GET_SIMULATION_KPIS_QUERY = 'GET_SIMULATION_KPIS_QUERY';

export const getSimulationKpis = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
        actions?: number[];
      }
    ]
  >;
}): Promise<{
  sales_and_marketing: KpiType[];
  finance: KpiType[];
  operations: KpiType[];
  state_of_business: {
    revenues: BusinessState;
    profitability: BusinessState;
    solvency: BusinessState;
  };
  all_metrics: {
    current_values: {
      aov: number;
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
    default_revenues: number[];
    simulated_revenues?: number[];
    default_forecast: {
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
    simulated_forecast: {
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
  };
}> => {
  const { getAccessTokenSilently, actions } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${
      process.env.REACT_APP_API_GATEWAY_VERSION
    }/reasoning/api/kpis/simulation/actions${
      Array.isArray(actions) && actions.length > 0
        ? `?actions=${actions.join(',')}`
        : ''
    }`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (!response.ok) {
    throw Error('Network Error');
  }

  const {
    data: { kpis, all_metrics, state_of_business },
  } = await response.json();

  return { ...kpis, all_metrics, state_of_business };
};
