import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartArea,
  ScatterDataPoint,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from 'context';

import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import theme from 'theme';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const axesOptions = {
  grid: {
    display: false,
    drawBorder: false,
  },
  ticks: {
    padding: 44,
    color: theme.colors.neutral[600],
    min: 100,
  },
};

export const createGradient = (
  ctx: CanvasRenderingContext2D,
  area: ChartArea,
  colorStops: string[]
) => {
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);
  const step = 1 / (colorStops.length - 1);
  colorStops.forEach((color, index) => {
    gradient.addColorStop(Math.round(index * step * 100) / 100, color);
  });

  return gradient;
};

const setColor = (context: any, dataset: { color: string | string[] }) => {
  const chart = context.chart;
  const { ctx, chartArea } = chart;
  if (!chartArea) {
    // This case happens on initial chart load
    return;
  }

  if (Array.isArray(dataset.color)) {
    return createGradient(ctx, chartArea, dataset.color);
  }
  return dataset.color;
};

export type LineChartProps = {
  labels: string[];
  datasets: {
    label: string;
    data: (number | ScatterDataPoint | null)[];
    color: string | string[];
  }[];
};

const LineChart: React.FC<LineChartProps> = ({ labels, datasets }) => {
  const { theme } = useTheme();
  const options = {
    responsive: true,
    scales: {
      x: axesOptions,
      y: axesOptions,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        position: 'top' as const,
        labels: {
          color:
            theme.mode === 'light'
              ? theme.colors.neutral[800]
              : theme.colors.neutral[0],
          font: {
            size: 12,
            family: '"Poppins", sans-serif',
            weight: 'normal',
            lineHeight: 1.33,
          },
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      title: {
        display: false,
        text: 'Line Chart',
        align: 'start' as const,
        color: theme.colors.neutral[900],
        font: {
          size: 16,
          family: '"Poppins", sans-serif',
          weight: '600',
          lineHeight: 1,
        },
      },
    },
  };
  const chartData = useMemo(
    () => ({
      labels,
      datasets: datasets.map((dataset) => ({
        label: dataset.label,
        data: dataset.data,
        tension: 0.4,
        pointBorderWidth: 1,
        lineBorderWidth: 3,
        borderColor: (context: any) => setColor(context, dataset),
        backgroundColor: (context: any) => setColor(context, dataset),
      })),
    }),
    [labels, datasets]
  );

  return <Line data={chartData} options={options} />;
};

export default LineChart;
