import { useEffect } from 'react';

import { useUser } from 'context';

import { Navigate } from 'react-router-dom';
import { useCreateUserMutation } from 'api-client/mutations';

import LoadingOverlay from 'components/molecules/loading-overlay';

const CreateUser = () => {
  const { data, isLoading, isError, mutate } = useCreateUserMutation();
  const { notFound } = useUser();

  useEffect(() => {
    if (notFound) {
      mutate();
    }
  }, [mutate, notFound]);

  if (!notFound) {
    return <Navigate to="/" />;
  }

  if (isError) {
    return <Navigate to="/error" />;
  }

  if (isLoading) {
    return <LoadingOverlay title="Creating User..." />;
  }

  if (data) {
    return <Navigate to="/" />;
  }

  return null;
};

export default CreateUser;
