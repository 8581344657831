import React from 'react';
import { ButtonHTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import Icon from '../icon';

export type AddSoftwareCardProps = {
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | RefObject<HTMLButtonElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'value' | 'ref'
>;

const StyledAddSoftwareCard = styled.button`
  width: ${({ theme }) => theme.pxToRem(120)};
  height: ${({ theme }) => theme.pxToRem(144)};
  cursor: pointer;
  background: transparent;
  border: unset;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.pxToRem(8)};

  & div {
    padding: ${({ theme }) => theme.pxToRem(10)};
    width: ${({ theme }) => theme.pxToRem(44)};
    height: ${({ theme }) => theme.pxToRem(44)};
    background: ${({ theme }) => theme.colors.blue50};
    border-radius: 50%;
    box-sizing: border-box;

    & div {
      width: 100%;
      height: 100%;
      padding: ${({ theme }) => theme.pxToRem(5)};
      box-sizing: border-box;

      display: flex;
      align-items: center;

      background: ${({ theme }) => theme.colors.tertiary['B200']};
      border-radius: 50%;

      & svg {
        width: 100%;
        height: auto;

        & path {
          stroke-width: 1.5;
          stroke: #60acff;
        }
        color: #60acff;
      }
    }
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.neutral[50]};
  }
  &:active {
    background: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

const AddSoftwareCard: React.FC<AddSoftwareCardProps> =
  React.forwardRef<HTMLButtonElement>((props, ref) => {
    return (
      <StyledAddSoftwareCard {...props} ref={ref} aria-label="Add">
        <div>
          <div>
            <Icon icon="PlusIcon" />
          </div>
        </div>
      </StyledAddSoftwareCard>
    );
  });
AddSoftwareCard.displayName = 'AddSoftwareCard';

export default AddSoftwareCard;
