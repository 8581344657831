import React from 'react';
import styled from 'styled-components';

import Icon from 'components/atoms/icon/Icon';
import TemplateCardOption from 'components/atoms/template-card-option';
import OnboardingNav from 'components/molecules/onboarding-nav';
import TemplateCard from 'components/atoms/template-card';
import Tooltip from 'components/atoms/tooltip';

import { templateData } from './templateData';
import { useNavigate } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { updateBluePrintTemplate } from 'pages/business-configuration/actions';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  & .cardsContainer {
    position: absolute;
    top: ${({ theme }) => `calc(50% - ${theme.pxToRem(200)})`};
    left: 50%;
    transform: translate(-50%, 0);

    display: flex;
  }

  & .templateCard {
    margin: ${({ theme }) => theme.pxToRem(12)};
    position: relative;
    & .templateOption {
      margin: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  & .bottomNavigation {
    position: absolute;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const BusinessConfiguration = () => {
  const {
    state: { bluePrintTemplateStore },
    actions,
  } = useStateMachine({ updateBluePrintTemplate });
  const navigate = useNavigate();

  const handleClickTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    templateId: string
  ) => {
    if (bluePrintTemplateStore?.templateId) {
      actions.updateBluePrintTemplate({
        templateId: undefined,
        selectedOptions: [],
      });
      return;
    }

    actions.updateBluePrintTemplate({
      ...bluePrintTemplateStore,
      templateId,
      ...(bluePrintTemplateStore?.templateId !== templateId
        ? { selectedOptions: [] }
        : {}),
    });
  };

  const handleChangeOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionId: string
  ) => {
    actions.updateBluePrintTemplate({
      ...bluePrintTemplateStore,
      selectedOptions: bluePrintTemplateStore?.selectedOptions?.includes(
        optionId
      )
        ? bluePrintTemplateStore?.selectedOptions.filter(
            (option) => option !== optionId
          )
        : [
            ...(bluePrintTemplateStore?.selectedOptions
              ? bluePrintTemplateStore.selectedOptions
              : []),
            optionId,
          ],
    });
  };

  return (
    <PageContainer>
      <div className="cardsContainer">
        {templateData.map(({ id, title, description, icon, options }) => (
          <Tooltip
            key={id}
            content="Comming Soon"
            position="top"
            disable={id === 'ecommerce'}
          >
            <div className="templateCard">
              <TemplateCard
                title={title}
                description={description}
                icon={icon}
                active={bluePrintTemplateStore?.templateId === id}
                onClick={(event) => handleClickTemplate(event, id)}
                disabled={options.length == 0}
                data-testid={`templateCard-${id}`}
              />

              {bluePrintTemplateStore?.templateId === id &&
                options.map((option) => (
                  <TemplateCardOption
                    key={option.id}
                    onChange={(event) => handleChangeOption(event, option.id)}
                    checked={bluePrintTemplateStore?.selectedOptions?.includes(
                      option.id
                    )}
                    className="templateOption"
                    data-testid={`templateOption-${option.id}`}
                  >
                    <Icon icon={option.icon} variant="outline" />
                    {option.title}
                  </TemplateCardOption>
                ))}
            </div>
          </Tooltip>
        ))}
      </div>

      <OnboardingNav
        className="bottomNavigation"
        timeSinceLastSave={600000}
        onClickNext={() => {
          console.log('Next', { bluePrintTemplateStore });
          localStorage.setItem(
            'onboarding',
            '/business-configuration/business-blueprint'
          );
          navigate('/business-configuration/business-blueprint');
        }}
        isNextDisabled={!bluePrintTemplateStore?.selectedOptions?.length}
        onClickBack={() => {
          navigate('/account-settings/business-info');
        }}
      />
    </PageContainer>
  );
};

export default BusinessConfiguration;
