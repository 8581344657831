import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';

import { FunnelCellData, KpiType } from 'api-client/queries';

export const GET_SIMULATION_KPI_QUERY = 'GET_SIMULATION_KPI_QUERY';

type BusinessState = {
  current: number;
  forecasted: number;
  is_percentage: boolean;
};

export const getSimulationKpi = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
        kpiKey?: string;
        change?: number;
        delay?: number;
      }
    ]
  >;
}): Promise<{
  kpis: {
    sales_and_marketing: KpiType[];
    finance: KpiType[];
    operations: KpiType[];
  };
  state_of_business: {
    revenues: BusinessState;
    profitability: BusinessState;
    solvency: BusinessState;
  };
  all_metrics: {
    default_revenues: number[];
    simulated_revenues?: number[];
    current_values: {
      aov: number;
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
    default_forecast: {
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
    simulated_forecast: {
      revenues?: number;
      solvency?: number;
      profitability?: number;
    };
  };
  funnels: {
    sales: {
      website_visits: FunnelCellData;
      added_to_cart: FunnelCellData;
      visited_checkout: FunnelCellData;
      made_a_purchase: FunnelCellData;
      repeat_customers: FunnelCellData;
    };
  };
}> => {
  const { getAccessTokenSilently, kpiKey, change, delay } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();

  return new Promise((resolve) => setTimeout(resolve, delay || 0)).then(
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/${
          process.env.REACT_APP_API_GATEWAY_VERSION
        }/reasoning/api/kpis/simulation?kpi=${kpiKey}${
          change ? `&change=${change}` : ''
        }`,
        {
          method: 'GET',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        }
      );

      if (!response.ok) {
        throw Error('Network Error');
      }

      const { data } = await response.json();

      return data;
    }
  );
};
