import { HTMLAttributes } from 'react';

import styled from 'styled-components';

import { Handle, HandleProps } from 'react-flow-renderer';
export type BusinessModelHandleProps = {
  children?: React.ReactNode;
} & HandleProps &
  Omit<
    React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'value' | 'ref'
  >;

const StyledBusinessModelHandle = styled(Handle)`
  background: ${({ theme }) => theme.colors.primary['BLUE']};
  z-index: 1;
  cursor: default !important;
`;

const BusinessModelHandle: React.FC<BusinessModelHandleProps> = ({
  ...props
}) => {
  return <StyledBusinessModelHandle {...props} />;
};

export default BusinessModelHandle;
