import styled from 'styled-components';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Control, Controller, FieldError } from 'react-hook-form';
import InputError from 'components/atoms/input-error';

export type DatePickerProps = {
  name: string;
  control: Control<any>;
  error?: FieldError;
  onChange?: (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
  'data-testid'?: string;
} & Omit<ReactDatePickerProps, 'onChange'>;

const StyledDatePicker = styled.div<Pick<DatePickerProps, 'error'>>`
  & .react-datepicker {
    border: ${({ theme }) => `1px solid ${theme.colors.neutral[500]}`};

    &__input-container {
      & > input {
        border: ${({ theme, error }) =>
          error
            ? `1px solid ${theme.colors.secondary['R400']}`
            : `1px solid ${theme.colors.neutral[400]}`};
        padding: ${({ theme }) => theme.pxToRem(12)};
        border-radius: ${({ theme }) => theme.pxToRem(8)};

        /* Input text styles (label and input) */
        font-weight: 400;
        font-size: ${({ theme }) => theme.pxToRem(16)};
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.neutral[800]};

        &::placeholder {
          color: ${({ theme }) => theme.colors.neutral[600]};
        }

        &:hover {
          &::placeholder {
            color: ${({ theme }) => theme.colors.neutral[700]};
          }
        }

        &:focus {
          outline: none;
          &::placeholder {
            color: ${({ theme }) => theme.colors.neutral[700]};
          }
        }

        &:disabled {
          background: ${({ theme }) => theme.colors.neutral[100]};
          border: ${({ theme }) => `1px solid ${theme.colors.neutral[300]}`};
          &::placeholder {
            color: ${({ theme }) => theme.colors.neutral[600]};
          }
        }
      }
    }

    &__header {
      background-color: ${({ theme }) => theme.colors.neutral[200]};
    }

    &__current-month {
      color: ${({ theme }) => theme.colors.neutral[800]};
    }

    &__day-names {
      color: ${({ theme }) => theme.colors.neutral[800]};
    }

    &__triangle {
      left: 50% !important;
      transform: translate(50%, 0) !important;
    }

    &__day {
      color: ${({ theme }) => theme.colors.neutral[800]};
      &--selected {
        color: ${({ theme }) => theme.colors.neutral[0]};
        background: ${({ theme }) => theme.colors.primary['B400']};
      }

      &--keyboard-selected {
        color: ${({ theme }) => theme.colors.neutral[0]};
        background: ${({ theme }) => theme.colors.primary['B400']};
      }
    }

    &__header__dropdown {
      margin-top: ${({ theme }) => theme.pxToRem(8)};
    }

    &__month-select {
      border: none;
      padding: ${({ theme }) => theme.pxToRem(4)};
      border-radius: ${({ theme }) => theme.pxToRem(4)};
      color: ${({ theme }) => theme.colors.neutral[800]};
    }
    &__year-select {
      border: none;
      padding: ${({ theme }) => theme.pxToRem(4)};
      border-radius: ${({ theme }) => theme.pxToRem(4)};
      color: ${({ theme }) => theme.colors.neutral[800]};
    }
  }
`;

const DatePicker: React.FC<DatePickerProps> = ({ ...props }) => {
  if (props?.control) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        render={({ ...field }) => {
          // Remove fieldState and formState from props to avoid React warnings
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { fieldState, formState, ...fieldProps } = field;
          const { error } = props;

          return (
            <>
              <StyledDatePicker
                error={props?.error}
                data-testid={props['data-testid']}
                className={props.className}
              >
                <ReactDatePicker
                  {...props}
                  {...fieldProps}
                  selected={field.field.value}
                  onChange={(date, event) => {
                    field.field.onChange?.(date);
                    props?.onChange?.(date, event);
                  }}
                />
              </StyledDatePicker>
              {error && <InputError>{error.message}</InputError>}
            </>
          );
        }}
      />
    );
  }
  return (
    <StyledDatePicker error={props?.error}>
      <ReactDatePicker
        {...props}
        onChange={(date, event) => {
          props?.onChange?.(date, event);
        }}
      />
    </StyledDatePicker>
  );
};

export default DatePicker;
