import styled from 'styled-components';

import Card from 'components/atoms/card';
import Icon from 'components/atoms/icon';

export type BusinessGoalCardProps = {
  children?: React.ReactNode;
  title?: string;
  selected?: boolean;
  locked?: boolean;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledBusinessGoalCard = styled.div<{
  selected?: boolean;
  locked?: boolean;
}>`
  & .business-goal-card {
    width: 100%;
    height: 100%;
    min-height: ${({ theme }) => theme.pxToRem(56)};
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
    cursor: ${({ locked }) => (locked ? 'not-allowed' : 'pointer')};

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 16,
          left: 24,
          right: 24,
          bottom: 16,
        })};
      & div:nth-child(1) {
        & h3 {
          font-weight: 500;
          color: ${({ theme, selected, locked }) =>
            selected || locked
              ? theme.colors.neutral[900]
              : theme.colors.neutral[500]};
        }
      }

      & div:nth-child(2) {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        & > svg {
          width: ${({ theme }) => theme.pxToRem(24)};
          height: ${({ theme }) => theme.pxToRem(24)};
          fill: ${({ theme, selected, locked }) =>
            selected || locked
              ? theme.colors.secondary['B400']
              : theme.colors.neutral[500]};
        }
      }
    }
  }
`;

const BusinessGoalCard: React.FC<BusinessGoalCardProps> = ({
  title,
  selected,
  locked,
  ...props
}) => {
  return (
    <StyledBusinessGoalCard selected={selected} locked={locked} {...props}>
      <Card className="business-goal-card">
        <div className="business-goal-card__content">
          <div>
            <h3 className="H-500">{title}</h3>
          </div>
          <div>
            <Icon icon={locked ? 'LockClosedIcon' : 'CheckCircleIcon'} />
          </div>
        </div>
      </Card>
    </StyledBusinessGoalCard>
  );
};

export default BusinessGoalCard;
