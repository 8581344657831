import React from 'react';
import styled from 'styled-components';

import { NestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { updateGoalsInfo } from 'pages/account-settings/actions';
import {
  goalsAspirations,
  goalsBusinessPerformance,
  GoalsInfoState,
  goalsMainConcerns,
  goalsOtherConsiderations,
  goalsYearlyRevenue,
} from 'pages/account-settings/StateMachine.types';
import { useNavigate } from 'react-router-dom';

import RadioButton from 'components/molecules/radio-button';
import InputWrapper from 'components/molecules/input-wrapper';
import Select from 'components/molecules/select';
import TagInput from 'components/molecules/tag-input';
import OnboardingNav from 'components/molecules/onboarding-nav';

const GoalsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & .form {
    width: ${({ theme }) => theme.pxToRem(561)};
  }

  & .input {
    width: 100%;
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const goalsInfoSchema = yup.object({
  aspirations: yup.string().oneOf(
    goalsAspirations.map((cost) => cost.value),
    'Please select one'
  ),
  mainConcerns: yup.array().min(1, 'Please select at least one'),
  businessPerformance: yup.string().oneOf(
    goalsBusinessPerformance.map((cost) => cost.value),
    'Please select one'
  ),
  yearlyRevenue: yup.string().oneOf(
    goalsYearlyRevenue.map((cost) => cost.value),
    'Please select one'
  ),
  otherConsiderations: yup.string().oneOf(
    goalsOtherConsiderations.map((cost) => cost.value),
    'Please select one'
  ),
});

const GoalsInfo = () => {
  const {
    state: { goalsInfoStore },
    actions,
  } = useStateMachine({ updateGoalsInfo });
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<
    Omit<GoalsInfoState, 'mainConcerns'> & {
      mainConcerns: NestedValue<typeof goalsMainConcerns>;
    }
  >({
    resolver: yupResolver<yup.AnyObjectSchema>(goalsInfoSchema) as any,
    defaultValues: {
      ...goalsInfoStore,
    },
  });

  const selectedMainConcerns = watch('mainConcerns');
  const autoCompleteSources = React.useMemo(
    () =>
      goalsMainConcerns.filter(
        (source) => !selectedMainConcerns.includes(source)
      ),
    [selectedMainConcerns]
  );

  return (
    <GoalsInfoContainer>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          actions.updateGoalsInfo(data);
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/risk-info'
          );
          navigate('/account-settings/risk-info');
        })}
      >
        <InputWrapper label="What aspiration do you have for your business?">
          <Select
            data-testid="aspirations-input"
            className="input"
            placeholder="Select One"
            name="aspirations"
            control={control}
            error={errors['aspirations']}
            errorMessage={errors['aspirations']?.message}
            values={goalsAspirations}
          />
        </InputWrapper>

        <InputWrapper label="What are your main concerns about the business?">
          <TagInput
            data-testid="mainConcerns-input"
            className="input"
            inputProps={{
              placeholder: 'Please select',
            }}
            name="mainConcerns"
            control={control}
            error={errors['mainConcerns']}
            errorMessage={errors['mainConcerns']?.message}
            autocompleteItems={autoCompleteSources}
          />
        </InputWrapper>

        <InputWrapper label="How do you perceive your business to be doing?">
          <Select
            data-testid="businessPerformance-input"
            className="input"
            placeholder="Select One"
            name="businessPerformance"
            control={control}
            error={errors['businessPerformance']}
            errorMessage={errors['businessPerformance']?.message}
            values={goalsBusinessPerformance}
          />
        </InputWrapper>

        <InputWrapper label="How much yearly revenue do you aspire to make?">
          <Select
            data-testid="yearlyRevenue-input"
            className="input"
            placeholder="Select One"
            name="yearlyRevenue"
            control={control}
            error={errors['yearlyRevenue']}
            errorMessage={errors['yearlyRevenue']?.message}
            values={goalsYearlyRevenue}
          />
        </InputWrapper>

        <InputWrapper label="Are there any considerations, besides financials, that are important to you?">
          <Select
            data-testid="otherConsiderations-input"
            className="input"
            placeholder="Select One"
            name="otherConsiderations"
            control={control}
            error={errors['otherConsiderations']}
            errorMessage={errors['otherConsiderations']?.message}
            values={goalsOtherConsiderations}
          />
        </InputWrapper>

        <InputWrapper label="Are you planning on selling new items/products?">
          <RadioButton
            data-testid="sellNewItems-input"
            name="sellNewItems"
            control={control}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </InputWrapper>

        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/account-settings/acuity-info');
          }}
        />
      </form>
    </GoalsInfoContainer>
  );
};

export default GoalsInfo;
