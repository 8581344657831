import { useState } from 'react';
import styled from 'styled-components';

import { DragDropContext } from 'react-beautiful-dnd';

import StoreFrontDroppableNode from 'components/molecules/store-front-droppable-node';
import StoreFrontSidePanel from 'components/molecules/store-front-side-panel';

import { StoreFrontItemType } from 'pages/business-configuration/nested-routes/business-blueprint/config';
import { useCanvas } from 'pages/business-configuration/nested-routes/business-blueprint/context';
import { useReactFlow, useStore } from 'react-flow-renderer';

import { customerJourneyChildNodes } from 'pages/business-configuration/nested-routes/business-blueprint/config/nodes/customerJourney';
import { customerJourneyEdges } from 'pages/business-configuration/nested-routes/business-blueprint/config/edges/customerJourney';
import { fulfillmentMethodNodes } from 'pages/business-configuration/nested-routes/customer-journey/config/nodes';

export type StoreFrontNodeProps = {
  id: string;
  children?: React.ReactNode;
  data: {
    values: StoreFrontItemType[];
  };
  xPos: number;
  yPos: number;
};

const StyledStoreFrontNode = styled.div``;

const StoreFrontNode: React.FC<StoreFrontNodeProps> = ({
  id,
  data,
  xPos,
  yPos,
}) => {
  const { values } = data;
  const [sidePanelActive, setSidePanelActive] = useState(false);
  const { customerJourney, setCanvasState } = useCanvas();
  const [draggableItems, setDraggableItems] = useState(values);
  console.log('CanvasState', customerJourney);
  const { getNodes, getEdges } = useReactFlow();
  const { setNodes, setEdges } = useStore();
  const { setCenter, setViewport } = useReactFlow();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;
        if (destination?.droppableId === source.droppableId) return;

        if (destination?.droppableId.includes('drop')) {
          setDraggableItems((prevState) =>
            prevState.filter(({ id }) => id !== draggableItems[source.index].id)
          );
          setCanvasState({
            type: 'ADD_CUSTOMER_JOURNEY',
            value: {
              id,
            },
          });
          setNodes([
            ...getNodes().filter((node) => node.id !== id),
            ...[
              {
                id: `customer-journey-${id.split('-')[1]}`,
                type: 'CustomerJourneyNode',
                data: {
                  id: draggableItems[source.index].logo,
                  logo: draggableItems[source.index].logo,
                  otherConnections:
                    draggableItems[source.index].values.otherConnections,
                },
                position: { x: xPos, y: yPos },
              },
              ...customerJourneyChildNodes({
                parentNode: `customer-journey-${id.split('-')[1]}`,
              }),
              ...fulfillmentMethodNodes,
            ],
          ]);
          setEdges([...getEdges(), ...customerJourneyEdges]);
          setCenter(xPos + 800, yPos + 400, {
            zoom: 0.6,
            duration: 500,
          });
        }
      }}
    >
      <StyledStoreFrontNode>
        <StoreFrontDroppableNode
          onClick={() => {
            setSidePanelActive(true);
            setCenter(xPos + 294, yPos + 160, {
              zoom: 1.25,
              duration: 500,
            });
          }}
        />
        <StoreFrontSidePanel
          active={sidePanelActive}
          title="Drag Over The E-commerce Storefront"
          draggableItems={draggableItems}
          onClose={() => {
            setSidePanelActive(false);
            setViewport({ x: 0, y: 0, zoom: 1 }, { duration: 500 });
          }}
        />
      </StyledStoreFrontNode>
    </DragDropContext>
  );
};

export default StoreFrontNode;
