import { RefObject, HTMLAttributes } from 'react';

import styled from 'styled-components';

import { IconParkIcon } from 'components/atoms/icon';
import { IconParkIconProps } from 'components/atoms/icon/IconPark';

export type FulfillmentMethodDraggableItemProps = {
  children?: React.ReactNode;
  label: string;
  icon?: IconParkIconProps['icon'];
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  innerRef?: any;
  type?: string;
  isDraggingOver?: string | boolean;
  ref?:
    | ((instance: HTMLElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledFulfillmentMethodDraggableItem = styled.div<
  Pick<FulfillmentMethodDraggableItemProps, 'isDraggingOver'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? `${theme.pxToRem(84)} !important` : '100%'};
  height: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? `${theme.pxToRem(84)} !important` : '100%'};
  border: ${({ theme }) => `1px solid ${theme.colors.neutral[400]}`};
  border-radius: ${({ theme }) => theme.pxToRem(4)};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.tertiary['B50'] : theme.colors.neutral[0]};
  cursor: ${({ isDraggingOver }) => (isDraggingOver ? 'grabbing' : 'grab')};
  position: relative;

  & > p {
    color: ${({ theme, isDraggingOver }) =>
      isDraggingOver ? theme.colors.neutral[800] : theme.colors.neutral[600]};
  }

  & > .icon {
    width: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(40)};
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};

    & svg {
      color: ${({ theme, isDraggingOver }) =>
        isDraggingOver ? theme.colors.neutral[800] : theme.colors.neutral[600]};
    }
  }
`;

const FulfillmentMethodDraggableItem: React.FC<
  FulfillmentMethodDraggableItemProps
> = ({ label, icon, innerRef, isDraggingOver, ...props }) => {
  return (
    <StyledFulfillmentMethodDraggableItem
      {...props}
      ref={innerRef}
      isDraggingOver={isDraggingOver}
    >
      <div className="icon">
        {icon && <IconParkIcon icon={icon} variant="outline" />}
      </div>
      <p>{label}</p>
    </StyledFulfillmentMethodDraggableItem>
  );
};

export default FulfillmentMethodDraggableItem;
