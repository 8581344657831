import colors from 'theme/colors';
import { pxToRem } from 'theme/helpers';

export const tagInput = {
  TagInput: {
    baseStyle: {
      paddingY: pxToRem(7),
      backgroundColor: colors.neutral[0],
      borderRadius: pxToRem(8),
      border: `1px solid ${colors.neutral[300]}`,
    },
    appearances: {
      default: {
        _focused: {
          outline: 'none',
          zIndex: 'zIndices.focused',
          transition: 'box-shadow 80ms ease-in-out',
          border: `1px solid  ${colors.tertiary['B200']}`,
          boxShadow: `0px 0px 0px 2px ${colors.tertiary['B200']}`,
        },
        _disabled: {
          cursor: 'not-allowed',
          backgroundColor: colors.neutral[100],
        },
      },
    },
    sizes: {},
  },
};
