import InputTooltip from 'components/atoms/input-tooltip';
import { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

export type InputWrapperProps = {
  children?: React.ReactNode;
  label?: string;
  description?: string;
  helpText?: string;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledInputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.pxToRem(8)};
  & label {
    & .label-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .label-title {
      color: ${({ theme }) => theme.colors.neutral[800]};
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      margin-bottom: ${({ theme }) => theme.pxToRem(4)};
    }

    & .label-description {
      color: ${({ theme }) => theme.colors.neutral[700]};
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(16)};
      font-weight: 00;
      margin-bottom: ${({ theme }) => theme.pxToRem(8)};
    }
  }
`;

const InputWrapper: React.FC<InputWrapperProps> = ({
  children,
  label,
  description,
  helpText,
  ...props
}) => {
  return (
    <StyledInputWrapper {...props}>
      <label>
        <div className="label-title-container">
          {label && <h5 className="label-title">{label}</h5>}
          {helpText && <InputTooltip content={helpText} />}
        </div>
        {description && <p className="label-description">{description}</p>}
        {children}
      </label>
    </StyledInputWrapper>
  );
};

export default InputWrapper;
