import BusinessObjectiveCard from 'components/atoms/business-objective-card';
import styled from 'styled-components';

export type BusinessGoalsAndObjectivesProps = {
  children?: React.ReactNode;
  variant?: 'profitability' | 'solvency' | 'revenue';
};

const bgColor = {
  profitability: '#f8f7fd',
  solvency: '#fef5fb',
  revenue: '#fdf7f4',
};

const StyledBusinessGoalsAndObjectives = styled.div<{
  variant: 'profitability' | 'solvency' | 'revenue';
}>`
  background-color: ${({ variant }) => bgColor[variant]};
  padding-bottom: ${({ theme }) => theme.pxToRem(24)};
  border-radius: ${({ theme }) => theme.pxToRem(16)};

  & .business-goals-list-container {
    margin-top: ${({ theme }) => theme.pxToRem(16)};
    padding-left: ${({ theme }) => theme.pxToRem(24)};
    padding-right: ${({ theme }) => theme.pxToRem(24)};
  }
`;

const BusinessGoalsAndObjectives: React.FC<BusinessGoalsAndObjectivesProps> = ({
  children,
  variant = 'profitability',
}) => {
  return (
    <StyledBusinessGoalsAndObjectives variant={variant}>
      <BusinessObjectiveCard variant={variant} />
      <div className="business-goals-list-container">{children}</div>
    </StyledBusinessGoalsAndObjectives>
  );
};

export default BusinessGoalsAndObjectives;
