import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { NestedValue, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStateMachine } from 'little-state-machine';

import Button from 'components/atoms/button';
import TextInput from 'components/molecules/text-input';
import InputWrapper from 'components/molecules/input-wrapper';
import ProgressBar from 'components/atoms/progress-bar';

import {
  personalRoles,
  PersonalState,
} from 'pages/account-settings/StateMachine.types';
import TagInput from 'components/molecules/tag-input';
import { updatePersonalDetails } from 'pages/account-settings/actions';

const personalSchema = yup
  .object({
    firstName: yup
      .string()
      .required('First name is required.')
      .matches(/[^-0-9\/]+/, "This field can't contain digits."),
    lastName: yup
      .string()
      .required('Last name is required.')
      .matches(/[^-0-9\/]+/, "This field can't contain digits."),
    email: yup
      .string()
      .required('Email is required.')
      .email('Please enter a valid email address.'),
    role: yup.array().of(yup.string()).min(1, 'Please enter your role.'),
  })
  .required();

const StyledPersonalInfo = styled.div`
  & > form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.pxToRem(32)};

    & .firstName-wrapper {
      width: ${({ theme }) => theme.pxToRem(237)};
    }

    & .lastName-wrapper {
      width: ${({ theme }) => theme.pxToRem(297)};
    }

    & .email-wrapper,
    .role-wrapper {
      width: 100%;
    }

    & .input {
      width: 100%;
    }
  }
`;

const PersonalInfo: React.FC = () => {
  const navigate = useNavigate();
  const {
    state: { personalStore },
    actions,
  } = useStateMachine({ updatePersonalDetails });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<
    Omit<PersonalState, 'role'> & { role: NestedValue<typeof personalRoles> }
  >({
    resolver: yupResolver<yup.AnyObjectSchema>(personalSchema) as any,
    defaultValues: {
      ...personalStore,
    },
  });
  const [selectedRoles, setSelectedRoles] = useState<typeof personalRoles>([]);
  const autocompleteRoles = useMemo(
    () => personalRoles.filter((role) => !selectedRoles.includes(role)),
    [selectedRoles]
  );

  return (
    <StyledPersonalInfo>
      <h3>Enter Your Personal Info</h3>
      <ProgressBar currentStep={1} totalSteps={2} />
      <form
        onSubmit={handleSubmit((data) => {
          actions.updatePersonalDetails(data);
          localStorage.setItem('onboarding', '/account-settings/business-info');
          navigate('/account-settings/business-info');
        })}
      >
        <InputWrapper label="First Name" className="firstName-wrapper">
          <TextInput
            data-testid="firstName-input"
            className="input"
            placeholder="Enter your first name"
            name="firstName"
            control={control}
            error={errors['firstName']}
          />
        </InputWrapper>

        <InputWrapper label="Last Name" className="lastName-wrapper">
          <TextInput
            data-testid="lastName-input"
            className="input"
            placeholder="Enter your last name"
            name="lastName"
            control={control}
            error={errors['lastName']}
          />
        </InputWrapper>

        <InputWrapper label="Email Address" className="email-wrapper">
          <TextInput
            data-testid="email-input"
            className="input"
            placeholder="Enter your email address"
            name="email"
            control={control}
            error={errors['email']}
          />
        </InputWrapper>

        <InputWrapper label="Role in company" className="role-wrapper">
          <TagInput
            data-testid="role-input"
            className="input"
            inputProps={{
              placeholder: 'Enter your role',
            }}
            onChange={(roles) => {
              setSelectedRoles(roles);
            }}
            name="role"
            control={control}
            error={errors['role']}
            errorMessage={errors['role']?.message}
            autocompleteItems={autocompleteRoles}
          />
        </InputWrapper>
        <div className="footer">
          <Button type="submit" appearance="primary">
            Next Step
          </Button>
        </div>
      </form>
    </StyledPersonalInfo>
  );
};

export default PersonalInfo;
