import React, { useState } from 'react';

type GlobalPageTemplateContext = {
  minimizeNavbar: boolean;
  extendSidePanel: boolean;
  setMinimizeNavBard: React.Dispatch<React.SetStateAction<boolean>>;
  setExtendSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  fixContentContainer?: number;
};

export const GlobalPageTemplateContext =
  React.createContext<GlobalPageTemplateContext | null>(null);

export const useGlobalPageTemplate = (): GlobalPageTemplateContext => {
  const globalPageTemplate = React.useContext(GlobalPageTemplateContext);

  if (globalPageTemplate === null) {
    throw new Error(
      `GlobalPageTemplateContext is null. This most probably means that "useGlobalPageTemplate" is being used outside "GlobalPageTemplateProvider"`
    );
  }

  return {
    ...globalPageTemplate,
  };
};

export const GlobalPageTemplateProvider: React.FC<{
  children: React.ReactNode;
  fixContentContainer?: number;
}> = ({ children, fixContentContainer }) => {
  const [minimizeNavbar, setMinimizeNavBard] = useState(false);
  const [extendSidePanel, setExtendSidePanel] = useState(false);

  return (
    <GlobalPageTemplateContext.Provider
      value={{
        minimizeNavbar,
        extendSidePanel,
        fixContentContainer,
        setMinimizeNavBard,
        setExtendSidePanel,
      }}
    >
      {children}
    </GlobalPageTemplateContext.Provider>
  );
};
