import GetUser from './components/GetUser';
import CreateUser from './components/CreateUser';

export type RequireAuthProps = {
  variant?: 'create-user' | 'get-user';
  children?: React.ReactNode;
};

const RequireAuth: React.FC<RequireAuthProps> = ({
  variant = 'get-user',
  ...props
}) => {
  if (variant === 'get-user') return <GetUser {...props} />;

  if (variant === 'create-user') return <CreateUser {...props} />;

  return null;
};

export default RequireAuth;
