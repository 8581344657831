import { useState } from 'react';
import styled from 'styled-components';

import AvatarMenu from 'components/molecules/avatar-menu';
import TextInput from 'components/molecules/text-input';
import InputWrapper from 'components/molecules/input-wrapper';
import Button from 'components/atoms/button';
import TabButton from 'components/atoms/tab-button/TabButton';

import { useUser } from 'context';
import { useForm } from 'react-hook-form';

const PageContainer = styled.div`
  & .page-header {
    & h1 {
      margin-bottom: ${({ theme }) => theme.pxToRem(24)};
    }

    display: flex;
    justify-content: space-between;
  }

  & .page-content {
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    padding-top: ${({ theme }) => theme.pxToRem(16)};

    &__tabs {
      display: flex;
      overflow: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        height: ${({ theme }) => theme.pxToRem(8)};
      }

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.neutral[500]};
        outline: none;
        border-radius: ${({ theme }) => theme.pxToRem(10)};
      }
    }

    &__tab-content {
      max-width: ${({ theme }) => theme.pxToRem(560)};
      margin: ${({ theme }) => theme.pxToRem(48)} auto;

      &--header {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.pxToRem(24)};
      }

      &--content {
        display: flex;
        flex-direction: column;
        padding-bottom: ${({ theme }) => theme.pxToRem(48)};

        & > .save-button {
          align-self: flex-start;
        }
      }
    }
  }
`;

const tabList = [
  {
    label: 'User Info',
    editUrl: '/account-settings/personal-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Business Info',
    editUrl: '/account-settings/business-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Financial Info',
    editUrl: '/account-settings/financial-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Working Capital Info',
    editUrl: '/account-settings/working-capital-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Acuity Info',
    editUrl: '/account-settings/acuity-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Goals Info',
    editUrl: '/account-settings/goals-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Risk Info',
    editUrl: '/account-settings/risk-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
  {
    label: 'Sophistication Info',
    editUrl: '/account-settings/business-sophistication-info',
    //this could be the property from the user object if all data is kept in one place
    apiEndpoint: 'property',
  },
];

const AccountSettings = () => {
  const { name } = useUser();
  const [activeTab, setActiveTab] = useState(tabList[0]);
  const { control } = useForm({
    defaultValues: {
      firstName: 'Joe',
      lastName: 'Dow',
      email: 'joe.dow@test.com',
    },
  });

  return (
    <PageContainer>
      <div className="page-header">
        <h1 className="H-900">Account Settings</h1>
        <AvatarMenu name={name} />
      </div>
      <div className="page-content">
        <div className="page-content__tabs">
          <div>
            {tabList.map(({ label, editUrl, apiEndpoint }) => {
              return (
                <TabButton
                  key={label}
                  active={label === activeTab?.label}
                  onClick={() => {
                    setActiveTab({ label, editUrl, apiEndpoint });
                  }}
                >
                  {label}
                </TabButton>
              );
            })}
          </div>
        </div>
        <div className="page-content__tab-content">
          <div className="page-content__tab-content--header">
            <h2 className="H-700">{activeTab?.label}</h2>
            <Button appearance="primary" iconEnd="PencilIcon">
              Edit
            </Button>
          </div>
          {/* TODO - we need to create an individual
          content component for each section of the account settings
          once the backend get's done */}
          <div className="page-content__tab-content--content">
            <InputWrapper label="First Name" className="firstName-wrapper">
              <TextInput
                data-testid="firstName-input"
                className="input"
                placeholder="Enter your first name"
                name="firstName"
                control={control}
                disabled
              />
            </InputWrapper>
            <InputWrapper label="Last Name" className="lastName-wrapper">
              <TextInput
                data-testid="lastName-input"
                className="input"
                placeholder="Enter your last name"
                name="lastName"
                control={control}
                disabled
              />
            </InputWrapper>
            <InputWrapper label="Email Address" className="email-wrapper">
              <TextInput
                data-testid="email-input"
                className="input"
                placeholder="Enter your email address"
                name="email"
                control={control}
                disabled
              />
            </InputWrapper>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default AccountSettings;
