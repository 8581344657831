import { HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { pxToRem } from 'theme/helpers';

const variants = {
  compact: { width: pxToRem(60) },
  normal: { width: pxToRem(327) },
};

export type FloatingNavVariants = keyof typeof variants;

export type FloatingNavContainerProps = {
  variant: FloatingNavVariants;
} & HTMLMotionProps<'div'>;

const StyledFloatingNavContainer = styled(motion.div)`
  height: ${({ theme }) => theme.pxToRem(392)};
  background: ${({ theme }) => theme.colors.neutral[0]};
  border: ${({ theme }) => `2px sollid ${theme.colors.neutral[0]}`};
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0px ${theme.pxToRem(4)} ${theme.pxToRem(8)} rgba(16, 24, 64, 0.16)`};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
`;

const FloatingNavContainer: React.FC<FloatingNavContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledFloatingNavContainer
      {...props}
      variants={variants}
      animate={props.variant}
      initial={false}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 60,
        mass: 0.1,
        delay: 0,
      }}
    >
      {children}
    </StyledFloatingNavContainer>
  );
};

export default FloatingNavContainer;
