import { useState } from 'react';

import Icon from 'components/atoms/icon';

import { Popover, Position } from 'evergreen-ui';

import styled from 'styled-components';
import IconParkIcon, {
  IconParkIconProps,
} from 'components/atoms/icon/IconPark';

import Menu from 'components/atoms/menu';
import CustomerJourneyHandle from 'components/atoms/customer-journey-handle';

import * as Logos from './logos';

import { Position as FlowPosition, useReactFlow } from 'react-flow-renderer';

export type CustomerJourneyChildNodeProps = {
  id: string;
  data: {
    header: {
      icon: IconParkIconProps['icon'];
      title: string;
    };
    options: {
      id: string;
      menuGroupTitle: string;
      selectedGroupTitle: string;
      options: {
        logo: 'Facebook' | 'Instagram' | 'Linkedin' | 'Mailchimp';
        value: string;
        label: string;
      }[];
    }[];
  };
  onSelect: (itemId: string) => void;
  onRemove: (itemId: string) => void;
};

const StyledMenuItem = styled(Menu.Item)`
  padding-left: ${({ theme }) => theme.pxToRem(0)};
  padding-right: ${({ theme }) => theme.pxToRem(16)};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  & .menu-item-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > .service-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > p {
        color: ${({ theme }) => theme.colors.neutral[600]};
      }

      & > img {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        margin-right: 8px;
      }
    }

    & > .service-icon {
      display: flex;
      align-items: center;

      & > svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(16)};
        height: ${({ theme }) => theme.pxToRem(16)};

        & path {
          fill: ${({ theme }) => theme.colors.secondary['R400']};
        }
      }
    }
  }
`;

const StyledCustomerJourneyChildNode = styled.div<{ buttonActive: boolean }>`
  width: ${({ theme }) => theme.pxToRem(220)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0px ${theme.pxToRem(4)} ${theme.pxToRem(50)} ${theme.pxToRem(
      -10
    )} rgba(193, 196, 214, 0.3)`};
  background: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 0, left: 16, right: 16, bottom: 0 })};
  cursor: default;

  & .header {
    display: flex;
    align-items: center;
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 16, right: 0, bottom: 16, left: 0 })};

    & > h2 {
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[900]};
      margin: ${({ theme }) =>
        theme.pxToRem({ top: 0, right: 8, bottom: 0, left: 8 })};
    }
    & svg {
      padding-top: 4px;
      height: ${({ theme }) => theme.pxToRem(20)};
      width: ${({ theme }) => theme.pxToRem(20)};
      color: ${({ theme }) => theme.colors.neutral[500]};
    }
  }

  & .listItem {
    border-top: ${({ theme }) =>
      `${theme.pxToRem(1)} solid ${theme.colors.neutral[100]}`};
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 12, right: 0, bottom: 12, left: 0 })};
  }

  & .buttonItem {
    width: 100%;
    border: none;
    background: ${({ buttonActive, theme }) =>
      buttonActive ? theme.colors.neutral[100] : 'transparent'};
    z-index: 99;

    padding: ${({ theme }) =>
      theme.pxToRem({ top: 4, right: 4, bottom: 4, left: 4 })};
    display: inline-flex;
    align-items: center;
    border-radius: ${({ theme }) => theme.pxToRem(8)};

    cursor: pointer;
    font-weight: 600;
    font-size: ${({ theme }) => theme.pxToRem(12)};
    line-height: ${({ theme }) => theme.pxToRem(16)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.neutral[500]};

    &:hover {
      background: ${({ theme }) => theme.colors.neutral[75]};
    }

    & .plusIcon {
      padding: ${({ theme }) => theme.pxToRem(6)};
      background: ${({ theme }) => theme.colors.tertiary['B50']};
      border-radius: ${({ theme }) => theme.pxToRem(40)};
      margin-right: ${({ theme }) => theme.pxToRem(8)};
      transform: ${({ buttonActive }) =>
        buttonActive ? 'rotate(45deg)' : 'rotate(0deg)'};
      transition: transform 0.25s;

      & div {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        width: ${({ theme }) => theme.pxToRem(20)};
        height: ${({ theme }) => theme.pxToRem(20)};
        background: ${({ theme }) => theme.colors.tertiary['B200']};
        border-radius: ${({ theme }) => theme.pxToRem(40)};

        & svg {
          width: ${({ theme }) => theme.pxToRem(14)};
          height: ${({ theme }) => theme.pxToRem(14)};
          color: #60acff;
          & path {
            fill: #60acff;
          }
        }
      }
    }
  }

  & .normalItem {
    & h2 {
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
    & .iconStack {
      position: flex;

      & img {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
        border-radius: 50%;
        &:not(:first-child) {
          margin-left: ${({ theme }) => theme.pxToRem(-12)};
        }
      }
    }
  }
`;

const CustomerJourneyChildNode: React.FC<CustomerJourneyChildNodeProps> = ({
  id,
  data,
}) => {
  const { header, options } = data;
  const [selected, setSelected] = useState<
    CustomerJourneyChildNodeProps['data']['options']
  >([]);
  const [buttonActive, setButtonActive] = useState(false);
  const { getEdges } = useReactFlow();
  const isSource = getEdges().find(({ source }) => source === id);
  const isTarget = getEdges().find(({ target }) => target === id);

  return (
    <>
      {isTarget && (
        <CustomerJourneyHandle
          type="target"
          position={FlowPosition.Left}
          id="left"
        />
      )}
      {isSource && (
        <CustomerJourneyHandle
          type="source"
          position={FlowPosition.Right}
          id="right"
        />
      )}

      <StyledCustomerJourneyChildNode buttonActive={buttonActive}>
        <div className="header">
          {header?.icon && <IconParkIcon icon={header.icon} variant="filled" />}
          <h2>{header?.title}</h2>
        </div>
        {id === 'visits' && (
          <div className="listItem normalItem">
            <h2 className="H-400">Organic Traffic</h2>
          </div>
        )}
        {selected.map(({ id, selectedGroupTitle, options }) => {
          return (
            <div key={id} className="listItem normalItem">
              <h2 className="H-400">{selectedGroupTitle}</h2>
              <div className="iconStack">
                {options.map(({ logo, value }) => {
                  return (
                    <img
                      key={value}
                      src={Logos[logo]}
                      alt={`${logo} logo image`}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="listItem">
          {options?.length > 0 && (
            <Popover
              onClose={() => {
                setButtonActive(false);
              }}
              onOpen={() => {
                setButtonActive(true);
              }}
              content={
                <>
                  {Array.isArray(options) &&
                    options.map(
                      ({ id, menuGroupTitle, options, selectedGroupTitle }) => {
                        return (
                          <Menu.Group title={menuGroupTitle} key={id}>
                            {options.map(({ label, logo, value }) => {
                              return (
                                <StyledMenuItem
                                  key={value}
                                  onClick={() => {
                                    //@TODO find a way to simplify the logic here or at least move it in separate module
                                    if (
                                      selected
                                        .find((item: any) => item.id === id)
                                        ?.options?.find(
                                          (item) => item.value === value
                                        )
                                    ) {
                                      setSelected((prevState) =>
                                        prevState.reduce((acc, item) => {
                                          if (item.id === id) {
                                            if (
                                              item.options.filter(
                                                (item) => item.value !== value
                                              ).length === 0
                                            ) {
                                              return [...acc];
                                            }

                                            return [
                                              ...acc,
                                              ...[
                                                {
                                                  ...item,
                                                  options: item.options.filter(
                                                    (item) =>
                                                      item.value !== value
                                                  ),
                                                },
                                              ],
                                            ];
                                          }

                                          return [...acc, ...[item]];
                                        }, [] as CustomerJourneyChildNodeProps['data']['options'])
                                      );
                                      return;
                                    }
                                    setSelected((prevState) => {
                                      return [
                                        ...prevState.filter(
                                          (item) => item.id !== id
                                        ),
                                        {
                                          id,
                                          menuGroupTitle,
                                          selectedGroupTitle,
                                          options: [
                                            ...(prevState.find(
                                              (item) => item.id === id
                                            )?.options || []),
                                            {
                                              label,
                                              logo,
                                              value,
                                            },
                                          ],
                                        },
                                      ];
                                    });
                                  }}
                                >
                                  <div className="menu-item-content">
                                    <div className="service-label">
                                      <img
                                        src={Logos[logo]}
                                        alt={`${logo} logo image`}
                                      />
                                      <p className="large-text">{label}</p>
                                    </div>
                                    {selected
                                      .find((item: any) => item.id === id)
                                      ?.options?.find(
                                        (item) => item.value === value
                                      ) && (
                                      <div className="service-icon">
                                        <Icon icon="XIcon" />
                                      </div>
                                    )}
                                  </div>
                                </StyledMenuItem>
                              );
                            })}
                          </Menu.Group>
                        );
                      }
                    )}
                </>
              }
              position={Position.RIGHT}
            >
              <button className="buttonItem">
                <div className="plusIcon">
                  <div>
                    <Icon icon="PlusIcon" />
                  </div>
                </div>
                {buttonActive ? 'Close Menu' : 'Add Service'}
              </button>
            </Popover>
          )}
        </div>
      </StyledCustomerJourneyChildNode>
    </>
  );
};

export default CustomerJourneyChildNode;
