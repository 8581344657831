import styled from 'styled-components';

import { motion } from 'framer-motion';

export type SalesFunnelCellProps = {
  children?: React.ReactNode;
  current?: number;
  forecast?: number;
  overlay?: boolean;
  title?: string;
  vertical?: boolean;
  value?: number;
  loading?: boolean;
  simulatedvalue?: number;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledSalesFunnelCell = styled.div<
  Pick<SalesFunnelCellProps, 'current' | 'forecast' | 'overlay'>
>`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-bottom: ${({ theme }) => theme.pxToRem(32)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[400]};

  & p {
    color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[600]
        : theme.colors.neutral[0]};
  }

  & .bar-container {
    position: relative;
    margin: 0 auto;
    height: ${({ theme }) => theme.pxToRem(20)};
    display: fex;
    justify-content: center;

    &__forecast {
      position: absolute;
      height: ${({ theme }) => theme.pxToRem(20)};
      background-color: ${({ theme, current, forecast }) =>
        current && forecast && current > forecast
          ? theme.colors.tertiary['R300']
          : theme.colors.secondary['G300']};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 5;
    }

    &__current {
      position: absolute;
      height: ${({ theme }) => theme.pxToRem(20)};
      background-image: ${({ theme }) => theme.colors.primary['N800']};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 10;
    }
  }
`;

const StyledVerticalSalesFunnelCell = styled.div<
  Pick<SalesFunnelCellProps, 'current' | 'forecast' | 'overlay'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};

  & .bar-container {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.pxToRem(20)};
    min-height: ${({ theme }) => theme.pxToRem(160)};

    &__loading {
      position: absolute;
      min-height: ${({ theme }) => theme.pxToRem(160)};
      width: ${({ theme }) => theme.pxToRem(20)};
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[200]
          : theme.colors.neutral[910]};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 5;

      &::after {
        position: absolute;
        top: 0;
        right: ${({ theme }) => theme.pxToRem(1)};
        left: ${({ theme }) => theme.pxToRem(1)};
        height: 60px;
        border-radius: ${({ theme }) => theme.pxToRem(22)};
        background-image: ${({ theme }) =>
          theme.mode === 'light'
            ? `linear-gradient(180deg, #EDEFF5, #ffffff 85%)`
            : `linear-gradient(180deg, #464a5c, #ffffff26 85%)`};
        content: '';
        z-index: 6;
        animation: shimmer 2s infinite;
      }

      @keyframes shimmer {
        100% {
          top: ${({ theme }) => theme.pxToRem(100)};
        }
      }
    }

    &__forecast {
      position: absolute;
      width: ${({ theme }) => theme.pxToRem(20)};
      background-color: ${({ theme, current, forecast }) =>
        current && forecast && current > forecast
          ? theme.colors.tertiary['R300']
          : theme.colors.secondary['G300']};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      margin-top: ${({ theme, current, forecast }) =>
        current && forecast
          ? theme.pxToRem(current - forecast)
          : theme.pxToRem(0)};
      z-index: 5;
    }

    &__current {
      position: absolute;
      width: ${({ theme }) => theme.pxToRem(20)};
      background-image: ${({ theme }) => theme.colors.primary['N800']};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 9;
    }
  }

  & > div {
    width: 100%;
    text-align: center;
  }

  & > div:nth-child(1) {
    & > p {
      color: ${({ theme }) => theme.colors.neutral[600]};
      margin-bottom: ${({ theme }) => theme.pxToRem(12)};
    }

    & > h5 {
      margin-bottom: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  & > div:nth-child(2) {
    min-height: ${({ theme }) => theme.pxToRem(160)};

    & > h5 {
      color: ${({ theme, current, forecast }) =>
        current && forecast && current > forecast
          ? theme.colors.tertiary['R300']
          : theme.colors.secondary['G300']};
    }
  }
`;

const SalesFunnelCell: React.FC<SalesFunnelCellProps> = ({
  title,
  vertical,
  current,
  forecast,
  value,
  loading,
  simulatedvalue,
  ...props
}) => {
  if (vertical) {
    return (
      <StyledVerticalSalesFunnelCell
        {...props}
        current={value}
        forecast={simulatedvalue}
      >
        <div>
          <p className="large-text">{title}</p>
          {!loading && (
            <h5 className="H-700">
              {simulatedvalue || simulatedvalue === 0
                ? `${simulatedvalue}`
                : `${value}`}
            </h5>
          )}
        </div>
        <div>
          <div className="bar-container">
            {loading ? (
              <div className="bar-container__loading" />
            ) : (
              <>
                <motion.div
                  className="bar-container__current"
                  animate={{
                    height: forecast
                      ? `${(160 * forecast) / 100}px`
                      : `${current ? (160 * current) / 100 : 0}px`,
                  }}
                />
                {/* <motion.div
                  className="bar-container__forecast"
                  animate={{
                    minHeight:
                      current && forecast && current > forecast
                        ? `${(160 * current) / 100}px`
                        : `${forecast ? (160 * forecast) / 100 : 0}px`,
                  }}
                /> */}
              </>
            )}
          </div>
          {value && simulatedvalue && (
            <h5 className="H-600">{simulatedvalue - value}</h5>
          )}
        </div>
      </StyledVerticalSalesFunnelCell>
    );
  }
  return (
    <StyledSalesFunnelCell
      {...props}
      {...props}
      current={current}
      forecast={forecast}
    >
      <div>
        <p className="large-text">{title}</p>
        {simulatedvalue ? `${value}/${simulatedvalue}` : `${value}`}
      </div>
      <div>
        <div className="bar-container">
          {loading ? (
            <div className="loading-skeleton" />
          ) : (
            <>
              <motion.div
                className="bar-container__current"
                animate={{
                  width:
                    current && forecast && current > forecast
                      ? `${forecast}%`
                      : `${current}%`,
                }}
              />
              <motion.div
                className="bar-container__forecast"
                animate={{
                  width:
                    current && forecast && current > forecast
                      ? `${current}%`
                      : `${forecast}%`,
                }}
              />
            </>
          )}
        </div>
      </div>
    </StyledSalesFunnelCell>
  );
};

export default SalesFunnelCell;
