import React from 'react';
import ReactFlow, { MiniMap, Controls } from 'react-flow-renderer';

import styled from 'styled-components';

import { nodes, edges } from './config';

import { BusinessModelState } from './context/reducer';

import BusinessModelCenterNode from 'components/molecules/business-model-center-node';
import BusinessModelNode from 'components/organisms/business-model-node';
import BusinessModelEdge from 'components/atoms/business-model-edge';
import OnboardingNav from 'components/molecules/onboarding-nav';

import { CanvasProvider, useCanvas } from './context';
import { useNavigate } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { updateBusinessBlueprint } from 'pages/business-configuration/actions';

const StyledPageContainer = styled.div`
  width: 100%;
  height: 100vh;

  & .bottomNavigation {
    position: absolute;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }
`;

// TODO: Move the business builder page container in it's own organism component

export const PageContainer: React.FC<{
  children: React.ReactNode;
  title: string;
  totalSteps: number;
  currentStep: number;
}> = ({ children }) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    businessModel: { selectedNodeId, ...options },
  } = useCanvas();
  const navigate = useNavigate();
  const isNextDisabled =
    Object.keys(options).reduce((acc, key) => {
      if (
        options[key as keyof Omit<BusinessModelState, 'selectedNodeId'>]
          ?.length > 0
      ) {
        return acc;
      }
      return [...acc, key];
    }, [] as string[]).length !== 0;

  console.log('STATE ', useCanvas());

  const { actions } = useStateMachine({ updateBusinessBlueprint });

  return (
    <StyledPageContainer>
      {children}
      <OnboardingNav
        className="bottomNavigation"
        timeSinceLastSave={600000}
        isNextDisabled={isNextDisabled}
        onClickNext={() => {
          actions.updateBusinessBlueprint({
            selectedNodeId,
            ...options,
          });
          window.localStorage.setItem(
            'onboarding',
            '/business-configuration/customer-journey'
          );
          navigate('/business-configuration/customer-journey');
        }}
        onClickBack={() => {
          navigate('/business-configuration/blueprint-template');
        }}
      />
    </StyledPageContainer>
  );
};

export const StyledMiniMap = styled(MiniMap)`
  width: 202px;
  height: 148px;
  right: ${({ theme }) => theme.pxToRem(36)};
  bottom: ${({ theme }) => theme.pxToRem(24)};
  box-shadow: 0px 4px 50px rgba(193, 196, 214, 0.3);
  border-radius: ${({ theme }) => theme.pxToRem(4)};
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  & > path {
    fill: rgba(214, 224, 255, 0.2);
  }

  & > rect {
    fill: ${({ theme }) => theme.colors.tertiary['B100']};
    stroke: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const StyledControls = styled(Controls)`
  bottom: 24px;
  box-shadow: 0px 4px 50px rgba(193, 196, 214, 0.3);

  & .react-flow {
    &__controls-zoomin {
      & svg {
        & > path {
          fill: ${({ theme }) => theme.colors.neutral[900]};
        }
      }
    }
    &__controls-zoomout {
      & svg {
        & > path {
          fill: ${({ theme }) => theme.colors.neutral[900]};
        }
      }
    }

    &__controls-fitview {
      & svg {
        & > path {
          fill: ${({ theme }) => theme.colors.primary['BLUE']};
        }
      }
    }
  }
`;

const BusinessBlueprint = () => {
  return (
    <CanvasProvider>
      <PageContainer title="Business Model" totalSteps={2} currentStep={1}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          defaultZoom={1}
          nodeTypes={{
            BusinessModelCenterNode,
            BusinessModelNode,
          }}
          edgeTypes={{
            BusinessModelEdge,
          }}
        >
          <StyledMiniMap />
          <StyledControls showInteractive={false} />
        </ReactFlow>
      </PageContainer>
    </CanvasProvider>
  );
};

export default BusinessBlueprint;
