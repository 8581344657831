import { IconParkIconProps } from 'components/atoms/icon/IconPark';

import { businessModelNodes } from './nodes';
import { businessModelEdges } from './edges';

export type BusinessModelOptionType = {
  id: string;
  label: string;
  icon: IconParkIconProps['icon'];
};

export type BusinessModelNodeType = {
  id:
    | 'centre-node'
    | 'distribution-model'
    | 'product-category'
    | 'revenue-model'
    | 'target-customers'
    | 'product-model'
    | 'business-model';
  type: string;
  data: {
    companyLogo?: string;
    label?: string;
    labelPosition?: string;
    handlePosition?: string;
    values?: BusinessModelOptionType[];
  };
  position: {
    x: number;
    y: number;
  };
};

export type SoreFrontItemValue = {
  value: string;
  logo: string;
  label: string;
};
/**
 * Store Front
 */

export type StoreFrontItemType = {
  id: string;
  logo: 'Shopify' | 'Amazon' | 'WooCommerce' | undefined;
  label: string;
  values: {
    otherConnections: SoreFrontItemValue[] | [];
  };
};

export type StoreFrontNodeType = {
  id: string;
  type: string;
  data: {
    values: StoreFrontItemType[];
  };
  position: {
    x: number;
    y: number;
  };
};

export type FulfillmentMethodNodeType = {
  id: string;
  type: string;
  data: {
    values: {
      id: string;
      icon: IconParkIconProps['icon'];
      label: string;
    }[];
  };
  position: {
    x: number;
    y: number;
  };
};

export type FulfillmentItemType = {
  id: string;
  logo: 'Shopify' | 'Amazon' | 'WooCommerce' | undefined;
  label: string;
  values: {
    otherConnections: SoreFrontItemValue[] | [];
  };
};

export type FulfillmentNodeType = {
  id: string;
  type: string;
  data: {
    values: FulfillmentItemType[];
  };
  position: {
    x: number;
    y: number;
  };
};

export const nodes = [...businessModelNodes];

export const edges = [...businessModelEdges];
