import styled from 'styled-components';

import {
  EdgeProps,
  getSmoothStepPath,
  StraightEdge,
} from 'react-flow-renderer';
import theme from 'theme';

export type BusinessModelEdgeProps = {
  children?: React.ReactNode;
  data: {
    variant?: 'step' | 'straight';
  };
} & EdgeProps;

const StyledBusinessModelEdge = styled.path`
  stroke: ${({ theme }) => theme.colors.primary['BLUE']};
  stroke-width: ${({ theme }) => theme.pxToRem(3)};
  stroke-dasharray: 7;
  stroke-linecap: round;
`;

const BusinessModelEdge: React.FC<BusinessModelEdgeProps> = ({ ...props }) => {
  const edgePath = getSmoothStepPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
  });

  if (props?.data?.variant === 'straight') {
    return (
      <StraightEdge
        {...props}
        style={{
          stroke: theme.colors.primary['BLUE'],
          strokeWidth: 3,
          strokeDasharray: 6,
          strokeLinecap: 'round',
        }}
      />
    );
  }

  return (
    <StyledBusinessModelEdge
      {...props}
      d={edgePath}
      markerEnd={props.markerEnd}
      className="react-flow__edge-path"
    />
  );
};

export default BusinessModelEdge;
