import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type DividerProps = {} & HTMLAttributes<HTMLHRElement>;

const StyledDivider = styled.hr`
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(1)};
  border: none;
  background-color: ${({ theme }) => theme.colors.neutral['300']};
`;

/**
 * The `Divider` component uses `hr` element.
 * It can be used to separate content within HTML.
 */

const Divider: React.FC<DividerProps> = ({ ...props }) => {
  return <StyledDivider {...props} />;
};

export default Divider;
