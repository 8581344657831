import styled from 'styled-components';

import { Controller, FieldError } from 'react-hook-form';
import {
  TagInput as EuiTagInput,
  TagInputProps as EuiTagInputProps,
} from 'evergreen-ui';
import InputError from 'components/atoms/input-error';

export type TagInputProps = {
  register?: any;
  name: string;
  required?: boolean;
  error?: FieldError | undefined;
  errorMessage?: FieldError['message'];
  control: any;
} & Omit<EuiTagInputProps, 'values'>;

const StyledTagInput = styled(EuiTagInput)`
  margin-bottom: ${({ theme, error }) =>
    error ? theme.pxToRem(0) : theme.pxToRem(16)};
  border: ${({ theme, error }) =>
    error
      ? `1px solid ${theme.colors.secondary['R400']}`
      : `1px solid ${theme.colors.neutral[300]}`};
  & input {
    color: ${({ theme }) => theme.colors.neutral[600]};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    line-height: ${({ theme }) => theme.pxToRem(32)};
    height: ${({ theme }) => theme.pxToRem(32)};
  }

  & strong {
    height: ${({ theme }) => theme.pxToRem(32)};
    font-size: ${({ theme }) => theme.pxToRem(16)};
    font-weight: 400;
    margin: 0;
    margin-left: ${({ theme }) => theme.pxToRem(16)};
    padding-right: ${({ theme }) => theme.pxToRem(9)};
    color: ${({ theme }) => theme.colors.neutral[800]};
    background-color: ${({ theme, error }) =>
      error ? theme.colors.tertiary['R100'] : theme.colors.neutral[200]};

    & > svg {
      width: ${({ theme }) => theme.pxToRem(10)};
      height: ${({ theme }) => theme.pxToRem(10)};
      margin-top: ${({ theme }) => theme.pxToRem(0)};
    }
  }

  & button {
    top: ${({ theme }) => theme.pxToRem(14)};
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    & > svg {
      fill: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const TagInput: React.FC<TagInputProps> = ({
  name,
  required,
  control,
  error,
  errorMessage,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ ...field }) => {
        // Remove fieldState and formState from props to avoid React warnings
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fieldState, formState, ...fieldProps } = field;

        return (
          <>
            <StyledTagInput
              {...props}
              {...fieldProps}
              values={field.field.value}
              onChange={(values: string[]) => {
                field.field.onChange?.(values);
                props?.onChange?.(values);
              }}
              error={error}
            />
            {error && <InputError>{errorMessage}</InputError>}
          </>
        );
      }}
    />
  );
};

export default TagInput;
