import styled from 'styled-components';

import { motion } from 'framer-motion';

export type KpiStatusBarProps = {
  current?: number;
  forecast?: number;
  forecastValue?: number;
  title?: string;
  value?: number;
  percentage?: boolean;
  reverse?: boolean;
  overlay?: boolean;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledKpiStatusBar = styled.div<
  Pick<KpiStatusBarProps, 'current' | 'forecast' | 'reverse' | 'overlay'>
>`
  position: relative;

  & > .overlay {
    display: ${({ overlay }) => (overlay ? 'block' : 'none')};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(193, 196, 214, 0.95);
    filter: blur(6px);
    z-index: 9;
  }

  & .bar-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    position: relative;
    width: 100%;

    & p {
      color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[600]
          : theme.colors.neutral[0]};
    }

    & p.value {
      text-align: center;
    }

    &__bar {
      width: 100%;
      height: ${({ theme }) => theme.pxToRem(16)};
      position: relative;
      padding-top: ${({ theme }) => theme.pxToRem(10)};
      padding-bottom: ${({ theme }) => theme.pxToRem(10)};
      margin-left: ${({ theme }) => theme.pxToRem(8)};
    }

    &__base {
      position: absolute;
      width: 100%;
      height: ${({ theme }) => theme.pxToRem(16)};
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[200]
          : theme.colors.neutral[910]};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 1;
    }

    &__forecast {
      position: absolute;
      height: ${({ theme }) => theme.pxToRem(16)};
      background-color: ${({ reverse, theme, current, forecast }) =>
        reverse
          ? current && forecast && current > forecast
            ? '#9FCAFC'
            : theme.colors.tertiary['R300']
          : current && forecast && current > forecast
          ? theme.colors.tertiary['R300']
          : '#9FCAFC'};
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 5;
    }

    &__current {
      position: absolute;
      height: ${({ theme }) => theme.pxToRem(16)};
      background: transparent linear-gradient(90deg, #678af5 19%, #52a2f9 100%)
        0% 0% no-repeat padding-box;
      border-radius: ${({ theme }) => theme.pxToRem(22)};
      z-index: 8;
    }
  }
`;

const KpiStatusBar: React.FC<KpiStatusBarProps> = ({
  current,
  forecast,
  forecastValue,
  title,
  value,
  percentage,
  reverse,
  overlay,
  ...props
}) => {
  return (
    <StyledKpiStatusBar
      {...props}
      current={current}
      forecast={forecast}
      reverse={reverse}
      overlay={overlay}
    >
      <div className="overlay" />
      <div className="bar-container">
        <div>
          <p>{title}</p>
        </div>
        <div className="bar-container__bar">
          <div className="bar-container__base" />
          <motion.div
            className="bar-container__forecast"
            animate={{
              width:
                current && forecast && current > forecast
                  ? `${current}%`
                  : `${forecast}%`,
            }}
          />
          <motion.div
            className="bar-container__current"
            animate={{
              width:
                current && forecast && current > forecast
                  ? `${forecast}%`
                  : `${current}%`,
            }}
          />
        </div>
        <div>
          <p className="value">
            {percentage
              ? `${value}%/${forecastValue}%`
              : `£${value}/£${forecastValue}`}
          </p>
        </div>
      </div>
    </StyledKpiStatusBar>
  );
};

export default KpiStatusBar;
