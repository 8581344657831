import { HTMLAttributes, RefObject, useMemo } from 'react';

import styled from 'styled-components';

import { motion } from 'framer-motion';

export type ProgressBarProps = {
  children?: React.ReactNode;
  totalSteps: number;
  currentStep: number;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledProgressBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .progress-container {
    width: 100%;
    height: ${({ theme }) => theme.pxToRem(10)};
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    background: ${({ theme }) => theme.colors.neutral[300]};

    & .progress {
      height: ${({ theme }) => theme.pxToRem(10)};
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      background-image: ${({ theme }) => theme.colors.primary['B400']};
    }
  }

  & .progress-count {
    width: ${({ theme }) => theme.pxToRem(58)};
    text-align: center;
    margin-left: ${({ theme }) => theme.pxToRem(8)};
    color: ${({ theme }) => theme.colors.neutral[500]};
  }
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  totalSteps,
  ...props
}) => {
  const progressPercent = useMemo(
    () =>
      currentStep > 0
        ? Math.min(Math.ceil((currentStep / totalSteps) * 100), 100)
        : 0,
    [currentStep, totalSteps]
  );

  return (
    <StyledProgressBar {...props}>
      <div className="progress-container">
        <motion.div
          className="progress"
          animate={{ width: `${progressPercent}%` }}
          initial={{ width: 0 }}
          transition={{
            type: 'spring',
            stiffness: 500,
            damping: 60,
            mass: 0.1,
            delay: 0,
          }}
        />
      </div>
      <p className="progress-count">{`${currentStep} of ${totalSteps}`}</p>
    </StyledProgressBar>
  );
};

export default ProgressBar;
