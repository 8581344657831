import { ReactComponent as barclays } from './barclays.svg';
import { ReactComponent as xero } from './xero.svg';
import { ReactComponent as hsbc } from './hsbc.svg';
import { ReactComponent as lloyds } from './lloyds.svg';
import { ReactComponent as shopify } from './shopify.svg';
import { ReactComponent as quickbooks } from './quickbooks.svg';
import { ReactComponent as sage } from './sage.svg';
import { ReactComponent as mailchimp } from './mailchimp.svg';
import { ReactComponent as facebookads } from './facebookads.svg';
import { ReactComponent as googleads } from './googleads.svg';
import { ReactComponent as trello } from './trello.svg';

const logos = {
  barclays,
  xero,
  hsbc,
  lloyds,
  shopify,
  quickbooks,
  sage,
  mailchimp,
  facebookads,
  googleads,
  trello,
};

export type SoftwareCardLogoType = keyof typeof logos;

export default logos;
