export const tooltip = {
  Tooltip: {
    baseStyle: {
      paddingY: 8,
      paddingX: 16,
      maxWidth: 240,
      borderRadius: 'radii.2',
      elevation: 'shadows.3',
    },
    appearances: {
      card: {
        backgroundColor: 'transparent',
        width: '100%',
        padding: 0,
        margin: 0,
      },
      default: {
        color: 'white',
        backgroundColor: 'colors.gray800',
      },
    },
    sizes: {},
  },
};
