import { SoftwareCardLogoType } from 'components/atoms/software-card/logos';
import SoftwareConnectedIcon from 'components/atoms/software-connected-icon';
import styled from 'styled-components';

export type SoftwareConnectedDialogHeaderProps = {
  softwareList: SoftwareCardLogoType[];
};

const StyledSoftwareConnectedDialogHeader = styled.div`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(330)};
  height: ${({ theme }) => theme.pxToRem(270)};

  & .center {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-60%);
  }

  & .left {
    position: absolute;
    left: 0;
    bottom: 10%;
  }

  & .top-left {
    position: absolute;
    left: 8%;
    top: 10%;
  }

  & .top {
    position: absolute;
    left: 50%;
  }

  & .top-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & .right {
    position: absolute;
    right: 10%;
    bottom: 0;
  }
`;

const SoftwareConnectedDialogHeader: React.FC<
  SoftwareConnectedDialogHeaderProps
> = ({ softwareList }) => {
  return (
    <StyledSoftwareConnectedDialogHeader>
      {softwareList[0] && (
        <SoftwareConnectedIcon
          logo={softwareList[0]}
          sizePx={100}
          className="center"
        />
      )}
      {softwareList[1] && (
        <SoftwareConnectedIcon
          logo={softwareList[1]}
          sizePx={48}
          className="left"
        />
      )}
      {softwareList[2] && (
        <SoftwareConnectedIcon
          logo={softwareList[2]}
          sizePx={48}
          className="right"
        />
      )}
      {softwareList[3] && (
        <SoftwareConnectedIcon
          logo={softwareList[3]}
          sizePx={64}
          className="top-right"
        />
      )}
      {softwareList[4] && (
        <SoftwareConnectedIcon
          logo={softwareList[4]}
          sizePx={80}
          className="top-left"
        />
      )}
      {softwareList[5] && (
        <SoftwareConnectedIcon
          logo={softwareList[5]}
          sizePx={80}
          className="top"
        />
      )}
    </StyledSoftwareConnectedDialogHeader>
  );
};

export default SoftwareConnectedDialogHeader;
