import styled from 'styled-components';

import { Handle, Position } from 'react-flow-renderer';
import { useNavigate } from 'react-router-dom';

export type BusinessMetricCenterNodeProps = {
  children?: React.ReactNode;
  data: {
    title?: string;
    nodeType?: string;
    to?: string;
  };
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledBusinessMetricCenterNode = styled.div<{ nodeType?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;

  &:hover {
    ${({ nodeType }) => {
      if (nodeType === 'finance') {
        return `background: #D3E2F9`;
      }

      if (nodeType === 'ops') {
        return `background: #E2F3F0;`;
      }

      return `background:  #e7e4f6;`;
    }};
  }

  ${({ nodeType, theme }) => {
    if (nodeType === 'finance') {
      return `background-image: ${theme.colors.primary['B400']};`;
    }

    if (nodeType === 'ops') {
      return `background: #99DFD2 0% 0% no-repeat padding-box;`;
    }

    return ` background: transparent
    linear-gradient(44deg, #b0a5f6 0%, #ad7bfa 100%, #ad79fa 100%) 0% 0%
    no-repeat padding-box;`;
  }};
  border: 10px solid ${({ theme }) => theme.colors.neutral[0]};
  border-radius: 50%;
  box-shadow: 0px 13px 16px #00000029;
  text-align: center;
  cursor: pointer;

  & > h2 {
    color: ${({ theme }) => theme.colors.neutral[0]};
  }
`;

const BusinessMetricCenterNode: React.FC<BusinessMetricCenterNodeProps> = ({
  data,
  ...props
}) => {
  const { title, nodeType, to } = data;
  const navigate = useNavigate();

  return (
    <>
      <Handle
        id="top"
        type="target"
        position={Position.Top}
        style={{
          opacity: 0,
          top: 10,
        }}
      />
      <Handle
        id="top-left"
        type="target"
        position={Position.Top}
        style={{
          top: '15px',
          left: '55px',
          opacity: 0,
        }}
      />
      <Handle
        id="top-far-left"
        type="target"
        position={Position.Top}
        style={{
          top: '10px',
          left: '30px',
          opacity: 0,
        }}
      />
      <Handle
        id="top-right"
        type="target"
        position={Position.Top}
        style={{
          top: '15px',
          left: '85px',
          opacity: 0,
        }}
      />
      <Handle
        id="top-far-right"
        type="target"
        position={Position.Top}
        style={{
          top: '15px',
          left: '110px',
          opacity: 0,
        }}
      />
      <Handle
        id="left-top"
        type="target"
        position={Position.Left}
        style={{
          opacity: 0,
          top: 50,
          left: 35,
        }}
      />
      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{
          left: '30px',
          opacity: 0,
        }}
      />
      <Handle
        id="left-bottom"
        type="target"
        position={Position.Left}
        style={{
          opacity: 0,
          top: 85,
          left: 35,
        }}
      />
      <Handle
        id="bottom-left"
        type="target"
        position={Position.Bottom}
        style={{
          opacity: 0,
          top: 85,
          left: 35,
        }}
      />
      <Handle
        id="bottom"
        type="target"
        position={Position.Bottom}
        style={{
          opacity: 0,
          top: 85,
          left: 65,
        }}
      />
      <Handle
        id="right"
        type="target"
        position={Position.Right}
        style={{
          opacity: 0,
          top: 65,
          left: 65,
        }}
      />
      <Handle
        id="right-top"
        type="target"
        position={Position.Right}
        style={{
          opacity: 0,
          top: 40,
          left: 65,
        }}
      />
      <StyledBusinessMetricCenterNode
        {...props}
        nodeType={nodeType}
        onClick={() => {
          if (to) {
            navigate(to);
          }
        }}
      >
        <h2 className="H-500">{title}</h2>
      </StyledBusinessMetricCenterNode>
    </>
  );
};

export default BusinessMetricCenterNode;
