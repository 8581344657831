import { Tooltip } from 'evergreen-ui';
import styled from 'styled-components';
import Icon from '../icon';

export type InputTooltipProps = {
  content: string;
};

const StyledTextContent = styled.p`
  max-width: ${({ theme }) => theme.pxToRem(200)};

  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }) => theme.pxToRem(16)};
  color: ${({ theme }) => theme.colors.neutral[0]};
`;

const StyledIconContainer = styled.div`
  display: inline-block;
  & svg {
    width: ${({ theme }) => theme.pxToRem(16)};
    height: ${({ theme }) => theme.pxToRem(16)};
    color: ${({ theme }) => theme.colors.neutral[900]};
    position: relative;
  }
`;

const InputTooltip: React.FC<InputTooltipProps> = ({ content }) => {
  return (
    <Tooltip
      position="right"
      content={<StyledTextContent>{content}</StyledTextContent>}
    >
      <StyledIconContainer>
        <Icon icon="QuestionMarkCircleIcon" className="questionMarkButton" />
      </StyledIconContainer>
    </Tooltip>
  );
};

export default InputTooltip;
