import { useMemo } from 'react';
import styled from 'styled-components';

import ReactFlow from 'react-flow-renderer';
import { StyledControls } from 'pages/business-configuration/nested-routes/business-blueprint/BusinessBlueprint';

import BusinessOverviewCenterNode from 'components/atoms/business-overview-center-node';
import BusinessMetricCenterNode from 'components/atoms/business-metric-center-node';
import BusinessMetricNode from 'components/atoms/business-metric-node';

import { KpiType } from 'api-client/queries';

import { nodes, edges } from './data';

export type BusinessOverviewProps = {
  children?: React.ReactNode;
  kpiList?: KpiType[];
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledBusinessOverview = styled.div`
  width: 100%;
  height: 100%;
`;

const BusinessOverview: React.FC<BusinessOverviewProps> = ({ kpiList }) => {
  const nodeTypes = useMemo(
    () => ({
      BusinessMetricCenterNode,
      BusinessOverviewCenterNode,
      BusinessMetricNode,
    }),
    []
  );
  if (Array.isArray(kpiList)) {
    const transformKpiList = nodes.reduce((acc, node): typeof nodes => {
      const kpiData = kpiList.find(({ key }) => key === node.key);

      if (kpiData) {
        return [
          ...acc,
          ...[
            {
              ...node,
              data: {
                ...node.data,
                title: kpiData.name,
                value: kpiData.value,
                isPercentage: kpiData.is_percentage,
                unit: kpiData.unit,
              },
            },
          ],
        ];
      }
      return [...acc];
    }, [] as typeof nodes);

    return (
      <StyledBusinessOverview>
        <ReactFlow
          fitView
          preventScrolling={false}
          zoomOnScroll={false}
          nodes={[
            ...[nodes[0]],
            ...[nodes[1]],
            ...[nodes[2]],
            ...[nodes[3]],
            ...transformKpiList,
          ]}
          edges={edges}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          nodesConnectable={false}
        >
          <StyledControls showInteractive={false} />
        </ReactFlow>
      </StyledBusinessOverview>
    );
  }

  return null;
};

export default BusinessOverview;
