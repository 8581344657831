import styled from 'styled-components';

import ImadPic from './imad_profile.png';

import {
  Avatar as EuiAvatar,
  AvatarProps as EuiAvatarProps,
} from 'evergreen-ui';

export type AvatarProps = {} & EuiAvatarProps;

const StyledAvatar = styled(EuiAvatar)`
  border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
`;

const Avatar: React.FC<AvatarProps> = ({ ...props }) => {
  return <StyledAvatar {...props} src={ImadPic} alt="Profile Pic" />;
};

export default Avatar;
