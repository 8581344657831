const colors = {
  white: '#ffffff',
  primary: {
    BLUE: '#60ACFF',
    B400: `linear-gradient(92.27deg, #678AF5 13.01%, #52A2F9 105.06%)`,
    N800: `linear-gradient(96.07deg, #688BF6 3.75%, #74C9E3 88.16%)`,
    B500: `linear-gradient(92.27deg, #4762B5 13.01%, #177CEA 105.06%)`,
    B600: `linear-gradient(92.27deg, #2D4583 20.09%, #1D68B9 105.06%)`,
  },
  secondary: {
    G200: '#DCF2EA',
    G300: '#A3E6CD',
    G400: '#1FCCB1',
    B400: '#4C7BFB',
    B500: '#4762B5',
    B600: '#2D4583',
    Y100: '#FFEFD2',
    Y200: '#FFDFA6',
    Y400: '#FFCC45',
    R400: '#F35B5A',
  },
  tertiary: {
    V400: '#736DE7',
    T400: '#6CDAF8',
    P400: '#E54A8E',
    O400: '#FFBF45',
    B300: '#9DB5FF',
    B200: '#D6E0FF',
    B100: '#EBF0FF',
    B50: '#F3F6FF',
    R100: '#F9DADA',
    R200: '#F4B6B6',
    R300: '#EE9191',
    R400: '#F35B5A',
    R500: '#CD4C4B',
    R600: '#913130',
  },
  neutral: {
    1000: '#1E1D2B',
    960: '#353E57',
    950: '#30354B',
    910: '#464A5C',
    900: '#101840',
    800: '#474D66',
    700: '#696F8C',
    600: '#8F95B2',
    500: '#C1C4D6',
    400: '#D8DAE5',
    300: '#E6E8F0',
    250: '#EEEDEF',
    200: '#EDEFF5',
    150: '#F7F9FB',
    100: '#F4F6FA',
    75: '#F9FAFC',
    50: '#FAFBFF',
    0: '#FFFFFF',
  },
};

export default colors;
