import colors from 'theme/colors';
import { pxToRem } from 'theme/helpers';

export const select = {
  Select: {
    baseStyle: {
      fontFamily: 'fontFamilies.ui',
      borderRadius: pxToRem(8),
    },
    appearances: {
      default: {
        backgroundColor: colors.neutral[0],
        _disabled: {
          cursor: 'not-allowed',
          color: colors.neutral[700],
          borderColor: colors.neutral[600],
        },
        _hover: {
          borderColor: colors.neutral[600],
          backgroundColor: colors.neutral[0],
          color: colors.neutral[700],
        },
        _focus: {
          color: colors.neutral[700],
          border: `1px solid  ${colors.tertiary['B300']}`,
          boxShadow: `0px 0px 0px 2px ${colors.tertiary['B200']}`,
        },
        _active: {
          color: colors.neutral[800],
          backgroundColor: colors.neutral[0],
        },
      },
    },
    sizes: {
      small: {
        height: 48,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
      },
      medium: {
        height: 48,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
      },
      large: {
        height: 48,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
      },
    },
  },
};
