import styled from 'styled-components';

import OtherConnectionsList from 'components/molecules/other-connections-list';
import { StoreFrontItemType } from 'pages/business-configuration/nested-routes/business-blueprint/config';
import SupplierManufacturerHandle from 'components/atoms/supplier-manufacturer-handle';
import { Position, useEdges } from 'react-flow-renderer';

export type CustomerJourneyNodeProps = {
  children?: React.ReactNode;
  id: string;
  data: {
    id: string;
    logo: StoreFrontItemType['logo'];
    otherConnections: StoreFrontItemType['values']['otherConnections'];
  };
};

const StyledCustomerJourneyNode = styled.div`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(1229)};
  height: ${({ theme }) => theme.pxToRem(420)};
  border: 5px solid ${({ theme }) => theme.colors.tertiary['B100']};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  margin: ${({ theme }) =>
    theme.pxToRem({
      top: 0,
      bottom: 0,
      left: 50,
      right: 0,
    })};
  cursor: default;

  & > .node-title {
    position: absolute;
    top: ${({ theme }) => theme.pxToRem(-12)};
    left: 50%;
    transform: translateX(-50%);
    color: ${({ theme }) => theme.colors.neutral[500]};
    background-color: ${({ theme }) => theme.colors.neutral[150]};
    padding-left: ${({ theme }) => theme.pxToRem(6)};
    padding-right: ${({ theme }) => theme.pxToRem(6)};
  }

  & .additional-options {
    position: absolute;
    left: ${({ theme }) => theme.pxToRem(-75)};
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledCustomerJourneyHandle = styled(SupplierManufacturerHandle)<{
  show: 'true' | 'false';
}>`
  opacity: ${({ show }) => (show == 'true' ? 1 : 0)};
`;

const CustomerJourneyNode: React.FC<CustomerJourneyNodeProps> = ({
  children,
  data,
  id,
}) => {
  const { logo, otherConnections } = data;
  const showHandle = useEdges().find((edge) => edge.source === id);

  return (
    <>
      <StyledCustomerJourneyHandle
        type="source"
        position={Position.Right}
        id="right"
        show={showHandle ? 'true' : 'false'}
      />
      <StyledCustomerJourneyNode>
        <h1 className="H-500 node-title">Customer Journey</h1>
        {logo && (
          <OtherConnectionsList
            customerJourneyId={id}
            logo={logo}
            options={otherConnections}
            className="additional-options"
          />
        )}
        {children}
      </StyledCustomerJourneyNode>
    </>
  );
};

export default CustomerJourneyNode;
