import styled from 'styled-components';

import Lottie, { LottieComponentProps } from 'lottie-react';

import turtleCreation from './data.json';

export type TurtleCreationLoaderProps = {} & Omit<
  LottieComponentProps,
  'ref' | 'as' | 'animationData'
>;

const StyledTurtleCreationLoader = styled(Lottie)`
  width: 100%;
  height: 100%;
  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors.neutral[600]};
      stroke: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const TurtleCreationLoader: React.FC<TurtleCreationLoaderProps> = ({
  ...props
}) => {
  return (
    <StyledTurtleCreationLoader {...props} animationData={turtleCreation} />
  );
};

export default TurtleCreationLoader;
