import styled from 'styled-components';

import Icon from 'components/atoms/icon';
import PageContentContainer from 'components/atoms/page-content-container';

import { PageContentContainerProps } from 'components/atoms/page-content-container/PageContentContainer';

import { Link } from 'react-router-dom';

export type PageHeaderProps = {
  title?: string;
  description?: string;
  to?: string;
} & PageContentContainerProps;

const StyledPageHeader = styled(PageContentContainer)`
  display: grid;
  grid-auto-columns: 4fr 1fr;
  grid-auto-flow: column;
  justify-content: flex-end;
  min-height: auto;
  padding: ${({ theme }) =>
    theme.pxToRem({
      top: 15,
      left: 24,
      right: 24,
      bottom: 15,
    })};

  & p {
    color: ${({ theme }) => theme.colors.neutral[600]};

    &.indent {
      margin-left: ${({ theme }) => theme.pxToRem(32)};
    }
  }

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    & > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-right: ${({ theme }) => theme.pxToRem(16)};

      & > svg {
        color: ${({ theme }) => theme.colors.neutral[600]};
        height: ${({ theme }) => theme.pxToRem(32)};
      }
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  description,
  to,
  ...props
}) => {
  return (
    <StyledPageHeader {...props}>
      <div>
        {to ? (
          <>
            <Link to={to}>
              <Icon icon="ChevronLeftIcon" />
              <h1 className="H-900">{title}</h1>
            </Link>
            {description && <p className="indent">{description}</p>}
          </>
        ) : (
          <>
            <h1 className="H-900">{title}</h1>
            {description && <p>{description}</p>}
          </>
        )}
      </div>
      <div>{children}</div>
    </StyledPageHeader>
  );
};

export default PageHeader;
