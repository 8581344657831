import React, { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

export type CardProps = {
  children?: React.ReactNode;
  elevation?: 'true';
  className?: string;
  style?: React.CSSProperties;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledCard = styled.div<CardProps>`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  box-shadow: ${({ elevation }) =>
    elevation === 'true' ? '0px 4px 8px rgba(16, 24, 64, 0.08)' : 'none'};
`;

const Card: React.FC<CardProps> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
