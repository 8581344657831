import styled from 'styled-components';
import React, { InputHTMLAttributes } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputError from 'components/atoms/input-error';
import { Range } from 'react-range';
import SliderLabel from 'components/atoms/slider-label';

export type SliderInputProps = {
  name: string;
  min: number;
  max: number;
  step: number;
  control: Control<any>;
  disabled?: boolean;
  error?: FieldError;
  marks?: boolean;
  unit?: string;
  onChange?: (value: number) => void;
  onFinalChange?: (value: number[]) => void;
  'data-testid'?: string;
} & Omit<
  React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'value' | 'ref' | 'type'
>;

const StyledSliderInput = styled.div<
  Pick<SliderInputProps, 'error' | 'disabled'> & {
    currentProgress: number;
  }
>`
  margin-top: ${({ theme }) => theme.pxToRem(40)};
  padding-bottom: ${({ theme, error }) =>
    error ? theme.pxToRem(40) : theme.pxToRem(56)};

  & .sliderTrack {
    height: ${({ theme }) => theme.pxToRem(8)};
    background: ${({ currentProgress, theme, disabled }) =>
      disabled
        ? theme.colors.neutral[300]
        : `linear-gradient(96.07deg, #549FF8 0%, #2FCBB1 ${currentProgress}%, ${theme.colors.neutral['300']} ${currentProgress}%, ${theme.colors.neutral['300']} 100%)`};
    border-radius: ${({ theme }) => theme.pxToRem(1)};
  }

  & .sliderThumb {
    position: relative;
    width: ${({ theme }) => theme.pxToRem(20)};
    height: ${({ theme }) => theme.pxToRem(20)};
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.neutral[500] : '#60acff'};
    border: none;
    border-radius: 50%;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    & .sliderLabel {
      position: absolute;
      top: ${({ theme }) => theme.pxToRem(-34)};
      left: 50%;
      transform: translateX(-50%);

      display: none;
    }

    &:hover,
    &:active {
      outline: ${({ theme, disabled }) =>
        disabled
          ? 'none'
          : `rgba(25, 118, 210, 0.12) solid ${theme.pxToRem(5)}`};
      & .sliderLabel {
        display: ${({ disabled }) => (disabled ? 'none' : 'inline-flex')};
      }
    }
  }

  & .sliderMark {
    width: ${({ theme }) => theme.pxToRem(4)};
    height: ${({ theme }) => theme.pxToRem(4)};
    background: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: 50%;

    &.active {
      background: #5276e1;
    }
  }
`;

const SliderInput: React.FC<SliderInputProps> = ({
  name,
  control,
  onChange,
  error,
  'data-testid': dataTestId,
  marks = true,
  onFinalChange,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ ...field }) => {
        // Remove fieldState and formState from props to avoid React warnings
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fieldState, formState, ...fieldProps } = field;
        const { min, max, step } = props;
        const currentProgress = ((field.field.value - min) / (max - min)) * 100;

        return (
          <>
            <StyledSliderInput
              currentProgress={currentProgress}
              error={error}
              disabled={props.disabled}
              data-testid={dataTestId}
            >
              <Range
                {...props}
                {...fieldProps}
                min={min}
                max={max}
                step={step}
                values={[field.field.value]}
                onFinalChange={(value) => {
                  onFinalChange?.(value);
                }}
                onChange={(values) => {
                  const value = values[0];
                  field.field.onChange(value);
                  onChange?.(value);
                }}
                renderMark={({ props: markProps, index }) =>
                  marks &&
                  index > 0 &&
                  index < max - min && (
                    <div
                      {...markProps}
                      style={{ ...markProps.style }}
                      data-testid={`slider-mark-${index}`}
                      className={`sliderMark ${
                        min + index * step < field.field.value &&
                        !props.disabled
                          ? 'active'
                          : ''
                      }`}
                    />
                  )
                }
                renderTrack={({ props: trackProps, children }) => (
                  <div
                    {...trackProps}
                    style={{ ...trackProps.style }}
                    className="sliderTrack"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props: thumbProps }) => (
                  <div
                    {...thumbProps}
                    style={{ ...thumbProps.style }}
                    className="sliderThumb"
                    aria-label="slider-thumb"
                  >
                    <div
                      className={`sliderMark ${props.disabled ? '' : 'active'}`}
                    />
                    <SliderLabel className="sliderLabel">
                      {`${
                        props?.unit
                          ? props?.unit !== '%'
                            ? `${props?.unit} ${field.field.value}`
                            : `${field.field.value} ${props?.unit}`
                          : ''
                      }`}
                    </SliderLabel>
                  </div>
                )}
              />
              {error && (
                <div className="error">
                  <InputError>{error.message}</InputError>
                </div>
              )}
            </StyledSliderInput>
            {error && <InputError>{error.message}</InputError>}
          </>
        );
      }}
    />
  );
};

export default SliderInput;
