import { useEffect } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'context';

import { useCreateDataSourceMutation } from 'api-client/mutations';
import LoadingOverlay from 'components/molecules/loading-overlay';
const CreateSource = () => {
  const user = useUser();
  const location = useLocation() as {
    state: {
      companyId?: string;
      sourceType?: 'google' | 'facebook' | 'mailchimp' | 'codat_shopify';
    };
  };

  const companyId = location?.state?.companyId;
  const sourceType = location?.state?.sourceType;
  const { mutate, isLoading, isError, isSuccess } =
    useCreateDataSourceMutation();

  useEffect(() => {
    if (isError) {
      return;
    }
    mutate({ companyId, sourceType, user: { id: user.id } });
  }, [mutate, companyId, sourceType, user.id, isError]);

  if (!companyId || !sourceType) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingOverlay title="Creating Data Source" />;
  }

  if (isError) {
    return <Navigate to="/error" />;
  }

  if (isSuccess) {
    return <Navigate to="/business-configuration" />;
  }

  return null;
};

export default CreateSource;
