import { createGlobalStyle, css } from 'styled-components';
import normalize from './@normalize';

const styles = css`
  html {
    color: ${({ theme }) => theme.colors.gray900};
    font-family: ${({ theme }): string => theme.fontFamilies.display};

    @media screen and (max-width: 1439px) {
      font-size: 80%;
    }
  }
  body {
    background-color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[250]
        : theme.colors.neutral[1000]};
    transition: background-color 1s;
    * {
      margin: 0;
      padding: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[800]
        : theme.colors.neutral[0]};
    transition: color 1s;

    &.H-900 {
      font-style: normal;
      font-weight: 600;

      font-size: ${({ theme }) => theme.pxToRem(24)};
      line-height: ${({ theme }) => theme.pxToRem(32)};

      @media (min-width: ${({ theme }) => theme.pxToRem(1281)}) {
        font-size: ${({ theme }) => theme.pxToRem(32)};
        line-height: ${({ theme }) => theme.pxToRem(40)};
      }
    }

    &.H-800 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(24)};
      line-height: ${({ theme }) => theme.pxToRem(32)};
    }

    &.H-700 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(20)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
    }

    &.H-600 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(18)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
    }

    &.H-500 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(18)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
    }
    &.H-400 {
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
    }

    &.H-300 {
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(12)};
      line-height: ${({ theme }) => theme.pxToRem(16)};
    }

    &.heading-200 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(12)};
      line-height: ${({ theme }) => theme.pxToRem(16)};
      text-transform: uppercase;
    }

    &.heading-100 {
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(10)};
      line-height: ${({ theme }) => theme.pxToRem(16)};
      text-transform: uppercase;
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(20)};
    color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[800]
        : theme.colors.neutral[0]};

    &.large-text {
      font-size: ${({ theme }) => theme.pxToRem(16)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      font-weight: 400;
    }

    &.small-text {
      font-size: ${({ theme }) => theme.pxToRem(12)};
      line-height: ${({ theme }) => theme.pxToRem(16)};
      font-weight: 400;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  ${normalize}

  ${styles}
`;

export default GlobalStyles;
