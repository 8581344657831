import { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import logos, { SoftwareCardLogoType } from '../software-card/logos';

export type SoftwareIconProps = {
  logo: SoftwareCardLogoType;
  sizePx: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledSoftwareIcon = styled.div<
  Pick<SoftwareIconProps, 'sizePx' | 'onClick'>
>`
  ${({ onClick }) => `& ${!!onClick ? 'button' : 'div'}`} {
    width: ${({ theme, sizePx }) => theme.pxToRem(sizePx)};
    height: ${({ theme, sizePx }) => theme.pxToRem(sizePx)};
    border: ${({ theme }) =>
      `${theme.pxToRem(1)} solid ${theme.colors.neutral[200]}`};
    background: transparent;
    box-sizing: border-box;
    border-radius: 50%;
    padding: ${({ theme }) => theme.pxToRem(3)};

    & svg {
      width: 100%;
      height: 100%;
    }

    cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
    &:hover {
      border-color: ${({ theme, onClick }) =>
        !!onClick ? theme.colors.neutral[400] : theme.colors.neutral[200]};
    }

    &:active {
      background: ${({ theme, onClick }) =>
        !!onClick ? theme.colors.neutral[100] : 'transparent'};
    }
  }
`;

const SoftwareIcon: React.FC<SoftwareIconProps> = ({
  logo,
  sizePx,
  onClick,
  ...props
}) => {
  const Logo = logos[logo];
  return (
    <StyledSoftwareIcon sizePx={sizePx} onClick={onClick} {...props}>
      {!!onClick ? (
        <button>
          <Logo />
        </button>
      ) : (
        <div>
          <Logo />
        </div>
      )}
    </StyledSoftwareIcon>
  );
};

export default SoftwareIcon;
