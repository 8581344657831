export * from './calculate-kpi-progress';

export const getRating = (rating?: number) => {
  if (!rating) return;

  if (rating < 33) {
    return 1;
  }

  if (rating >= 33 && rating < 66) {
    return 2;
  }

  return 3;
};
