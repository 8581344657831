import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Icon from 'components/atoms/icon';

import { Link } from 'react-router-dom';

import PageContentContainer from 'components/atoms/page-content-container';
import FinanceOfferCard from 'components/molecules/finance-offer-card';
import FundingOptionsDroppable from 'components/molecules/funding-options-droppable';
import LineChart from 'components/molecules/line-chart';
import Tab from 'components/atoms/tab';
import Tablist from 'components/molecules/tab-list';
import HonuSuggestionsIndicator from 'components/atoms/honu-suggestions-indicator';
import IntelligenceLoadingOverlay from 'components/template/intelligence-loading-overlay';

import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import {
  GET_FINANCIAL_OPTIONS_QUERY,
  getFinancialOptions,
  GET_FINANCING_SIMULATION_QUERY,
  getFinancingSimulation,
} from 'api-client/queries';
import { FinancingType } from 'api-client/queries';

import { useTheme } from 'context';
import { useLocation } from 'react-router-dom';

import { useGlobalPageTemplate } from 'context';

import { listForecastedMonths } from './utils';
import Loading from 'components/atoms/loading';

const PageContainer = styled.div`
  & h3 {
    margin-bottom: ${({ theme }) => theme.pxToRem(24)};
  }

  & p {
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  & > .page-header {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-content: flex-end;
    min-height: auto;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 15,
        left: 24,
        right: 24,
        bottom: 15,
      })};

    & > div:nth-child(1) {
      display: flex;
      align-items: center;

      & > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-right: ${({ theme }) => theme.pxToRem(16)};

        & > svg {
          color: ${({ theme }) => theme.colors.neutral[600]};
          height: ${({ theme }) => theme.pxToRem(32)};
        }
      }
    }

    & div:nth-child(3) {
      display: flex;
      justify-content: flex-end;
    }
  }

  & .page-content {
    min-height: auto;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 50% 50%;
    grid-row-gap: ${({ theme }) => theme.pxToRem(16)};
    grid-column-gap: ${({ theme }) => theme.pxToRem(16)};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 0,
        left: 0,
        right: 16,
        bottom: 0,
      })};

    &__finance-offers {
      overflow-y: scroll;
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
      height: ${({ theme }) => theme.pxToRem(380)};
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 32,
          right: 32,
          bottom: 32,
        })};

      &::-webkit-scrollbar {
        width: ${({ theme }) => theme.pxToRem(8)};
      }

      &::-webkit-scrollbar-track {
        margin-top: ${({ theme }) => theme.pxToRem(8)};
        margin-bottom: ${({ theme }) => theme.pxToRem(8)};
        border: none;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.neutral[400]};
        border-radius: ${({ theme }) => theme.pxToRem(16)};
      }

      & > .offers-heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.pxToRem(8)};
      }
    }

    &__offers-container {
      & > div {
        margin-bottom: ${({ theme }) => theme.pxToRem(12)};
      }
    }

    &__finance-impact {
      border-radius: ${({ theme }) => theme.pxToRem(16)};
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};

      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 32,
          right: 32,
          bottom: 32,
        })};
    }
  }

  & .forecast {
    min-height: ${({ theme }) => theme.pxToRem(16)};
    padding: 0px;

    &__finance-forecast {
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};

      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 32,
          right: 32,
          bottom: 32,
        })};
    }
  }

  & .financing-options-loading {
    height: ${({ theme }) => theme.pxToRem(250)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FinancingOptions = () => {
  const { setTheme } = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const uri = location.pathname;
  const [provider, setProvider] = useState<undefined | string>(undefined);
  const [draggableItems, setDraggableItems] = useState<FinancingType[] | []>(
    []
  );
  const [droppableItems, setDroppableItems] = useState<FinancingType[] | []>(
    []
  );
  const { minimizeNavbar } = useGlobalPageTemplate();

  const { data: financialOptionsData } = useQuery(
    [GET_FINANCIAL_OPTIONS_QUERY, { getAccessTokenSilently }] as const,
    getFinancialOptions
  );
  const {
    data: financingSimulationData,
    isLoading: isFinancingSimulationLoading,
  } = useQuery(
    [
      GET_FINANCING_SIMULATION_QUERY,
      {
        getAccessTokenSilently,
        provider,
        delay: droppableItems?.length > 0 ? 5500 : 0,
      },
    ] as const,
    getFinancingSimulation,
    {
      refetchInterval: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (Array.isArray(financialOptionsData)) {
      setDraggableItems(financialOptionsData);
    }
  }, [financialOptionsData, setDraggableItems]);

  useEffect(() => {
    if (droppableItems.length > 0) {
      setTheme?.((prevState) => ({
        ...prevState,
        mode: 'dark',
      }));
    }
  }, [droppableItems.length, setTheme]);

  useEffect(() => {
    return () => {
      setTheme?.((prevState) => ({
        ...prevState,
        mode: 'light',
      }));
    };
  }, [uri, setTheme]);

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;

        if (destination?.droppableId === source.droppableId) return;

        if (destination?.droppableId.includes('drop')) {
          if (droppableItems.length === 0) {
            setDraggableItems((prevState) =>
              prevState.filter(
                ({ id }) => id !== draggableItems[source.index]?.id
              )
            );

            setDroppableItems(() => {
              return [...[draggableItems[source.index]]];
            });
            setProvider(draggableItems[source.index].id);

            return;
          }
          setDraggableItems((prevState) => {
            const option = droppableItems[0];
            //remove dropped item first
            const filter = prevState.filter(
              ({ id }) => id !== draggableItems[source.index]?.id
            );
            //add the currently dropped item to the draggable list
            filter.splice(source.index, 0, option);
            return filter;
          });
          setDroppableItems([...[draggableItems[source.index]]]);
          setProvider(draggableItems[source.index].id);
        }
      }}
    >
      <IntelligenceLoadingOverlay
        show={droppableItems.length > 0 && isFinancingSimulationLoading}
        delay={2}
        notifications={[
          'Accessing Financing Options',
          'Updating Financing Forecast',
          'Running Simulation',
        ]}
      />
      <PageContainer>
        <PageContentContainer
          className="page-header"
          minimizeNavbar={minimizeNavbar}
        >
          <div>
            <Link to="/overview">
              <Icon icon="ChevronLeftIcon" />
              <h1 className="H-900">Financing Options</h1>
            </Link>
          </div>
          <div />
          <div>
            <HonuSuggestionsIndicator
              disabled={droppableItems.length === 0}
              localStorageFlag="financeIntelligenceIndicator"
              description="You are now in simulation mode. Explore different financing options
            and see how those could affect your revenue."
            />
          </div>
        </PageContentContainer>
        <PageContentContainer
          className="page-content"
          wrapper
          minimizeNavbar={minimizeNavbar}
        >
          <div className="page-content__finance-offers">
            <div className="offers-heading">
              <h3 className="H-600">Offers</h3>
              <Tablist>
                <Tab isSelected>Current Eligible</Tab>
                <Tab>Upcoming</Tab>
              </Tablist>
            </div>
            <p>
              Here are some funding options that you are currently eligible for
            </p>
            {isFinancingSimulationLoading ? (
              <div className="financing-options-loading">
                <Loading />
              </div>
            ) : (
              <Droppable droppableId="drag-options">
                {(provided) => {
                  return (
                    <div
                      className="page-content__offers-container"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {draggableItems?.map(
                        (
                          { type, amount_max, duration_max, interest, id },
                          index
                        ) => {
                          return (
                            <Draggable
                              key={index}
                              draggableId={`drag-${index}`}
                              index={index}
                            >
                              {(provided) => {
                                return (
                                  <FinanceOfferCard
                                    innerRef={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    id={id}
                                    type={type}
                                    max={amount_max}
                                    duration={duration_max}
                                    interest={interest}
                                  />
                                );
                              }}
                            </Draggable>
                          );
                        }
                      )}

                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            )}
          </div>
          <div className="page-content__finance-impact">
            <h3 className="H-600">Impact</h3>
            <p>
              Drag one of your Funding Options into the box below to see how it
              impacts your business
            </p>
            <Droppable droppableId={`drop-options`}>
              {(provided) => {
                return (
                  <FundingOptionsDroppable
                    className="page-content__offers-container"
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {droppableItems?.map(
                      (
                        { id, type, amount_max, duration_max, interest },
                        index
                      ) => {
                        return (
                          <Draggable
                            key={index}
                            draggableId={`drop-${index}`}
                            index={index}
                          >
                            {(provided) => {
                              return (
                                <FinanceOfferCard
                                  id={id}
                                  innerRef={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  type={type}
                                  max={amount_max}
                                  duration={duration_max}
                                  interest={interest}
                                />
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </FundingOptionsDroppable>
                );
              }}
            </Droppable>
          </div>
        </PageContentContainer>
        <PageContentContainer
          wrapper
          className="forecast"
          minimizeNavbar={minimizeNavbar}
        >
          <div className="forecast__finance-forecast">
            <h3 className="H-600">Monthly Revenue - Forecast</h3>
            <LineChart
              labels={listForecastedMonths(
                financingSimulationData?.default_revenues
              )}
              datasets={[
                {
                  label: 'No Financing',
                  data: financingSimulationData?.default_revenues || [],
                  color: ['#94C6E3', '#A78DF5'],
                },
                ...(financingSimulationData?.financing_revenues
                  ? [
                      {
                        label: 'With Financing',
                        data: financingSimulationData?.financing_revenues || [],
                        color: '#FEB2BF',
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        </PageContentContainer>
      </PageContainer>
    </DragDropContext>
  );
};

export default FinancingOptions;
