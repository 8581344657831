import { FulfillmentMethodNodeType } from '..';

export const fulfillmentMethodNodes: FulfillmentMethodNodeType[] = [
  {
    id: 'fulfillmentMethod-0',
    type: 'FulfillmentMethodNode',
    data: {
      values: [
        {
          id: 'in-house',
          icon: 'Shop',
          label: 'In House',
        },
        {
          id: '3pl',
          icon: 'Railway',
          label: '3PL',
        },
        {
          id: 'dropshipping',
          icon: 'Box',
          label: 'Dropshipping',
        },
      ],
    },
    position: { x: 2300, y: 335 },
  },
];
