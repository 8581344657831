import styled from 'styled-components';

import { Handle, Position } from 'react-flow-renderer';

import CompanyLogo from './logo/company-logo.png';

export type BusinessOverviewCenterNodeProps = {
  children?: React.ReactNode;
};

const StyledBusinessOverviewCenterNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.pxToRem(132)};
  height: ${({ theme }) => theme.pxToRem(132)};
  background-image: ${({ theme }) => theme.colors.primary['B500']};
  border: 40px solid ${({ theme }) => theme.colors.neutral[0]};
  border-radius: 50%;
  box-shadow: 0px 13px 16px #00000029;
  text-align: center;
  cursor: default;

  & > img {
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    width: ${({ theme }) => theme.pxToRem(82)};
    height: ${({ theme }) => theme.pxToRem(82)};
    border-radius: 50%;
  }
`;

const BusinessOverviewCenterNode: React.FC<BusinessOverviewCenterNodeProps> = ({
  children,
}) => {
  return (
    <>
      <Handle
        id="top"
        type="source"
        position={Position.Top}
        style={{
          opacity: 0,
          top: 15,
        }}
      />
      <Handle
        id="left"
        type="source"
        position={Position.Left}
        style={{
          left: 30,
          opacity: 0,
        }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{
          opacity: 0,
          right: 5,
        }}
      />
      <StyledBusinessOverviewCenterNode>
        <img src={CompanyLogo} alt="Company Logo" />
        {children}
      </StyledBusinessOverviewCenterNode>
    </>
  );
};

export default BusinessOverviewCenterNode;
