import styled from 'styled-components';

import { Handle, Position } from 'react-flow-renderer';
import { useNavigate } from 'react-router-dom';

export type BusinessMetricNodeProps = {
  children?: React.ReactNode;
  data: {
    value: number;
    title: string;
    unit: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
    to?: string;
    nodeType?: string;
  };
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledBusinessMetricNode = styled.div<{
  nodeType?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}>`
  display: flex;
  flex-direction: ${({ position }) => {
    if (position === 'left') {
      return 'row-reverse';
    }
    if (position === 'bottom') {
      return 'column';
    }

    if (position === 'top') {
      return 'column-reverse';
    }

    return 'row';
  }};
  align-items: center;
  cursor: pointer;

  &:hover {
    & > div:nth-child(1) {
      ${({ nodeType }) => {
        if (nodeType === 'finance') {
          return `background: #D3E2F9`;
        }

        if (nodeType === 'ops') {
          return `background: #E2F3F0;`;
        }

        return `background:  #e7e4f6;`;
      }};
    }

    & > div:nth-child(2) {
      & > p {
        color: ${({ theme }) => theme.colors.neutral[200]};
      }
    }
  }

  & > div:nth-child(1) {
    width: ${({ theme }) => theme.pxToRem(60)};
    height: ${({ theme }) => theme.pxToRem(60)};
    border-radius: 50%;
    ${({ nodeType, theme }) => {
      if (nodeType === 'finance') {
        return `background-image: ${theme.colors.primary['B400']};`;
      }

      if (nodeType === 'ops') {
        return `background: #99DFD2 0% 0% no-repeat padding-box;`;
      }

      return ` background: #b0a5f6 0% 0% no-repeat padding-box`;
    }};
    text-align: center;
    & > h3 {
      color: ${({ theme }) => theme.colors.neutral[0]};
      line-height: ${({ theme }) => theme.pxToRem(60)};
    }
  }

  & > div:nth-child(2) {
    margin: ${({ theme }) => theme.pxToRem(8)};

    & > p {
      max-width: ${({ theme }) => theme.pxToRem(120)};
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const BusinessMetricNode: React.FC<BusinessMetricNodeProps> = ({
  data,
  ...props
}) => {
  const { value, unit, title, position = 'right', to, nodeType } = data;
  const navigate = useNavigate();

  return (
    <>
      <Handle
        id="left"
        type="source"
        position={Position.Left}
        style={{
          left: '30px',
          top: '55px',
          opacity: 0,
          cursor: 'pointer',
        }}
      />
      <Handle
        id="right"
        type="source"
        position={Position.Right}
        style={{
          opacity: 0,
          top: 35,
          right: 10,
          cursor: 'pointer',
        }}
      />
      <Handle
        id="top"
        type="source"
        position={Position.Top}
        style={{
          opacity: 0,
          top: 35,
          right: 10,
          cursor: 'pointer',
        }}
      />
      <Handle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{
          opacity: 0,
          left: 40,
          bottom: 30,
          cursor: 'pointer',
        }}
      />
      <StyledBusinessMetricNode
        {...props}
        nodeType={nodeType}
        position={position}
        onClick={() => {
          if (to) {
            navigate(to);
          }
        }}
      >
        <div>
          <h3 className="H-500">
            {unit === '£' ? `${unit}${value}` : `${value}${unit}`}
          </h3>
        </div>
        <div>
          <p>{title}</p>
        </div>
      </StyledBusinessMetricNode>
    </>
  );
};

export default BusinessMetricNode;
