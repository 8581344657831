import { RefObject, HTMLAttributes } from 'react';

import styled from 'styled-components';

import * as LogoImages from './logos';

export type ShopFrontDraggableItemProps = {
  children?: React.ReactNode;
  label: string;
  logo?: 'Shopify' | 'Amazon' | 'WooCommerce';
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  innerRef?: any;
  type?: string;
  isDraggingOver?: string | boolean;
  ref?:
    | ((instance: HTMLElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledShopFrontDraggableItem = styled.div<
  Pick<ShopFrontDraggableItemProps, 'isDraggingOver'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? `${theme.pxToRem(84)} !important` : '100%'};
  height: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? `${theme.pxToRem(84)} !important` : '100%'};
  border: ${({ theme }) => `1px solid ${theme.colors.neutral[400]}`};
  border-radius: ${({ theme }) => theme.pxToRem(4)};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.tertiary['B50'] : theme.colors.neutral[0]};
  cursor: ${({ isDraggingOver }) => (isDraggingOver ? 'grabbing' : 'grab')};
  position: relative;

  & > p {
    color: ${({ theme, isDraggingOver }) =>
      isDraggingOver ? theme.colors.neutral[800] : theme.colors.neutral[600]};
  }

  & > .logo {
    width: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(40)};
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};
  }
`;

const ShopFrontDraggableItem: React.FC<ShopFrontDraggableItemProps> = ({
  label,
  logo,
  innerRef,
  isDraggingOver,
  ...props
}) => {
  return (
    <StyledShopFrontDraggableItem
      {...props}
      ref={innerRef}
      isDraggingOver={isDraggingOver}
    >
      <div className="logo">
        {logo && <img src={LogoImages[logo]} alt={`${logo} Logo Image`} />}
      </div>
      <p>{label}</p>
    </StyledShopFrontDraggableItem>
  );
};

export default ShopFrontDraggableItem;
