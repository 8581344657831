import styled from 'styled-components';
import Icon from 'components/atoms/icon';
import IconParkIcon, {
  IconParkIconProps,
} from 'components/atoms/icon/IconPark';
import { RefObject, HTMLAttributes } from 'react';

import Button from 'components/atoms/button';

export type BusinessModelDraggableItemProps = {
  label: string;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: IconParkIconProps['icon'];
  innerRef?: any;
  type?: string;
  isDraggingOver?: string | boolean;
  ref?:
    | ((instance: HTMLElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const ListView = styled.div<
  Pick<BusinessModelDraggableItemProps, 'isDraggingOver'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral[400]}`};
  border-radius: ${({ theme }) => theme.pxToRem(4)};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.tertiary['B50'] : theme.colors.neutral[0]};
  cursor: ${({ isDraggingOver }) => (isDraggingOver ? 'grabbing' : 'grab')};
  position: relative;

  & > .icon {
    width: ${({ theme }) => theme.pxToRem(40)};
    height: ${({ theme }) => theme.pxToRem(40)};
    color: ${({ theme }) => theme.colors.neutral[700]};
  }
`;

const StyledBusinessModelDraggableItem = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(40)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutral[0]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  cursor: default;
  margin: ${({ theme }) =>
    theme.pxToRem({ top: 8, bottom: 8, right: 0, left: 0 })};
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 0, bottom: 0, right: 16, left: 16 })};

  & .draggableContainer {
    display: flex;
    text-transform: uppercase;

    & .icon {
      height: ${({ theme }) => theme.pxToRem(20)};
      width: ${({ theme }) => theme.pxToRem(20)};
      margin-right: ${({ theme }) => theme.pxToRem(8)};
      color: ${({ theme }) => theme.colors.neutral[900]};
    }
  }

  & > button {
    background: transparent;
    border: unset;
    border-radius: 50%;
    width: ${({ theme }) => theme.pxToRem(32)};
    height: ${({ theme }) => theme.pxToRem(32)};

    & svg {
      position: absolute;
      width: ${({ theme }) => theme.pxToRem(16)};
      height: ${({ theme }) => theme.pxToRem(16)};
      & > path {
        fill: ${({ theme }) => theme.colors.neutral[500]};
      }
    }
  }
`;

const BusinessModelDraggableItem: React.FC<BusinessModelDraggableItemProps> = ({
  label,
  icon,
  onClose,
  innerRef,
  type,
  isDraggingOver,
  ...props
}) => {
  if (type === 'drag') {
    return (
      <ListView {...props} ref={innerRef} isDraggingOver={isDraggingOver}>
        <IconParkIcon className="icon" icon={icon} />
        <p>{label}</p>
      </ListView>
    );
  }
  return (
    <StyledBusinessModelDraggableItem {...props} ref={innerRef}>
      <div className="draggableContainer">
        {icon && <IconParkIcon className="icon" icon={icon} />}
        <h6 className="H-400">{label}</h6>
      </div>
      <Button
        aria-label="Close Side Panel"
        onClick={onClose}
        appearance="minimal"
      >
        <Icon icon="XIcon" />
      </Button>
    </StyledBusinessModelDraggableItem>
  );
};

export default BusinessModelDraggableItem;
