import styled from 'styled-components';

import Avatar from 'components/atoms/avatar';
import { Popover, Position } from 'evergreen-ui';
import Menu from 'components/atoms/menu';
import Icon from 'components/atoms/icon';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export type AvatarMenuProps = {
  src?: string;
  size?: number;
  name?: string;
  className?: string;
};

const StyledAvatarMenu = styled.div`
  & > button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    & svg {
      width: ${({ theme }) => theme.pxToRem(16)};
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  & .menu-item-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      padding-right: ${({ theme }) => theme.pxToRem(8)};
      width: ${({ theme }) => theme.pxToRem(16)};
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const AvatarMenu: React.FC<AvatarMenuProps> = ({
  src,
  size = 39,
  name,
  className,
  ...props
}) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  return (
    <StyledAvatarMenu className={className} {...props}>
      <Popover
        display="false"
        position={Position.BOTTOM_LEFT}
        content={
          <Menu>
            <Menu.Group>
              <StyledMenuItem
                onClick={() => {
                  navigate('/account-settings');
                }}
              >
                <div className="menu-item-content">
                  <Icon icon="CogIcon" />
                  <p>Settings</p>
                </div>
              </StyledMenuItem>

              <StyledMenuItem
                onClick={() => {
                  logout();
                }}
              >
                <div className="menu-item-content">
                  <Icon icon="LogoutIcon" />
                  <p>Log Out</p>
                </div>
              </StyledMenuItem>
            </Menu.Group>
          </Menu>
        }
      >
        <button>
          <Avatar src={src} size={size} name={name} />
          <Icon icon="ChevronDownIcon" />
        </button>
      </Popover>
    </StyledAvatarMenu>
  );
};

export default AvatarMenu;
