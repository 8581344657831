import { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';
import KpiSimulationCard from 'components/molecules/kpi-simulation-card';
import SalesFunnel from 'components/organisms/sales-funnel';
import LineChart from 'components/molecules/line-chart';
// import SpiderChart from 'components/molecules/spider-chart';
// import Button from 'components/atoms/button';
// import Icon from 'components/atoms/icon';
// import AvatarMenu from 'components/molecules/avatar-menu';
import ShieldCreationLoader from 'components/atoms/shield-creation-loader';
import IntelligenceLoadingOverlay from 'components/template/intelligence-loading-overlay';
import BarChart from 'components/molecules/bar-chart';
import PageHeader from 'components/molecules/page-header';
import HonuSuggestionsIndicator from 'components/atoms/honu-suggestions-indicator';
import OnboardingNav from 'components/molecules/onboarding-nav';
import Icon from 'components/atoms/icon';

import { useLocation, useNavigate } from 'react-router-dom';

import { kpiList } from './kpi-list';

import { useQuery } from 'react-query';

import {
  GET_SIMULATION_KPI_QUERY,
  getSimulationKpi,
  getKpis,
  GET_KPIS_QUERY,
} from 'api-client/queries';

import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from 'context';

import { useForm } from 'react-hook-form';

const PageContainer = styled.div`
  & .page-header {
    min-height: auto;
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 24,
        right: 24,
        bottom: 32,
      })};

    & > div:nth-child(1) {
      display: flex;
      align-items: center;

      & > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-right: ${({ theme }) => theme.pxToRem(16)};

        & > svg {
          color: ${({ theme }) => theme.colors.neutral[600]};
          height: ${({ theme }) => theme.pxToRem(32)};
        }
      }

      & > .nav-cta {
        display: flex;
        & > button {
          width: ${({ theme }) => theme.pxToRem(32)};
          height: ${({ theme }) => theme.pxToRem(42)};
          box-shadow: 0px 3px 11px #0000001a;
          margin-right: ${({ theme }) => theme.pxToRem(8)};
          & svg {
            position: absolute;
          }
        }
      }
    }
    & > div:nth-child(3) {
      align-self: flex-end;
    }
  }

  & .page-content {
    min-height: auto;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: 50% 50%;
    grid-row-gap: ${({ theme }) => theme.pxToRem(16)};
    grid-column-gap: ${({ theme }) => theme.pxToRem(16)};
    padding-top: 0px;
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};

    &__kpi-simulation {
      overflow: hidden;
    }

    &__sales-funnel-container {
      & > .sales-funnel {
        background-color: ${({ theme }) =>
          theme.mode === 'light'
            ? theme.colors.neutral[50]
            : theme.colors.neutral[950]};
        border: ${({ theme }) =>
          `${theme.pxToRem(2)} solid ${
            theme.mode === 'light'
              ? theme.colors.neutral[0]
              : theme.colors.neutral[950]
          }`};
        border-radius: ${({ theme }) => theme.pxToRem(16)};

        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 32,
            left: 32,
            right: 32,
            bottom: 32,
          })};

        & > div {
          & > h2 {
            margin-bottom: ${({ theme }) => theme.pxToRem(24)};
          }
        }
      }
    }

    &__line-chart {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${theme.colors.white}`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
      overflow: hidden;

      & > h2 {
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 32,
            left: 32,
            right: 32,
            bottom: 32,
          })};
      }

      & > .overlay {
        position: absolute;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(193, 196, 214, 0.8);
      }
    }

    &__spider-chart {
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 32,
          right: 32,
          bottom: 32,
        })};
      & > h2 {
        margin-bottom: ${({ theme }) => theme.pxToRem(24)};
      }
    }
  }

  & .navigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(16)};
    left: 50%;
    transform: translateX(-40%);

    & .selected-goals-list {
      display: flex;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[600]};

      & > svg {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(44)};
      }
      & > p {
        max-width: ${({ theme }) => theme.pxToRem(650)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const Kpi = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setTheme, theme } = useTheme();
  const [sliderSimulationMode, setSliderSimulationMode] = useState(false);
  const businessMetric =
    location.pathname.split('/')[1] === 'sales-and-marketing'
      ? 'sales_and_marketing'
      : (location.pathname.split('/')[1] as
          | 'sales_and_marketing'
          | 'operations'
          | 'finance');
  const kpiUri = location.pathname.split('/')[2];
  const kpiKey = kpiList?.find((kpi) => kpi.id === kpiUri)?.key;
  const { getAccessTokenSilently } = useAuth0();
  const [change, setChange] = useState<number | undefined>(undefined);

  const { data: kpisData, isLoading: kpisIsLoading } = useQuery(
    [GET_KPIS_QUERY, { getAccessTokenSilently }] as const,
    getKpis
  );

  const { data: kpiData, isLoading: kpiIsLoading } = useQuery(
    [
      GET_SIMULATION_KPI_QUERY,
      { getAccessTokenSilently, kpiKey, change, delay: 0 },
    ] as const,
    getSimulationKpi,
    { enabled: typeof change === 'number' }
  );

  const kpi = useMemo(
    () => kpisData?.kpis[businessMetric]?.find((kpi) => kpi.key === kpiKey),
    [kpisData, kpiKey, businessMetric]
  );

  const { control, setValue } = useForm({
    defaultValues: {
      slider: 0,
    },
  });

  useEffect(() => {
    if (!kpiKey) {
      navigate('/404');
    }
    setChange(undefined);

    setValue(
      'slider',
      kpi?.value
        ? kpi?.is_percentage
          ? Math.round(kpi.value * 100)
          : kpi.value
        : 0
    );

    return () => {
      setTheme?.((prevState) => ({
        ...prevState,
        mode: 'light',
      }));
    };
  }, [
    kpiUri,
    kpiKey,
    navigate,
    setTheme,
    setChange,
    setValue,
    kpi?.value,
    kpi?.is_percentage,
  ]);
  const currentRevenue = kpisData?.all_metrics.default_revenues[0]
    ? kpisData?.all_metrics.default_revenues[0]
    : 0;
  const forecastedRevenue =
    kpiData?.all_metrics?.simulated_revenues &&
    kpiData?.all_metrics?.simulated_revenues[0]
      ? kpiData?.all_metrics?.simulated_revenues[0]
      : 0;

  return (
    <>
      <IntelligenceLoadingOverlay
        show={false}
        delay={1}
        notifications={[
          'Running Simulation',
          'Updating Sales Funnel',
          'Calculating Forecasted Revenue',
        ]}
      />
      <PageContainer>
        <PageHeader
          title={kpiList?.find((kpi) => kpi.id === kpiUri)?.data?.title}
        >
          <HonuSuggestionsIndicator
            disabled={!sliderSimulationMode}
            localStorageFlag="kpiIntelligenceIndicator"
            description="You are now in simulation mode. Use the slider to explore how changes in that KPI could affect your business."
          />
        </PageHeader>
        {/* <PageContentContainer className="page-header">
          <div>
            <Link to="/sales-and-marketing">
              <Icon icon="ChevronLeftIcon" />
              <h1 className="H-900">
                {kpiList?.find((kpi) => kpi.id === kpiUri)?.data?.title}
              </h1>
            </Link>
            <div className="nav-cta">
              <Button
                disabled={kpiList.findIndex((kpi) => kpi.id === kpiUri) === 0}
                onClick={() => {
                  const currentKpiIndex = kpiList?.findIndex(
                    (kpi) => kpi.id === kpiUri
                  );
                  if (currentKpiIndex - 1 >= 0) {
                    navigate(
                      `/sales-and-marketing/${
                        kpiList[currentKpiIndex - 1].data.to
                      }`
                    );
                  }
                }}
              >
                <Icon icon="ChevronLeftIcon" />
              </Button>
              <Button
                disabled={
                  kpiList?.findIndex((kpi) => kpi.id === kpiUri) + 1 === 9
                }
                onClick={() => {
                  const currentKpiIndex = kpiList?.findIndex(
                    (kpi) => kpi.id === kpiUri
                  );

                  if (currentKpiIndex + 1 <= 9) {
                    navigate(
                      `/sales-and-marketing/${
                        kpiList[currentKpiIndex + 1].data.to
                      }`
                    );
                  }
                }}
              >
                <Icon icon="ChevronRightIcon" />
              </Button>
            </div>
          </div>
          <div />
          <div>
            <AvatarMenu name={name} />
          </div>
        </PageContentContainer> */}
        <PageContentContainer className="page-content" wrapper>
          <div className="page-content__kpi-simulation">
            <KpiSimulationCard
              simulationMode={sliderSimulationMode}
              control={control}
              loading={kpisIsLoading}
              name="slider"
              unit={kpi?.is_percentage ? '%' : '£'}
              disabled={kpiIsLoading}
              min={kpi?.scale?.start || 0}
              max={kpi?.scale?.end || 100}
              onFinalChange={(value) => {
                const sliderValue = value[0];

                if (
                  kpi?.value &&
                  sliderValue !== Math.round(kpi?.value * 100)
                ) {
                  if (theme.mode !== 'dark') {
                    setTheme?.((prevState) => ({
                      ...prevState,
                      mode: 'dark',
                    }));
                  }

                  const calculateChangeValue = kpi?.value
                    ? kpi?.is_percentage
                      ? ((sliderValue - Math.round(kpi?.value * 100)) /
                          Math.round(kpi?.value * 100)) *
                        100
                      : ((sliderValue - kpi?.value) / kpi?.value) * 100
                    : 0;

                  setChange(Math.round(calculateChangeValue));
                  setSliderSimulationMode(true);
                  return;
                }
                setTheme?.((prevState) => ({
                  ...prevState,
                  mode: 'light',
                }));
                setSliderSimulationMode(false);
                setChange(0);
              }}
            />
          </div>
          <div className="page-content__sales-funnel-container">
            <div className="sales-funnel">
              <h2 className="H-700">Sales Funnel</h2>
              <SalesFunnel
                loading={kpiIsLoading || kpisIsLoading}
                funnelCells={
                  kpiData
                    ? Object.keys(kpiData?.funnels?.sales).map(
                        //@ts-ignore
                        (key) => kpiData?.funnels?.sales[key]
                      )
                    : kpisData
                    ? Object.keys(kpisData?.funnels?.sales).map(
                        //@ts-ignore
                        (key) => kpisData?.funnels?.sales[key]
                      )
                    : []
                }
                vertical
              />
            </div>
          </div>
          <div className="page-content__line-chart">
            <div className="overlay">
              <h2 className="H-700">Coming Soon</h2>
              <p className="large-text">KPI Historic Data</p>
            </div>
            <h2 className="H-700">{kpi?.name}</h2>
            <div>
              <LineChart
                labels={
                  kpi && Array.isArray(kpi?.historical_data)
                    ? kpi.historical_data.map(({ month }) => month)
                    : []
                }
                datasets={[
                  {
                    label: 'Current',
                    data:
                      kpi && Array.isArray(kpi?.historical_data)
                        ? kpi.historical_data.map(({ value }) => value)
                        : [],
                    color: ['#94C6E3', '#A78DF5'],
                  },
                  // {
                  //   label: 'Forecast',
                  //   data: [
                  //     13000, 14000, 17000, 16000, 29000, 15000, 13000, 12000,
                  //   ],
                  //   color: '#FEB2BF',
                  // },
                ]}
              />
            </div>
          </div>
          <div className="page-content__spider-chart">
            <h2 className="H-700">Revenue</h2>
            {kpisIsLoading ? (
              <ShieldCreationLoader />
            ) : (
              <>
                <BarChart
                  labels={['Revenues']}
                  dataSets={[
                    {
                      label: 'Current',
                      data: [currentRevenue],
                    },
                    ...(kpiData?.all_metrics?.simulated_forecast && change !== 0
                      ? [
                          {
                            label: 'Forecasted',
                            data: [forecastedRevenue],
                          },
                        ]
                      : []),
                  ]}
                />
                {/* <SpiderChart
                  labels={['Revenues', 'Profitability', 'Solvency']}
                  currentDataset={
                    kpisData?.all_metrics && [
                      kpisData?.all_metrics?.default_forecast?.revenues || 0,
                      kpisData?.all_metrics?.default_forecast?.profitability ||
                        0,
                      kpisData?.all_metrics?.default_forecast?.solvency || 0,
                    ]
                  }
                  forecastedDataset={
                    kpiData?.all_metrics && [
                      kpiData?.all_metrics?.simulated_forecast?.revenues || 0,
                      kpiData?.all_metrics?.simulated_forecast?.profitability ||
                        0,
                      kpiData?.all_metrics?.simulated_forecast?.solvency || 0,
                    ]
                  }
                /> */}
              </>
            )}
          </div>
          {change && (
            <div className="navigation">
              <OnboardingNav
                backButtonLabel="Discard"
                nextButtonLabel="See Suggested Actions"
                onClickNext={() => {
                  navigate(`/choose-actions?kpi=${kpi?.key}`);
                }}
                onClickBack={() => {
                  setChange(
                    Math.round(
                      kpi?.value
                        ? kpi?.is_percentage
                          ? Math.round(kpi.value * 100)
                          : kpi.value
                        : 0
                    )
                  );
                  setValue(
                    'slider',
                    kpi?.value
                      ? kpi?.is_percentage
                        ? Math.round(kpi.value * 100)
                        : kpi.value
                      : 0
                  );
                }}
              >
                <div className="selected-goals-list">
                  <Icon icon="CheckCircleIcon" variant="outline" />
                  <p className="large-text">{kpi?.name}</p>
                </div>
              </OnboardingNav>
            </div>
          )}
        </PageContentContainer>
      </PageContainer>
    </>
  );
};

export default Kpi;
