import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';

import { ActionType } from 'api-client/queries';

export const GET_SELECTED_ACTIONS_QUERY = 'GET_SELECTED_ACTIONS_QUERY';

export const getSelectedActions = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
      }
    ]
  >;
}): Promise<ActionType[]> => {
  const { getAccessTokenSilently } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/actions/selected`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (!response.ok) {
    throw Error('Network Error');
  }

  const {
    data: { collection: actions },
  } = await response.json();

  return actions;
};
