import styled from 'styled-components';

import {
  Button as EuiButton,
  ButtonProps as EuiButtonProps,
} from 'evergreen-ui';
import Icon from 'components/atoms/icon';
import { IconProps } from 'components/atoms/icon/Icon';

export type ButtonProps = {
  appearance?: 'default' | 'primary' | 'minimal' | 'error';
  disabled?: boolean;
  iconStart?: IconProps['icon'];
  iconEnd?: IconProps['icon'];
  icon?: IconProps['icon'];
} & Omit<EuiButtonProps, 'size'>;

const StyledButton = styled(EuiButton)<Pick<ButtonProps, 'icon'>>`
  display: flex;
  align-items: center;

  & .iconStart {
    margin-right: ${({ theme }) => theme.pxToRem(4)};
    height: ${({ theme }) => theme.pxToRem(14)};
    width: auto;
  }
  & .iconEnd {
    margin-left: ${({ theme }) => theme.pxToRem(8)};
    height: ${({ theme }) => theme.pxToRem(14)};
    width: auto;
  }
  & .mainIcon {
    height: ${({ theme }) => theme.pxToRem(20)};
    width: auto;
  }

  ${({ theme, icon }) => (icon ? `padding: ${theme.pxToRem(10)}` : '')}
`;

/**
 * To add icon to the left / right side of the `Button` component, you
 * can set the `iconStart` / `iconEnd` property.
 * To use the `Button` component as an icon button, you can set the `icon` property
 * which will override the `children`, `iconStart`, `iconEnd` properties
 * and only display the icon.
 */

const Button: React.FC<ButtonProps> = ({
  children,
  iconStart,
  iconEnd,
  ...props
}) => {
  return (
    <StyledButton {...props}>
      {props.icon ? (
        <Icon icon={props.icon} className="mainIcon" />
      ) : (
        <>
          {iconStart && <Icon icon={iconStart} className="iconStart" />}
          {children}
          {iconEnd && <Icon icon={iconEnd} className="iconEnd" />}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
