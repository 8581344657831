import styled from 'styled-components';

import DropTarget from 'components/atoms/drop-target';

import { Droppable, Draggable } from 'react-beautiful-dnd';

export type FulfillmentMethodDroppableNodeProps = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  droppableItems?: [];
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledFulfillmentMethodDroppableNode = styled.div``;

const DroppableContainer = styled.div<{ isDraggingOver?: boolean }>`
  width: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.pxToRem(92) : theme.pxToRem(0)};
  height: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.pxToRem(92) : theme.pxToRem(0)};
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  position: absolute;

  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.neutral[50] : theme.colors.neutral[0]};
  cursor: ${({ isDraggingOver }) => (isDraggingOver ? 'grabbing' : 'default')};
`;

const FulfillmentMethodDroppableNode: React.FC<
  FulfillmentMethodDroppableNodeProps
> = ({ onClick, droppableItems, ...props }) => {
  return (
    <StyledFulfillmentMethodDroppableNode {...props}>
      <DropTarget label="Add Fulfillment Method" onClick={onClick}>
        <Droppable droppableId="shop-droppable">
          {(provided, snapshot) => {
            return (
              <DroppableContainer
                isDraggingOver={snapshot.isDraggingOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {droppableItems?.map(({ id, label }, index) => {
                  <Draggable
                    key={`${index}-${id}`}
                    index={index}
                    draggableId={`drop-${index}-${label}`}
                  >
                    {(provided) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        ></div>
                      );
                    }}
                  </Draggable>;
                })}
                {provided.placeholder}
              </DroppableContainer>
            );
          }}
        </Droppable>
      </DropTarget>
    </StyledFulfillmentMethodDroppableNode>
  );
};

export default FulfillmentMethodDroppableNode;
