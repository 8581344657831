export const listForecastedMonths = (forecast?: number[]) => {
  const currentDate = new Date();
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (!Array.isArray(forecast)) return [];

  let monthIndex: number[] = [];
  let count = currentDate.getMonth();

  forecast.forEach(() => {
    if (count !== 1 && count <= 12) {
      monthIndex.push(count);
      count = count + 1;
    } else {
      monthIndex.push(1);
      count = 2;
    }
  });

  return monthIndex.map((i) => month[i - 1]);
};
