import styled from 'styled-components';

import CanvasSidePanel from 'components/molecules/canvas-side-panel';
import { CanvasSidePanelProps } from '../canvas-side-panel/CanvasSidePanel';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { StoreFrontItemType } from 'pages/business-configuration/nested-routes/business-blueprint/config';
import ShopFrontDraggableItem from 'components/molecules/shop-front-draggable-item';

export type StoreFrontSidePanelProps = {
  children?: React.ReactNode;
  onClose?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  draggableItems?: StoreFrontItemType[];
} & CanvasSidePanelProps;

const StyledStoreFrontSidePanel = styled(CanvasSidePanel)`
  & .items-container {
    display: grid;
    grid-template-columns: 120px 120px;
    grid-gap: 24px;
    align-items: center;
    justify-content: center;
    grid-template-rows: auto;
    position: relative;
    margin-top: ${({ theme }) => theme.pxToRem(24)};

    & > div {
      height: 120px;
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      border-radius: ${({ theme }) => theme.pxToRem(4)};
      border: ${({ theme }) => `1px dashed ${theme.colors.neutral[400]}`};
      overflow: hidden;
      z-index: 0;
    }
  }
`;

const StoreFrontSidePanel: React.FC<StoreFrontSidePanelProps> = ({
  onClose,
  draggableItems,
  ...props
}) => {
  return (
    <StyledStoreFrontSidePanel {...props} onClose={onClose}>
      <Droppable droppableId={`side-panel`}>
        {(provided, snapshot) => {
          return (
            <div
              className="items-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {draggableItems?.map(({ id, label, logo }, index) => {
                return (
                  <Draggable
                    key={`${index}-${id}`}
                    index={index}
                    draggableId={`drag-${index}-${label}`}
                  >
                    {(provided, snapshot) => {
                      return (
                        <ShopFrontDraggableItem
                          label={label}
                          logo={logo}
                          isDraggingOver={snapshot.isDragging}
                          innerRef={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {snapshot.isUsingPlaceholder ? (
                <div className="items-container__placeholder">
                  {provided.placeholder}
                </div>
              ) : (
                provided.placeholder
              )}
            </div>
          );
        }}
      </Droppable>
    </StyledStoreFrontSidePanel>
  );
};

export default StoreFrontSidePanel;
