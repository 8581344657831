import { Auth0ContextInterface } from '@auth0/auth0-react';

type GetSourcesDataQueryKey = {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<any>['getAccessTokenSilently'];
      }
    ]
  >;
};

type SourcesDataType = {
  email: string;
  id: string;
  name: string;
  provider_id: string;
  source: {
    source_type: 'google' | 'facebook' | 'mailchimp' | 'codat_shopify';
    external_id: string;
  }[];
};

export const GET_SOURCES_DATA_QUERY = 'GET_SOURCES_DATA_QUERY';

export const getSourcesData = async ({
  queryKey,
}: GetSourcesDataQueryKey): Promise<SourcesDataType> => {
  const { getAccessTokenSilently } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/users/sources`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (response.status !== 200) {
    throw Error('Network Error');
  }

  const data = await response.json();
  return data;
};
