import Icon from 'components/atoms/icon';
import SoftwareIcon from 'components/atoms/software-icon';
import { IconButton, Popover } from 'evergreen-ui';
import { SoftwareType } from 'pages/business-configuration/softwareData';
import styled from 'styled-components';

export type SoftwarePickerPopoverProps = {
  children?: React.ReactNode;

  availableSoftwares: SoftwareType[];
  onSelect: (id: string) => void;

  selectedSoftwares: SoftwareType[];
  onRemove: (id: string) => void;
};

const StyledSoftwarePickerPopover = styled.div`
  background: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  padding: ${({ theme }) => theme.pxToRem(24)};

  & input {
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 13, right: 16, bottom: 13, left: 16 })};
    background: ${({ theme }) => theme.colors.neutral[100]};
    border: none;
    height: unset;
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    width: ${({ theme }) => theme.pxToRem(225)};
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(20)};
      color: #b6b6c7;
      opacity: 0.9;
    }
  }

  & header {
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.pxToRem(10)};
    line-height: ${({ theme }) => theme.pxToRem(16)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.neutral[600]};

    margin-bottom: ${({ theme }) => theme.pxToRem(8)};
  }

  & .resultList {
    display: flex;
    & > * {
      margin-right: ${({ theme }) => theme.pxToRem(8)};
    }
  }

  & hr {
    width: 100%;
    border: ${({ theme }) =>
      `${theme.pxToRem(1)} solid ${theme.colors.neutral[200]}`};
    margin: ${({ theme }) =>
      theme.pxToRem({ top: 16, right: 0, bottom: 16, left: 0 })};
  }

  & .addedSoftwareContainer {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      align-items: center;
      margin-bottom: ${({ theme }) => theme.pxToRem(4)};

      & > p {
        font-weight: 400;
        font-size: ${({ theme }) => theme.pxToRem(14)};
        line-height: ${({ theme }) => theme.pxToRem(20)};
        color: ${({ theme }) => theme.colors.neutral[600]};
        margin: ${({ theme }) =>
          theme.pxToRem({ top: 0, right: 0, bottom: 0, left: 12 })};
      }

      & button {
        margin-left: auto;
        border-radius: 50%;
        padding: ${({ theme }) => theme.pxToRem(5)};
        border: unset;
        & svg {
          width: ${({ theme }) => theme.pxToRem(16)};
          height: ${({ theme }) => theme.pxToRem(16)};
          color: ${({ theme }) => theme.colors.secondary['R400']};
          & path {
            stroke-width: 1.5;
            stroke: ${({ theme }) => theme.colors.secondary['R400']};
          }
        }

        &:hover {
          background: ${({ theme }) => theme.colors.neutral[100]};
        }
      }
    }
  }
`;

const SoftwarePickerPopover: React.FC<SoftwarePickerPopoverProps> = ({
  children,
  availableSoftwares,
  onSelect,
  selectedSoftwares,
  onRemove,
}) => {
  return (
    <Popover
      position="bottom-left"
      content={
        <StyledSoftwarePickerPopover
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div>
            <header>Your Services</header>
            <div className="resultList">
              {availableSoftwares.map(({ id, logo }) => (
                <SoftwareIcon
                  key={id}
                  logo={logo}
                  sizePx={32}
                  onClick={() => onSelect(id)}
                />
              ))}
            </div>
          </div>

          {!!selectedSoftwares.length && <hr />}
          <div className="addedSoftwareContainer">
            {selectedSoftwares.map(({ id, logo, title }) => (
              <div key={id}>
                <SoftwareIcon logo={logo} sizePx={32} />
                <p>{title}</p>
                <IconButton
                  icon={<Icon icon="XIcon" />}
                  onClick={() => onRemove(id)}
                />
              </div>
            ))}
          </div>
        </StyledSoftwarePickerPopover>
      }
    >
      {children}
    </Popover>
  );
};

export default SoftwarePickerPopover;
