import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';
import BusinessMetricKpis from 'components/organisms/business-metric-kpis';
import SalesFunnel from 'components/organisms/sales-funnel';
import ShieldTransformationLoader from 'components/atoms/shield-transformation-loader';
import PageHeader from 'components/molecules/page-header';
import HonuSuggestionsIndicator from 'components/atoms/honu-suggestions-indicator';
import MetricsList from 'components/molecules/metrics-list';

import { useGlobalPageTemplate } from 'context';

import { useQuery } from 'react-query';
import { getKpis, GET_KPIS_QUERY } from 'api-client/queries';

import { useAuth0 } from '@auth0/auth0-react';

const PageContainer = styled.div`
  & .page-content {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-column-gap: ${({ theme }) => theme.pxToRem(16)};
    margin-top: ${({ theme }) => theme.pxToRem(0)};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 16,
        left: 0,
        right: 16,
        bottom: 0,
      })};

    & > div {
      background-color: ${({ theme }) => theme.colors.neutral[50]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${theme.colors.white}`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
    }

    &__kpi-container {
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 0,
          left: 8,
          right: 8,
          bottom: 0,
        })};
    }

    &__sales-funnel-container {
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 32,
          right: 32,
          bottom: 0,
        })};

      & > div {
        & > h2 {
          margin-bottom: ${({ theme }) => theme.pxToRem(76)};
        }
      }
    }
  }
`;

const SalesAndMarketing = () => {
  const { minimizeNavbar } = useGlobalPageTemplate();
  const { getAccessTokenSilently } = useAuth0();
  // Those values will come from API
  const time = 80;
  const budget = 2500;
  const budgetSpent = 0;
  const timeSpent = 0;

  const { data: kpisData, isLoading: kpisIsLoading } = useQuery(
    [GET_KPIS_QUERY, { getAccessTokenSilently }] as const,
    getKpis
  );

  return (
    <PageContainer>
      <PageHeader minimizeNavbar={minimizeNavbar} title="Marketing" to="/">
        <HonuSuggestionsIndicator disabled />
      </PageHeader>
      <MetricsList
        loadingMetrics={kpisIsLoading}
        currentRevenue={Math.round(
          kpisData?.state_of_business?.revenues?.current || 0
        )}
        forecastedRevenue={Math.round(
          kpisData?.state_of_business?.revenues?.forecasted || 0
        )}
        currentProfitability={
          kpisData?.state_of_business?.profitability?.current || 0
        }
        forecastedProfitability={
          kpisData?.state_of_business?.profitability?.forecasted || 0
        }
        currentSolvency={kpisData?.state_of_business?.solvency?.current || 0}
        forecastedSolvency={
          kpisData?.state_of_business?.solvency?.forecasted || 0
        }
        time={time}
        timeSpent={timeSpent}
        budget={budget}
        budgetSpent={budgetSpent}
      />
      <PageContentContainer
        className="page-content"
        wrapper
        minimizeNavbar={minimizeNavbar}
      >
        <div className="page-content__kpi-container">
          <BusinessMetricKpis
            kpiList={kpisData?.kpis.sales_and_marketing.map((kpi) => {
              return kpi?.is_percentage
                ? { ...kpi, value: Math.round(kpi.value * 100) }
                : { ...kpi };
            })}
          />
        </div>
        <div className="page-content__sales-funnel-container">
          <div>
            <h2 className="H-700">Sales Funnel</h2>
            {kpisIsLoading ? (
              <ShieldTransformationLoader />
            ) : (
              <SalesFunnel
                funnelCells={Object.keys(kpisData?.funnels?.sales || {}).map(
                  //@ts-ignore
                  (key) => kpisData?.funnels?.sales[key]
                )}
              />
            )}
          </div>
        </div>
      </PageContentContainer>
    </PageContainer>
  );
};

export default SalesAndMarketing;
