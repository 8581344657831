import { MarkerType } from 'react-flow-renderer';
import theme from 'theme';

const style = {
  stroke: theme.colors.secondary['B400'],
  strokeWidth: 2,
  padding: 10,
  margin: 10,
};

export const supplyChainEdges = [
  {
    id: 'delivery-processing',
    target: 'delivery',
    source: 'processing',
    sourceHandle: 'top',
    targetHandle: 'bottom',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'simplebezier',
    style,
  },
  {
    id: 'processing-inventory',
    target: 'processing',
    source: 'inventory',
    sourceHandle: 'top',
    targetHandle: 'bottom',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'simplebezier',
    style,
  },
  {
    id: 'inventory-supplier',
    target: 'inventory',
    source: 'supplier',
    sourceHandle: 'bottom',
    targetHandle: 'top',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'BusinessModelEdge',
  },
];
