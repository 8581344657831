import styled from 'styled-components';

import NavBarContainer from 'components/atoms/nav-bar-container';
import Logo from 'components/atoms/logo';
import Divider from 'components/atoms/divider';
import NavBarLink from 'components/atoms/nav-bar-link';

import Button from 'components/atoms/button';
import { useGlobalPageTemplate, useUser } from 'context';

import { useLocation } from 'react-router-dom';
import TurtleLogo from './turtle-logo.svg';

import { useAuth0 } from '@auth0/auth0-react';

export type NavBarProps = {
  children?: React.ReactNode;
  minimize?: boolean;
};

const StyledNavBar = styled(NavBarContainer)`
  & > .logo {
    display: flex;
    justify-items: center;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 16,
        left: 16,
        right: 16,
        bottom: 0,
      })};
    & > a {
      margin: ${({ theme }) => theme.pxToRem(16, 'auto')};
    }
  }

  & .logo-divider {
    width: ${({ theme }) => `calc(100% - ${theme.pxToRem(24)})`};
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  & .section-title {
    text-align: left;
    margin-left: ${({ theme }) => theme.pxToRem(16)};
    & > h2 {
      line-height: ${({ minimize, theme }) =>
        minimize ? theme.pxToRem(0) : theme.pxToRem(24)};
      font-size: ${({ minimize, theme }) =>
        minimize ? theme.pxToRem(0) : theme.pxToRem(12)};
      color: ${({ minimize, theme }) =>
        minimize
          ? 'transparent'
          : theme.mode === 'light'
          ? theme.colors.neutral[800]
          : theme.colors.neutral[600]};
      font-weight: 700;
      transition: line-height 0.5s, font-size 0.5s, color 0.5s;
    }
  }

  & > .link-list {
    padding-top: ${({ theme }) => theme.pxToRem(8)};
    margin-bottom: ${({ theme }) => theme.pxToRem(8)};

    & > a {
      display: flex;
      justify-content: ${({ minimize }) =>
        minimize ? 'center' : 'flex-start'};
      align-items: center;
      margin-left: ${({ theme }) => theme.pxToRem(8)};

      &.active {
        & > span {
          color: ${({ theme }) => theme.colors.secondary['B400']};
        }
      }

      &:hover {
        & > span {
          color: ${({ theme }) => theme.colors.secondary['B400']};
        }
      }

      & > span {
        line-height: ${({ minimize, theme }) =>
          minimize ? theme.pxToRem(0) : theme.pxToRem(24)};
        font-size: ${({ minimize, theme }) =>
          minimize ? theme.pxToRem(0) : theme.pxToRem(16)};
        color: ${({ minimize, theme }) =>
          minimize
            ? 'transparent'
            : theme.mode === 'light'
            ? theme.colors.neutral[600]
            : theme.colors.neutral[0]};
        transition: line-height 0.25s, font-size 0.25s, color 0.25s;
      }
    }
  }

  & .minimize {
    width: 100%;
    position: absolute;
    bottom: ${({ theme }) => theme.pxToRem(13)};
    text-align: center;

    &__button {
      display: flex;
      justify-content: ${({ minimize }) =>
        minimize ? 'center' : 'space-evenly'};
      transition: justify-content 0.25s;
      margin-bottom: ${({ theme }) => theme.pxToRem(16)};

      & > button {
        border-radius: 50%;
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};

        & > svg {
          position: absolute;
          transform: ${({ minimize }) =>
            minimize ? 'rotate(0deg)' : 'rotate(180deg)'};
          transition: transform 0.25s;
        }
      }

      & > p {
        transform: ${({ minimize }) => (minimize ? 'scale(0)' : 'rotate(1)')};
        line-height: ${({ minimize, theme }) =>
          minimize ? theme.pxToRem(0) : theme.pxToRem(24)};
        font-size: ${({ minimize, theme }) =>
          minimize ? theme.pxToRem(0) : theme.pxToRem(12)};
        color: ${({ minimize, theme }) =>
          minimize ? 'transparent' : theme.colors.neutral[600]};
        text-transform: uppercase;
        font-weight: 700;
        transition: line-height 0.5s, font-size 0.5s, color 0.5s;
      }
    }
    & > img {
      max-width: ${({ theme }) => theme.pxToRem(63)};
      max-height: ${({ theme }) => theme.pxToRem(38)};
    }
  }

  .log-out-button {
    margin: ${({ theme }) => theme.pxToRem(12, 'auto')};
    padding: ${({ theme }) => theme.pxToRem(8)};
  }
`;

const NavBar: React.FC<NavBarProps> = () => {
  const { logout } = useAuth0();
  const { name } = useUser();
  const location = useLocation();
  const { minimizeNavbar, setMinimizeNavBard } = useGlobalPageTemplate();
  return (
    <StyledNavBar className="nav-bar" minimize={minimizeNavbar}>
      <div className="logo">
        <Logo to="/" />
      </div>
      <Divider className="logo-divider" />
      <div className="section-title">
        <h2 className="H-600">KPIs</h2>
      </div>
      <div className="link-list">
        <NavBarLink
          to="/"
          icon="ChartPieIcon"
          className={
            location.pathname === '/' || location.pathname === '/kpi-overview'
              ? 'active'
              : ''
          }
        >
          <span>Summary</span>
        </NavBarLink>
        <NavBarLink to="/finance" icon="DatabaseIcon">
          <span>Finance</span>
        </NavBarLink>
        <NavBarLink to="/operations" icon="CubeIcon">
          <span>Operations</span>
        </NavBarLink>
        <NavBarLink to="/sales-and-marketing" icon="ChartBarIcon">
          <span>Marketing</span>
        </NavBarLink>
      </div>
      <div className="section-title">
        <h2 className="H-600">ACTIONS</h2>
      </div>
      <div className="link-list">
        <NavBarLink to="/objectives" icon="ViewGridAddIcon">
          <span>Objectives</span>
        </NavBarLink>
        <NavBarLink to="/my-actions" icon="ViewBoardsIcon">
          <span>Action Plan</span>
        </NavBarLink>
        <NavBarLink to="/financing-options" icon="CashIcon">
          <span>Funding Options</span>
        </NavBarLink>
      </div>
      <Button
        title={name}
        className="log-out-button"
        onClick={() => {
          logout();
        }}
      >
        Log Out
      </Button>
      <div className="minimize">
        <div className="minimize__button">
          <Button
            icon="ChevronRightIcon"
            aria-label="Minimize Side Nav Button"
            onClick={() => {
              setMinimizeNavBard((prevState) => !prevState);
            }}
          />
          <p>Minimize Sidebar</p>
        </div>
        <Divider className="logo-divider" />
        <img src={TurtleLogo} alt="Honu Turtle Logo" />
      </div>
    </StyledNavBar>
  );
};

export default NavBar;
