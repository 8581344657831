import styled from 'styled-components';

import { motion } from 'framer-motion';

import Icon from 'components/atoms/icon';

export type BusinessResourcesProps = {
  children?: React.ReactNode;
  time: number;
  timeSpent: number;
  budget: number;
  budgetSpent: number;
};

const StyledBusinessResources = styled.div`
  width: 100%;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & > div {
    flex: 1;
    padding-left: ${({ theme }) => theme.pxToRem(32)};

    & > h1 {
      display: flex;
      align-items: center;
      color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[800]
          : theme.colors.neutral[0]};
      font-size: ${({ theme }) => theme.pxToRem(21)};

      & > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: ${({ theme }) => theme.pxToRem(4)};
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        border-radius: 50%;

        & > svg {
          width: ${({ theme }) => theme.pxToRem(20)};
          height: ${({ theme }) => theme.pxToRem(20)};
        }
      }

      & > p {
        font-size: ${({ theme }) => theme.pxToRem(15)};
        font-weight: 600;
        color: ${({ theme }) =>
          theme.mode === 'light'
            ? theme.colors.neutral[800]
            : theme.colors.neutral[0]};
        margin-left: ${({ theme }) => theme.pxToRem(4)};
      }
    }

    & > p {
      color: ${({ theme }) => theme.colors.neutral[600]};
      font-size: 13px;
      text-indent: ${({ theme }) => theme.pxToRem(32)};
    }

    & .bar-container {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: ${({ theme }) => theme.pxToRem(157)};
      height: ${({ theme }) => theme.pxToRem(2)};

      &__bar {
        height: ${({ theme }) => theme.pxToRem(2)};
        position: relative;
        margin-left: ${({ theme }) => theme.pxToRem(8)};
        margin-right: ${({ theme }) => theme.pxToRem(8)};
        padding-top: ${({ theme }) => theme.pxToRem(10)};
        padding-bottom: ${({ theme }) => theme.pxToRem(10)};
      }

      &__base {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(157)};
        height: ${({ theme }) => theme.pxToRem(2)};
        background-color: ${({ theme }) => theme.colors.neutral[200]};
        border-radius: ${({ theme }) => theme.pxToRem(8)};
        z-index: 1;
      }

      &__value {
        position: absolute;
        height: ${({ theme }) => theme.pxToRem(2)};
        border-radius: ${({ theme }) => theme.pxToRem(8)};
        z-index: 1;
      }
    }

    &.time {
      @media (min-width: ${({ theme }) => theme.pxToRem(1439)}) {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
      }
      & span {
        background-color: ${({ theme }) => theme.colors.secondary['G400']};
        & > svg {
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }

      & .bar-container {
        &__value {
          background-color: ${({ theme }) => theme.colors.secondary['G400']};
        }
      }
    }
    &.budget {
      & span {
        background-color: ${({ theme }) => theme.colors.tertiary['T400']};
        & > svg {
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
      & .bar-container {
        &__value {
          background-color: ${({ theme }) => theme.colors.tertiary['T400']};
        }
      }
    }
  }
`;

const BusinessResources: React.FC<BusinessResourcesProps> = ({
  time,
  timeSpent,
  budget,
  budgetSpent,
}) => {
  return (
    <StyledBusinessResources>
      <div className="time">
        <h1>
          <span>
            <Icon icon="ClockIcon" />
          </span>
          {time - timeSpent}
          <p>h / month</p>
        </h1>
        <p>Available Time</p>
        <div className="bar-container">
          <div className="bar-container__base" />
          <motion.div
            className="bar-container__value"
            animate={{
              width: `${
                time - timeSpent > 0 ? ((time - timeSpent) / time) * 100 : 0
              }%`,
            }}
          />
        </div>
      </div>
      <div className="budget">
        <h1>
          <span>
            <Icon icon="CurrencyDollarIcon" />
          </span>
          {budget > 1000 ? (budget - budgetSpent) / 1000 : budget - budgetSpent}
          <p>{`${budget > 1000 ? 'k / month' : '/ month'}`}</p>
        </h1>
        <p>Available Budget</p>
        <div className="bar-container">
          <div className="bar-container__base" />
          <motion.div
            className="bar-container__value"
            animate={{
              width: `${
                budget - budgetSpent > 0
                  ? ((budget - budgetSpent) / budget) * 100
                  : 0
              }%`,
            }}
          />
        </div>
      </div>
    </StyledBusinessResources>
  );
};

export default BusinessResources;
