import { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

export type SliderLabelProps = {
  children?: React.ReactNode;
  ref?:
    | ((instance: HTMLSpanElement | null) => void)
    | RefObject<HTMLSpanElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
  'value' | 'ref'
>;

const StyledSliderLabel = styled.span`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(28)};
  height: ${({ theme }) => theme.pxToRem(24)};
  background: #4d4864;
  border-radius: ${({ theme }) => theme.pxToRem(8)};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.pxToRem(14)};
  line-height: ${({ theme }) => theme.pxToRem(20)};
  color: ${({ theme }) => theme.colors.neutral[0]};

  .pointer {
    position: absolute;
    height: ${({ theme }) => theme.pxToRem(10)};
    width: ${({ theme }) => theme.pxToRem(10)};
    background: #4d4864;
    border-radius: ${({ theme }) => `0 0 ${theme.pxToRem(12)} 0`};
    bottom: ${({ theme }) => theme.pxToRem(-1)};
    left: 50%;
    transform: translateX(-50%) rotate(230deg);
  }
`;

const SliderLabel: React.FC<SliderLabelProps> = ({ children, ...props }) => {
  return (
    <StyledSliderLabel {...props}>
      <span className="pointer" />
      {children}
    </StyledSliderLabel>
  );
};

export default SliderLabel;
