import { BusinessModelNodeType } from '..';

export const businessModelNodes: BusinessModelNodeType[] = [
  {
    id: 'centre-node',
    type: 'BusinessModelCenterNode',
    data: {
      companyLogo: 'logo-image.png',
    },
    position: { x: 555, y: 326 },
  },
  {
    id: 'revenue-model',
    type: 'BusinessModelNode',
    data: {
      label: 'Revenue Model',
      labelPosition: 'top',
      handlePosition: 'bottom',
      values: [
        {
          id: 'transactional',
          label: 'Transactional',
          icon: 'ExchangeTwo',
        },
        {
          id: 'subscription',
          label: 'Subscription',
          icon: 'Financing',
        },
      ],
    },
    position: { x: 577, y: 53 },
  },
  {
    id: 'distribution-model',
    type: 'BusinessModelNode',
    data: {
      label: 'Distribution model',
      labelPosition: 'right',
      handlePosition: 'right',
      values: [
        {
          id: 'online',
          label: 'Online',
          icon: 'DatabaseNetworkPoint',
        },
        {
          id: 'offline',
          label: 'Offline',
          icon: 'LinkCloudFaild',
        },
        {
          id: 'omnichannel',
          label: 'Omnichannel',
          icon: 'DataDisplay',
        },
      ],
    },
    position: { x: 250, y: 250 },
  },
  {
    id: 'product-model',
    type: 'BusinessModelNode',
    data: {
      label: 'Product Model',
      labelPosition: 'left',
      handlePosition: 'left',
      values: [
        {
          id: 'retail',
          label: 'Retail',
          icon: 'Shopping',
        },
        {
          id: 'brand',
          label: 'Brand',
          icon: 'TagOne',
        },
      ],
    },
    position: { x: 935, y: 250 },
  },
  // {
  //   id: 'target-customers',
  //   type: 'BusinessModelNode',
  //   data: {
  //     label: 'Target Customers',
  //     labelPosition: 'right',
  //     handlePosition: 'right',
  //     values: [
  //       {
  //         id: 'female',
  //         label: 'Female',
  //         icon: 'Female',
  //       },
  //       {
  //         id: 'male',
  //         label: 'Male',
  //         icon: 'Female',
  //       },
  //     ],
  //   },
  //   position: { x: 245, y: 590 },
  // },
  {
    id: 'product-category',
    type: 'BusinessModelNode',
    data: {
      label: 'Category of products',
      labelPosition: 'left',
      handlePosition: 'left',
      values: [
        {
          id: 'fashion',
          label: 'Fashion',
          icon: 'ClothesCardigan',
        },
        {
          id: 'accessories',
          label: 'Accessories',
          icon: 'DiamondRing',
        },
        {
          id: 'homewear',
          label: 'Homewear',
          icon: 'SlippersOne',
        },
      ],
    },
    position: { x: 940, y: 590 },
  },
  {
    id: 'business-model',
    type: 'BusinessModelNode',
    data: {
      label: 'Business Model',
      labelPosition: 'bottom',
      handlePosition: 'top',
      values: [
        {
          id: 'b2b',
          label: 'B2B',
          icon: 'InclusiveGateway',
        },
        {
          id: 'b2c',
          label: 'B2C',
          icon: 'Hexagonal',
        },
        {
          id: 'b2b2c',
          label: 'B2B2C',
          icon: 'MultiTriangularThree',
        },
        {
          id: 'b2g',
          label: 'B2G',
          icon: 'QuadrangularPyramid',
        },
      ],
    },
    position: { x: 245, y: 590 },
    // position: { x: 577, y: 740 },
  },
];
