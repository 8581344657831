import { useState, useMemo, useEffect, useRef } from 'react';

import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';
// import AvatarMenu from 'components/molecules/avatar-menu';
import SelectMenu from 'components/molecules/select-menu';
import ActionCardCta from 'components/molecules/action-card-cta';
import Icon from 'components/atoms/icon';
import Button from 'components/atoms/button';
import Loading from 'components/atoms/loading';
import KpiStatusBar from 'components/atoms/kpi-status-bar';
import ShieldTransformationLoader from 'components/atoms/shield-transformation-loader';
import OnboardingNav from 'components/molecules/onboarding-nav';
import PageHeader from 'components/molecules/page-header';
import HonuSuggestionsCta from 'components/atoms/honu-suggestions-cta';
import MetricsList from 'components/molecules/metrics-list';

import { useGlobalPageTemplate, useTheme } from 'context';

import { useQuery } from 'react-query';
import {
  getUserActions,
  GET_USER_ACTIONS_QUERY,
  ActionType,
  getSimulationKpis,
  GET_SIMULATION_KPIS_QUERY,
  // getKpis,
  // GET_KPIS_QUERY,
} from 'api-client/queries';
import { useSetSelectedActions } from 'api-client/mutations';

import { useAuth0 } from '@auth0/auth0-react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { calculateKpiProgress, getRating } from 'pages/utils';

const PageContainer = styled.div<{ kpiListHeight?: number }>`
  padding-bottom: ${({ theme }) => theme.pxToRem(120)};
  & .resources-container {
    min-height: 100%;
    margin-bottom: ${({ theme }) => theme.pxToRem(0)};
    padding: 0;

    & > .page-header {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      justify-content: center;
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 32,
          left: 24,
          right: 24,
          bottom: 0,
        })};
      margin-bottom: ${({ theme }) => theme.pxToRem(30)};

      & div:nth-child(2) {
        display: flex;
        justify-content: center;
      }

      & div:nth-child(3) {
        display: flex;
        justify-content: flex-end;

        & > .suggestions-cta {
          margin-right: ${({ theme }) => theme.pxToRem(24)};
          box-shadow: 0px 0px 12px #559ff97c;

          & > svg {
            width: ${({ theme }) => theme.pxToRem(34)};
            height: ${({ theme }) => theme.pxToRem(18)};
            margin-right: ${({ theme }) => theme.pxToRem(8)};
            & > path {
              fill: ${({ theme }) => theme.colors.neutral[0]};
            }
          }
        }
      }
    }
  }

  & .metrics-list {
    display: flex;

    &__metrics-container {
      width: 100%;
      border: 2px solid ${({ theme }) => theme.colors.neutral[0]};
      margin-right: ${({ theme }) => theme.pxToRem(8)};
      border-radius: 0 0 8px 8px;
    }

    &__metrics-loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__metrics {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.neutral[0]};
      padding-top: ${({ theme }) => theme.pxToRem(24)};
      padding-bottom: ${({ theme }) => theme.pxToRem(24)};

      & > div {
        flex: 1;
        padding-left: ${({ theme }) => theme.pxToRem(32)};
        padding-right: ${({ theme }) => theme.pxToRem(32)};

        & > h3 {
          display: flex;
          align-items: center;
          white-space: nowrap;

          & > span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: ${({ theme }) => theme.pxToRem(14)};
            width: ${({ theme }) => theme.pxToRem(32)};
            height: ${({ theme }) => theme.pxToRem(32)};
            border-radius: 50%;

            & > svg {
              width: ${({ theme }) => theme.pxToRem(20)};
              height: ${({ theme }) => theme.pxToRem(20)};
            }
          }

          & > p {
            font-size: ${({ theme }) => theme.pxToRem(20)};
            font-weight: 600;
            color: ${({ theme }) => theme.colors.neutral[600]};
          }
        }

        & > p {
          text-indent: ${({ theme }) => theme.pxToRem(48)};
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }

      & .revenue {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['O400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }

      & .profitability {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['V400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }

      & .solvency {
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['P400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }
    }

    &__footer {
      text-align: center;
      padding: ${({ theme }) =>
        theme.pxToRem({
          top: 8,
          left: 0,
          right: 0,
          bottom: 8,
        })};

      & > p {
        & > span {
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }
    }

    &__resources {
      width: 100%;
      min-height: ${({ theme }) => theme.pxToRem(120)};
      background-color: ${({ theme }) => theme.colors.neutral[0]};
      border-radius: 0 0 8px 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      & > div {
        flex: 1;
        padding-left: ${({ theme }) => theme.pxToRem(32)};

        & > h1 {
          display: flex;
          align-items: center;
          & > span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: ${({ theme }) => theme.pxToRem(14)};
            width: ${({ theme }) => theme.pxToRem(32)};
            height: ${({ theme }) => theme.pxToRem(32)};
            border-radius: 50%;

            & > svg {
              width: ${({ theme }) => theme.pxToRem(20)};
              height: ${({ theme }) => theme.pxToRem(20)};
            }
          }

          & > p {
            font-size: ${({ theme }) => theme.pxToRem(32)};
            font-weight: 600;
            color: ${({ theme }) => theme.colors.neutral[500]};
          }
        }

        & > p {
          color: ${({ theme }) => theme.colors.neutral[600]};
          text-indent: ${({ theme }) => theme.pxToRem(48)};
        }

        & .bar-container {
          display: inline-flex;
          align-items: center;
          position: relative;
          width: ${({ theme }) => theme.pxToRem(256)};
          height: ${({ theme }) => theme.pxToRem(2)};

          &__bar {
            height: ${({ theme }) => theme.pxToRem(2)};
            position: relative;
            margin-left: ${({ theme }) => theme.pxToRem(8)};
            margin-right: ${({ theme }) => theme.pxToRem(8)};
            padding-top: ${({ theme }) => theme.pxToRem(10)};
            padding-bottom: ${({ theme }) => theme.pxToRem(10)};
          }

          &__base {
            position: absolute;
            width: ${({ theme }) => theme.pxToRem(256)};
            height: ${({ theme }) => theme.pxToRem(2)};
            background-color: ${({ theme }) => theme.colors.neutral[200]};
            border-radius: ${({ theme }) => theme.pxToRem(8)};
            z-index: 1;
          }

          &__value {
            position: absolute;
            height: ${({ theme }) => theme.pxToRem(2)};
            border-radius: ${({ theme }) => theme.pxToRem(8)};
            z-index: 1;
          }
        }

        &.time {
          @media (min-width: ${({ theme }) => theme.pxToRem(1439)}) {
            border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
          }
          & span {
            background-color: ${({ theme }) => theme.colors.secondary['G400']};
            & > svg {
              fill: ${({ theme }) => theme.colors.neutral[0]};
            }
          }

          & .bar-container {
            &__value {
              background-color: ${({ theme }) =>
                theme.colors.secondary['G400']};
            }
          }
        }
        &.budget {
          & span {
            background-color: ${({ theme }) => theme.colors.tertiary['T400']};
            & > svg {
              fill: ${({ theme }) => theme.colors.neutral[0]};
            }
          }
          & .bar-container {
            &__value {
              background-color: ${({ theme }) => theme.colors.tertiary['T400']};
            }
          }
        }
      }
    }
  }

  & > .page-content {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: ${({ theme }) => theme.pxToRem(16)};
    margin-top: ${({ theme }) => theme.pxToRem(0)};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 8,
        right: 24,
        bottom: 0,
      })};
    height: ${({ kpiListHeight, theme }) =>
      kpiListHeight ? theme.pxToRem(kpiListHeight + 68) : 0};

    & > div {
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[50]
          : theme.colors.neutral[950]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${
          theme.mode === 'light'
            ? theme.colors.neutral[0]
            : theme.colors.neutral[950]
        }`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
    }

    & .action-list {
      padding: ${({ theme }) => theme.pxToRem(32)};
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: ${({ theme }) => theme.pxToRem(8)};
      }

      &::-webkit-scrollbar-track {
        margin-top: ${({ theme }) => theme.pxToRem(8)};
        margin-bottom: ${({ theme }) => theme.pxToRem(8)};
        border: none;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.neutral[400]};
        border-radius: ${({ theme }) => theme.pxToRem(16)};
      }

      &__header {
        display: flex;
        justify-content: space-between;
      }

      &__actions {
        padding: ${({ theme }) => theme.pxToRem(16)};
        max-width: ${({ theme }) => theme.pxToRem(560)};
        margin: 0 auto;

        & > div {
          margin-bottom: ${({ theme }) => theme.pxToRem(24)};
        }
      }
    }

    & .kpi-list {
      padding: ${({ theme }) => theme.pxToRem(32)};
      position: relative;

      &__action-info {
        margin-bottom: ${({ theme }) => theme.pxToRem(24)};
        position: relative;

        & > button {
          position: absolute;
          top: 0;
          right: 0;
          width: ${({ theme }) => theme.pxToRem(32)};
          height: ${({ theme }) => theme.pxToRem(32)};
          border-radius: 50%;

          & > svg {
            position: absolute;
            width: ${({ theme }) => theme.pxToRem(24)};
            height: ${({ theme }) => theme.pxToRem(24)};
            fill: ${({ theme }) => theme.colors.neutral[900]};
          }
        }

        & > h3 {
          padding-top: ${({ theme }) => theme.pxToRem(4)};
          margin: ${({ theme }) =>
            theme.pxToRem({
              top: 0,
              left: 0,
              right: 32,
              bottom: 16,
            })};
        }

        & > ul {
          list-style: none;

          & > li {
            margin-bottom: ${({ theme }) => theme.pxToRem(8)};

            & > svg {
              width: ${({ theme }) => theme.pxToRem(16)};
              height: ${({ theme }) => theme.pxToRem(16)};
              margin-right: ${({ theme }) => theme.pxToRem(8)};
              fill: ${({ theme }) => theme.colors.secondary['B500']};
            }
          }
        }
      }

      &__header {
        display: flex;
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[200]};
        padding-bottom: ${({ theme }) => theme.pxToRem(16)};
        margin-bottom: ${({ theme }) => theme.pxToRem(16)};

        & span {
          display: inline-block;
          width: ${({ theme }) => theme.pxToRem(16)};
          height: ${({ theme }) => theme.pxToRem(16)};
          border-radius: 50%;
          margin-right: ${({ theme }) => theme.pxToRem(16)};
        }

        & > div {
          display: flex;
          align-items: center;

          & > p {
            color: ${({ theme }) => theme.colors.neutral[600]};
          }
        }

        & > div:nth-child(1) {
          & span {
            background-image: ${({ theme }) => theme.colors.primary['B400']};
          }
          margin-right: ${({ theme }) => theme.pxToRem(16)};
        }

        & > div:nth-child(2) {
          & span {
            background-color: ${({ theme }) => theme.colors.tertiary['B200']};
          }
        }
      }

      &__content {
        & .kpi {
          padding: ${({ theme }) =>
            theme.pxToRem({
              top: 12,
              left: 8,
              right: 8,
              bottom: 12,
            })};
        }

        & > div {
          & > h5 {
            text-transform: uppercase;
            margin-top: ${({ theme }) => theme.pxToRem(8)};
            margin-bottom: ${({ theme }) => theme.pxToRem(8)};
            color: ${({ theme }) =>
              theme.mode === 'light'
                ? theme.colors.neutral[0]
                : theme.colors.neutral[600]};
          }
        }
      }

      &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${({ theme }) => theme.pxToRem(350)};
      }
    }
  }

  & .loading-overlay {
    min-height: auto;
    height: 350px;
  }

  & .navigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(44)};
    left: 50%;
    transform: translateX(-40%);
    z-index: 89;

    & .selected-goals-list {
      display: flex;
      align-items: center;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[600]};

      & > svg {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(44)};
      }
      & > p {
        max-width: ${({ theme }) => theme.pxToRem(650)};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const ChooseActions = () => {
  const [searchParams] = useSearchParams();
  const affectedKpi = searchParams.get('kpi');
  const { setTheme } = useTheme();
  const location = useLocation().pathname;
  const locationState = useLocation()?.state as {
    fromObjectives?: boolean;
  };
  const kpiListRef = useRef<HTMLDivElement>(null);
  // Those values will come from API
  const time = 80;
  const budget = 2500;
  const { minimizeNavbar } = useGlobalPageTemplate();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [selectedActions, setSelectedActions] = useState<number[] | []>([]);
  const [kpiListHeight, setKpiListHeight] = useState<undefined | number>(
    undefined
  );
  const [focusedAction, setFocusedAction] = useState<null | ActionType>(null);
  const [suggestionsState, setSuggestionsState] = useState(true);

  const { data: actionsData, isLoading: isActionsLoading } = useQuery(
    [GET_USER_ACTIONS_QUERY, { getAccessTokenSilently }] as const,
    getUserActions
  );
  const { data: kpisData, isLoading: kpisIsLoading } = useQuery(
    [
      GET_SIMULATION_KPIS_QUERY,
      { getAccessTokenSilently, actions: selectedActions },
    ] as const,
    getSimulationKpis
  );
  const { isLoading: settingActions, mutate } = useSetSelectedActions({
    onSuccess: () => {
      navigate('/my-actions');
    },
  });

  // const { data: businessStateData, isLoading: businessStateIsLoading } =
  //   useQuery([GET_KPIS_QUERY, { getAccessTokenSilently }] as const, getKpis);

  const [filterActions, setFilterActions] = useState<undefined | string>(
    undefined
  );

  const budgetSpent = useMemo(() => {
    //@ts-ignore
    return selectedActions.reduce((acc: any, actionId: any) => {
      const budgetPerMonth = actionsData?.find(
        ({ id }) => id === actionId
      )?.budget_per_month_required;

      if (budgetPerMonth) {
        return acc + budgetPerMonth;
      }
      return acc;
    }, 0);
  }, [selectedActions, actionsData]);

  const timeSpent = useMemo(() => {
    //@ts-ignore
    return selectedActions.reduce((acc: any, actionId: any) => {
      const hoursPerMonth = actionsData?.find(
        ({ id }) => id === actionId
      )?.hours_per_month_required;

      if (hoursPerMonth) {
        return acc + hoursPerMonth;
      }
      return acc;
    }, 0);
  }, [selectedActions, actionsData]);

  useEffect(() => {
    if (locationState?.fromObjectives || affectedKpi) {
      return;
    }
    navigate('/objectives');
  }, [locationState, navigate, affectedKpi]);

  useEffect(() => {
    setTheme?.((prevState) => ({
      ...prevState,
      mode: 'dark',
    }));
    return () => {
      setTheme?.((prevState) => ({
        ...prevState,
        mode: 'light',
      }));
    };
  }, [setTheme, location]);

  useEffect(() => {
    if (!kpiListHeight && !kpisIsLoading) {
      setKpiListHeight(kpiListRef?.current?.offsetHeight);
      return;
    }

    window.addEventListener('resize', () => {
      setKpiListHeight(kpiListRef?.current?.offsetHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [
    kpiListRef?.current?.offsetHeight,
    setKpiListHeight,
    kpiListHeight,
    kpisIsLoading,
  ]);

  const actions = useMemo(() => {
    if (affectedKpi) {
      return actionsData?.filter((action) => {
        return action.affected_kpis.includes(affectedKpi);
      });
    }
    if (!filterActions) return actionsData;

    if (filterActions === 'shortest_time') {
      return actionsData?.sort((prev, next) =>
        prev.hours_per_month_required > next.hours_per_month_required ? 1 : -1
      );
    }

    if (filterActions === 'longest_time') {
      return actionsData?.sort((prev, next) =>
        prev.hours_per_month_required < next.hours_per_month_required ? 1 : -1
      );
    }

    if (filterActions === 'smallest_budget') {
      return actionsData?.sort((prev, next) =>
        prev.budget_per_month_required > next.budget_per_month_required ? 1 : -1
      );
    }

    if (filterActions === 'biggest_budget') {
      return actionsData?.sort((prev, next) =>
        prev.budget_per_month_required < next.budget_per_month_required ? 1 : -1
      );
    }

    if (filterActions === 'Marketing') {
      return actionsData?.filter((action) => action.category === 'Marketing');
    }

    if (filterActions === 'Finance') {
      return actionsData?.filter((action) => action.category === 'Finance');
    }

    if (filterActions === 'Operations') {
      return actionsData?.filter((action) => action.category === 'Operations');
    }
  }, [filterActions, actionsData, affectedKpi]);

  useEffect(() => {
    setSelectedActions(() =>
      actions
        ? actions
            .filter((action) => action.is_suggested)
            .map((action) => action.id)
        : []
    );
  }, [actions]);

  return (
    <PageContainer kpiListHeight={kpiListHeight}>
      <PageHeader
        minimizeNavbar={minimizeNavbar}
        title="Choose Actions"
        description="We’ve chosen the best Actions for you based on their impact, your available budget and how much time they’ll take"
      >
        <HonuSuggestionsCta
          popUpInfo="Your Honu Suggestions are on! You can always turn them off by clicking this button and do it manually."
          active={isActionsLoading || suggestionsState}
          onClick={() => {
            if (suggestionsState) {
              setSelectedActions([]);
              setSuggestionsState((prevState) => !prevState);
              return;
            }

            setSelectedActions(() =>
              actionsData
                ? actionsData
                    .filter((action) => action.is_suggested)
                    .map((action) => action.id)
                : []
            );
            setSuggestionsState((prevState) => !prevState);
          }}
        />
      </PageHeader>
      <MetricsList
        loadingMetrics={kpisIsLoading}
        currentRevenue={Math.round(
          kpisData?.state_of_business?.revenues?.current || 0
        )}
        forecastedRevenue={Math.round(
          kpisData?.state_of_business?.revenues?.forecasted || 0
        )}
        currentProfitability={
          kpisData?.state_of_business?.profitability?.current || 0
        }
        forecastedProfitability={
          kpisData?.state_of_business?.profitability?.forecasted || 0
        }
        currentSolvency={kpisData?.state_of_business?.solvency?.current || 0}
        forecastedSolvency={
          kpisData?.state_of_business?.solvency?.forecasted || 0
        }
        time={time}
        timeSpent={timeSpent}
        budget={budget}
        budgetSpent={budgetSpent}
      />
      {isActionsLoading || settingActions ? (
        <PageContentContainer
          wrapper
          minimizeNavbar={minimizeNavbar}
          className="loading-overlay"
        >
          <ShieldTransformationLoader />
        </PageContentContainer>
      ) : (
        <PageContentContainer
          className="page-content"
          wrapper
          minimizeNavbar={minimizeNavbar}
        >
          <div className="action-list">
            <div>
              <div className="action-list__header">
                <h3 className="H-800">Suggested Actions</h3>
                <SelectMenu
                  closeOnSelect
                  placeholder="Sort Actions"
                  selected={filterActions}
                  options={[
                    { label: 'Shortest Time', value: 'shortest_time' },
                    { label: 'Longest Time', value: 'longest_time' },
                    { label: 'Smallest Budget', value: 'smallest_budget' },
                    { label: 'Biggest Budget', value: 'biggest_budget' },
                    { label: 'Finance', value: 'Finance' },
                    { label: 'Operations', value: 'Operations' },
                    {
                      label: 'Sales & Marketing',
                      value: 'Marketing',
                    },
                  ]}
                  onSelect={(item) => {
                    setFilterActions(item.value as string);
                  }}
                />
              </div>
              <div className="action-list__actions">
                {Array.isArray(actions) &&
                  actions?.map(
                    ({
                      id,
                      name,
                      category,
                      budget_rating,
                      hours_rating,
                      ...action
                    }) => {
                      return (
                        <ActionCardCta
                          key={id}
                          title={name}
                          type={
                            category.toLocaleLowerCase() as
                              | 'marketing'
                              | 'operations'
                              | 'finance'
                          }
                          timeWeight={getRating(hours_rating)}
                          budgetWeight={getRating(budget_rating)}
                          selected={
                            selectedActions.find(
                              (actionId) => actionId === id
                            ) !== undefined
                          }
                          focused={focusedAction?.name === name}
                          onSelect={(event) => {
                            event.stopPropagation();
                            if (
                              typeof selectedActions.find(
                                (actionId) => actionId === id
                              ) !== 'undefined'
                            ) {
                              setSelectedActions((prevState) =>
                                prevState.filter((actionId) => actionId !== id)
                              );
                              return;
                            }
                            setSelectedActions((prevState) => [
                              ...prevState,
                              ...[id],
                            ]);
                          }}
                          onFocus={(event) => {
                            event.preventDefault();
                            if (
                              event.target.ariaLabel === 'Select Action Button'
                            ) {
                              return;
                            }
                            setFocusedAction({
                              id,
                              name,
                              category,
                              budget_rating,
                              hours_rating,
                              ...action,
                            });
                            setKpiListHeight(undefined);
                          }}
                        />
                      );
                    }
                  )}
              </div>
            </div>
          </div>
          <div className="kpi-list">
            <div ref={kpiListRef}>
              {focusedAction && (
                <div className="kpi-list__action-info">
                  <Button
                    appearance="minimal"
                    onClick={() => {
                      setFocusedAction(null);
                      setKpiListHeight(undefined);
                    }}
                  >
                    <Icon icon="XIcon" />
                  </Button>
                  <h3 className="H-700">
                    This action “{focusedAction.name}” affects the below KPIs:
                  </h3>
                  <p>{focusedAction?.additional_info}</p>
                </div>
              )}
              <div className="kpi-list__header">
                <div>
                  <span />
                  <p> Starting Point</p>
                </div>
                <div>
                  <span />
                  <p> State After Change </p>
                </div>
              </div>
              <div className="kpi-list__content">
                {kpisIsLoading ? (
                  <div className="kpi-list__loading">
                    <Loading />
                  </div>
                ) : (
                  <>
                    <div className="kpi-list__container-sales">
                      <h5 className="H-300">Sales And Marketing</h5>
                      {kpisData?.sales_and_marketing?.map(
                        ({
                          key,
                          name,
                          value,
                          is_percentage,
                          simulated_value,
                          target,
                          goal,
                        }) => {
                          return (
                            <KpiStatusBar
                              key={key}
                              title={name}
                              value={
                                is_percentage
                                  ? Number(value) * 100
                                  : Number(value)
                              }
                              percentage={is_percentage}
                              current={calculateKpiProgress({
                                kpiValue: value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecast={calculateKpiProgress({
                                kpiValue: simulated_value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecastValue={
                                is_percentage
                                  ? Number(target) * 100
                                  : Number(target)
                              }
                              className="kpi"
                              overlay={
                                focusedAction
                                  ? focusedAction?.affected_kpis?.find(
                                      (kpiKey) => kpiKey === key
                                    ) === undefined
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </div>
                    <div className="kpi-list__container-finance">
                      <h5 className="H-300">Finance</h5>
                      {kpisData?.finance?.map(
                        ({
                          key,
                          name,
                          goal,
                          value,
                          target,
                          is_percentage,
                          simulated_value,
                        }) => {
                          return (
                            <KpiStatusBar
                              key={key}
                              title={name}
                              value={
                                is_percentage
                                  ? Math.round(Number(value) * 100)
                                  : Math.round(Number(value))
                              }
                              percentage={is_percentage}
                              current={calculateKpiProgress({
                                kpiValue: value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecast={calculateKpiProgress({
                                kpiValue: simulated_value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecastValue={
                                is_percentage
                                  ? Math.round(Number(target) * 100)
                                  : Math.round(Number(target))
                              }
                              className="kpi"
                              overlay={
                                focusedAction
                                  ? focusedAction?.affected_kpis?.find(
                                      (kpiKey) => kpiKey === key
                                    ) === undefined
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </div>
                    <div className="kpi-list__container-operations">
                      <h5 className="H-300">Operations</h5>
                      {kpisData?.operations?.map(
                        ({
                          key,
                          name,
                          value,
                          target,
                          goal,
                          is_percentage,
                          simulated_value,
                        }) => {
                          return (
                            <KpiStatusBar
                              key={key}
                              title={name}
                              value={
                                is_percentage
                                  ? Math.round(Number(value) * 100)
                                  : Number(value)
                              }
                              percentage={is_percentage}
                              current={calculateKpiProgress({
                                kpiValue: value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecast={calculateKpiProgress({
                                kpiValue: simulated_value,
                                target,
                                isPercentage: is_percentage,
                                goal,
                              })}
                              forecastValue={
                                is_percentage
                                  ? Math.round(Number(target) * 100)
                                  : Math.round(Number(target))
                              }
                              className="kpi"
                              overlay={
                                focusedAction
                                  ? focusedAction?.affected_kpis?.find(
                                      (kpiKey) => kpiKey === key
                                    ) === undefined
                                  : false
                              }
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="navigation">
            <OnboardingNav
              isBackDisabled={selectedActions.length === 0}
              isNextDisabled={selectedActions.length === 0}
              backButtonLabel="Discard"
              nextButtonLabel="Confirm & Continue"
              onClickNext={() => {
                mutate({ actionsIds: selectedActions });
              }}
              onClickBack={() => {
                setSelectedActions([]);
                if (suggestionsState) {
                  setSuggestionsState((prevState) => !prevState);
                }
              }}
            >
              <div className="selected-goals-list">
                <Icon icon="CheckCircleIcon" variant="outline" />
                <p className="large-text">Choose Action</p>
              </div>
            </OnboardingNav>
          </div>
        </PageContentContainer>
      )}
    </PageContainer>
  );
};

export default ChooseActions;
