import styled from 'styled-components';

import Loading from 'components/atoms/loading';
import Icon from 'components/atoms/icon';

export type BusinessMetricsListProps = {
  children?: React.ReactNode;
  loading?: boolean;
  currentRevenue?: number;
  forecastedRevenue?: number;
  currentProfitability?: number;
  forecastedProfitability?: number;
  currentSolvency?: number;
  forecastedSolvency?: number;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledBusinessMetricsList = styled.div`
  & .metrics-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .metrics {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[0]
        : theme.colors.neutral[910]};
    padding-top: ${({ theme }) => theme.pxToRem(12)};
    padding-bottom: ${({ theme }) => theme.pxToRem(8)};
    border-radius: 16px 16px 0 0;

    & > div {
      flex: 1;
      padding-left: ${({ theme }) => theme.pxToRem(32)};
      padding-right: ${({ theme }) => theme.pxToRem(32)};

      & > h3 {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: ${({ theme }) =>
          theme.mode === 'light'
            ? theme.colors.neutral[800]
            : theme.colors.neutral[0]};

        & > span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: ${({ theme }) => theme.pxToRem(14)};
          width: ${({ theme }) => theme.pxToRem(32)};
          height: ${({ theme }) => theme.pxToRem(32)};
          border-radius: 50%;

          & > svg {
            width: ${({ theme }) => theme.pxToRem(20)};
            height: ${({ theme }) => theme.pxToRem(20)};
          }
        }

        & > p {
          font-size: ${({ theme }) => theme.pxToRem(20)};
          font-weight: 600;
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }

      & > p {
        text-indent: ${({ theme }) => theme.pxToRem(48)};
        color: ${({ theme }) => theme.colors.neutral[600]};
      }
    }

    & .revenue {
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
      & span {
        background-color: ${({ theme }) => theme.colors.tertiary['O400']};
        & > svg {
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }

    & .profitability {
      border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
      & span {
        background-color: ${({ theme }) => theme.colors.tertiary['V400']};
        & > svg {
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }

    & .solvency {
      & span {
        background-color: ${({ theme }) => theme.colors.tertiary['P400']};
        & > svg {
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }
  }

  & .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    /* @media (min-width: ${({ theme }) => theme.pxToRem(1439)}) {
      } */

    & > p {
      color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[800]
          : theme.colors.neutral[0]};

      & > span {
        color: ${({ theme }) => theme.colors.neutral[600]};
      }
    }
  }
`;

const BusinessMetricsList: React.FC<BusinessMetricsListProps> = ({
  loading,
  currentRevenue,
  forecastedRevenue,
  currentProfitability,
  forecastedProfitability,
  currentSolvency,
  forecastedSolvency,
  ...props
}) => {
  return (
    <StyledBusinessMetricsList {...props}>
      <div className="metrics">
        {loading ? (
          <div className="metrics-loading">
            <Loading />
          </div>
        ) : (
          <>
            <div className="revenue">
              <h3 className="H-600">
                <span>
                  <Icon icon="CurrencyDollarIcon" />
                </span>
                £{currentRevenue}/<p>£{forecastedRevenue}</p>
              </h3>
              <p className="large-text">Revenue</p>
            </div>
            <div className="profitability">
              <h3 className="H-700">
                <span>
                  <Icon icon="TrendingUpIcon" />
                </span>
                {currentProfitability}
                %/
                <p>{forecastedProfitability}%</p>
              </h3>
              <p className="large-text">Profitability</p>
            </div>
            <div className="solvency">
              <h3 className="H-700">
                <span>
                  <Icon icon="ReceiptTaxIcon" />
                </span>
                {currentSolvency}
                %/
                <p>{forecastedSolvency}%</p>
              </h3>
              <p className="large-text">Solvency</p>
            </div>
          </>
        )}
      </div>
      <div className="footer">
        <p>
          Current / <span>Forecast</span>
        </p>
      </div>
    </StyledBusinessMetricsList>
  );
};

export default BusinessMetricsList;
