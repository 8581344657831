import { Auth0ContextInterface } from '@auth0/auth0-react';

export type BusinessOptionType = {
  id: string;
  name: string;
  is_locked: boolean;
  is_suggested: boolean;
};

type BusinessOptionsType = {
  revenues: BusinessOptionType[];
  solvency: BusinessOptionType[];
  profitability: BusinessOptionType[];
};

export const GET_BUSINESS_OBJECTIVES_QUERY = 'GET_BUSINESS_OBJECTIVES_QUERY';

export const getBusinessObjectives = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<any>['getAccessTokenSilently'];
      }
    ]
  >;
}): Promise<BusinessOptionsType> => {
  const { getAccessTokenSilently } = queryKey[1];
  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();
  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/objectives`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (response.status !== 200) {
    throw Error('Network Error');
  }

  const { data: objectives } = await response.json();

  return objectives;
};
