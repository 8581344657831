import styled from 'styled-components';
import { Tab as EuiTab, TabProps as EuiTabProps } from 'evergreen-ui';

export type TabProps = EuiTabProps;

const StyledTab = styled(EuiTab)``;

/**
 * Tab is using the **Evergreen UI native Tab** component
 * It expects to be used under a Tablist component.
 *
 */

const Tab: React.FC<TabProps> = (props) => {
  return <StyledTab {...props} />;
};

export default Tab;
