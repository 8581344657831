import { StoreFrontNodeType } from '..';

export const storeFrontNodes: StoreFrontNodeType[] = [
  {
    id: 'storefront-0',
    type: 'StoreFrontNode',
    data: {
      values: [
        {
          id: 'shopify',
          logo: 'Shopify',
          label: 'Shopify',
          values: {
            otherConnections: [
              {
                value: 'google-analytics',
                logo: 'GoogleAnalytics',
                label: 'Google Analytics',
              },
              {
                value: 'intercom',
                logo: 'Intercom',
                label: 'Intercom',
              },
            ],
          },
        },
        {
          id: 'amazon',
          logo: 'Amazon',
          label: 'Amazon',
          values: {
            otherConnections: [],
          },
        },
        {
          id: 'woocommerce',
          logo: 'WooCommerce',
          label: 'WooCommerce',
          values: {
            otherConnections: [],
          },
        },
      ],
    },
    position: { x: 625, y: 335 },
  },
];
