import Button from 'components/atoms/button';
import Icon, { IconProps } from 'components/atoms/icon/Icon';
import logos, {
  SoftwareCardLogoType,
} from 'components/atoms/software-card/logos';
import { Dialog, DialogProps } from 'evergreen-ui';
import styled from 'styled-components';

import { ReactComponent as HonuLogo } from './icons/honu.svg';

export type SoftwareConnectionDialogProps = {
  title: string;
  description: string;
  accessList?: {
    icon: IconProps['icon'];
    title: string;
    description: string;
  }[];
  onConnect?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  logo: SoftwareCardLogoType;
  footerText?: string;
} & Pick<DialogProps, 'isShown' | 'onCloseComplete'>;

const StyledSoftwareConnectionDialog = styled.div`
  padding-top: ${({ theme }) => theme.pxToRem(24)};

  & > h3 {
    margin-top: ${({ theme }) => theme.pxToRem(20)};
    font-weight: 600;
    font-size: ${({ theme }) => theme.pxToRem(24)};
    line-height: ${({ theme }) => theme.pxToRem(36)};
    color: ${({ theme }) => theme.colors.neutral[900]};
    text-align: center;
  }

  & > p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(16)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[800]};

    margin-top: ${({ theme }) => theme.pxToRem(24)};
  }

  & .access-list-container {
    margin-top: ${({ theme }) => theme.pxToRem(32)};
    display: flex;
    align-items: flex-start;

    & svg {
      width: ${({ theme }) => theme.pxToRem(25)};
      color: ${({ theme }) => theme.colors.neutral[500]};
      margin-right: ${({ theme }) => theme.pxToRem(10)};
    }

    & h4 {
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[900]};
    }

    & p {
      font-weight: 400;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(20)};
      color: ${({ theme }) => theme.colors.neutral[600]};
    }
  }

  & .illustration {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: ${({ theme }) => theme.pxToRem(48)};
      height: ${({ theme }) => theme.pxToRem(48)};
    }

    & > .arrow-left-right {
      margin: ${({ theme }) =>
        theme.pxToRem({ top: 0, left: 11, bottom: 0, right: 11 })};
      display: flex;
      flex-direction: column;
      align-items: center;
      & svg {
        color: ${({ theme }) => theme.colors.neutral[500]};
        height: ${({ theme }) => theme.pxToRem(8)};
      }
    }

    & > .software-logo {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${({ theme }) => theme.pxToRem(48)};
      height: ${({ theme }) => theme.pxToRem(48)};
      background: ${({ theme }) => theme.colors.neutral[200]};
      border-radius: ${({ theme }) => theme.pxToRem(8)};
    }
  }

  & .footer {
    margin-top: ${({ theme }) => theme.pxToRem(32)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(16)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[500]};
    }
  }
`;

const SoftwareConnectionDialog: React.FC<SoftwareConnectionDialogProps> = ({
  title,
  description,
  accessList,
  onConnect,
  logo,
  footerText: stepText,
  ...dialogProps
}) => {
  const Logo = logos[logo];
  return (
    <Dialog {...dialogProps} hasFooter={false} hasHeader={false}>
      <StyledSoftwareConnectionDialog>
        <div className="illustration">
          <HonuLogo />
          <div className="arrow-left-right">
            <Icon icon="ArrowLeftIcon" />
            <Icon icon="ArrowRightIcon" />
          </div>
          <div className="software-logo">
            <Logo />
          </div>
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
        {accessList?.map((item) => (
          <div key={item.title} className="access-list-container">
            <Icon icon={item.icon} />
            <div>
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        <div className="footer">
          <p>{stepText}</p>
          <Button appearance="primary" onClick={onConnect}>
            Connect
          </Button>
        </div>
      </StyledSoftwareConnectionDialog>
    </Dialog>
  );
};

export default SoftwareConnectionDialog;
