import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const useCreateUserMutation = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  return useMutation(async () => {
    const token = window.Cypress
      ? 'cypress-mock-token'
      : await getAccessTokenSilently();

    if (!isAuthenticated && !window.Cypress) {
      throw new Error(`You need to login to create a user.`);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/users`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          name: user?.name,
        }),
      }
    );

    if (response.status !== 201) {
      throw new Error(`Failed to create a user.`);
    }
    const data = await response.json();
    return data;
  });
};
