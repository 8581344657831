import React from 'react';
import styled from 'styled-components';

import { motion, HTMLMotionProps } from 'framer-motion';

import { pxToRem } from 'theme/helpers';

export type NavBarContainerProps = {
  minimize?: boolean;
} & HTMLMotionProps<'div'>;

const StyledNavBarContainer = styled(motion.div)`
  height: ${({ theme }) => `calc(100vh - ${theme.pxToRem(40)})`};
  background-color: ${({ theme }) =>
    theme?.mode === 'light'
      ? theme.colors.neutral['50']
      : theme.colors.neutral['950']};
  transition: background-color 1s;
  position: fixed;
  top: ${({ theme }) => theme.pxToRem(20)};
  left: ${({ theme }) => theme.pxToRem(16)};
  margin-right: ${({ theme }) => theme.pxToRem(16)};
  border: 2px solid
    ${({ theme }) =>
      theme?.mode === 'light'
        ? theme.colors.neutral[0]
        : theme.colors.neutral[950]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  z-index: 99;
`;

const variants = {
  minimized: { width: pxToRem(100) },
  extended: { width: pxToRem(200) },
};

/**
 * NavBarContainer atom /to be used for the side panel main navigation
 */

const NavBarContainer: React.FC<NavBarContainerProps> = ({
  children,
  minimize = false,
  ...props
}) => {
  return (
    <StyledNavBarContainer
      {...props}
      variants={variants}
      animate={minimize ? 'minimized' : 'extended'}
      initial={false}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 60,
        mass: 0.1,
        delay: 0,
      }}
    >
      {children}
    </StyledNavBarContainer>
  );
};

export default NavBarContainer;
