import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export type User = {
  email?: string;
  id?: string;
  provider_id?: string[];
  name?: string;
  notFound?: boolean;
};

export type UserContext = {
  setUser: React.Dispatch<React.SetStateAction<User>>;
} & User;
export const UserContext = React.createContext<UserContext | null>(null);

export const useUser = (): UserContext => {
  const user = React.useContext(UserContext);

  if (user === null) {
    throw new Error(
      `User is null. This means that either we were unable to get the user data from the API,
       or "useUser" is used outside "UserProvider"`
    );
  }

  return {
    ...user,
  };
};

type UserProviderProps = {
  children: React.ReactNode;
  user: User;
};

export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  ...props
}) => {
  const [user, setUser] = useState<User>(props?.user);
  const onboarding = window.localStorage.getItem('onboarding');
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Cypress) {
      return;
    }
    if (onboarding === 'completed') {
      return;
    }
    if (onboarding) {
      navigate(onboarding);
      return;
    }
    window.localStorage.setItem(
      'onboarding',
      '/account-settings/personal-info'
    );
    navigate('/account-settings/personal-info');

    // We need to force this to run only during initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ ...user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
