import styled from 'styled-components';

export type LoadingOverlayProps = {
  children?: React.ReactNode;
  title: string;
  description?: string;
};

const StyledLoadingOverlay = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .description {
    text-align: center;
  }
`;

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  children,
  title,
  description,
  ...props
}) => {
  return (
    <StyledLoadingOverlay {...props}>
      <div>
        <h1 className="H-900">{title}</h1>
        {description && <p className="description">{description}</p>}
      </div>
      {children}
    </StyledLoadingOverlay>
  );
};

export default LoadingOverlay;
