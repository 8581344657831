import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';

import { useGlobalPageTemplate } from 'context';

const PageContainer = styled.div`
  & > .page-header {
    min-height: auto;
    display: flex;
    justify-content: flex-start;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 24,
        right: 24,
        bottom: 32,
      })};

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
    }
  }

  & > .page-content {
    min-height: auto;
    height: 100vh;
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    border: ${({ theme }) =>
      `${theme.pxToRem(2)} solid ${theme.colors.neutral[0]}`};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 32,
        right: 32,
        bottom: 32,
      })};

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Operations = () => {
  const { minimizeNavbar } = useGlobalPageTemplate();

  return (
    <>
      <PageContainer>
        <PageContentContainer
          className="page-header"
          minimizeNavbar={minimizeNavbar}
        >
          <div>
            <h1 className="H-900">Operations</h1>
          </div>
          <div />
          <div></div>
        </PageContentContainer>
        <PageContentContainer
          wrapper
          className="page-content"
          minimizeNavbar={minimizeNavbar}
        >
          <h1 className="H-900">Coming Soon</h1>
        </PageContentContainer>
      </PageContainer>
    </>
  );
};

export default Operations;
