import { Auth0ContextInterface } from '@auth0/auth0-react';
import { User } from 'context/user-context';

export const GET_USER_DATA_QUERY = 'GET_USER_DATA_QUERY';

export const getUserData = async ({
  queryKey,
}: {
  queryKey: Readonly<
    [
      string,
      {
        getAccessTokenSilently: Auth0ContextInterface<User>['getAccessTokenSilently'];
        user: User | undefined;
      }
    ]
  >;
}): Promise<{
  notFound?: boolean;
  name?: string;
}> => {
  const { getAccessTokenSilently } = queryKey[1];

  const token = window.Cypress
    ? 'cypress-mock-token'
    : await getAccessTokenSilently();

  const response = await fetch(
    `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/users`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }
  );

  if (response.status === 404) {
    return {
      notFound: true,
    };
  }

  if (!response.ok) {
    throw Error('Network Error');
  }

  const { data } = await response.json();

  return data;
};
