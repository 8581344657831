import {
  BusinessModelOptionType,
  SoreFrontItemValue,
} from 'pages/business-configuration/nested-routes/business-blueprint/config';
import { CustomerJourneyType } from 'pages/business-configuration/nested-routes/business-blueprint/context';

export type BusinessModelState = {
  'distribution-model': BusinessModelOptionType[];
  'product-category': BusinessModelOptionType[];
  'revenue-model': BusinessModelOptionType[];
  // 'target-customers': BusinessModelOptionType[];
  'product-model': BusinessModelOptionType[];
  'business-model': BusinessModelOptionType[];
  selectedNodeId: null | string;
};

// This potentially will come from the API
export const defaultState: {
  businessModel: BusinessModelState;
  customerJourney: CustomerJourneyType[] | any[];
} = {
  businessModel: {
    'distribution-model': [],
    'product-category': [],
    'revenue-model': [],
    // 'target-customers': [],
    'product-model': [],
    'business-model': [],
    selectedNodeId: null,
  },
  customerJourney: [],
};

/**
 * BUSINESS MODEL ACTIONS
 */

export const SET_ACTIVE_BUSINESS_MODEL_ITEM = 'SET_ACTIVE_BUSINESS_MODEL_ITEM';
export const DE_ACTIVATE_BUSINESS_MODEL_ITEM =
  'DE_ACTIVATE_BUSINESS_MODEL_ITEM';
export const ADD_BUSINESS_MODEL_ITEM_VALUE = 'ADD_BUSINESS_MODEL_ITEM_VALUE';
export const REMOVE_BUSINESS_MODEL_ITEM_VALUE =
  'REMOVE_BUSINESS_MODEL_ITEM_VALUE';
/** */

export const reducer = (
  state: typeof defaultState,
  action: {
    type: // Business Model Actions
    | 'SET_ACTIVE_BUSINESS_MODEL_ITEM'
      | 'DE_ACTIVATE_BUSINESS_MODEL_ITEM'
      | 'ADD_BUSINESS_MODEL_ITEM_VALUE'
      | 'REMOVE_BUSINESS_MODEL_ITEM_VALUE'
      // Customer Journey Actions
      | 'ADD_CUSTOMER_JOURNEY'
      | 'ADD_OTHER_CONNECTIONS';
    businessModelItemId:
      | 'distribution-model'
      | 'product-category'
      | 'revenue-model'
      // | 'target-customers'
      | 'product-model'
      | 'business-model';
    customerJourneyId?: string;
    value?: BusinessModelOptionType | CustomerJourneyType;
    storeFrontItemValue?: SoreFrontItemValue;
  }
) => {
  switch (action.type) {
    /**
     * BUSINESS MODEL
     */
    case SET_ACTIVE_BUSINESS_MODEL_ITEM:
      return {
        ...state,
        businessModel: {
          ...state.businessModel,
          selectedNodeId: action.businessModelItemId,
        },
      };
    case DE_ACTIVATE_BUSINESS_MODEL_ITEM:
      return {
        ...state,
        businessModel: {
          ...state.businessModel,
          selectedNodeId: null,
        },
      };
    case ADD_BUSINESS_MODEL_ITEM_VALUE:
      return {
        ...state,
        businessModel: {
          ...state.businessModel,
          [action.businessModelItemId]: [
            ...state.businessModel[action.businessModelItemId],
            ...(action?.value ? [action.value] : []),
          ],
        },
      };
    case REMOVE_BUSINESS_MODEL_ITEM_VALUE:
      return {
        ...state,
        businessModel: {
          ...state.businessModel,
          [action.businessModelItemId]: state.businessModel[
            action.businessModelItemId
          ].filter(({ id }) => id !== action?.value?.id),
        },
      };
    /** */

    /**
     * Customer Journey
     */
    case 'ADD_CUSTOMER_JOURNEY':
      return {
        ...state,
        customerJourney: [...state.customerJourney, ...[action.value]],
      };
    case 'ADD_OTHER_CONNECTIONS':
      return {
        ...state,
        customerJourney: [
          ...[
            state.customerJourney.map((customerJourney) => {
              if (customerJourney.id === action.customerJourneyId) {
                return {
                  ...customerJourney,
                  otherConnections: [
                    ...customerJourney.otherConnections,
                    ...[{ ...action.storeFrontItemValue }],
                  ],
                };
              }
              return { ...customerJourney };
            }),
          ],
        ],
      };

    default:
      return {
        ...state,
      };
  }
};
