import FloatingNavContainer from 'components/atoms/floating-nav-container';
import Icon from 'components/atoms/icon';
import { IconProps } from 'components/atoms/icon/Icon';
import { HTMLMotionProps } from 'framer-motion';
import { Fragment, useState } from 'react';
import styled from 'styled-components';

export type VerticalProgressBarProps = {
  steps: { title: string; icon: IconProps['icon'] }[];
  currentStep: { index: number; percent?: number };
} & HTMLMotionProps<'div'>;

const StyledVerticalProgressBar = styled(FloatingNavContainer)<
  Pick<VerticalProgressBarProps, 'currentStep'> & {
    variant: 'compact' | 'normal';
  }
>`
  height: auto;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99;

  padding: ${({ theme }) =>
    theme.pxToRem({ top: 16, right: 12, bottom: 16, left: 12 })};

  & > hr {
    border: ${({ theme }) =>
      `${theme.pxToRem(1)} solid ${theme.colors.neutral[400]}`};
    height: ${({ theme }) => theme.pxToRem(24)};
    margin: ${({ theme }) =>
      theme.pxToRem({ top: 4, right: 0, bottom: 4, left: 16 })};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
  }

  & > .stepContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    & > svg {
      display: ${({ variant }) => (variant === 'normal' ? 'initial' : 'none')};

      height: 16px;
      margin-left: auto;
      color: #60acff;
      & path {
        stroke: #60acff;
      }
    }

    & > div {
      width: ${({ theme }) => theme.pxToRem(16)};
      height: ${({ theme }) => theme.pxToRem(16)};
      padding: ${({ theme }) => theme.pxToRem(9)};
      background: ${({ theme }) => theme.colors.neutral[100]};
      border-radius: 50%;
      margin-right: ${({ theme, variant }) =>
        variant === 'normal' ? theme.pxToRem(20) : '0'};

      & svg {
        width: ${({ theme }) => theme.pxToRem(16)};
        height: ${({ theme }) => theme.pxToRem(16)};
        color: ${({ theme }) => theme.colors.neutral[600]};
        & path {
          stroke: ${({ theme }) => theme.colors.neutral[600]};
        }
      }
    }

    & > p {
      display: ${({ variant }) => (variant === 'normal' ? 'initial' : 'none')};
      white-space: nowrap;
      overflow: hidden;

      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(16)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[600]};
    }

    & > span {
      display: ${({ variant }) => (variant === 'normal' ? 'initial' : 'none')};

      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(16)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: #60acff;
      margin-left: auto;
    }
  }

  ${({ currentStep }) =>
    `.stepContainer:nth-of-type(n+${currentStep.index + 2})`} {
    & > p {
      font-weight: 500;
      font-size: ${({ theme }) => theme.pxToRem(16)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[400]};
    }
    & > div {
      background: ${({ theme }) => theme.colors.neutral[75]};
      & svg {
        color: ${({ theme }) => theme.colors.neutral[400]};
        & path {
          stroke: ${({ theme }) => theme.colors.neutral[400]};
        }
      }
    }
  }

  ${({ currentStep }) =>
    `.stepContainer:nth-of-type(${currentStep.index + 1})`} {
    & > div {
      background: #60acff;
      box-shadow: ${({ theme }) =>
        `0px ${theme.pxToRem(4)} ${theme.pxToRem(20)} ${theme.pxToRem(
          2
        )} rgba(106, 145, 245, 0.3)`};

      & svg {
        color: ${({ theme }) => theme.colors.neutral[0]};
        & path {
          stroke: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }
  }
`;

const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({
  steps,
  ...props
}) => {
  const [variant, setVariant] = useState<'compact' | 'normal'>('compact');
  return (
    <StyledVerticalProgressBar
      variant={variant}
      {...props}
      onMouseEnter={() => setVariant('normal')}
      onMouseLeave={() => setVariant('compact')}
    >
      {steps.map(({ icon, title }, index) => (
        <Fragment key={`${icon}-${index}`}>
          <div className="stepContainer">
            <div>
              <Icon icon={icon} />
            </div>
            <p>{title}</p>
            {index < props.currentStep.index && <Icon icon="CheckIcon" />}
            {index === props.currentStep.index && props.currentStep.percent && (
              <span>{props.currentStep.percent}%</span>
            )}
          </div>
          {index !== steps.length - 1 && <hr />}
        </Fragment>
      ))}
    </StyledVerticalProgressBar>
  );
};

export default VerticalProgressBar;
