import { useEffect } from 'react';

import styled from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import { getUserData, GET_USER_DATA_QUERY } from 'api-client/queries';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { UserProvider } from 'context';

import LoadingOverlay from 'components/molecules/loading-overlay';
import DotsScaleLoader from 'components/atoms/dots-scale-loader';

export type GetUserProps = {
  children?: React.ReactNode;
};

const StyledLoadingOverlay = styled(LoadingOverlay)`
  & .animated-loader {
    width: 350px;
    height: 350px;
  }
`;

const GetUser: React.FC<GetUserProps> = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const { data: userData, isLoading: userDataLoading } = useQuery(
    [GET_USER_DATA_QUERY, { getAccessTokenSilently, user }] as const,
    getUserData,
    {
      enabled: window.Cypress ? true : isAuthenticated,
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.notFound) {
      navigate('/create-user');
    }

    if (window.Cypress) {
      return;
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
      return;
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    userDataLoading,
    getAccessTokenSilently,
    userData,
    navigate,
    user,
  ]);

  if (window.Cypress && !userDataLoading) {
    return (
      <>
        <UserProvider user={{ ...userData }}>{children}</UserProvider>
      </>
    );
  }

  if (isLoading) {
    return <LoadingOverlay title="Logging you in securely..." />;
  }

  if (userDataLoading) {
    return (
      <StyledLoadingOverlay
        data-testid="loading-user"
        title="Preparing user data..."
      >
        <DotsScaleLoader className="animated-loader" />
      </StyledLoadingOverlay>
    );
  }

  if (isAuthenticated && userData && !userData?.notFound) {
    return (
      <>
        <UserProvider user={{ ...userData }}>{children}</UserProvider>
      </>
    );
  }

  return null;
};

export default GetUser;
