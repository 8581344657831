import { SoftwareCardLogoType } from 'components/atoms/software-card/logos';

export type SoftwareType = {
  id: string;
  logo: SoftwareCardLogoType;
  title: string;
};

export const softwareMap: Record<string, SoftwareType> = {
  barclays: { id: 'barclays', logo: 'barclays', title: 'Barclays' },
  hsbc: { id: 'hsbc', logo: 'hsbc', title: 'HSBC' },
  xero: { id: 'xero', logo: 'xero', title: 'Xero' },
  lloyds: { id: 'lloyds', logo: 'lloyds', title: 'Lloyds' },
  shopify: { id: 'shopify', logo: 'shopify', title: 'Shopify' },
  quickbooks: { id: 'quickbooks', logo: 'quickbooks', title: 'Quickbooks' },
  sage: { id: 'sage', logo: 'sage', title: 'Sage' },
  mailchimp: { id: 'mailchimp', logo: 'mailchimp', title: 'Mailchimp' },
  facebookads: {
    id: 'facebookads',
    logo: 'facebookads',
    title: 'Facebook Ads',
  },
  googleads: { id: 'googleads', logo: 'googleads', title: 'Google Ads' },
  trello: { id: 'trello', logo: 'trello', title: 'Trello' },
};

export type SoftwareKeys = keyof typeof softwareMap;
