import styled from 'styled-components';

export type InputErrorProps = {
  children?: React.ReactNode;
};

const StyledInputError = styled.p`
  font-size: ${({ theme }) => theme.pxToRem(12)};
  line-height: ${({ theme }) => theme.pxToRem(16)};
  color: ${({ theme }) => theme.colors.secondary['R400']};
`;

/**
 * The `InputError` atom should be used with input components
 * (e.g InputTag, Select, TextInput etc.) to display error messages
 */

const InputError: React.FC<InputErrorProps> = ({ children, ...props }) => {
  return (
    <StyledInputError data-testid="input-error" {...props}>
      {children}
    </StyledInputError>
  );
};

export default InputError;
