import { useEffect } from 'react';

import styled, { createGlobalStyle } from 'styled-components';

import { motion, useAnimationControls } from 'framer-motion';

import TurtleCreationLoader from 'components/atoms/turtle-creation-loader/TurtleCreationLoader';
import Icon from 'components/atoms/icon';

const GlobalStyle = createGlobalStyle`
body {
  overflow: hidden;
}
`;

export type IntelligenceLoadingOverlayProps = {
  delay: number;
  notifications: string[];
  show?: boolean;
};

const StyledIntelligenceLoadingOverlay = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(30, 29, 43, 0.95);
  z-index: 99;
  padding-top: ${({ theme }) => theme.pxToRem(120)};

  & > ul {
    list-style: none;

    & > li {
      display: flex;
      align-items: center;
      font-size: ${({ theme }) => theme.pxToRem(16)};
      font-weight: 600;
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[0]};
      margin-bottom: ${({ theme }) => theme.pxToRem(18)};

      & > svg {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        color: ${({ theme }) => theme.colors.primary['BLUE']};
        margin-right: ${({ theme }) => theme.pxToRem(8)};
      }
    }
  }

  & .loader {
    color: white;
    width: ${({ theme }) => theme.pxToRem(400)};
    height: ${({ theme }) => theme.pxToRem(400)};

    & > svg {
      & path {
        fill: ${({ theme }) => theme.colors.neutral[0]};
        stroke: ${({ theme }) => theme.colors.neutral[0]};
      }
    }
  }
`;

const IntelligenceLoadingOverlay: React.FC<IntelligenceLoadingOverlayProps> = ({
  delay,
  notifications,
  show,
}) => {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start((i: number) => ({
      opacity: 1,
      transition: { delay: i * delay },
    }));
  }, [controls, delay, show]);

  if (show) {
    return (
      <>
        <GlobalStyle />
        <StyledIntelligenceLoadingOverlay layoutId="loader">
          <TurtleCreationLoader className="loader" />
          <ul>
            {notifications.map((notification, index) => {
              return (
                <motion.li
                  key={index}
                  initial={{
                    opacity: 0,
                  }}
                  custom={index}
                  animate={controls}
                >
                  <Icon icon="CheckIcon" variant="solid" /> {notification}
                </motion.li>
              );
            })}
          </ul>
        </StyledIntelligenceLoadingOverlay>
      </>
    );
  }

  return null;
};

export default IntelligenceLoadingOverlay;
