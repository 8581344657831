import styled from 'styled-components';

import { IconParkIcon } from 'components/atoms/icon';
import { IconParkIconProps } from 'components/atoms/icon/IconPark';

import SupplierManufacturerHandle from 'components/atoms/supplier-manufacturer-handle';
import { Position } from 'react-flow-renderer';

export type SupplyChainNodeProps = {
  children?: React.ReactNode;
  data: {
    label: string;
    icon: IconParkIconProps['icon'];
  };
};

const StyledSupplyChainNode = styled.div`
  position: relative;
  width: ${({ theme }) => theme.pxToRem(284)};
  height: ${({ theme }) => theme.pxToRem(700)};
  border: 5px solid ${({ theme }) => theme.colors.tertiary['B100']};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  margin-top: ${({ theme }) => theme.pxToRem(50)};
  cursor: default;

  & > .node-title {
    position: absolute;
    top: 50%;
    right: -138px;
    transform: translateY(-50%);
    transform: rotate(90deg);
    color: ${({ theme }) => theme.colors.neutral[500]};
    background-color: ${({ theme }) => theme.colors.neutral[150]};
    padding-left: ${({ theme }) => theme.pxToRem(6)};
    padding-right: ${({ theme }) => theme.pxToRem(6)};
  }

  & .additional-options {
    position: absolute;
    left: ${({ theme }) => theme.pxToRem(-75)};
    top: 50%;
    transform: translateY(-50%);
  }

  & .fulfillment-method {
    width: ${({ theme }) => theme.pxToRem(179)};
    height: ${({ theme }) => theme.pxToRem(76)};
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    position: absolute;
    top: -37px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 0,
        bottom: 0,
        left: 16,
        right: 16,
      })};

    & svg {
      width: ${({ theme }) => theme.pxToRem(32)};
      color: ${({ theme }) => theme.colors.neutral[700]};
    }

    & .label {
      margin-left: ${({ theme }) => theme.pxToRem(8)};

      & > p {
        color: ${({ theme }) => theme.colors.neutral[700]};
      }
    }
  }
`;

const StyledSupplyChainHandle = styled(SupplierManufacturerHandle)`
  top: ${({ theme }) => theme.pxToRem(110)};
`;

const SupplyChainNode: React.FC<SupplyChainNodeProps> = ({
  children,
  data,
}) => {
  const { icon, label } = data;
  return (
    <>
      <StyledSupplyChainHandle
        type="target"
        position={Position.Left}
        id="left"
      />
      <StyledSupplyChainNode>
        <h1 className="H-500 node-title">Supply Chain Management</h1>
        <div className="fulfillment-method">
          {icon && <IconParkIcon icon={icon} />}
          <div className="label">
            <h2 className="H-500">{label}</h2>
            <p className="small-text">Fulfillment Method</p>
          </div>
        </div>
        {children}
      </StyledSupplyChainNode>
    </>
  );
};

export default SupplyChainNode;
