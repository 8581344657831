import { HTMLAttributes, RefObject, useMemo } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export type DetailedProgressBarProps = {
  children?: React.ReactNode;
  title: string;
  totalSteps: number;
  currentStep: number;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledDetailedProgressBar = styled.div<{ progressPercent: number }>`
  box-shadow: 0px 4px 50px -10px rgba(193, 196, 214, 0.5);

  & .progressBox {
    display: inline-block;
    background: ${({ theme }) => theme.colors.white};
    border-top-left-radius: ${({ theme }) => theme.pxToRem(20)};
    border-top-right-radius: ${({ theme }) => theme.pxToRem(20)};

    & .progressText {
      display: inline-flex;
      padding: ${({ theme }) => theme.pxToRem(15)};

      & span {
        color: #60acff;
        font-weight: bold;
        font-size: ${({ theme }) => theme.pxToRem(19)};
        margin-right: ${({ theme }) => theme.pxToRem(10)};
      }
    }

    & .progressBar {
      width: 100%;
      height: ${({ theme }) => theme.pxToRem(4)};
      border-bottom-left-radius: ${({ theme }) => theme.pxToRem(4)};
      border-bottom-right-radius: ${({ theme }) => theme.pxToRem(4)};
      background: ${({ theme }) => theme.colors.tertiary['B200']};

      & .progress {
        height: ${({ theme }) => theme.pxToRem(4)};
        border-bottom-left-radius: ${({ theme }) => theme.pxToRem(4)};
        border-bottom-right-radius: ${({ theme, progressPercent }) =>
          progressPercent === 100 ? theme.pxToRem(4) : 'none'};
        background: ${({ theme }) => theme.colors.primary.B400};
      }
    }
  }
`;

const DetailedProgressBar: React.FC<DetailedProgressBarProps> = ({
  title,
  totalSteps: totalStep,
  currentStep,
  ...props
}) => {
  const progressPercent = useMemo(
    () =>
      currentStep > 0
        ? Math.min(Math.ceil((currentStep / totalStep) * 100), 100)
        : 0,
    [currentStep, totalStep]
  );

  return (
    <StyledDetailedProgressBar {...props} progressPercent={progressPercent}>
      <div className="progressBox">
        <div className="progressText">
          <span>{progressPercent}%</span>
          <h3>{title}</h3>
        </div>
        <div className="progressBar">
          <motion.div
            className="progress"
            animate={{ width: `${progressPercent}%` }}
            initial={false}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 60,
              mass: 0.1,
              delay: 0,
            }}
          />
        </div>
      </div>
    </StyledDetailedProgressBar>
  );
};

export default DetailedProgressBar;
