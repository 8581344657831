import styled from 'styled-components';
import { RadioGroup, RadioGroupProps } from 'evergreen-ui';
import { Control, Controller, FieldError } from 'react-hook-form';
import React from 'react';
import InputError from 'components/atoms/input-error';

export type RadioButtonProps = {
  name: string;
  control: Control<any, any>;
  error?: FieldError;
} & RadioGroupProps;

const StyledRadioButton = styled(RadioGroup)`
  display: inline-flex;

  & > label {
    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: ${({ theme }) => theme.pxToRem(14)};
      line-height: ${({ theme }) => theme.pxToRem(20)};
      color: ${({ theme }) => theme.colors.neutral[800]};

      margin-right: ${({ theme }) => theme.pxToRem(24)};
    }

    & > div {
      width: ${({ theme }) => theme.pxToRem(14)};
      height: ${({ theme }) => theme.pxToRem(14)};
    }

    & > input:checked + div {
      background: ${({ theme }) => theme.colors.primary['B400']};
    }
  }
`;

const RadioButton: React.FC<RadioButtonProps> = ({
  control,
  options,
  name,
  error,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ ...field }) => {
        // Remove fieldState and formState from props to avoid React warnings
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fieldState, formState, ...fieldProps } = field;
        return (
          <>
            <StyledRadioButton
              {...props}
              {...fieldProps}
              value={field.field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                field.field.onChange?.(event.target.value);
              }}
              options={options}
            />
            {error && <InputError>{error.message}</InputError>}
          </>
        );
      }}
    />
  );
};

export default RadioButton;
