import { useState } from 'react';

import styled from 'styled-components';

import FulfillmentMethodDroppableNode from 'components/molecules/fulfillment-method-droppable-node';
import FulfillmentMethodSidePanel from 'components/molecules/fulfillment-method-side-panel';
import { useReactFlow, useStore } from 'react-flow-renderer';

import { FulfillmentMethodNodeType } from 'pages/business-configuration/nested-routes/business-blueprint/config';

import { DragDropContext } from 'react-beautiful-dnd';

import { supplyChainChildrenNodes } from 'pages/business-configuration/nested-routes/business-blueprint/config/nodes/supplyChain';
import { supplyChainEdges } from 'pages/business-configuration/nested-routes/business-blueprint/config/edges/supplyChain';

export type FulfillmentMethodNodeProps = {
  id: string;
  children?: React.ReactNode;
  data: {
    values: FulfillmentMethodNodeType['data']['values'] | [];
  };
  xPos: number;
  yPos: number;
};

const StyledFulfillmentMethodNode = styled.div``;

const FulfillmentMethodNode: React.FC<FulfillmentMethodNodeProps> = ({
  id,
  data,
  xPos,
  yPos,
}) => {
  const { values } = data;
  const [sidePanelActive, setSidePanelActive] = useState(false);
  const [draggableItems, setDraggableItems] = useState(values);
  const { getNodes, getEdges, setCenter } = useReactFlow();
  const { setNodes, setEdges } = useStore();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;
        if (destination?.droppableId === source.droppableId) return;

        if (destination?.droppableId.includes('drop')) {
          setDraggableItems((prevState) =>
            prevState.filter(({ id }) => id !== draggableItems[source.index].id)
          );

          setNodes([
            ...getNodes().filter((node) => node.id !== id),
            ...[
              {
                id: `supply-chain-${id.split('-')[1]}`,
                type: 'SupplyChainNode',
                data: {
                  label: draggableItems[source.index].label,
                  id: draggableItems[source.index].id,
                  icon: draggableItems[source.index].icon,
                },
                position: { x: xPos, y: yPos },
              },
              ...supplyChainChildrenNodes({
                parentNode: `supply-chain-${id.split('-')[1]}`,
              }),
              {
                id: 'supplier',
                type: 'FulfillmentList',
                data: {
                  header: {
                    title: 'Suppliers/Manufacturers ',
                    icon: 'PeoplesTwo',
                  },
                  options: [
                    {
                      id: 'delivery',
                      menuGroupTitle: 'Suppliers/Manufacturers ',
                      selectedGroupTitle: 'Suppliers/Manufacturers ',
                      options: [
                        {
                          logo: 'Precoro',
                          label: 'Precoro',
                          value: 'precoro',
                        },
                      ],
                    },
                  ],
                },
                zIndex: 10,
                position: { x: xPos + 32, y: yPos + 820 },
              },
            ],
          ]);
          setEdges([
            ...getEdges(),
            ...supplyChainEdges,
            {
              id: `customer-journey-${id.split('-')[1]}-supply-chain-${
                id.split('-')[1]
              }`,
              source: `customer-journey-${id.split('-')[1]}`,
              target: `supply-chain-${id.split('-')[1]}`,
              sourceHandle: 'right',
              targetHandle: 'left',
              type: 'BusinessModelEdge',
            },
          ]);
          setCenter(xPos - 800, yPos + 500, {
            zoom: 0.6,
            duration: 500,
          });
        }
      }}
    >
      <StyledFulfillmentMethodNode>
        <FulfillmentMethodDroppableNode
          onClick={() => {
            setSidePanelActive(true);
            setCenter(xPos + 294, yPos + 160, {
              zoom: 1.25,
              duration: 500,
            });
          }}
        />
        <FulfillmentMethodSidePanel
          active={sidePanelActive}
          title="Drag Over The Fulfillment Method"
          draggableItems={draggableItems}
          onClose={() => {
            setSidePanelActive(false);
            setCenter(xPos - 800, yPos, {
              zoom: 0.6,
              duration: 500,
            });
          }}
        />
      </StyledFulfillmentMethodNode>
    </DragDropContext>
  );
};

export default FulfillmentMethodNode;
