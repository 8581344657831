import styled from 'styled-components';

import NavBar from 'components/organisms/nav-bar';
import PageContentContainer from 'components/atoms/page-content-container';
import { useGlobalPageTemplate } from 'context';

export type GlobalPageTemplateProps = {
  minimizeNavbar?: boolean;
  extendSidePanel?: boolean;
  pageContentContainer?: boolean;
  children?: React.ReactNode;
};

const StyledGlobalPageTemplate = styled.div`
  position: relative;
`;

const GlobalPageTemplate: React.FC<GlobalPageTemplateProps> = ({
  children,
  pageContentContainer = true,
  ...props
}) => {
  const { minimizeNavbar, extendSidePanel } = useGlobalPageTemplate();

  if (!pageContentContainer) {
    return (
      <StyledGlobalPageTemplate>
        <NavBar minimize={props?.minimizeNavbar || minimizeNavbar} />
        {children}
      </StyledGlobalPageTemplate>
    );
  }

  return (
    <StyledGlobalPageTemplate>
      <NavBar minimize={props?.minimizeNavbar || minimizeNavbar} />
      <PageContentContainer
        minimizeNavbar={props?.minimizeNavbar || minimizeNavbar}
        sidebarOpen={props?.extendSidePanel || extendSidePanel}
      >
        {children}
      </PageContentContainer>
    </StyledGlobalPageTemplate>
  );
};

export default GlobalPageTemplate;
