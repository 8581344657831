import styled from 'styled-components';

import { Handle, HandleProps } from 'react-flow-renderer';

export type SupplyChainHandleProps = {
  children?: React.ReactNode;
} & HandleProps;

const StyledSourceHandle = styled(Handle)`
  background-color: ${({ theme }) => theme.colors.secondary['B400']};
  bottom: -5px;
  right: 20%;
`;

const StyledTargetHandle = styled(Handle)`
  left: 35%;
  height: 3px;
  width: 3px;
  background-color: transparent !important;
  border-radius: 0;
  border: none;

  & svg {
    position: absolute;
    left: -7px;
    top: -5px;
  }
`;

const SupplyChainHandle: React.FC<SupplyChainHandleProps> = ({
  type,
  ...props
}) => {
  if (type === 'source') {
    return <StyledSourceHandle type={type} {...props} />;
  }
  return (
    <StyledTargetHandle type={type} {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071C12.3166 11.0976 11.6834 11.0976 11.2929 10.7071L9 8.41421L9 9C9 9.55228 8.55228 10 8 10C7.44772 10 7 9.55228 7 9L7 8.41421L4.70711 10.7071C4.31658 11.0976 3.68342 11.0976 3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289L6.93934 5.64645C7.52513 5.06066 8.47487 5.06066 9.06066 5.64645L12.7071 9.29289Z"
          fill="url(#paint0_linear_2870_28868)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2870_28868"
            x1="13"
            y1="11.4958"
            x2="12.8434"
            y2="4.67643"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1875" stopColor="#678AF5" />
            <stop offset="1" stopColor="#52A2F9" />
          </linearGradient>
        </defs>
      </svg>
    </StyledTargetHandle>
  );
};

export default SupplyChainHandle;
