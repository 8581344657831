import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';
import React, { HTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

export type OnboardingNavProps = {
  timeSinceLastSave?: number;
  onClickBack?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onClickNext?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isNextDisabled?: boolean;
  isBackDisabled?: boolean;
  ref?:
    | ((instance: HTMLDivElement | null) => void)
    | RefObject<HTMLDivElement>
    | null
    | undefined;
  backButtonLabel?: string;
  nextButtonLabel?: string;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledOnboardingNav = styled.div`
  height: ${({ theme }) => theme.pxToRem(56)};
  padding: ${({ theme }) => theme.pxToRem(8)};

  background: ${({ theme }) =>
    theme.mode === 'light'
      ? theme.colors.neutral[0]
      : theme.colors.neutral[950]};
  box-shadow: ${({ theme }) =>
    `0px ${theme.pxToRem(4)} ${theme.pxToRem(50)} ${theme.pxToRem(
      -10
    )} rgba(193, 196, 214, 0.3)`};
  border-radius: ${({ theme }) => theme.pxToRem(16)};

  display: inline-flex;
  align-items: center;

  & svg {
    width: ${({ theme }) => theme.pxToRem(15)};
    height: ${({ theme }) => theme.pxToRem(15)};
    margin-right: ${({ theme }) => theme.pxToRem(7)};
    & path {
      stroke: ${({ theme }) => theme.colors.neutral[500]};
    }
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(20)};
    color: ${({ theme }) => theme.colors.neutral[600]};
    margin-right: ${({ theme }) => theme.pxToRem(16)};
  }

  & .separator {
    border: ${({ theme }) =>
      `${theme.pxToRem(1)} solid ${theme.colors.neutral[400]}`};
    height: ${({ theme }) => theme.pxToRem(32)};
  }

  & > button {
    margin-left: ${({ theme }) => theme.pxToRem(16)};
  }
`;

const OnboardingNav: React.FC<OnboardingNavProps> = ({
  timeSinceLastSave,
  onClickBack,
  onClickNext,
  isNextDisabled,
  isBackDisabled,
  backButtonLabel = 'Back',
  nextButtonLabel = 'Next step',
  ...props
}) => {
  const minutes = timeSinceLastSave ? timeSinceLastSave / 1000 / 60 : 0;

  const correctTime = minutes < 60 ? minutes : Math.floor(minutes / 60);
  const timeText =
    minutes < 60
      ? `${correctTime} min${correctTime > 1 ? 's' : ''}`
      : `${correctTime} hour${correctTime > 1 ? 's' : ''}`;
  return (
    <StyledOnboardingNav {...props}>
      {props.children}
      {timeSinceLastSave && (
        <>
          <Icon icon="CheckCircleIcon" variant="outline" />
          <p>{`saved ${timeText} ago`}</p>
          <hr className="separator" />
        </>
      )}
      <Button
        disabled={isBackDisabled}
        appearance="minimal"
        onClick={onClickBack}
        data-testid="onboardingNav-back-button"
      >
        {backButtonLabel}
      </Button>
      <Button
        type={onClickNext ? 'button' : 'submit'}
        disabled={isNextDisabled}
        appearance="primary"
        onClick={onClickNext}
        data-testid="onboardingNav-next-button"
      >
        {nextButtonLabel}
      </Button>
    </StyledOnboardingNav>
  );
};

export default OnboardingNav;
