import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';

import { useTheme } from 'context';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export type BarChartProps = {
  labels?: Array<string>;
  dataSets?: Array<{
    label: string;
    data: Array<string | number>;
  }>;
  children?: React.ReactNode;
};

const BarChart: React.FC<BarChartProps> = ({ labels, dataSets, ...props }) => {
  const { theme } = useTheme();
  if (!Array.isArray(labels) || !Array.isArray(dataSets)) {
    return null;
  }

  return (
    <Bar
      {...props}
      options={{
        scales: {
          x: {
            display: false,
            ticks: {
              color:
                theme.mode === 'light'
                  ? theme.colors.neutral[600]
                  : theme.colors.neutral[0],
              font: {
                size: 12,
                family: '"Poppins", sans-serif',
                weight: 'normal',
                lineHeight: 1,
              },
            },
            grid: {
              drawBorder: false,
              display: false,
            },
          },
          y: {
            ticks: {
              color:
                theme.mode === 'light'
                  ? theme.colors.neutral[600]
                  : theme.colors.neutral[0],
              font: {
                size: 12,
                family: '"Poppins", sans-serif',
                weight: 'normal',
                lineHeight: 1,
              },
            },
            grid: {
              drawBorder: false,
              display: false,
            },
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
            display: true,
            labels: {
              color:
                theme.mode === 'light'
                  ? theme.colors.neutral[600]
                  : theme.colors.neutral[0],
              font: {
                size: 16,
                family: '"Poppins", sans-serif',
                weight: 'normal',
                lineHeight: 1.33,
              },
              usePointStyle: true,
              boxWidth: 10,
            },
          },
          title: {
            display: true,
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            color:
              theme.mode === 'light'
                ? theme.colors.neutral[600]
                : theme.colors.neutral[0],
            font: {
              family: theme.fontFamilies.display,
              size: 12,
              weight: 900,
            },
          },
        },
      }}
      data={{
        labels,
        datasets: dataSets?.map(({ label, data }, index) => ({
          label,
          data,
          backgroundColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top
            );

            if (index > 0) {
              gradient.addColorStop(0, '#1FCCB1');
              gradient.addColorStop(1, '#1FCCB1');

              return gradient;
            }

            gradient.addColorStop(0, '#678AF5');
            gradient.addColorStop(1, '#52A2F9');

            return gradient;
          },
          borderRadius: 20,
          borderSkipped: false as false,
          maxBarThickness: 24,
        })),
      }}
    />
  );
};

export default BarChart;
