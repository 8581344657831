import { useState } from 'react';

import styled from 'styled-components';

import SidebarContainer from 'components/atoms/sidebar-container';
import PageContentContainer from 'components/atoms/page-content-container';
import MonthlyActionsDroppable from 'components/molecules/monthly-actions-droppable';
import ActionCard from 'components/molecules/action-card';

import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useGlobalPageTemplate } from 'context';

import { actions } from './data';

const PageContainer = styled.div`
  & .actions-droppable {
    min-height: 100%;
    & .heading {
      max-width: ${({ theme }) => theme.pxToRem(533)};
      margin-bottom: ${({ theme }) => theme.pxToRem(16)};

      & > h1 {
        margin-bottom: ${({ theme }) => theme.pxToRem(16)};
      }

      & > p {
        color: ${({ theme }) => theme.colors.neutral[600]};
      }
    }

    & .action-card {
      box-shadow: 0px 4px 50px -10px rgba(193, 196, 214, 0.4);
      margin-bottom: 14px;
    }

    & .actions-droppable-container {
      margin: 0 auto;
    }
  }
`;

const StyledSidePanel = styled(SidebarContainer)`
  z-index: 0;

  & .actions-container {
    margin-top: ${({ theme }) => theme.pxToRem(24)};
  }

  & .action-card {
    margin-bottom: ${({ theme }) => theme.pxToRem(14)};
  }
`;

const ActionPlan = () => {
  const { minimizeNavbar } = useGlobalPageTemplate();
  const [availableActions, setAvailableActions] = useState(actions);
  const [selectedActions, setSelectedActions] = useState<typeof actions | []>(
    []
  );

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          const { destination, source } = result;

          if (destination?.droppableId === source.droppableId) return;

          if (destination?.droppableId.includes('drop')) {
            setSelectedActions((prevState) => {
              const result = prevState;
              result.splice(source.index, 0, availableActions[source.index]);
              return result;
            });
            setAvailableActions((prevState) => {
              const result = prevState;
              result.splice(source.index, 1);
              return result;
            });
            return;
          }
          if (source?.droppableId.includes('drop')) {
            setAvailableActions((prevState) => {
              const result = prevState;
              result.splice(
                destination?.index || source.index,
                0,
                selectedActions[source.index]
              );
              return result;
            });
            setSelectedActions((prevState) => {
              const result = prevState;
              result.splice(source.index, 1);
              return result;
            });
          }
        }}
      >
        <PageContainer>
          <PageContentContainer
            className="actions-droppable"
            sidebarOpen
            minimizeNavbar={minimizeNavbar}
          >
            <div className="heading">
              <h1 className="H-900">Set Monthly Action Plan</h1>
              <p>
                Drag the actions to the area to choose them and to see the
                impact on your metrics as these tasks are done
              </p>
            </div>
            <Droppable droppableId={`drop-actions`}>
              {(provided) => {
                return (
                  <MonthlyActionsDroppable
                    innerRef={provided.innerRef}
                    {...provided.droppableProps}
                    className="actions-droppable-container"
                  >
                    {selectedActions.map(
                      ({ title, timeWeight, budgetWeight, id }, index) => {
                        return (
                          <Draggable
                            key={id}
                            draggableId={`drop-${id}`}
                            index={index}
                          >
                            {(provided) => (
                              <ActionCard
                                title={title}
                                budgetWeight={budgetWeight}
                                timeWeight={timeWeight}
                                innerRef={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="action-card"
                              />
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </MonthlyActionsDroppable>
                );
              }}
            </Droppable>
          </PageContentContainer>
        </PageContainer>
        <StyledSidePanel open>
          <Droppable droppableId="side-panel">
            {(provided) => {
              return (
                <div
                  className="actions-container"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {availableActions.map(
                    ({ title, timeWeight, budgetWeight, id }, index) => {
                      return (
                        <Draggable
                          key={id}
                          draggableId={`drag-${id}`}
                          index={index}
                        >
                          {(provided) => (
                            <ActionCard
                              title={title}
                              budgetWeight={budgetWeight}
                              timeWeight={timeWeight}
                              innerRef={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="action-card"
                            />
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </StyledSidePanel>
      </DragDropContext>
    </>
  );
};

export default ActionPlan;
