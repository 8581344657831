import styled from 'styled-components';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { updateBusinessSophistication } from '../../actions';
import {
  BusinessSophisticationInfoState,
  weeklyBusinessManagementTimes,
} from '../../StateMachine.types';
import { useNavigate } from 'react-router-dom';

import InputWrapper from 'components/molecules/input-wrapper';
import OnboardingNav from 'components/molecules/onboarding-nav';
import SliderInput from 'components/molecules/slider-input';
import Select from 'components/molecules/select';

const BusinessSophisticationInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & .form {
    display: flex;
    flex-direction: column;

    width: ${({ theme }) => theme.pxToRem(561)};

    & .input {
      width: 100%;
    }

    & .bottomNavigation {
      align-self: center;
      margin-top: ${({ theme }) => theme.pxToRem(24)};
    }
  }
`;

const businessSophisticationInfoSchema = yup.object({
  financialKnowledge: yup
    .number()
    .min(1, 'The minimum value is 1')
    .max(10, 'The maximum value is 10')
    .required(),
  accountingKnowledge: yup
    .number()
    .min(1, 'The minimum value is 1')
    .max(10, 'The maximum value is 10')
    .required(),
  marketingKnowledge: yup
    .number()
    .min(1, 'The minimum value is 1')
    .max(10, 'The maximum value is 10')
    .required(),
  operationalKnowledge: yup
    .number()
    .min(1, 'The minimum value is 1')
    .max(10, 'The maximum value is 10')
    .required(),
  weeklyBusinessManagementTime: yup
    .string()
    .notOneOf(['placeholder'], 'Please select one'),
});

const BusinessSophisticationInfo = () => {
  const navigate = useNavigate();
  const {
    state: { businessSophisticationInfoStore },
    actions,
  } = useStateMachine({
    updateBusinessSophistication,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessSophisticationInfoState>({
    resolver: yupResolver<yup.AnyObjectSchema>(
      businessSophisticationInfoSchema
    ) as any,
    defaultValues: {
      ...businessSophisticationInfoStore,
    },
  });

  console.log('ERRORS', errors);

  return (
    <BusinessSophisticationInfoContainer>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          actions.updateBusinessSophistication(data);
          navigate('/business-configuration/link-accounts');
        })}
      >
        <InputWrapper label="Please rate your Financial knowledge from 1-10">
          <SliderInput
            data-testid="financialKnowledge-slider"
            className="input"
            min={0}
            max={10}
            step={1}
            name="financialKnowledge"
            control={control}
            error={errors['financialKnowledge']}
          />
        </InputWrapper>

        <InputWrapper label="Please rate your Accounting knowledge from 1-10">
          <SliderInput
            data-testid="accountingKnowledge-slider"
            className="input"
            min={0}
            max={10}
            step={1}
            name="accountingKnowledge"
            control={control}
            error={errors['accountingKnowledge']}
          />
        </InputWrapper>

        <InputWrapper label="Please rate your Marketing knowledge from 1-10">
          <SliderInput
            data-testid="marketingKnowledge-slider"
            className="input"
            min={0}
            max={10}
            step={1}
            name="marketingKnowledge"
            control={control}
            error={errors['marketingKnowledge']}
          />
        </InputWrapper>

        <InputWrapper label="Please rate your Operational knowledge from 1-10">
          <SliderInput
            data-testid="operationalKnowledge-slider"
            className="input"
            min={0}
            max={10}
            step={1}
            name="operationalKnowledge"
            control={control}
            error={errors['operationalKnowledge']}
          />
        </InputWrapper>

        <InputWrapper label="How many hours per week do you spend managing your business?">
          <Select
            data-testid="weeklyBusinessManagementTime-input"
            className="input"
            placeholder="Select One"
            name="weeklyBusinessManagementTime"
            control={control}
            error={errors['weeklyBusinessManagementTime']}
            errorMessage={errors['weeklyBusinessManagementTime']?.message}
            values={weeklyBusinessManagementTimes}
          />
        </InputWrapper>
        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/account-settings/risk-info');
          }}
        />
      </form>
    </BusinessSophisticationInfoContainer>
  );
};

export default BusinessSophisticationInfo;
