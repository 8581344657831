import InputError from 'components/atoms/input-error';
import { InputHTMLAttributes, RefObject } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import styled from 'styled-components';

export type TextInputProps = {
  name: string;
  control: Control<any>;
  placeholder?: string;
  error?: FieldError;
  disabled?: boolean;
  prefix?: string;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'value' | 'ref'
>;

const StyledTextInput = styled.div<Pick<TextInputProps, 'error'>>`
  /* Parent box styles */
  display: inline-flex;
  margin-bottom: ${({ theme, error }) =>
    error ? theme.pxToRem(0) : theme.pxToRem(16)};
  border: ${({ theme, error }) =>
    error
      ? `1px solid ${theme.colors.secondary['R400']}`
      : `1px solid ${theme.colors.neutral[400]}`};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.pxToRem(8)};
  background: ${({ theme }) => theme.colors.neutral[0]};

  &:focus-within {
    outline: none;
    transition: box-shadow 80ms ease-in-out;
    border: ${({ theme }) => `1px solid  ${theme.colors.tertiary['B200']}`};
    box-shadow: ${({ theme }) =>
      `0px 0px 0px 2px ${theme.colors.tertiary['B200']}`};
    box-sizing: border-box;
  }

  label {
    background: ${({ theme }) => theme.colors.neutral[200]};
    padding: ${({ theme }) => theme.pxToRem(12)};
    border-top-left-radius: ${({ theme }) => theme.pxToRem(8)};
    border-bottom-left-radius: ${({ theme }) => theme.pxToRem(8)};

    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(16)};
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  input {
    width: 100%;
    border: none;
    padding: ${({ theme }) => theme.pxToRem(12)};
    border-radius: ${({ theme }) => theme.pxToRem(8)};

    /* Input text styles (label and input) */
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(16)};
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.neutral[800]};

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral[600]};
    }

    &:hover {
      &::placeholder {
        color: ${({ theme }) => theme.colors.neutral[700]};
      }
    }

    &:focus {
      outline: none;
      &::placeholder {
        color: ${({ theme }) => theme.colors.neutral[700]};
      }
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.neutral[100]};
      border: ${({ theme }) => `1px solid ${theme.colors.neutral[300]}`};
      &::placeholder {
        color: ${({ theme }) => theme.colors.neutral[600]};
      }
    }
  }
`;

const TextInput: React.FC<TextInputProps> = ({
  name,
  control,
  error,
  prefix,
  className,
  style,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ ...field }) => {
        // Remove fieldState and formState from props to avoid React warnings
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fieldState, formState, ...fieldProps } = field;

        return (
          <>
            <StyledTextInput error={error} className={className} style={style}>
              {prefix && <label>{prefix}</label>}
              <input
                {...props}
                {...fieldProps}
                value={field.field.value}
                onChange={(event) => {
                  field.field.onChange?.(event.target.value);
                }}
              />
            </StyledTextInput>
            {error && <InputError>{error.message}</InputError>}
          </>
        );
      }}
    />
  );
};

export default TextInput;
