import { useMutation, UseMutationOptions } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const useSetUserObjectives = (
  options?:
    | Omit<
        UseMutationOptions<
          string,
          unknown,
          { objectivesIds: string[] },
          unknown
        >,
        'mutationFn'
      >
    | undefined
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  return useMutation(
    async ({
      objectivesIds,
      delay,
    }: {
      objectivesIds: string[];
      delay?: number;
    }) => {
      return new Promise((resolve) => setTimeout(resolve, delay || 0)).then(
        async () => {
          const token = window.Cypress
            ? 'cypress-mock-token'
            : await getAccessTokenSilently();

          if (!isAuthenticated && !window.Cypress) {
            throw new Error(`You need to login to create a user.`);
          }

          const response = await fetch(
            `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/objectives/select`,
            {
              method: 'POST',
              headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                ids: objectivesIds,
              }),
            }
          );

          if (response.status !== 201) {
            throw new Error(`Failed to set the user objectives.`);
          }

          return 'success';
        }
      );
    },
    { ...(options ? { ...options } : {}) }
  );
};
