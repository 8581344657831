import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { getUserData, GET_USER_DATA_QUERY } from 'api-client/queries';
import { useQuery } from 'react-query';

import LoadingOverlay from 'components/molecules/loading-overlay';
import { useNavigate } from 'react-router-dom';

import { UserProvider } from 'context';

export type CreateUserProps = {
  children?: React.ReactNode;
};

const CreateUser: React.FC<CreateUserProps> = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const navigate = useNavigate();
  const { data: userData, isLoading: userDataLoading } = useQuery(
    [GET_USER_DATA_QUERY, { getAccessTokenSilently, user }] as const,
    getUserData,
    {
      enabled: window.Cypress ? true : isAuthenticated,
    }
  );

  useEffect(() => {
    if (userData && !userData.notFound) {
      navigate('/');
    }
    if (window.Cypress) {
      return;
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
      return;
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    navigate,
    userData,
    user,
  ]);

  if (window.Cypress && userData && userData?.notFound) {
    return (
      <>
        <UserProvider user={{ ...userData }}>{children}</UserProvider>
      </>
    );
  }

  if (isLoading) {
    return <LoadingOverlay title="Logging you in securely..." />;
  }

  if (userDataLoading) {
    return (
      <LoadingOverlay
        data-testid="loading-user"
        title="Preparing user data..."
      />
    );
  }

  if (isAuthenticated && userData && userData?.notFound) {
    return (
      <>
        <UserProvider user={{ ...userData }}>{children}</UserProvider>
      </>
    );
  }

  return null;
};

export default CreateUser;
