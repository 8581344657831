import styled from 'styled-components';

import { Handle, HandleProps } from 'react-flow-renderer';

export type CustomerJourneyHandleProps = {
  children?: React.ReactNode;
} & HandleProps;

const StyledSourceHandle = styled(Handle)`
  background-color: ${({ theme }) => theme.colors.secondary['B400']};
  top: 70%;
`;

const StyledTargetHandle = styled(Handle)`
  background-color: transparent;
  top: 30%;
  height: 19px;

  border: none;

  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: -7px;
    border-color: ${({ theme }) => theme.colors.secondary['B400']};
  }

  .right {
    transform: rotate(-45deg);
  }
`;

const CustomerJourneyHandle: React.FC<CustomerJourneyHandleProps> = ({
  type,
  ...props
}) => {
  if (type === 'source') {
    return <StyledSourceHandle type={type} {...props} />;
  }
  return (
    <StyledTargetHandle type={type} {...props}>
      <i className="arrow right"></i>
    </StyledTargetHandle>
  );
};

export default CustomerJourneyHandle;
