import { useReducer, Dispatch } from 'react';
import { useCanvas } from '../context';

import { BusinessModelOptionType } from 'pages/business-configuration/nested-routes/business-blueprint/config';

type UseBusinessModelDragItem = (
  values: BusinessModelOptionType[],
  id:
    | 'distribution-model'
    | 'product-category'
    | 'revenue-model'
    // | 'target-customers'
    | 'product-model'
    | 'business-model'
) => {
  draggableItems: BusinessModelOptionType[] | [];
  droppableItems: BusinessModelOptionType[] | [];
  dispatchBusinessModelDragAction: Dispatch<{
    type: 'DROP_ITEM' | 'REMOVE_ITEM';
    item: BusinessModelOptionType;
  }>;
};

export const DROP_ITEM = 'DROP_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export const useBusinessModelDragItem: UseBusinessModelDragItem = (
  values,
  id
) => {
  const { businessModel } = useCanvas();
  const stateValues = businessModel[id];
  const defaultState = {
    draggableItems: values.reduce(
      (acc: BusinessModelOptionType[], { id, label, icon }) => {
        const value = stateValues.find((stateValue) => {
          return label === stateValue.label;
        });

        if (value) {
          return [...acc];
        }
        return [...acc, { id, label, icon }];
      },
      []
    ),
    droppableItems: businessModel[id],
  };

  const reducer = (
    state: {
      draggableItems: BusinessModelOptionType[] | [];
      droppableItems: BusinessModelOptionType[] | [];
    },
    action: {
      type: 'DROP_ITEM' | 'REMOVE_ITEM';
      item: BusinessModelOptionType;
    }
  ) => {
    switch (action.type) {
      case DROP_ITEM:
        return {
          ...state,
          draggableItems: [
            ...state.draggableItems.filter(({ id }) => id !== action.item.id),
          ],
          droppableItems: [...state.droppableItems, action.item],
        };
      case REMOVE_ITEM:
        return {
          ...state,
          draggableItems: [...state.draggableItems, ...[action.item]],
          droppableItems: state.droppableItems.filter(
            ({ id }) => id !== action.item.id
          ),
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  return { ...state, dispatchBusinessModelDragAction: dispatch };
};
