import styled from 'styled-components';

import { Link, LinkProps } from 'react-router-dom';
export type LogoProps = {} & LinkProps;

import LogoImage from './honu-logo.svg';

const StyledLogo = styled(Link)`
  display: inline-block;
  width: 100%;
  height: ${({ theme }) => theme.pxToRem(36)};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${LogoImage});
`;

const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return <StyledLogo {...props} aria-label="Honu Logo Image" />;
};

export default Logo;
