import { Routes, Route } from 'react-router-dom';

import styled from 'styled-components';

import Home from 'pages/home';
import BusinessConfiguration from 'pages/business-configuration';
import NotFound from 'pages/not-found';
import CreateUser from 'pages/create-user';
import CreateSource from 'pages/create-source';
import BusinessBlueprint from 'pages/business-configuration/nested-routes/business-blueprint';

import GlobalPageTemplate from 'components/template/global-page-template';
import AccountSettingsPageTemplate from 'components/template/account-settings-page-template';
import UserBusinessInfoTemplate from 'components/template/user-business-info-template';
import OnboardingJourneyMenu from 'components/molecules/onboarding-journey-menu';

import RequireAuth from 'components/utility/require-auth';

import AccountSettings, {
  PersonalInfo,
  BusinessInfo,
} from 'pages/account-settings';

import BlueprintTemplate from 'pages/business-configuration/nested-routes/blueprint-template/BlueprintTemplate';
import CustomerJourney from 'pages/business-configuration/nested-routes/customer-journey';
import FinancialPlatforms from 'pages/business-configuration/nested-routes/financial-platforms/FinancialPlatforms';
import FinancialInfo from './pages/account-settings/nested-routes/financial-info/FinancialInfo';
import { createStore, StateMachineProvider } from 'little-state-machine';
import AcuityInfo from 'pages/account-settings/nested-routes/acuity-info/AcuityInfo';
import RiskInfo from 'pages/account-settings/nested-routes/risk-info/RiskInfo';
import BusinessSophisticationInfo from 'pages/account-settings/nested-routes/business-sophistication-info/BusinessSophisticationInfo';
import GoalsInfo from 'pages/account-settings/nested-routes/goals-info/GoalsInfo';
import WorkingCapitalInfo from 'pages/account-settings/nested-routes/working-capital-info/WorkingCapitalInfo';
import LinkAccounts from 'pages/business-configuration/nested-routes/link-accounts/LinkAccounts';
import Objectives from 'pages/objectives';
import ActionPlan from 'pages/action-plan';
import ChooseActions from 'pages/choose-actions';
import SalesAndMarketing from 'pages/sales-and-marketing';
import Kpi from 'pages/kpi';
import FinancingOptions from 'pages/financing-options';
import MyActions from 'pages/my-actions';
import Finance from 'pages/finance';
import Operations from 'pages/operations';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as EuiThemeProvider } from 'evergreen-ui';

import GlobalStyles from 'global-styles';
import { useTheme } from 'context/theme';

import { GlobalPageTemplateProvider } from 'context';

import { useAuth0 } from '@auth0/auth0-react';

import Card from 'components/atoms/card';

const SelectMenuContainer = styled(Card)``;

const App = () => {
  const { logout } = useAuth0();
  const { theme } = useTheme();

  createStore({
    personalStore: {
      firstName: 'Imad',
      lastName: 'Riachi',
      email: 'imad.riachi@honu.ai',
      role: ['CEO'],
    },
    businessStore: {
      logo: [],
      businessName: 'Save the Turtles',
      companyNumber: '12532524',
      companyType: 'Private Limited',
      companyWebsite: 'www.savetheturtles.org.uk',
      dateOfIncorporation: new Date(
        'Thurs Jun 09 2022 12:00:00 GMT+0100 (British Summer Time)'
      ),
      numberOfEmployees: '6 to 10',
    },
    financialInfoStore: {
      isBesideSale: 'no',
      incomeSources: ['Affiliates'],
      mainCostCentre: 'marketing',
      fundingToDate: '10000',
      isRaiseMoney: 'yes',
      raiseAmount: '10000',
      isDebt: 'yes',
      debtAmount: '20000',
    },
    goalsInfoStore: {
      aspirations: 'make_a_living',
      mainConcerns: ['Low margin', 'Lack of growth'],
      businessPerformance: 'ok',
      yearlyRevenue: '100k-500k',
      otherConsiderations: 'green',
      sellNewItems: 'yes',
      raiseAmount: '',
      isDebt: 'yes',
      debtAmount: '',
    },
    acuityInfoStore: {
      metrics: ['Orders', 'CLTV', 'Revenue'],
      financialKnowledgeRate: 5,
      marketingKnowledgeRate: 4,
      operationalManagementKnowledgeRate: 3,
      functions: ['Operations'],
      missingRoles: ['CFO'],
      financingProducts: ['Credit Card'],
    },
    riskInfoStore: {
      riskTolerance: 6,
      takePersonalLoans: 'yes',
    },
    businessSophisticationInfoStore: {
      financialKnowledge: 7,
      accountingKnowledge: 3,
      marketingKnowledge: 6,
      operationalKnowledge: 3,
      weeklyBusinessManagementTime: '11-20h',
    },
    workingCapitalInfoStore: {
      payableDaysOutstanding: '11-20',
      inventorySize: '£6-15k',
      payableDays: '20',
      inventoryDays: '20',
      receivableDays: '10',
      processingDays: '10',
      fullfilmentDays: '5',
    },
    bluePrintTemplateStore: {
      templateId: undefined,
      selectedOptions: [],
    },
    businessBlueprint: {
      selectedNodeId: null,
      'distribution-model': [],
      'product-category': [],
      'revenue-model': [],
      // 'target-customers': [],
      'product-model': [],
      'business-model': [],
    },
  });

  return (
    <StateMachineProvider>
      <ThemeProvider theme={theme}>
        <EuiThemeProvider value={theme}>
          <OnboardingJourneyMenu />
          <GlobalStyles />
          <SelectMenuContainer elevation="true"></SelectMenuContainer>
          <StateMachineProvider>
            <Routes>
              {/* Business Cockpit */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <GlobalPageTemplateProvider>
                      <GlobalPageTemplate pageContentContainer={false}>
                        <Home />
                      </GlobalPageTemplate>
                    </GlobalPageTemplateProvider>
                  </RequireAuth>
                }
              />
              {/* <Route
                index
                element={
                  <RequireAuth>
                    <MetricsTab />
                  </RequireAuth>
                }
              />
              <Route
                path="kpi-overview"
                element={
                  <RequireAuth>
                    <KpiOverviewTab />
                  </RequireAuth>
                }
              /> */}

              <Route
                path="objectives"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <Objectives />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="goals/action-plan"
                element={
                  <GlobalPageTemplate
                    extendSidePanel
                    pageContentContainer={false}
                  >
                    <ActionPlan />
                  </GlobalPageTemplate>
                }
              />
              {/* Account Settings */}
              <Route
                path="/account-settings"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate>
                      <AccountSettings />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/personal-info"
                element={
                  <RequireAuth>
                    <AccountSettingsPageTemplate title="Personal Info">
                      <PersonalInfo />
                    </AccountSettingsPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/business-info"
                element={
                  <RequireAuth>
                    <AccountSettingsPageTemplate title="Business Info">
                      <BusinessInfo />
                    </AccountSettingsPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/financial-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Financial Info"
                      currentStep={1}
                      totalSteps={5}
                      progress={{ index: 1, percent: 25 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/financial-info'
                        );
                        logout();
                      }}
                    >
                      <FinancialInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/working-capital-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Working Capital"
                      currentStep={1}
                      totalSteps={5}
                      progress={{ index: 1, percent: 25 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/working-capital-info'
                        );
                        logout();
                      }}
                    >
                      <WorkingCapitalInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/acuity-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Acuity Info"
                      currentStep={1}
                      totalSteps={5}
                      progress={{ index: 2, percent: 30 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/acuity-info'
                        );
                        logout();
                      }}
                    >
                      <AcuityInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/goals-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Goals Info"
                      currentStep={3}
                      totalSteps={5}
                      progress={{ index: 2, percent: 75 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/goals-info'
                        );
                        logout();
                      }}
                    >
                      <GoalsInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/risk-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Risk Info"
                      currentStep={4}
                      totalSteps={5}
                      progress={{ index: 2, percent: 75 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/risk-info'
                        );
                        logout();
                      }}
                    >
                      <RiskInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/account-settings/business-sophistication-info"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Business Sophistication Info"
                      currentStep={5}
                      totalSteps={5}
                      progress={{ index: 2, percent: 75 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/account-settings/business-sophistication-info'
                        );
                        logout();
                      }}
                    >
                      <BusinessSophisticationInfo />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              {/* Business Configuration */}
              <Route
                path="/business-configuration"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate>
                      <BusinessConfiguration />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/business-configuration/blueprint-template"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Business Blueprint"
                      currentStep={1}
                      totalSteps={4}
                      progress={{ index: 0, percent: 0 }}
                      bodyBgColor={theme.colors.neutral[150]}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/business-configuration/blueprint-template'
                        );
                        logout();
                      }}
                    >
                      <BlueprintTemplate />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/business-configuration/business-blueprint"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Business Model"
                      currentStep={2}
                      totalSteps={4}
                      progress={{ index: 0, percent: 50 }}
                      bodyBgColor={theme.colors.neutral[150]}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/business-configuration/business-blueprint'
                        );
                        logout();
                      }}
                    >
                      <BusinessBlueprint />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/business-configuration/customer-journey"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Customer Journey"
                      currentStep={1}
                      totalSteps={4}
                      progress={{ index: 0, percent: 0 }}
                      bodyBgColor={theme.colors.neutral[150]}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/business-configuration/customer-journey'
                        );
                        logout();
                      }}
                    >
                      <CustomerJourney />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/business-configuration/financial-platforms"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Financial Platforms"
                      currentStep={1}
                      totalSteps={4}
                      progress={{ index: 0, percent: 0 }}
                      onSaveAndExit={() => {
                        window.localStorage.setItem(
                          'onboarding',
                          '/business-configuration/financial-platforms'
                        );
                        logout();
                      }}
                    >
                      <FinancialPlatforms />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/business-configuration/link-accounts"
                element={
                  <RequireAuth>
                    <UserBusinessInfoTemplate
                      title="Financial Platforms"
                      currentStep={1}
                      totalSteps={4}
                      progress={{ index: 0, percent: 0 }}
                    >
                      <LinkAccounts />
                    </UserBusinessInfoTemplate>
                  </RequireAuth>
                }
              />
              {/* GENERAL ROUTES */}
              <Route
                path="/choose-actions"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <ChooseActions />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/sales-and-marketing"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <SalesAndMarketing />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/sales-and-marketing/:kpi"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <Kpi />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/financing-options"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <FinancingOptions />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/my-actions"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <MyActions />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/finance"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <Finance />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              <Route
                path="/operations"
                element={
                  <RequireAuth>
                    <GlobalPageTemplate pageContentContainer={false}>
                      <Operations />
                    </GlobalPageTemplate>
                  </RequireAuth>
                }
              />
              {/* Utility Routes */}
              <Route
                path="/create-user"
                element={
                  <RequireAuth variant="create-user">
                    <CreateUser />
                  </RequireAuth>
                }
              />
              <Route
                path="/create-source"
                element={
                  <RequireAuth>
                    <CreateSource />
                  </RequireAuth>
                }
              />
              <Route
                path="/business-blueprint"
                element={
                  <RequireAuth>
                    <BusinessBlueprint />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </StateMachineProvider>
        </EuiThemeProvider>
      </ThemeProvider>
    </StateMachineProvider>
  );
};
export default App;
