import styled from 'styled-components';

import BusinessOverview from 'components/organisms/business-overview';
import PageContentContainer from 'components/atoms/page-content-container';
import TurtleCreationLoader from 'components/atoms/turtle-creation-loader';

import PageHeader from 'components/molecules/page-header';
import HonuSuggestionsIndicator from 'components/atoms/honu-suggestions-indicator';

import { useQuery } from 'react-query';
import { getKpis, GET_KPIS_QUERY, KpiType } from 'api-client/queries';

import { useAuth0 } from '@auth0/auth0-react';

import { useGlobalPageTemplate } from 'context';

const PageContainer = styled.div`
  & > .page-header {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-content: flex-end;
    min-height: auto;
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 24,
        right: 24,
        bottom: 32,
      })};

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
    }
    & div:nth-child(3) {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .page-content {
    height: ${({ theme }) => theme.pxToRem(450)};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    border: ${({ theme }) =>
      `${theme.pxToRem(2)} solid ${theme.colors.neutral[0]}`};

    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 32,
        right: 32,
        bottom: 32,
      })};
  }
`;

const Home = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { minimizeNavbar } = useGlobalPageTemplate();

  const { data: kpisData, isLoading: kpisIsLoading } = useQuery(
    [GET_KPIS_QUERY, { getAccessTokenSilently }] as const,
    getKpis
  );

  return (
    <>
      <PageContainer>
        <PageHeader title="Summary">
          <HonuSuggestionsIndicator disabled />
        </PageHeader>
        <PageContentContainer
          wrapper
          className="page-content"
          minimizeNavbar={minimizeNavbar}
        >
          {kpisIsLoading ? (
            <TurtleCreationLoader />
          ) : (
            <BusinessOverview
              kpiList={
                kpisData &&
                (
                  Object.keys(kpisData.kpis) as (
                    | 'sales_and_marketing'
                    | 'finance'
                    | 'operations'
                  )[]
                ).reduce(
                  (acc, key) => [
                    ...acc,
                    ...kpisData.kpis[key].map((kpi) => {
                      return kpi?.is_percentage
                        ? { ...kpi, value: Math.round(kpi.value * 100) }
                        : { ...kpi };
                    }),
                  ],
                  [] as KpiType[]
                )
              }
            />
          )}
        </PageContentContainer>
      </PageContainer>
    </>
  );
};

export default Home;
