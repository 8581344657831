import styled from 'styled-components';

import { IconProps } from 'components/atoms/icon/Icon';

import Icon from 'components/atoms/icon';
import Button from 'components/atoms/button';

export type ActionCardCtaProps = {
  children?: React.ReactNode;
  title?: string;
  timeWeight?: number;
  budgetWeight?: number;
  innerRef?: any;
  focused?: boolean;
  selected?: boolean;
  completed?: boolean;
  actionCta?: React.ReactNode;
  type?: 'finance' | 'operations' | 'marketing';
  onSelect?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledActionCardCta = styled.div<
  Pick<ActionCardCtaProps, 'focused'> & { iconColor?: string }
>`
  min-width: ${({ theme }) => theme.pxToRem(350)};
  position: relative;

  & > .card {
    width: 100%;
    min-height: ${({ theme }) => theme.pxToRem(88)};
    background-color: ${({ theme, focused }) =>
      focused
        ? theme.mode === 'light'
          ? theme.colors.neutral[200]
          : theme.colors.neutral[960]
        : theme.mode === 'light'
        ? theme.colors.neutral[0]
        : theme.colors.neutral[910]};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    display: grid;
    grid-template-columns: 20% 70% 10%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    padding-top: ${({ focused, theme }) =>
      focused ? theme.pxToRem(21) : theme.pxToRem(22)};
    padding-bottom: ${({ focused, theme }) =>
      focused ? theme.pxToRem(21) : theme.pxToRem(22)};
    border: ${({ focused, theme }) =>
      focused ? `1px solid ${theme.colors.primary['BLUE']}` : 'none'};

    &:hover {
      background-color: ${({ theme }) =>
        theme.mode === 'light'
          ? theme.colors.neutral[200]
          : theme.colors.neutral[960]};
    }

    & div:nth-child(1) {
      & > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        background-color: ${({ theme, iconColor }) =>
          iconColor || theme.colors.secondary['Y400']};
        border-radius: 50%;

        & > svg {
          width: ${({ theme }) => theme.pxToRem(14)};
          height: ${({ theme }) => theme.pxToRem(14)};
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }

    & div:nth-child(2) {
      width: 100%;

      & h5 {
        color: ${({ theme }) =>
          theme.mode === 'light'
            ? theme.colors.neutral[900]
            : theme.colors.neutral[0]};
        &.completed {
          text-decoration: line-through;
        }
        text-align: left;
        font-size: ${({ theme }) => theme.pxToRem(16)};
        font-weight: 400;
      }

      & .scale {
        display: flex;
        margin-top: ${({ theme }) => theme.pxToRem(4)};

        & svg {
          width: ${({ theme }) => theme.pxToRem(20)};
          height: ${({ theme }) => theme.pxToRem(20)};
          fill: ${({ theme }) => theme.colors.neutral[500]};
        }

        &__time {
          display: flex;
          border-right: 1px solid ${({ theme }) => theme.colors.neutral[200]};
          padding: ${({ theme }) =>
            theme.pxToRem({
              top: 0,
              left: 0,
              right: 8,
              bottom: 0,
            })};

          & > svg {
            &.active {
              fill: ${({ theme }) => theme.colors.secondary['G400']};
            }
          }
        }

        &__money {
          display: flex;
          padding: ${({ theme }) =>
            theme.pxToRem({
              top: 0,
              left: 8,
              right: 8,
              bottom: 0,
            })};

          & > svg {
            &.active {
              fill: ${({ theme }) => theme.colors.tertiary['T400']};
            }
          }
        }
      }
    }
  }

  & > .select-cta {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > button {
      width: ${({ theme }) => theme.pxToRem(32)};
      height: ${({ theme }) => theme.pxToRem(32)};
      border-radius: 50%;

      & > svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(16)};
        height: ${({ theme }) => theme.pxToRem(16)};
        fill: ${({ theme }) => theme.colors.neutral[500]};
      }
    }
  }
`;

const ActionCardCta: React.FC<ActionCardCtaProps> = ({
  children,
  title,
  timeWeight,
  budgetWeight,
  focused,
  selected,
  onSelect,
  type,
  actionCta,
  completed,
  ...props
}) => {
  const time = new Array(3).fill(undefined).map((undefined, index) => {
    if (timeWeight && index + 1 <= timeWeight) {
      return <Icon icon="ClockIcon" key={index} className="active" />;
    }
    return <Icon icon="ClockIcon" key={index} />;
  });

  const budget = new Array(3).fill(undefined).map((undefined, index) => {
    if (budgetWeight && index + 1 <= budgetWeight) {
      return <Icon icon="CurrencyDollarIcon" key={index} className="active" />;
    }
    return <Icon icon="CurrencyDollarIcon" key={index} />;
  });

  const iconType = {
    finance: {
      icon: 'DatabaseIcon',
      iconColor: '#678AF5',
    },
    operations: {
      icon: 'CubeIcon',
      iconColor: '#99DFD2',
    },
    marketing: {
      icon: 'ChartBarIcon',
      iconColor: '#B0A5F6',
    },
  };

  return (
    <StyledActionCardCta
      {...props}
      focused={focused}
      iconColor={type ? iconType[type].iconColor : 'V400'}
    >
      <button className="card">
        <div>
          <span>
            {type && <Icon icon={iconType[type].icon as IconProps['icon']} />}
          </span>
        </div>
        <div>
          <h5 className={`H-600 ${completed ? 'completed' : ''}`}>{title}</h5>
          <div className="scale">
            <div className="scale__time">{time}</div>
            <div className="scale__money">{budget}</div>
          </div>
        </div>
      </button>
      <div className="select-cta">
        {actionCta ? (
          actionCta
        ) : (
          <Button
            aria-label="Select Action Button"
            appearance={selected ? 'primary' : 'default'}
            onClick={(event) => {
              onSelect?.(event);
            }}
          >
            <Icon icon="CheckIcon" />
          </Button>
        )}
      </div>
      {children}
    </StyledActionCardCta>
  );
};

export default ActionCardCta;
