import { useMutation, UseMutationOptions } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

export const useSetSelectedActions = (
  options?:
    | Omit<
        UseMutationOptions<string, unknown, { actionsIds: number[] }, unknown>,
        'mutationFn'
      >
    | undefined
) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  return useMutation(
    async ({ actionsIds }: { actionsIds: number[] }) => {
      const token = window.Cypress
        ? 'cypress-mock-token'
        : await getAccessTokenSilently();

      if (!isAuthenticated && !window.Cypress) {
        throw new Error(`You need to login to create a user.`);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/reasoning/api/options/actions/select`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            ids: actionsIds,
          }),
        }
      );

      if (response.status !== 201) {
        throw new Error(`Failed to set the selected actions.`);
      }

      return 'success';
    },
    { ...(options ? { ...options } : {}) }
  );
};
