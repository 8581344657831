export const financingProducts = [
  'Unsecured loan',
  'Secured loan',
  'Revenue based financing',
  'Invoice Financing',
  'Credit Card',
  'Revolving Credit Facility',
  'Startup Loan',
] as const;

export const personalRoles = ['CEO', 'CFO', 'CMO', 'CPO', 'COO'];

export const incomeSources = ['Advertising', 'Affiliates'];

export const mainCostCentre = [
  { value: 'marketing', label: 'Marketing' },
  { value: 'procurement', label: 'Procurement' },
  { value: 'hr', label: 'HR' },
];

export const acuityMetrics = [
  'CAC',
  'CLTV',
  'Orders',
  'Revenue',
  'Cart Abandonement',
] as const;

export const acuityFunctions = [
  'Marketing',
  'Sales',
  'Operations',
  'Technology',
  'Finance',
] as const;

export const missingRoles = ['CEO', 'CFO', 'COO', 'CTO', 'CMO'] as const;

/* === Goals information ===*/
export const goalsMainConcerns = [
  'Cash management',
  'Lack of growth',
  'High fixed expenses',
  'Low margin',
];

export const goalsAspirations = [
  { value: 'unicorn', label: 'To become the next Unicorn' },
  { value: 'side_business', label: 'To be a side business' },
  { value: 'make_a_living', label: 'To make a living out of it' },
  { value: 'business_afloat', label: 'To keep the business afloat' },
];

export const goalsBusinessPerformance = [
  { value: 'very_well', label: 'Very well' },
  { value: 'well', label: 'Well' },
  { value: 'ok', label: 'It’s ok' },
  { value: 'poorly', label: 'Very poorly' },
];

export const goalsYearlyRevenue = [
  { value: '0k-50k', label: '£0-50k' },
  { value: '50-100k', label: '£50-100k' },
  { value: '100k-500k', label: '£100k-500k' },
  { value: '500k-2m', label: '£500k-2m' },
  { value: '2m-5m', label: '£2m-5m' },
  { value: '>5m', label: 'More than £5m' },
];
export const goalsOtherConsiderations = [
  { value: 'difference', label: 'To make a difference to peoples lives' },
  { value: 'green', label: 'To be environmentally friendly' },
  { value: 'CSR', label: 'To have CSR' },
];
/* === Goals information ===*/

export const weeklyBusinessManagementTimes = [
  { value: '<5h', label: 'Less than 5 hours' },
  { value: '5-10h', label: '5-10 hours' },
  { value: '11-20h', label: '11-20 hours' },
  { value: '21-30h', label: '21-30 hours' },
  { value: '31-40h', label: '31-40 hours' },
  { value: '>40h', label: 'More than 40 hours' },
];

/* === Working Capital Information screen === */
export const payableDaysOutstanding = [
  { value: '0-10', label: '0-10' },
  { value: '11-20', label: '11-20' },
  { value: '21-30', label: '21-30' },
  { value: '30 or more', label: '30 or more' },
];

export const inventorySize = [
  { value: '£0-5k', label: '£0-5k' },
  { value: '£6-15k', label: '£6-15k' },
  { value: '£16-30k', label: '£16-30k' },
  { value: '£30k-100k', label: '£30k-100k' },
  { value: 'Above £100k', label: 'Above £100k' },
];

export type PersonalState = {
  firstName: string;
  lastName: string;
  email: string;
  role: typeof personalRoles;
};

export type BusinessState = {
  logo: File[];
  businessName: string;
  companyNumber: string;
  companyType:
    | 'placeholder'
    | 'Private Limited'
    | 'Sole Trader'
    | 'Business Partnership'
    | 'Social Enterprise'
    | 'Overseas Company'
    | 'Unincorporated Association';
  companyWebsite: string;
  dateOfIncorporation?: Date;
  numberOfEmployees:
    | 'placeholder'
    | '0 to 5'
    | '6 to 10'
    | '11 to 20'
    | 'above 21';
};

export type FinancialInfoState = {
  isBesideSale: 'yes' | 'no';
  incomeSources: typeof incomeSources;
  mainCostCentre: string;
  fundingToDate: string;
  isRaiseMoney: 'yes' | 'no';
  raiseAmount: string | null;
  isDebt: 'yes' | 'no';
  debtAmount: string | null;
};

export type AcuityInfoState = {
  metrics: Array<typeof acuityMetrics[number]>;
  financialKnowledgeRate: number;
  marketingKnowledgeRate: number;
  operationalManagementKnowledgeRate: number;
  functions: Array<typeof acuityFunctions[number]>;
  missingRoles: Array<typeof missingRoles[number]>;
  financingProducts: Array<typeof financingProducts[number]>;
};

export type GoalsInfoState = {
  aspirations: string;
  mainConcerns: typeof goalsMainConcerns;
  businessPerformance: string;
  yearlyRevenue: string;
  otherConsiderations: string;
  sellNewItems: 'yes' | 'no';
  raiseAmount: string;
  isDebt: 'yes' | 'no';
  debtAmount: string;
};

export type RiskInfoState = {
  riskTolerance: number;
  takePersonalLoans: 'yes' | 'no';
};

export type BusinessSophisticationInfoState = {
  financialKnowledge: number;
  accountingKnowledge: number;
  marketingKnowledge: number;
  operationalKnowledge: number;
  weeklyBusinessManagementTime: string;
};

export type WorkingCapitalInfoState = {
  payableDaysOutstanding: string;
  inventorySize: string;
  payableDays: string;
  inventoryDays: string;
  receivableDays: string;
  processingDays: string;
  fullfilmentDays: string;
};
