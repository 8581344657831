import Button from 'components/atoms/button';
import { SoftwareCardLogoType } from 'components/atoms/software-card/logos';
import { Dialog, DialogProps } from 'evergreen-ui';
import styled from 'styled-components';
import SoftwareConnectedDialogHeader from '../software-connected-dialog-header';

export type SoftwareConnectedDialogProps = {
  title: string;
  description: string;
  submitButton: {
    title: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  softwareList: SoftwareCardLogoType[];
} & Pick<DialogProps, 'isShown' | 'onCloseComplete'>;

const StyledSoftwareConnectedDialog = styled.div`
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 32, left: 60, bottom: 32, right: 60 })};

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: ${({ theme }) => theme.pxToRem(24)};
    line-height: ${({ theme }) => theme.pxToRem(32)};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[900]};
    margin-top: ${({ theme }) => theme.pxToRem(32)};
  }

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.pxToRem(14)};
    line-height: ${({ theme }) => theme.pxToRem(20)};
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[600]};

    margin-top: ${({ theme }) => theme.pxToRem(16)};
  }

  & > button {
    margin-top: ${({ theme }) => theme.pxToRem(32)};
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    width: 100%;
  }
`;

const SoftwareConnectedDialog: React.FC<SoftwareConnectedDialogProps> = ({
  title,
  description,
  submitButton,
  softwareList,
  ...dialogProps
}) => {
  return (
    <Dialog {...dialogProps} hasFooter={false} hasHeader={false}>
      <StyledSoftwareConnectedDialog>
        <div>
          <SoftwareConnectedDialogHeader softwareList={softwareList} />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
        <Button appearance="primary" onClick={submitButton.onClick}>
          {submitButton.title}
        </Button>
      </StyledSoftwareConnectedDialog>
    </Dialog>
  );
};

export default SoftwareConnectedDialog;
