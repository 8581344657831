import styled from 'styled-components';

import Lottie, { LottieComponentProps } from 'lottie-react';

import shieldTransformation from './data.json';

export type ShieldTransformationLoaderProps = {} & Omit<
  LottieComponentProps,
  'ref' | 'as' | 'animationData'
>;

const StyledShieldTransformationLoader = styled(Lottie)`
  width: 100%;
  height: 100%;
  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors.neutral[600]};
      stroke: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const ShieldTransformationLoader: React.FC<ShieldTransformationLoaderProps> = ({
  ...props
}) => {
  return (
    <StyledShieldTransformationLoader
      {...props}
      animationData={shieldTransformation}
    />
  );
};

export default ShieldTransformationLoader;
