import styled from 'styled-components';

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFound = () => {
  return (
    <PageContainer>
      <div>
        <h1>404 Page Not Found</h1>
      </div>
    </PageContainer>
  );
};

export default NotFound;
