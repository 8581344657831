import { MarkerType } from 'react-flow-renderer';
import theme from 'theme';

const style = {
  stroke: theme.colors.secondary['B400'],
  strokeWidth: 2,
};
export const customerJourneyEdges = [
  {
    id: 'visits-cart',
    target: 'cart',
    source: 'visits',
    sourceHandle: 'right',
    targetHandle: 'left',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'simplebezier',
    style,
  },
  {
    id: 'cart-checkouts',
    target: 'checkouts',
    source: 'cart',
    sourceHandle: 'right',
    targetHandle: 'left',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'simplebezier',
    style,
  },
  {
    id: 'checkouts-payments',
    target: 'payment',
    source: 'checkouts',
    sourceHandle: 'right',
    targetHandle: 'left',
    markerStart: MarkerType.ArrowClosed,
    markerEnd: MarkerType.Arrow,
    type: 'simplebezier',
    style,
  },
];
