import evergreenTheme from './evergreen-theme';
import { pxToRem } from './helpers';
import colors from './colors';
import typography from './typography';
import { table, button, tagInput, select, tooltip } from './elements';

export type Theme = typeof evergreenTheme & {
  mode: string;
  pxToRem: typeof pxToRem;
  colors: typeof evergreenTheme['colors'] & typeof colors;
  components: typeof evergreenTheme['components'];
};

const theme = {
  mode: 'light',
  ...evergreenTheme,
  // An example how we can extend the default colors of the Evergreen theme
  colors: {
    ...evergreenTheme.colors,
    ...colors,
  },
  components: {
    ...evergreenTheme.components,
    ...table,
    ...button,
    ...tagInput,
    ...select,
    ...tooltip,
  },
  fontFamilies: {
    ...evergreenTheme.fontFamilies,
    ...typography.fontFamilies,
  },
  pxToRem,
};

export default theme;
