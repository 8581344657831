import React, { useReducer } from 'react';

import { reducer, defaultState, BusinessModelState } from './reducer';

import { BusinessModelOptionType } from 'pages/business-configuration/nested-routes/business-blueprint/config';

import {
  StoreFrontItemType,
  SoreFrontItemValue,
} from 'pages/business-configuration/nested-routes/business-blueprint/config';

import { useStateMachine } from 'little-state-machine';

export type CustomerJourneyType = {
  id: string;
  platform?: {
    id: string;
    label: string;
    logo: string;
  };
  otherConnections?: { value: string; label: string; logo: string }[];
  visits?: { value: string; label: string; logo: string }[];
  cart?: { value: string; label: string; logo: string }[];
  checkout?: { value: string; label: string; logo: string }[];
  payment?: { value: string; label: string; logo: string }[];
  supplyChain?: {
    // Data for the connected supply chain
    id: string;
  };
};

type CanvasState = null | {
  businessModel: BusinessModelState;
  customerJourney: StoreFrontItemType[] | [];
  setCanvasState: React.Dispatch<{
    type: string;
    businessModelItemId?:
      | 'distribution-model'
      | 'product-category'
      | 'revenue-model'
      | 'target-customers'
      | 'product-model'
      | 'business-model';
    value?: BusinessModelOptionType | CustomerJourneyType;
    customerJourneyId?: string;
    storeFrontItemValue?: SoreFrontItemValue;
  }>;
};

export type CanvasContext = {
  children?: React.ReactNode;
};

export const CanvasContext = React.createContext<CanvasState | null>(null);

export const useCanvas = () => {
  const canvas = React.useContext(CanvasContext);

  if (!canvas) {
    throw new Error(
      'Empty canvas state check that you have wrapped your component in CanvasProvider'
    );
  }

  return {
    ...canvas,
  };
};

export const CanvasProvider: React.FC<CanvasContext> = ({
  children,
  ...props
}) => {
  const {
    state: { businessBlueprint },
  } = useStateMachine();
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    businessModel: {
      ...defaultState.businessModel,
      ...businessBlueprint,
    },
  });

  return (
    <CanvasContext.Provider
      value={
        {
          ...state,
          setCanvasState: dispatch,
        } as CanvasState
      }
      {...props}
    >
      {children}
    </CanvasContext.Provider>
  );
};
