import { useRef, useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';
import ActionCardCta from 'components/molecules/action-card-cta';
import Menu from 'components/atoms/menu';
import Icon from 'components/atoms/icon';
import Tab from 'components/atoms/tab';
import Tablist from 'components/molecules/tab-list';
import SelectMenu from 'components/molecules/select-menu/SelectMenu';
import Loading from 'components/atoms/loading';
import KpiStatusBar from 'components/atoms/kpi-status-bar';
import Button from 'components/atoms/button';
import PageHeader from 'components/molecules/page-header';
import HonuSuggestionsIndicator from 'components/atoms/honu-suggestions-indicator';

import { Popover, Position } from 'evergreen-ui';

import { useGlobalPageTemplate } from 'context';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery, useQueryClient } from 'react-query';
import {
  GET_SELECTED_ACTIONS_QUERY,
  getSelectedActions,
} from 'api-client/queries/get-selected-actions';
import { useUpdateAction } from 'api-client/mutations';

import {
  ActionType,
  getSimulationKpis,
  GET_SIMULATION_KPIS_QUERY,
} from 'api-client/queries';

import { calculateKpiProgress, getRating } from 'pages/utils';

const PageContainer = styled.div<{ kpiListHeight?: number }>`
  & .resources-container {
    min-height: 100%;
    margin-bottom: ${({ theme }) => theme.pxToRem(0)};
    padding: 0;
  }

  & > .page-content {
    overflow: hidden;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: ${({ theme }) => theme.pxToRem(16)};
    margin-top: ${({ theme }) => theme.pxToRem(0)};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 16,
        left: 0,
        right: 16,
        bottom: 0,
      })};
    height: ${({ kpiListHeight, theme }) => {
      return kpiListHeight ? theme.pxToRem(kpiListHeight + 68) : 0;
    }};

    & > div {
      background-color: ${({ theme }) => theme.colors.neutral[50]};
      border: ${({ theme }) =>
        `${theme.pxToRem(2)} solid ${theme.colors.white}`};
      border-radius: ${({ theme }) => theme.pxToRem(16)};
    }

    & .action-list {
      padding: ${({ theme }) => theme.pxToRem(32)};
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: ${({ theme }) => theme.pxToRem(8)};
      }

      &::-webkit-scrollbar-track {
        margin-top: ${({ theme }) => theme.pxToRem(8)};
        margin-bottom: ${({ theme }) => theme.pxToRem(8)};
        border: none;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.neutral[400]};
        border-radius: ${({ theme }) => theme.pxToRem(16)};
      }

      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: ${({ theme }) => theme.pxToRem(8)};
      }

      &__actions {
        padding: ${({ theme }) => theme.pxToRem(16)};
        max-width: ${({ theme }) => theme.pxToRem(560)};
        margin: 0 auto;

        & > div {
          margin-bottom: ${({ theme }) => theme.pxToRem(24)};
        }

        & .action-cta {
          border: none;
          outline: none;
          background-color: ${({ theme }) => theme.colors.neutral[0]};
          width: ${({ theme }) => theme.pxToRem(32)};
          height: ${({ theme }) => theme.pxToRem(32)};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: ${({ theme }) => theme.colors.neutral[200]};
          }

          & > svg {
            width: ${({ theme }) => theme.pxToRem(18)};
            height: ${({ theme }) => theme.pxToRem(18)};
            fill: ${({ theme }) => theme.colors.neutral[500]};
          }
        }
      }
    }
  }

  & .kpi-list {
    padding: ${({ theme }) => theme.pxToRem(32)};
    position: relative;

    &__action-info {
      margin-bottom: ${({ theme }) => theme.pxToRem(24)};
      position: relative;

      & > button {
        position: absolute;
        top: 0;
        right: 0;
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        border-radius: 50%;

        & > svg {
          position: absolute;
          width: ${({ theme }) => theme.pxToRem(24)};
          height: ${({ theme }) => theme.pxToRem(24)};
          fill: ${({ theme }) => theme.colors.neutral[900]};
        }
      }

      & > h3 {
        padding-top: ${({ theme }) => theme.pxToRem(4)};
        margin: ${({ theme }) =>
          theme.pxToRem({
            top: 0,
            left: 0,
            right: 32,
            bottom: 16,
          })};
      }

      & > ul {
        list-style: none;

        & > li {
          margin-bottom: ${({ theme }) => theme.pxToRem(8)};

          & > svg {
            width: ${({ theme }) => theme.pxToRem(16)};
            height: ${({ theme }) => theme.pxToRem(16)};
            margin-right: ${({ theme }) => theme.pxToRem(8)};
            fill: ${({ theme }) => theme.colors.secondary['B500']};
          }
        }
      }
    }

    &__header {
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[200]};
      padding-bottom: ${({ theme }) => theme.pxToRem(16)};
      margin-bottom: ${({ theme }) => theme.pxToRem(16)};

      & span {
        display: inline-block;
        width: ${({ theme }) => theme.pxToRem(16)};
        height: ${({ theme }) => theme.pxToRem(16)};
        border-radius: 50%;
        margin-right: ${({ theme }) => theme.pxToRem(16)};
      }

      & > div {
        display: flex;
        align-items: center;

        & > p {
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }

      & > div:nth-child(1) {
        & span {
          background-image: ${({ theme }) => theme.colors.primary['B400']};
        }
        margin-right: ${({ theme }) => theme.pxToRem(16)};
      }

      & > div:nth-child(2) {
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['B200']};
        }
      }
    }

    &__content {
      & .kpi {
        padding: ${({ theme }) =>
          theme.pxToRem({
            top: 12,
            left: 8,
            right: 8,
            bottom: 12,
          })};
      }

      & > div {
        & > h5 {
          text-transform: uppercase;
          margin-bottom: ${({ theme }) => theme.pxToRem(16)};
        }
      }
    }
  }

  & .metrics-list {
    display: flex;

    &__metrics-container {
      width: 100%;
      margin-right: ${({ theme }) => theme.pxToRem(8)};
      border-radius: 0 0 8px 8px;
    }

    &__metrics-loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__metrics {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: ${({ theme }) => theme.pxToRem(24)};
      padding-bottom: ${({ theme }) => theme.pxToRem(24)};

      & > div {
        flex: 1;
        padding-left: ${({ theme }) => theme.pxToRem(32)};
        padding-right: ${({ theme }) => theme.pxToRem(32)};

        & > h3 {
          display: flex;
          align-items: center;
          white-space: nowrap;

          & > span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-right: ${({ theme }) => theme.pxToRem(14)};
            width: ${({ theme }) => theme.pxToRem(32)};
            height: ${({ theme }) => theme.pxToRem(32)};
            border-radius: 50%;

            & > svg {
              width: ${({ theme }) => theme.pxToRem(20)};
              height: ${({ theme }) => theme.pxToRem(20)};
            }
          }

          & > p {
            font-size: ${({ theme }) => theme.pxToRem(20)};
            font-weight: 600;
            color: ${({ theme }) => theme.colors.neutral[600]};
          }
        }

        & > p {
          text-indent: ${({ theme }) => theme.pxToRem(48)};
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }

      & .progress {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['O400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }

      & .time {
        border-right: 1px solid ${({ theme }) => theme.colors.neutral[400]};
        & span {
          background-color: ${({ theme }) => theme.colors.secondary['G400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }

      & .budget {
        & span {
          background-color: ${({ theme }) => theme.colors.tertiary['T400']};
          & > svg {
            fill: ${({ theme }) => theme.colors.neutral[0]};
          }
        }
      }
    }
  }
`;

const MyActions = () => {
  const kpiListRef = useRef<HTMLDivElement>(null);
  const [focusedAction, setFocusedAction] = useState<null | ActionType>(null);
  const queryClient = useQueryClient();
  const { minimizeNavbar } = useGlobalPageTemplate();
  const businessStateIsLoading = false;
  const { getAccessTokenSilently } = useAuth0();
  const [kpiListHeight, setKpiListHeight] = useState<undefined | number>(
    undefined
  );
  const [sortActions, setSortActions] = useState<undefined | string>(undefined);
  const [filterActions, setFilterActions] = useState<'TODO' | 'DONE'>('TODO');
  const { data: kpisData, isLoading: kpisIsLoading } = useQuery(
    [GET_SIMULATION_KPIS_QUERY, { getAccessTokenSilently }] as const,
    getSimulationKpis
  );

  const { data: selectedActionsData } = useQuery(
    [GET_SELECTED_ACTIONS_QUERY, { getAccessTokenSilently }] as const,
    getSelectedActions
  );
  const { mutate: mutateUpdateAction } = useUpdateAction({
    onSuccess: (data) => {
      if (data === 'success') {
        queryClient.invalidateQueries(GET_SELECTED_ACTIONS_QUERY);
        queryClient.invalidateQueries(GET_SIMULATION_KPIS_QUERY);
      }
    },
  });

  useEffect(() => {
    if (!kpiListHeight && !kpisIsLoading) {
      setKpiListHeight(kpiListRef?.current?.clientHeight);
      return;
    }

    window.addEventListener('resize', () => {
      setKpiListHeight(kpiListRef?.current?.clientHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [
    kpiListRef?.current?.clientHeight,
    setKpiListHeight,
    kpiListHeight,
    kpisIsLoading,
  ]);

  const actions = useMemo(() => {
    if (!sortActions) return selectedActionsData;

    if (sortActions === 'shortest_time') {
      return selectedActionsData?.sort((prev, next) =>
        prev.hours_per_month_required > next.hours_per_month_required ? 1 : -1
      );
    }

    if (sortActions === 'longest_time') {
      return selectedActionsData?.sort((prev, next) =>
        prev.hours_per_month_required < next.hours_per_month_required ? 1 : -1
      );
    }

    if (sortActions === 'smallest_budget') {
      return selectedActionsData?.sort((prev, next) =>
        prev.budget_per_month_required > next.budget_per_month_required ? 1 : -1
      );
    }

    if (sortActions === 'biggest_budget') {
      return selectedActionsData?.sort((prev, next) =>
        prev.budget_per_month_required < next.budget_per_month_required ? 1 : -1
      );
    }

    if (sortActions === 'Marketing') {
      return selectedActionsData?.filter(
        (action) => action.category === 'Marketing'
      );
    }

    if (sortActions === 'Finance') {
      return selectedActionsData?.filter(
        (action) => action.category === 'Finance'
      );
    }

    if (sortActions === 'Operations') {
      return selectedActionsData?.filter(
        (action) => action.category === 'Operations'
      );
    }
  }, [sortActions, selectedActionsData]);

  return (
    <PageContainer kpiListHeight={kpiListHeight}>
      <PageHeader
        minimizeNavbar={minimizeNavbar}
        title="Action Plan"
        description="Here is a list of the Actions that you’ve chosen to perform this month, based on the goals you’ve set yourself"
      >
        <HonuSuggestionsIndicator disabled />
      </PageHeader>
      <PageContentContainer
        className="resources-container"
        minimizeNavbar={minimizeNavbar}
      >
        <div className="metrics-list">
          <div className="metrics-list__metrics-container">
            <div className="metrics-list__metrics">
              {businessStateIsLoading ? (
                <div className="metrics-list__metrics-loading">
                  <Loading />
                </div>
              ) : (
                <>
                  <div className="progress">
                    <h3 className="H-600">
                      <span>
                        <Icon icon="CheckIcon" />
                      </span>
                      {selectedActionsData
                        ? Math.round(
                            (selectedActionsData?.filter(
                              ({ status }) => status === 'DONE'
                            )?.length /
                              selectedActionsData?.filter(
                                ({ status }) => status !== 'DELETED'
                              )?.length) *
                              100
                          )
                        : 0}
                      <p>%</p>
                    </h3>
                    <p className="large-text">Progress</p>
                  </div>
                  <div className="time">
                    <h3 className="H-700">
                      <span>
                        <Icon icon="ClockIcon" />
                      </span>
                      {selectedActionsData
                        ? selectedActionsData?.reduce((acc, action) => {
                            return action.status === 'DONE' ||
                              action.status === 'DELETED'
                              ? acc + action.hours_per_month_required
                              : acc;
                          }, 0)
                        : 0}
                      <p>h</p>
                    </h3>
                    <p className="large-text">Time spent on actions</p>
                  </div>
                  <div className="budget">
                    <h3 className="H-700">
                      <span>
                        <Icon icon="CurrencyDollarIcon" />
                      </span>
                      £{' '}
                      {selectedActionsData
                        ? selectedActionsData?.reduce((acc, action) => {
                            return action.status === 'DONE' ||
                              action.status === 'DELETED'
                              ? acc + action.budget_per_month_required
                              : acc;
                          }, 0) / 100
                        : 0}
                      <p>k</p>
                    </h3>
                    <p className="large-text">Money Spent On Actions</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </PageContentContainer>
      <PageContentContainer
        className="page-content"
        wrapper
        minimizeNavbar={minimizeNavbar}
      >
        <div className="action-list">
          <div>
            <div className="action-list__header">
              <h3 className="H-800">My Actions</h3>
              <Tablist>
                <Tab
                  isSelected={filterActions === 'TODO'}
                  onSelect={() => {
                    setFilterActions('TODO');
                  }}
                >
                  In Progress
                </Tab>
                <Tab
                  isSelected={filterActions === 'DONE'}
                  onSelect={() => {
                    setFilterActions('DONE');
                  }}
                >
                  Completed
                </Tab>
              </Tablist>
            </div>
            <SelectMenu
              closeOnSelect
              placeholder="Sort Actions"
              options={[
                { label: 'Shortest Time', value: 'shortest_time' },
                { label: 'Longest Time', value: 'longest_time' },
                { label: 'Smallest Budget', value: 'smallest_budget' },
                { label: 'Biggest Budget', value: 'biggest_budget' },
                { label: 'Finance', value: 'Finance' },
                { label: 'Operations', value: 'Operations' },
                {
                  label: 'Marketing',
                  value: 'Marketing',
                },
              ]}
              onSelect={(item) => {
                setSortActions(item.value as string);
              }}
            />
          </div>
          <div className="action-list__actions">
            {Array.isArray(actions) &&
              actions
                .filter(({ status }) => status === filterActions)
                ?.map(
                  ({
                    id,
                    name,
                    status,
                    category,
                    hours_rating,
                    budget_rating,
                    ...action
                  }) => {
                    return (
                      <ActionCardCta
                        key={id}
                        title={name}
                        aria-label="card"
                        type={
                          category.toLocaleLowerCase() as
                            | 'marketing'
                            | 'operations'
                            | 'finance'
                        }
                        completed={status === 'DONE'}
                        focused={focusedAction?.name === name}
                        timeWeight={getRating(hours_rating)}
                        budgetWeight={getRating(budget_rating)}
                        onFocus={(event) => {
                          event.preventDefault();

                          if (
                            event.target.ariaLabel ===
                              'Update Action Menu Item' ||
                            event.target.ariaLabel === 'Select Action Button'
                          ) {
                            return;
                          }

                          setFocusedAction({
                            id,
                            name,
                            status,
                            category,
                            ...action,
                          });
                          setKpiListHeight(undefined);
                        }}
                        actionCta={
                          <Popover
                            position={Position.BOTTOM_LEFT}
                            content={
                              <Menu>
                                <Menu.Group>
                                  {status === 'TODO' && (
                                    <Menu.Item
                                      disabled
                                      onSelect={() => {}}
                                      aria-label="Update Action Menu Item"
                                    >
                                      Assign Action
                                    </Menu.Item>
                                  )}
                                  {status === 'TODO' && (
                                    <Menu.Item
                                      aria-label="Update Action Menu Item"
                                      onSelect={() => {
                                        mutateUpdateAction({
                                          actionId: id,
                                          status: 'DONE',
                                        });
                                      }}
                                    >
                                      Mark As Complete
                                    </Menu.Item>
                                  )}
                                </Menu.Group>
                                {status === 'TODO' && <Menu.Divider />}
                                <Menu.Group>
                                  <Menu.Item
                                    intent="danger"
                                    aria-label="Update Action Menu Item"
                                    onSelect={() => {
                                      mutateUpdateAction({
                                        actionId: id,
                                        status: 'DELETED',
                                      });
                                    }}
                                  >
                                    Remove Action
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <button
                              aria-label="Select Action Button"
                              className="action-cta"
                            >
                              <Icon icon="DotsHorizontalIcon" />
                            </button>
                          </Popover>
                        }
                      />
                    );
                  }
                )}
          </div>
        </div>
        <div className="kpi-list">
          <div ref={kpiListRef}>
            {focusedAction && (
              <div className="kpi-list__action-info">
                <Button
                  appearance="minimal"
                  onClick={() => {
                    setFocusedAction(null);
                    setKpiListHeight(undefined);
                  }}
                >
                  <Icon icon="XIcon" />
                </Button>
                <h3 className="H-700">
                  This action “{focusedAction.name}” affects the below KPIs:
                </h3>
                <p>{focusedAction?.additional_info}</p>
              </div>
            )}
            <div className="kpi-list__header">
              <div>
                <span />
                <p> Starting Point</p>
              </div>
            </div>
            <div className="kpi-list__content">
              <div className="kpi-list__container-sales">
                <h5 className="H-300">Sales And Marketing</h5>
                {kpisData?.sales_and_marketing?.map(
                  ({
                    key,
                    name,
                    value,
                    goal,
                    target,
                    is_percentage,
                    simulated_value,
                  }) => {
                    return (
                      <KpiStatusBar
                        key={key}
                        title={name}
                        value={
                          is_percentage ? Number(value) * 100 : Number(value)
                        }
                        percentage={is_percentage}
                        current={calculateKpiProgress({
                          kpiValue: value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecast={calculateKpiProgress({
                          kpiValue: simulated_value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecastValue={
                          is_percentage ? Number(target) * 100 : Number(target)
                        }
                        className="kpi"
                        overlay={
                          focusedAction
                            ? focusedAction?.affected_kpis?.find(
                                (kpiKey) => kpiKey === key
                              ) === undefined
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
              <div className="kpi-list__container-finance">
                <h5 className="H-300">Finance</h5>
                {kpisData?.finance?.map(
                  ({
                    key,
                    name,
                    goal,
                    target,
                    value,
                    is_percentage,
                    simulated_value,
                  }) => {
                    return (
                      <KpiStatusBar
                        key={key}
                        title={name}
                        value={
                          is_percentage
                            ? Math.round(Number(value) * 100)
                            : Math.round(Number(value))
                        }
                        percentage={is_percentage}
                        current={calculateKpiProgress({
                          kpiValue: value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecast={calculateKpiProgress({
                          kpiValue: simulated_value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecastValue={
                          is_percentage
                            ? Math.round(Number(target) * 100)
                            : Math.round(Number(target))
                        }
                        className="kpi"
                        overlay={
                          focusedAction
                            ? focusedAction?.affected_kpis?.find(
                                (kpiKey) => kpiKey === key
                              ) === undefined
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
              <div className="kpi-list__container-operations">
                <h5 className="H-300">Operations</h5>
                {kpisData?.operations?.map(
                  ({
                    key,
                    name,
                    value,
                    target,
                    goal,
                    is_percentage,
                    simulated_value,
                  }) => {
                    return (
                      <KpiStatusBar
                        key={key}
                        title={name}
                        value={
                          is_percentage
                            ? Math.round(Number(value) * 100)
                            : Number(value)
                        }
                        percentage={is_percentage}
                        current={calculateKpiProgress({
                          kpiValue: value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecast={calculateKpiProgress({
                          kpiValue: simulated_value,
                          target,
                          isPercentage: is_percentage,
                          goal,
                        })}
                        forecastValue={
                          is_percentage
                            ? Math.round(Number(target) * 100)
                            : Math.round(Number(target))
                        }
                        className="kpi"
                        overlay={
                          focusedAction
                            ? focusedAction?.affected_kpis?.find(
                                (kpiKey) => kpiKey === key
                              ) === undefined
                            : false
                        }
                      />
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContentContainer>
    </PageContainer>
  );
};

export default MyActions;
