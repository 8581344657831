import React, { useEffect } from 'react';
import styled from 'styled-components';
import { NestedValue, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { updateFinancialInfo } from '../../actions';
import {
  FinancialInfoState,
  incomeSources,
  mainCostCentre,
} from '../../StateMachine.types';
import { useNavigate } from 'react-router-dom';

import RadioButton from 'components/molecules/radio-button';
import InputWrapper from 'components/molecules/input-wrapper';
import Select from 'components/molecules/select';
import TextInput from 'components/molecules/text-input';
import TagInput from 'components/molecules/tag-input';
import OnboardingNav from 'components/molecules/onboarding-nav';

const MONEY_MIN = 0;
const MONEY_MAX = 999999999;

const FinancialInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & .financialForm {
    width: ${({ theme }) => theme.pxToRem(561)};
  }

  & .input {
    width: 100%;
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const financialInfoSchema = yup.object({
  incomeSources: yup.array(),
  mainCostCentre: yup.string().oneOf(
    mainCostCentre.map((cost) => cost.value),
    'Please select one'
  ),
  fundingToDate: yup
    .string()
    .required('Funding date is required')
    .matches(/^\d+$/, 'Funding to date can only contain numbers'),
  raiseAmount: yup
    .number()
    .min(MONEY_MIN, 'Please type in a positive number')
    .max(MONEY_MAX, `The maximum amount is ${MONEY_MAX}`)
    .nullable()
    .transform((val) => (val === Number(val) ? val : null)),
  debtAmount: yup
    .number()
    .min(0, 'Please type in a positive number')
    .min(MONEY_MIN, 'Please type in a positive number')
    .max(MONEY_MAX, `The maximum amount is ${MONEY_MAX}`)
    .nullable()
    .transform((val) => (val === Number(val) ? val : null)),
});

const FinancialInfo = () => {
  const {
    state: { financialInfoStore },
    actions,
  } = useStateMachine({ updateFinancialInfo });
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<
    Omit<FinancialInfoState, 'incomeSources'> & {
      incomeSources: NestedValue<typeof incomeSources>;
    }
  >({
    resolver: yupResolver<yup.AnyObjectSchema>(financialInfoSchema) as any,
    defaultValues: {
      ...financialInfoStore,
    },
  });

  const selectedIncomeSources = watch('incomeSources');
  const autoCompleteSources = React.useMemo(
    () =>
      incomeSources.filter((source) => !selectedIncomeSources.includes(source)),
    [selectedIncomeSources]
  );

  const isBesideSaleValue = watch('isBesideSale');
  useEffect(() => {
    if (isBesideSaleValue === 'no') {
      setValue('incomeSources', []);
    }
  }, [isBesideSaleValue, setValue]);

  const isRaiseMoneyValue = watch('isRaiseMoney');
  useEffect(() => {
    if (isRaiseMoneyValue === 'no') {
      setValue('raiseAmount', '');
    }
  }, [isRaiseMoneyValue, setValue]);

  const isDebtValue = watch('isDebt');
  useEffect(() => {
    if (isDebtValue === 'no') {
      setValue('debtAmount', '');
    }
  }, [isDebtValue, setValue]);

  return (
    <FinancialInfoContainer>
      <form
        className="financialForm"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          actions.updateFinancialInfo(data);
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/working-capital-info'
          );
          navigate('/account-settings/working-capital-info');
        })}
      >
        <InputWrapper label="Do you have income besides sales?">
          <RadioButton
            data-testid="isBesideSale-input"
            name="isBesideSale"
            control={control}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </InputWrapper>

        <InputWrapper label="Other income source">
          <TagInput
            data-testid="incomeSources-input"
            className="input"
            inputProps={{
              placeholder: 'Select your income sources',
            }}
            name="incomeSources"
            control={control}
            error={errors['incomeSources']}
            errorMessage={errors['incomeSources']?.message}
            autocompleteItems={autoCompleteSources}
            disabled={watch('isBesideSale') !== 'yes'}
          />
        </InputWrapper>

        <InputWrapper label="What are your main cost centres?">
          <Select
            data-testid="mainCostCentre-input"
            className="input"
            placeholder="Select One"
            name="mainCostCentre"
            control={control}
            error={errors['mainCostCentre']}
            errorMessage={errors['mainCostCentre']?.message}
            values={mainCostCentre}
          />
        </InputWrapper>

        <InputWrapper label="How much funding have you had to date?">
          <TextInput
            data-testid="fundingToDate-input"
            className="input"
            type="number"
            min={MONEY_MIN}
            max={MONEY_MAX}
            placeholder="Enter amount"
            name="fundingToDate"
            control={control}
            error={errors['fundingToDate']}
          />
        </InputWrapper>

        <InputWrapper label="Are you expecting to raise money in the next 12 months?">
          <RadioButton
            data-testid="isRaiseMoney-input"
            name="isRaiseMoney"
            control={control}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </InputWrapper>

        <InputWrapper label="Raise amount">
          <TextInput
            data-testid="raiseAmount-input"
            className="input"
            type="number"
            min={MONEY_MIN}
            max={MONEY_MAX}
            placeholder="Enter amount"
            name="raiseAmount"
            control={control}
            error={errors['raiseAmount']}
            disabled={watch('isRaiseMoney') !== 'yes'}
          />
        </InputWrapper>

        <InputWrapper label="Do you have any debt??">
          <RadioButton
            data-testid="isDebt-input"
            name="isDebt"
            control={control}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </InputWrapper>

        <InputWrapper label="Debt amount">
          <TextInput
            data-testid="debtAmount-input"
            className="input"
            type="number"
            min={MONEY_MIN}
            max={MONEY_MAX}
            placeholder="Enter amount"
            name="debtAmount"
            control={control}
            error={errors['debtAmount']}
            disabled={watch('isDebt') !== 'yes'}
          />
        </InputWrapper>

        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/business-configuration/financial-platforms');
          }}
        />
      </form>
    </FinancialInfoContainer>
  );
};

export default FinancialInfo;
