import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider as EuiThemeProvider } from 'evergreen-ui';
import { Auth0Provider } from '@auth0/auth0-react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { GlobalPageTemplateProvider } from 'context';

import 'swiper/swiper.min.css';

import { ThemeProvider } from 'context/theme';

const queryClient = new QueryClient();

import theme from 'theme';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
      cacheLocation="localstorage"
    >
      <GlobalPageTemplateProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ThemeProvider>
              <EuiThemeProvider value={theme}>
                <App />
              </EuiThemeProvider>
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </GlobalPageTemplateProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
