import styled from 'styled-components';

import ConnectDataWidget from 'components/molecules/connect-data-widget';

import { getSourcesData, GET_SOURCES_DATA_QUERY } from 'api-client/queries';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { Navigate, useSearchParams } from 'react-router-dom';
import AvatarMenu from 'components/molecules/avatar-menu';
import { useUser } from 'context';

const PageContainer = styled.div`
  & .page-header {
    & h1 {
      margin-bottom: ${({ theme }) => theme.pxToRem(24)};
    }

    display: flex;
    justify-content: space-between;
  }
`;

const BusinessConfiguration = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const sourceType = searchParams.get('sourceType');
  const { data, isLoading } = useQuery(
    [GET_SOURCES_DATA_QUERY, { getAccessTokenSilently }] as const,
    getSourcesData,
    { enabled: typeof companyId !== 'string' }
  );
  const source = data?.source;
  const shopifyData = source?.find(
    ({ source_type }) => source_type === 'codat_shopify'
  );
  const { name } = useUser();

  if (companyId && sourceType) {
    return (
      <Navigate
        to="/create-source"
        state={{
          companyId,
          sourceType,
        }}
      />
    );
  }

  return (
    <PageContainer>
      <div className="page-header">
        <h1 className="H-900">Business Cockpit</h1>
        <AvatarMenu name={name} />
      </div>
      <ConnectDataWidget
        data-testid="commerce-data"
        title="Commerce Data"
        loading={isLoading}
        connected={shopifyData ? 'true' : undefined}
        onClick={() => {
          window.location = process.env.REACT_APP_CODAT_URI;
        }}
      />
    </PageContainer>
  );
};

export default BusinessConfiguration;
