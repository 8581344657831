import { useMemo } from 'react';

import ReactFlow from 'react-flow-renderer';
import styled from 'styled-components';

import BusinessMetricCenterNode from 'components/atoms/business-metric-center-node';
import BusinessMetricNode from 'components/atoms/business-metric-node';

import { StyledControls } from 'pages/business-configuration/nested-routes/business-blueprint/BusinessBlueprint';

import { KpiType } from 'api-client/queries';

export type BusinessMetricKpisProps = {
  children?: React.ReactNode;
  kpiList?: KpiType[];
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

type KpiListNodeType = {
  index: number;
  id: string;
  key?: string;
  type: string;
  data: {
    title?: string;
    value?: number;
    unit?: string;
    position?: string;
    to?: string;
  };
  position: {
    x: number;
    y: number;
  };
  draggable: boolean;
  connectable: boolean;
};

const nodes: KpiListNodeType[] = [
  {
    index: 0,
    id: 'centre-node',
    key: undefined,
    type: 'BusinessMetricCenterNode',
    data: {
      title: 'Sales And Marketing',
    },
    position: { x: 400, y: 350 },
    draggable: false,
    connectable: false,
  },
  {
    index: 1,
    id: 'advertising-clicks',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Paid Advertising Clicks',
      value: 200,
      unit: '£',
      position: 'right',
      to: 'paid-advertising-clicks',
    },
    position: { x: 410, y: 58 },
    draggable: false,
    connectable: false,
  },
  {
    index: 2,
    key: 'cpc',
    id: 'cost-per-click',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cost Per Click',
      value: 200,
      unit: '£',
      position: 'top',
      to: 'cost-per-click',
    },
    position: { x: 300, y: 150 },
    draggable: false,
    connectable: false,
  },
  {
    index: 3,
    id: 'organic-per-paid-visitors',
    key: 'organic_per_paid_visitors',
    type: 'BusinessMetricNode',
    data: {
      title: 'Organic visitors / Paid visitors',
      value: 30,
      unit: '%',
      position: 'right',
      to: 'organic-per-paid-visitors',
    },
    position: { x: 500, y: 170 },
    draggable: false,
    connectable: false,
  },
  {
    index: 4,
    id: 'cost-of-returning-visitor-per-cpc',
    key: 'cost_of_returning_visitor_per_cpc',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cost of returning visitors / CPC',
      value: 177,
      unit: undefined,
      position: 'top',
      to: 'cost-of-returning-visitor-per-cpc',
    },
    position: { x: 160, y: 100 },
    draggable: false,
    connectable: false,
  },
  {
    index: 5,
    id: 'returning-visitors-per-new-visitors',
    key: 'returning_visitors_per_new_visitors',
    type: 'BusinessMetricNode',
    data: {
      title: 'Returning visitors / New visitors',
      value: 177,
      unit: undefined,
      position: 'right',
      to: 'returning-visitors-per-new-visitors',
    },
    position: { x: 600, y: 235 },
    draggable: false,
    connectable: false,
  },
  {
    index: 6,
    id: 'email-open-rate',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Email Open Rate',
      value: 41,
      unit: '%',
      position: 'left',
      to: 'email-open-rate',
    },
    position: { x: 80, y: 240 },
    draggable: false,
    connectable: false,
  },
  {
    index: 7,
    id: 'email-subscriber-growth',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Email Subscriber Growth',
      value: 41,
      unit: '%',
      position: 'left',
      to: 'email-subscriber-growth',
    },
    position: { x: 40, y: 330 },
    draggable: false,
    connectable: false,
  },
  {
    index: 8,
    id: 'email-click-trough-rate',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Email Click Through Rate',
      value: 41,
      unit: '%',
      position: 'left',
      to: 'email-click-trough-rate',
    },
    position: { x: 100, y: 420 },
    draggable: false,
    connectable: false,
  },
  {
    index: 9,
    id: 'email-unsubscribes',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Email Unsubscribes',
      value: 41,
      unit: '%',
      position: 'left',
      to: 'email-unsubscribes',
    },
    position: { x: 60, y: 520 },
    draggable: false,
    connectable: false,
  },
  {
    index: 10,
    id: 'items-per-order',
    key: undefined,
    type: 'BusinessMetricNode',
    data: {
      title: 'Number of Items Per Order',
      value: 41,
      unit: '%',
      position: 'left',
      to: 'items-per-order',
    },
    position: { x: 160, y: 620 },
    draggable: false,
    connectable: false,
  },
  {
    index: 11,
    id: 'average-order-value',
    key: 'aov',
    type: 'BusinessMetricNode',
    data: {
      title: 'Average order value',
      position: 'bottom',
      to: 'average-order-value',
    },
    position: { x: 380, y: 580 },
    draggable: false,
    connectable: false,
  },
  {
    index: 12,
    id: 'checkout-to-order-rate',
    key: 'checkout_to_order',
    type: 'BusinessMetricNode',
    data: {
      title: 'Checkout to Order Rate',
      value: 41,
      unit: '%',
      position: 'bottom',
      to: 'checkout-to-order-rate',
    },
    position: { x: 490, y: 520 },
    draggable: false,
    connectable: false,
  },
  {
    index: 13,
    id: 'cart-to-checkout-rate',
    key: 'cart_to_checkout',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cart to Checkout Rate',
      value: 41,
      unit: '%',
      position: 'bottom',
      to: 'cart-to-checkout-rate',
    },
    position: { x: 620, y: 560 },
    draggable: false,
    connectable: false,
  },
  {
    index: 14,
    id: 'visitor-to-cart-rate',
    key: 'visits_to_cart',
    type: 'BusinessMetricNode',
    data: {
      title: 'Visits to cart rate',
      value: 41,
      unit: '%',
      position: 'right',
      to: 'visitor-to-cart-rate',
    },
    position: { x: 640, y: 440 },
    draggable: false,
    connectable: false,
  },
  {
    index: 15,
    id: 'bounce-rate',
    key: 'bounce_rate',
    type: 'BusinessMetricNode',
    data: {
      title: 'Bounce Rate',
      value: 41,
      unit: '%',
      position: 'right',
      to: 'bounce-rate',
    },
    position: { x: 580, y: 380 },
    draggable: false,
    connectable: false,
  },
  {
    index: 16,
    id: 'returning-visitor-cost',
    key: 'returning-visitor-cost',
    type: 'BusinessMetricNode',
    data: {
      title: 'Returning Visitor Cost',
      value: 41,
      unit: '%',
      position: 'right',
      to: 'returning-visitor-cost',
    },
    position: { x: 700, y: 320 },
    draggable: false,
    connectable: false,
  },
];

const edges = [
  {
    id: `${nodes[0].id}-${nodes[1].id}`,
    target: nodes[0].id,
    source: nodes[1].id,
    sourceHandle: 'left',
    targetHandle: 'top',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[2].id}`,
    target: nodes[0].id,
    source: nodes[2].id,
    sourceHandle: 'left',
    targetHandle: 'top-left',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[3].id}`,
    target: nodes[0].id,
    source: nodes[3].id,
    sourceHandle: 'left',
    targetHandle: 'top-right',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[4].id}`,
    target: nodes[0].id,
    source: nodes[4].id,
    sourceHandle: 'bottom',
    targetHandle: 'top-far-left',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[5].id}`,
    target: nodes[0].id,
    source: nodes[5].id,
    sourceHandle: 'left',
    targetHandle: 'top-far-right',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[6].id}`,
    target: nodes[0].id,
    source: nodes[6].id,
    sourceHandle: 'right',
    targetHandle: 'left-top',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[7].id}`,
    target: nodes[0].id,
    source: nodes[7].id,
    sourceHandle: 'right',
    targetHandle: 'left',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[8].id}`,
    target: nodes[0].id,
    source: nodes[8].id,
    sourceHandle: 'right',
    targetHandle: 'left-bottom',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[9].id}`,
    target: nodes[0].id,
    source: nodes[9].id,
    sourceHandle: 'right',
    targetHandle: 'bottom-left',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[10].id}`,
    target: nodes[0].id,
    source: nodes[10].id,
    sourceHandle: 'right',
    targetHandle: 'bottom',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[11].id}`,
    target: nodes[0].id,
    source: nodes[11].id,
    sourceHandle: 'top',
    targetHandle: 'bottom',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[12].id}`,
    target: nodes[0].id,
    source: nodes[12].id,
    sourceHandle: 'top',
    targetHandle: 'bottom',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[13].id}`,
    target: nodes[0].id,
    source: nodes[13].id,
    sourceHandle: 'top',
    targetHandle: 'right',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[14].id}`,
    target: nodes[0].id,
    source: nodes[14].id,
    sourceHandle: 'bottom',
    targetHandle: 'right',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[15].id}`,
    target: nodes[0].id,
    source: nodes[15].id,
    sourceHandle: 'bottom',
    targetHandle: 'right',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[16].id}`,
    target: nodes[0].id,
    source: nodes[16].id,
    sourceHandle: 'bottom',
    targetHandle: 'right-top',
    type: 'straight',
    style: {
      stroke: ' #B7B7B7',
    },
  },
];

const StyledBusinessMetricKpis = styled.div`
  height: 100vh;
  width: 100%;
`;

const BusinessMetricKpis: React.FC<BusinessMetricKpisProps> = ({
  kpiList,
  ...props
}) => {
  const nodeTypes = useMemo(
    () => ({
      BusinessMetricCenterNode,
      BusinessMetricNode,
    }),
    []
  );
  if (Array.isArray(kpiList)) {
    const transformKpiList = nodes.reduce((acc, node) => {
      const kpiData = kpiList.find((kpi) => kpi.key === node.key);

      if (kpiData) {
        return [
          ...acc,
          ...[
            {
              ...node,
              data: {
                ...node.data,
                title: kpiData.name,
                value: kpiData.value,
                isPercentage: kpiData.is_percentage,
                unit: kpiData.unit,
              },
            },
          ],
        ];
      }
      return [...acc];
    }, [] as typeof nodes);

    return (
      <StyledBusinessMetricKpis {...props}>
        <ReactFlow
          fitView
          zoomOnScroll={false}
          preventScrolling={false}
          nodes={[...[nodes[0]], ...transformKpiList]}
          edges={edges}
          nodeTypes={nodeTypes}
          nodesDraggable={false}
          nodesConnectable={false}
        >
          <StyledControls showInteractive={false} />
        </ReactFlow>
      </StyledBusinessMetricKpis>
    );
  }

  return null;
};

export default BusinessMetricKpis;
