import { GlobalState } from 'little-state-machine';
import { BusinessModelState } from './nested-routes/business-blueprint/context/reducer';
import { BluePrintTemplateStore } from './StateMachine.types';

export const updateBluePrintTemplate = (
  state: GlobalState,
  payload: BluePrintTemplateStore
): GlobalState => ({
  ...state,
  bluePrintTemplateStore: {
    ...payload,
  },
});

export const updateBusinessBlueprint = (
  state: GlobalState,
  payload: BusinessModelState
) => ({
  ...state,
  businessBlueprint: {
    ...payload,
  },
});
