import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';

import { useStateMachine } from 'little-state-machine';
import { updateRiskInfo } from 'pages/account-settings/actions';
import { RiskInfoState } from 'pages/account-settings/StateMachine.types';

import { useNavigate } from 'react-router-dom';

import RadioButton from 'components/molecules/radio-button';
import InputWrapper from 'components/molecules/input-wrapper';
import OnboardingNav from 'components/molecules/onboarding-nav';
import SliderInput from 'components/molecules/slider-input';

const RiskInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) =>
    theme.pxToRem({ top: 100, right: 0, bottom: 100, left: 0 })};

  & .form {
    width: ${({ theme }) => theme.pxToRem(561)};
  }

  & .input {
    width: 100%;
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const riskInfoSchema = yup.object({
  riskTolerance: yup
    .number()
    .min(1, 'The minimum value is 1')
    .max(10, 'The maximum value is 10')
    .required(),
  takePersonalLoans: yup
    .string()
    .matches(/^(?:yes|no)$/, 'Please select Yes or No'),
});

const RiskInfo = () => {
  const navigate = useNavigate();
  const {
    state: { riskInfoStore },
    actions,
  } = useStateMachine({ updateRiskInfo });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RiskInfoState>({
    resolver: yupResolver<yup.AnyObjectSchema>(riskInfoSchema) as any,
    defaultValues: {
      ...riskInfoStore,
    },
  });

  return (
    <RiskInfoContainer>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          console.log(data);
          actions.updateRiskInfo(data);
          window.localStorage.setItem(
            'onboarding',
            '/account-settings/business-sophistication-info'
          );
          navigate('/account-settings/business-sophistication-info');
        })}
      >
        <InputWrapper label="Please rate your risk tolerance from 1-10">
          <SliderInput
            data-testid="riskTolerance-input"
            className="input"
            min={0}
            max={10}
            step={1}
            name="riskTolerance"
            control={control}
            error={errors['riskTolerance']}
          />
        </InputWrapper>

        <InputWrapper label="Are you willing to take personal loans for your business?">
          <RadioButton
            data-testid="takePersonalLoans-input"
            name="takePersonalLoans"
            control={control}
            options={[
              { label: 'No', value: 'no' },
              { label: 'Yes', value: 'yes' },
            ]}
          />
        </InputWrapper>

        <OnboardingNav
          className="bottomNavigation"
          timeSinceLastSave={600000}
          onClickBack={() => {
            navigate('/account-settings/goals-info');
          }}
        />
      </form>
    </RiskInfoContainer>
  );
};

export default RiskInfo;
