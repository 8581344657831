import React from 'react';

import * as HeroIconsSolid from '@heroicons/react/solid';
import * as HeroIconsOutline from '@heroicons/react/outline';

export type IconProps = {
  icon:
    | 'AcademicCapIcon'
    | 'AdjustmentsIcon'
    | 'AnnotationIcon'
    | 'ArchiveIcon'
    | 'ArrowCircleDownIcon'
    | 'ArrowCircleLeftIcon'
    | 'ArrowCircleRightIcon'
    | 'ArrowCircleUpIcon'
    | 'ArrowDownIcon'
    | 'ArrowLeftIcon'
    | 'ArrowNarrowDownIcon'
    | 'ArrowNarrowLeftIcon'
    | 'ArrowNarrowRightIcon'
    | 'ArrowNarrowUpIcon'
    | 'ArrowRightIcon'
    | 'ArrowSmDownIcon'
    | 'ArrowSmLeftIcon'
    | 'ArrowSmRightIcon'
    | 'ArrowSmUpIcon'
    | 'ArrowUpIcon'
    | 'ArrowsExpandIcon'
    | 'AtSymbolIcon'
    | 'BackspaceIcon'
    | 'BadgeCheckIcon'
    | 'BanIcon'
    | 'BeakerIcon'
    | 'BellIcon'
    | 'BookOpenIcon'
    | 'BookmarkAltIcon'
    | 'BookmarkIcon'
    | 'BriefcaseIcon'
    | 'CakeIcon'
    | 'CalculatorIcon'
    | 'CalendarIcon'
    | 'CameraIcon'
    | 'CashIcon'
    | 'ChartBarIcon'
    | 'ChartPieIcon'
    | 'ChartSquareBarIcon'
    | 'ChatAlt2Icon'
    | 'ChatAltIcon'
    | 'ChatIcon'
    | 'CheckCircleIcon'
    | 'CheckIcon'
    | 'ChevronDoubleDownIcon'
    | 'ChevronDoubleLeftIcon'
    | 'ChevronDoubleRightIcon'
    | 'ChevronDoubleUpIcon'
    | 'ChevronDownIcon'
    | 'ChevronLeftIcon'
    | 'ChevronRightIcon'
    | 'ChevronUpIcon'
    | 'ChipIcon'
    | 'ClipboardCheckIcon'
    | 'ClipboardCopyIcon'
    | 'ClipboardIcon'
    | 'ClipboardListIcon'
    | 'ClockIcon'
    | 'CloudDownloadIcon'
    | 'CloudIcon'
    | 'CloudUploadIcon'
    | 'CodeIcon'
    | 'CogIcon'
    | 'CollectionIcon'
    | 'ColorSwatchIcon'
    | 'CreditCardIcon'
    | 'CubeIcon'
    | 'CubeTransparentIcon'
    | 'CurrencyBangladeshiIcon'
    | 'CurrencyDollarIcon'
    | 'CurrencyEuroIcon'
    | 'CurrencyPoundIcon'
    | 'CurrencyRupeeIcon'
    | 'CurrencyYenIcon'
    | 'CursorClickIcon'
    | 'DatabaseIcon'
    | 'DesktopComputerIcon'
    | 'DeviceMobileIcon'
    | 'DeviceTabletIcon'
    | 'DocumentAddIcon'
    | 'DocumentDownloadIcon'
    | 'DocumentDuplicateIcon'
    | 'DocumentIcon'
    | 'DocumentRemoveIcon'
    | 'DocumentReportIcon'
    | 'DocumentSearchIcon'
    | 'DocumentTextIcon'
    | 'DotsCircleHorizontalIcon'
    | 'DotsHorizontalIcon'
    | 'DotsVerticalIcon'
    | 'DownloadIcon'
    | 'DuplicateIcon'
    | 'EmojiHappyIcon'
    | 'EmojiSadIcon'
    | 'ExclamationCircleIcon'
    | 'ExclamationIcon'
    | 'ExternalLinkIcon'
    | 'EyeIcon'
    | 'EyeOffIcon'
    | 'FastForwardIcon'
    | 'FilmIcon'
    | 'FilterIcon'
    | 'FingerPrintIcon'
    | 'FireIcon'
    | 'FlagIcon'
    | 'FolderAddIcon'
    | 'FolderDownloadIcon'
    | 'FolderIcon'
    | 'FolderOpenIcon'
    | 'FolderRemoveIcon'
    | 'GiftIcon'
    | 'GlobeAltIcon'
    | 'GlobeIcon'
    | 'HandIcon'
    | 'HashtagIcon'
    | 'HeartIcon'
    | 'HomeIcon'
    | 'IdentificationIcon'
    | 'InboxIcon'
    | 'InboxInIcon'
    | 'InformationCircleIcon'
    | 'KeyIcon'
    | 'LibraryIcon'
    | 'LightBulbIcon'
    | 'LightningBoltIcon'
    | 'LinkIcon'
    | 'LocationMarkerIcon'
    | 'LockClosedIcon'
    | 'LockOpenIcon'
    | 'LoginIcon'
    | 'LogoutIcon'
    | 'MailIcon'
    | 'MailOpenIcon'
    | 'MapIcon'
    | 'MenuAlt1Icon'
    | 'MenuAlt2Icon'
    | 'MenuAlt3Icon'
    | 'MenuAlt4Icon'
    | 'MenuIcon'
    | 'MicrophoneIcon'
    | 'MinusCircleIcon'
    | 'MinusIcon'
    | 'MinusSmIcon'
    | 'MoonIcon'
    | 'MusicNoteIcon'
    | 'NewspaperIcon'
    | 'OfficeBuildingIcon'
    | 'PaperAirplaneIcon'
    | 'PaperClipIcon'
    | 'PauseIcon'
    | 'PencilAltIcon'
    | 'PencilIcon'
    | 'PhoneIcon'
    | 'PhoneIncomingIcon'
    | 'PhoneMissedCallIcon'
    | 'PhoneOutgoingIcon'
    | 'PhotographIcon'
    | 'PlayIcon'
    | 'PlusCircleIcon'
    | 'PlusIcon'
    | 'PlusSmIcon'
    | 'PresentationChartBarIcon'
    | 'PresentationChartLineIcon'
    | 'PrinterIcon'
    | 'PuzzleIcon'
    | 'QrcodeIcon'
    | 'QuestionMarkCircleIcon'
    | 'ReceiptRefundIcon'
    | 'ReceiptTaxIcon'
    | 'RefreshIcon'
    | 'ReplyIcon'
    | 'RewindIcon'
    | 'RssIcon'
    | 'SaveAsIcon'
    | 'SaveIcon'
    | 'ScaleIcon'
    | 'ScissorsIcon'
    | 'SearchCircleIcon'
    | 'SearchIcon'
    | 'SelectorIcon'
    | 'ServerIcon'
    | 'ShareIcon'
    | 'ShieldCheckIcon'
    | 'ShieldExclamationIcon'
    | 'ShoppingBagIcon'
    | 'ShoppingCartIcon'
    | 'SortAscendingIcon'
    | 'SortDescendingIcon'
    | 'SparklesIcon'
    | 'SpeakerphoneIcon'
    | 'StarIcon'
    | 'StatusOfflineIcon'
    | 'StatusOnlineIcon'
    | 'StopIcon'
    | 'SunIcon'
    | 'SupportIcon'
    | 'SwitchHorizontalIcon'
    | 'SwitchVerticalIcon'
    | 'TableIcon'
    | 'TagIcon'
    | 'TemplateIcon'
    | 'TerminalIcon'
    | 'ThumbDownIcon'
    | 'ThumbUpIcon'
    | 'TicketIcon'
    | 'TranslateIcon'
    | 'TrashIcon'
    | 'TrendingDownIcon'
    | 'TrendingUpIcon'
    | 'TruckIcon'
    | 'UploadIcon'
    | 'UserAddIcon'
    | 'UserCircleIcon'
    | 'UserGroupIcon'
    | 'UserIcon'
    | 'UserRemoveIcon'
    | 'UsersIcon'
    | 'VariableIcon'
    | 'VideoCameraIcon'
    | 'ViewBoardsIcon'
    | 'ViewGridAddIcon'
    | 'ViewGridIcon'
    | 'ViewListIcon'
    | 'VolumeOffIcon'
    | 'VolumeUpIcon'
    | 'WifiIcon'
    | 'XCircleIcon'
    | 'XIcon'
    | 'ZoomInIcon'
    | 'ZoomOutIcon';
  variant?: 'solid' | 'outline';
} & React.SVGProps<SVGSVGElement>;

const IconSet = {
  solid: {
    ...HeroIconsSolid,
  },
  outline: {
    ...HeroIconsOutline,
  },
};

const Icon: React.FC<IconProps> = ({ icon, variant = 'solid', ...props }) => {
  const Component = IconSet[variant][icon];
  return <Component {...props} />;
};

export default Icon;
