import { useState } from 'react';

import styled from 'styled-components';

import { DragDropContext } from 'react-beautiful-dnd';

import BusinessModelDroppableNode from 'components/molecules/business-model-droppable-node';
import BusinessModelSidePanel from 'components/molecules/business-model-side-panel';

import { useCanvas } from 'pages/business-configuration/nested-routes/business-blueprint/context';

import { useReactFlow } from 'react-flow-renderer';

import {
  DROP_ITEM,
  REMOVE_ITEM,
  useBusinessModelDragItem,
} from 'pages/business-configuration/nested-routes/business-blueprint/hooks';
import { BusinessModelOptionType } from 'pages/business-configuration/nested-routes/business-blueprint/config';
import {
  ADD_BUSINESS_MODEL_ITEM_VALUE,
  DE_ACTIVATE_BUSINESS_MODEL_ITEM,
  REMOVE_BUSINESS_MODEL_ITEM_VALUE,
  SET_ACTIVE_BUSINESS_MODEL_ITEM,
} from 'pages/business-configuration/nested-routes/business-blueprint/context/reducer';

export type BusinessModelNodeProps = {
  children?: React.ReactNode;
  id:
    | 'distribution-model'
    | 'product-category'
    | 'revenue-model'
    // | 'target-customers'
    | 'product-model'
    | 'business-model';
  data: {
    label: string;
    handlePosition: string;
    labelPosition?: 'top' | 'bottom' | 'left' | 'right';
    values: BusinessModelOptionType[];
  };
  xPos: number;
  yPos: number;
};

const StyledBusinessModelNode = styled.div``;

const BusinessModelNode: React.FC<BusinessModelNodeProps> = ({
  id,
  data,
  xPos,
  yPos,
}) => {
  const { values, label } = data;
  const { setCanvasState } = useCanvas();

  const [sidePanelActive, setSidePanelActive] = useState(false);
  const { draggableItems, droppableItems, dispatchBusinessModelDragAction } =
    useBusinessModelDragItem(values, id);
  const { setCenter, setViewport } = useReactFlow();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { destination, source } = result;
        if (destination?.droppableId === source.droppableId) return;

        if (destination?.droppableId.includes('drop')) {
          dispatchBusinessModelDragAction({
            type: DROP_ITEM,
            item: draggableItems[source.index],
          });
          setCanvasState({
            type: ADD_BUSINESS_MODEL_ITEM_VALUE,
            businessModelItemId: id,
            value: draggableItems[source.index],
          });
        }
      }}
    >
      <StyledBusinessModelNode>
        <BusinessModelDroppableNode
          id={id}
          data={data}
          droppableItems={droppableItems}
          onClick={() => {
            setSidePanelActive(true);
            setCanvasState({
              type: SET_ACTIVE_BUSINESS_MODEL_ITEM,
              businessModelItemId: id,
            });
            setCenter(xPos + 294, yPos + 160, {
              zoom: 1.25,
              duration: 500,
            });
          }}
          onDroppableItemClose={(item) => {
            dispatchBusinessModelDragAction({ type: REMOVE_ITEM, item });
            setCanvasState({
              type: REMOVE_BUSINESS_MODEL_ITEM_VALUE,
              businessModelItemId: id,
              value: item,
            });
          }}
          xPos={xPos}
          yPos={yPos}
        />
        <BusinessModelSidePanel
          active={sidePanelActive}
          draggableItems={draggableItems}
          title={label}
          subTitle="(Can Be Multiple)"
          onClose={() => {
            setSidePanelActive(false);
            setCanvasState({ type: DE_ACTIVATE_BUSINESS_MODEL_ITEM });
            setViewport({ x: 0, y: 0, zoom: 0.9 }, { duration: 500 });
          }}
        />
      </StyledBusinessModelNode>
    </DragDropContext>
  );
};

export default BusinessModelNode;
