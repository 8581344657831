export const nodes: {
  index: number;
  id: string;
  key?: string;
  type: string;
  data: {
    key?: string;
    title?: string;
    name?: string;
    value?: number;
    simulated_value?: number;
    isPercentage?: boolean;
    position?: string;
    to?: string;
    unit?: string;
    nodeType?: string;
    scale?: {
      start?: number;
      end?: number;
    };
  };
  position: { x: number; y: number };
  draggable?: boolean;
  connectable?: boolean;
}[] = [
  {
    index: 0,
    id: 'business-overview-centre-node',
    key: undefined,
    type: 'BusinessOverviewCenterNode',
    data: {},
    position: { x: 480, y: 370 },
    draggable: false,
    connectable: false,
  },
  {
    index: 1,
    id: 'sales-marketing-centre-node',
    key: undefined,
    type: 'BusinessMetricCenterNode',
    data: {
      title: 'Sales & Marketing',
      to: 'sales-and-marketing',
    },
    position: { x: 755, y: 375 },
    draggable: false,
    connectable: false,
  },
  {
    index: 2,
    id: 'finance-centre-node',
    key: undefined,
    type: 'BusinessMetricCenterNode',
    data: {
      title: 'Finance',
      nodeType: 'finance',
      to: '/finance',
    },
    position: { x: 390, y: 194 },
    draggable: false,
    connectable: false,
  },
  {
    index: 3,
    id: 'ops-centre-node',
    key: undefined,
    type: 'BusinessMetricCenterNode',
    data: {
      title: 'Operations',
      to: '/operations',
      nodeType: 'ops',
    },
    position: { x: 330, y: 570 },
    draggable: false,
    connectable: false,
  },
  {
    index: 4,
    id: 'working-capital-ratio',
    key: 'working_capital_ratio',
    type: 'BusinessMetricNode',
    data: {
      title: 'Working Capital Ratio',
      value: 200,
      unit: '£',
      position: 'right',
      to: '/finance',
      nodeType: 'finance',
    },
    position: { x: 535, y: 158 },
    draggable: false,
    connectable: false,
  },
  {
    index: 5,
    id: 'account-payable-turnover-ratio',
    key: 'accounts_payable_turnover_ratio',
    type: 'BusinessMetricNode',
    data: {
      title: 'Accounts Payable Turnover Ratio',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/finance',
      nodeType: 'finance',
    },
    position: { x: 322, y: 95 },
    draggable: false,
    connectable: false,
  },
  {
    index: 6,
    id: 'account-receivables-turnover-ratio',
    key: 'accounts_receivable_turnover_ratio',
    type: 'BusinessMetricNode',
    data: {
      title: 'Account Receivables Turnover Ratio',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/finance',
      nodeType: 'finance',
    },
    position: { x: 150, y: 190 },
    draggable: false,
    connectable: false,
  },
  {
    index: 7,
    id: 'inventory-turnover-ratio',
    key: 'inventory_turnover_ratio',
    type: 'BusinessMetricNode',
    data: {
      title: 'Inventory Turnover Ratio',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/finance',
      nodeType: 'finance',
    },
    position: { x: 150, y: 290 },
    draggable: false,
    connectable: false,
  },
  {
    index: 8,
    id: 'capex-to-sales-ratio',
    key: 'capex_to_sales_ratio',
    type: 'BusinessMetricNode',
    data: {
      title: 'Capex to Sales Ratio',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/operations',
      nodeType: 'ops',
    },
    position: { x: 100, y: 530 },
    draggable: false,
    connectable: false,
  },
  {
    index: 9,
    id: 'other-opex-per-revenue',
    key: 'other_opex_per_revenues',
    type: 'BusinessMetricNode',
    data: {
      title: 'Other Opex / Revenues (£)',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/operations',
      nodeType: 'ops',
    },
    position: { x: 80, y: 680 },
    draggable: false,
    connectable: false,
  },
  {
    index: 10,
    id: 'gross-margin-profit',
    key: 'gross_profit_margin',
    type: 'BusinessMetricNode',
    data: {
      title: 'Gross Profit Margin',
      value: 200,
      unit: '£',
      position: 'right',
      to: '/operations',
      nodeType: 'ops',
    },
    position: { x: 480, y: 730 },
    draggable: false,
    connectable: false,
  },
  {
    index: 11,
    key: 'cpc',
    id: 'cost-per-click',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cost Per Click',
      value: 200,
      unit: '£',
      position: 'left',
      to: '/sales-and-marketing/cost-per-click',
    },
    position: { x: 590, y: 230 },
    draggable: false,
    connectable: false,
  },
  {
    index: 12,
    id: 'organic-per-paid-visitors',
    key: 'organic_per_paid_visitors',
    type: 'BusinessMetricNode',
    data: {
      title: 'Organic visitors / Paid visitors',
      value: 30,
      unit: '%',
      position: 'right',
      to: '/sales-and-marketing/organic-per-paid-visitors',
    },
    position: { x: 760, y: 140 },
    draggable: false,
    connectable: false,
  },
  {
    index: 13,
    id: 'cost-of-returning-visitor-per-cpc',
    key: 'cost_of_returning_visitor_per_cpc',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cost of returning visitors / CPC',
      value: 177,
      unit: undefined,
      position: 'right',
      to: '/sales-and-marketing/cost-of-returning-visitor-per-cpc',
    },
    position: { x: 840, y: 215 },
    draggable: false,
    connectable: false,
  },
  {
    index: 14,
    id: 'returning-visitors-per-new-visitors',
    key: 'returning_visitors_per_new_visitors',
    type: 'BusinessMetricNode',
    data: {
      title: 'Returning visitors / New visitors',
      value: 177,
      unit: undefined,
      position: 'right',
      to: '/sales-and-marketing/returning-visitors-per-new-visitors',
    },
    position: { x: 930, y: 320 },
    draggable: false,
    connectable: false,
  },
  {
    index: 15,
    id: 'bounce-rate',
    key: 'bounce_rate',
    type: 'BusinessMetricNode',
    data: {
      title: 'Bounce Rate',
      value: 41,
      unit: '%',
      position: 'right',
      to: '/sales-and-marketing/bounce-rate',
    },
    position: { x: 1000, y: 400 },
    draggable: false,
    connectable: false,
  },
  {
    index: 16,
    id: 'visitor-to-cart-rate',
    key: 'visits_to_cart',
    type: 'BusinessMetricNode',
    data: {
      title: 'Visits to cart rate',
      value: 41,
      unit: '%',
      position: 'right',
      to: '/sales-and-marketing/visitor-to-cart-rate',
    },
    position: { x: 980, y: 500 },
    draggable: false,
    connectable: false,
  },
  {
    index: 17,
    id: 'cart-to-checkout-rate',
    key: 'cart_to_checkout',
    type: 'BusinessMetricNode',
    data: {
      title: 'Cart to Checkout Rate',
      value: 41,
      unit: '%',
      position: 'bottom',
      to: '/sales-and-marketing/cart-to-checkout-rate',
    },
    position: { x: 900, y: 620 },
    draggable: false,
    connectable: false,
  },
  {
    index: 18,
    id: 'checkout-to-order-rate',
    key: 'checkout_to_order',
    type: 'BusinessMetricNode',
    data: {
      title: 'Checkout to Order Rate',
      value: 41,
      unit: '%',
      position: 'bottom',
      to: '/sales-and-marketing/checkout-to-order-rate',
    },
    position: { x: 770, y: 550 },
    draggable: false,
    connectable: false,
  },
  {
    index: 19,
    id: 'average-order-value',
    key: 'aov',
    type: 'BusinessMetricNode',
    data: {
      title: 'Average order value',
      position: 'bottom',
      to: '/sales-and-marketing/average-order-value',
    },
    position: { x: 680, y: 640 },
    draggable: false,
    connectable: false,
  },
];

export const edges = [
  {
    id: `${nodes[0].id}-${nodes[1].id}`,
    source: nodes[0].id,
    target: nodes[1].id,
    sourceHandle: 'right',
    targetHandle: 'left',
    type: 'straight',
    style: {
      stroke: '#AE82F9',
      strokeWidth: 4,
    },
  },
  {
    id: `${nodes[0].id}-${nodes[2].id}`,
    source: nodes[0].id,
    target: nodes[2].id,
    sourceHandle: 'top',
    targetHandle: 'bottom',
    type: 'straight',
    style: {
      stroke: '#658CF5',
      strokeWidth: 4,
    },
  },
  {
    id: `${nodes[0].id}-${nodes[3].id}`,
    source: nodes[0].id,
    target: nodes[3].id,
    sourceHandle: 'left',
    targetHandle: 'right',
    type: 'straight',
    style: {
      stroke: '#84D6E5',
      strokeWidth: 4,
    },
  },
  {
    id: `${nodes[2].id}-${nodes[4].id}`,
    target: nodes[2].id,
    source: nodes[4].id,
    sourceHandle: 'left',
    targetHandle: 'right',
    type: 'straight',
  },
  {
    id: `${nodes[2].id}-${nodes[5].id}`,
    target: nodes[2].id,
    source: nodes[5].id,
    sourceHandle: 'right',
    targetHandle: 'top',
    type: 'straight',
  },
  {
    id: `${nodes[2].id}-${nodes[6].id}`,
    target: nodes[2].id,
    source: nodes[6].id,
    sourceHandle: 'right',
    targetHandle: 'right',
    type: 'straight',
  },
  {
    id: `${nodes[2].id}-${nodes[7].id}`,
    target: nodes[2].id,
    source: nodes[7].id,
    sourceHandle: 'right',
    targetHandle: 'right',
    type: 'straight',
  },
  {
    id: `${nodes[3].id}-${nodes[8].id}`,
    target: nodes[3].id,
    source: nodes[8].id,
    sourceHandle: 'right',
    targetHandle: 'right',
    type: 'straight',
  },
  {
    id: `${nodes[3].id}-${nodes[9].id}`,
    target: nodes[3].id,
    source: nodes[9].id,
    sourceHandle: 'right',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[3].id}-${nodes[10].id}`,
    target: nodes[3].id,
    source: nodes[10].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[11].id}`,
    target: nodes[1].id,
    source: nodes[11].id,
    sourceHandle: 'right',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[12].id}`,
    target: nodes[1].id,
    source: nodes[12].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[13].id}`,
    target: nodes[1].id,
    source: nodes[13].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[14].id}`,
    target: nodes[1].id,
    source: nodes[14].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[15].id}`,
    target: nodes[1].id,
    source: nodes[15].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[16].id}`,
    target: nodes[1].id,
    source: nodes[16].id,
    sourceHandle: 'left',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[17].id}`,
    target: nodes[1].id,
    source: nodes[17].id,
    sourceHandle: 'top',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[18].id}`,
    target: nodes[1].id,
    source: nodes[18].id,
    sourceHandle: 'top',
    targetHandle: 'left',
    type: 'straight',
  },
  {
    id: `${nodes[1].id}-${nodes[19].id}`,
    target: nodes[1].id,
    source: nodes[19].id,
    sourceHandle: 'top',
    targetHandle: 'left',
    type: 'straight',
  },
];
