export const customerJourneyChildNodes = ({
  parentNode,
}: {
  parentNode: string;
}) => [
  {
    id: 'visits',
    type: 'CustomerJourneyChildNode',
    data: {
      header: {
        title: 'Visits',
        icon: 'PushDoor',
      },
      options: [
        {
          id: 'paid-traffic',
          menuGroupTitle: 'Add Paid Traffic Software',
          selectedGroupTitle: 'Paid Traffic',
          options: [
            {
              logo: 'Facebook',
              label: 'Facebook',
              value: 'facebook',
            },
            {
              logo: 'Instagram',
              label: 'Instagram',
              value: 'instagram',
            },
            {
              logo: 'Linkedin',
              label: 'LinkedIn',
              value: 'linkedin',
            },
          ],
        },
        {
          id: 'returning-traffic',
          menuGroupTitle: 'Add Returning traffic software',
          selectedGroupTitle: 'Return Traffic',
          options: [
            {
              logo: 'Mailchimp',
              label: 'Mailchimp',
              value: 'mailchimp',
            },
            {
              logo: 'SendX',
              label: 'SendX',
              value: 'sendx',
            },
          ],
        },
      ],
    },
    position: { x: 135, y: 105 },
    parentNode,
  },
  {
    id: 'cart',
    type: 'CustomerJourneyChildNode',
    data: {
      header: {
        title: 'Cart',
        icon: 'Shopping',
      },
      options: [
        {
          id: 'cart',
          menuGroupTitle: 'Cart Services',
          selectedGroupTitle: 'Cart Service',
          options: [
            {
              logo: 'Keptify',
              label: 'Keptify',
              value: 'keptify',
            },
          ],
        },
      ],
    },
    position: { x: 435, y: 105 },
    parentNode,
  },
  {
    id: 'checkouts',
    type: 'CustomerJourneyChildNode',
    data: {
      header: {
        title: 'Checkout',
        icon: 'Commodity',
      },
    },
    position: { x: 700, y: 105 },
    parentNode,
  },
  {
    id: 'payment',
    type: 'CustomerJourneyChildNode',
    data: {
      header: {
        title: 'Payment',
        icon: 'BankCard',
      },
      options: [
        {
          id: 'payment',
          menuGroupTitle: 'Payment Services',
          selectedGroupTitle: 'Payment Services',
          options: [
            {
              logo: 'Klarna',
              label: 'Klarna',
              value: 'klarna',
            },
            {
              logo: 'Stripe',
              label: 'Stripe',
              value: 'stripe',
            },
          ],
        },
      ],
    },
    position: { x: 985, y: 105 },
    parentNode,
  },
];
