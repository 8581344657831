import styled from 'styled-components';

import Card from 'components/atoms/card';
import Icon from 'components/atoms/icon';
import { IconProps } from 'components/atoms/icon/Icon';
import Tooltip from 'components/atoms/tooltip';

export type BusinessObjectiveCardProps = {
  children?: React.ReactNode;
  variant?: 'profitability' | 'solvency' | 'revenue';
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledBusinessObjectiveCard = styled.div<{
  iconColor: 'V400' | 'P400' | 'O400';
}>`
  & .business-objective-card {
    width: 100%;
    height: 100%;
    min-height: ${({ theme }) => theme.pxToRem(104)};

    &__heading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: ${({ theme }) => theme.pxToRem(32)};

      & > div:nth-child(1) {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        background-color: ${({ theme, iconColor }) =>
          theme.colors.tertiary[iconColor]};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
          width: ${({ theme }) => theme.pxToRem(24)};
          height: ${({ theme }) => theme.pxToRem(24)};
          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }

      & > div:nth-child(2) {
        margin-left: ${({ theme }) => theme.pxToRem(16)};
        margin-right: ${({ theme }) => theme.pxToRem(8)};
      }
      & > div:nth-child(3) {
        display: flex;
        align-items: center;
        & > svg {
          width: ${({ theme }) => theme.pxToRem(20)};
          height: ${({ theme }) => theme.pxToRem(20)};
          fill: ${({ theme }) => theme.colors.neutral[500]};
        }
      }
    }
  }
`;

const BusinessObjectiveCard: React.FC<BusinessObjectiveCardProps> = ({
  children,
  variant = 'profitability',
  ...props
}) => {
  const variants = {
    profitability: {
      icon: 'ChartBarIcon',
      iconColor: 'V400',
      title: 'Profitability',
      description: `Revenue is the total amount of income generated by the sale of goods or services related to the company's primary operations.`,
    },
    solvency: {
      icon: 'ReceiptTaxIcon',
      iconColor: 'P400',
      title: 'Solvency',
      description: `Solvency is the ability of a company to meet its long-term debts and financial obligations.`,
    },
    revenue: {
      icon: 'TrendingUpIcon',
      iconColor: 'O400',
      title: 'Revenue',
      description: `Revenue is the total amount of income generated by the sale of goods or services related to the company's primary operations.`,
    },
  };
  return (
    <StyledBusinessObjectiveCard
      iconColor={variants[variant].iconColor as 'V400' | 'P400' | 'O400'}
      {...props}
    >
      <Card className="business-objective-card">
        <div className="business-objective-card__heading">
          <div>
            <Icon icon={variants[variant].icon as IconProps['icon']} />
          </div>
          <div>
            <h2 className="H-700">{variants[variant].title}</h2>
          </div>
          <Tooltip content={variants[variant].description} position="right">
            <div>
              <Icon icon="InformationCircleIcon" />
            </div>
          </Tooltip>
        </div>
        {children}
      </Card>
    </StyledBusinessObjectiveCard>
  );
};

export default BusinessObjectiveCard;
