// TODO: write unit tests after demo
export const calculateKpiProgress = ({
  kpiValue,
  target,
  goal,
  isPercentage,
}: {
  kpiValue?: number;
  target?: number;
  goal?: 'Increase' | 'Decrease';
  isPercentage?: boolean;
}) => {
  if (!kpiValue || !target) return;

  if (!isPercentage && goal === 'Decrease') {
    const percentageValue = Math.round((kpiValue / target) * 100);
    return percentageValue > 100 ? 200 - percentageValue : 100;
  }

  if (isPercentage && goal === 'Decrease') {
    const percentageValue = Math.round(
      ((kpiValue * 100) / (target * 100)) * 100
    );
    return percentageValue > 100 ? 200 - percentageValue : 100;
  }

  if (isPercentage) {
    const percentageValue = Math.round(
      ((kpiValue * 100) / (target * 100)) * 100
    );
    if (percentageValue > 100) {
      return 100;
    }
    return percentageValue;
  }

  return Math.round((kpiValue / target) * 100);
};
