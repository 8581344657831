import styled from 'styled-components';

import theme from 'theme';

import companyLogo from './logo/company-logo.png';

import { Position } from 'react-flow-renderer';

import BusinessModelHandle from 'components/atoms/business-model-handle';

export type BusinessModelCenterNodeProps = {
  children?: React.ReactNode;
  radius?: number;
  imageSize?: number;
};

const StyledBusinessModelCenterNode = styled.svg<{ radius: number }>`
  width: ${({ theme, radius }) => theme.pxToRem(radius * 2)};
  height: ${({ theme, radius }) => theme.pxToRem(radius * 2)};
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: 50%;
  cursor: default;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

const BusinessModelCenterNode: React.FC<BusinessModelCenterNodeProps> = ({
  radius = 150,
  imageSize = 80,
}) => {
  return (
    <>
      <BusinessModelHandle
        id="left-top"
        type="source"
        position={Position.Left}
        style={{ top: 120, opacity: 0 }}
      />
      <BusinessModelHandle
        id="left-bottom"
        type="source"
        position={Position.Left}
        style={{ top: 180, opacity: 0 }}
      />
      <BusinessModelHandle
        id="bottom"
        type="source"
        position={Position.Bottom}
        style={{ opacity: 0 }}
      />
      <BusinessModelHandle
        id="right-top"
        type="source"
        position={Position.Right}
        style={{ top: 120, opacity: 0 }}
      />
      <BusinessModelHandle
        id="right-bottom"
        type="source"
        position={Position.Right}
        style={{ top: 180, opacity: 0 }}
      />
      <BusinessModelHandle
        id="top"
        type="source"
        position={Position.Top}
        style={{ opacity: 0 }}
      />
      <StyledBusinessModelCenterNode radius={radius}>
        <circle
          r={radius - 35 / 2}
          cx={radius}
          cy={radius}
          fill={theme.colors.neutral[100]}
        />
        <circle
          r={radius - 70 / 2}
          cx={radius}
          cy={radius}
          fill={theme.colors.neutral[0]}
        />
        <circle
          r={radius - 125 / 2}
          cx={radius}
          cy={radius}
          fill={theme.colors.neutral[150]}
        />
        <circle
          r={radius - 150 / 2}
          cx={radius}
          cy={radius}
          fill={theme.colors.neutral[100]}
        />
        <circle
          r={radius - 175 / 2}
          cx={radius}
          cy={radius}
          fill={theme.colors.neutral[75]}
        />
        <foreignObject
          x={radius - imageSize / 2}
          y={radius - imageSize / 2}
          width={imageSize}
          height={imageSize}
        >
          <img alt="Company Logo" src={companyLogo} />
        </foreignObject>
      </StyledBusinessModelCenterNode>
    </>
  );
};

export default BusinessModelCenterNode;
