import { businessModelNodes as nodes } from '../nodes';
export const businessModelEdges = [
  {
    id: `${nodes[0].id}-${nodes[1].id}`,
    target: nodes[1].id,
    source: nodes[0].id,
    sourceHandle: 'top',
    targetHandle: 'bottom',
    type: 'BusinessModelEdge',
    data: {
      variant: 'straight',
    },
  },
  {
    id: `${nodes[0].id}-${nodes[2].id}`,
    target: nodes[2].id,
    source: nodes[0].id,
    sourceHandle: 'left-top',
    targetHandle: 'right',
    type: 'BusinessModelEdge',
  },
  {
    id: `${nodes[0].id}-${nodes[3].id}`,
    target: nodes[3].id,
    source: nodes[0].id,
    sourceHandle: 'right-top',
    targetHandle: 'left',
    type: 'BusinessModelEdge',
  },
  {
    id: `${nodes[0].id}-${nodes[4].id}`,
    target: nodes[4].id,
    source: nodes[0].id,
    sourceHandle: 'right-bottom',
    targetHandle: 'right',
    type: 'BusinessModelEdge',
  },
  {
    id: `${nodes[0].id}-${nodes[5].id}`,
    target: nodes[5].id,
    source: nodes[0].id,
    sourceHandle: 'left-bottom',
    targetHandle: 'left',
    type: 'BusinessModelEdge',
  },
  // {
  //   id: `${nodes[0].id}-${nodes[6].id}`,
  //   target: nodes[6].id,
  //   source: nodes[0].id,
  //   sourceHandle: 'bottom',
  //   targetHandle: 'top',
  //   type: 'BusinessModelEdge',
  //   data: {
  //     variant: 'straight',
  //   },
  // },
];
