import { useState } from 'react';

import styled from 'styled-components';

import * as LogoImages from './logos';

import Icon from 'components/atoms/icon';
import { Popover, Position } from 'evergreen-ui';
import Menu from 'components/atoms/menu';
import { useCanvas } from 'pages/business-configuration/nested-routes/business-blueprint/context';

export type OtherConnectionsListProps = {
  children?: React.ReactNode;
  logo: 'Shopify' | 'Amazon' | 'WooCommerce';
  customerJourneyId: string;
  options: {
    value: string;
    logo: string;
    label: string;
  }[];
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'value' | 'ref'
>;

const StyledMenuItem = styled(Menu.Item)`
  padding-left: ${({ theme }) => theme.pxToRem(0)};
  padding-right: ${({ theme }) => theme.pxToRem(16)};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
  }

  & .menu-item-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > .service-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > p {
        color: ${({ theme }) => theme.colors.neutral[600]};
        margin-right: ${({ theme }) => theme.pxToRem(8)};
      }

      & > img {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        margin-right: 8px;
      }
    }

    & > .service-icon {
      display: flex;
      align-items: center;

      & > svg {
        position: absolute;
        width: ${({ theme }) => theme.pxToRem(16)};
        height: ${({ theme }) => theme.pxToRem(16)};

        & path {
          fill: ${({ theme }) => theme.colors.secondary['R400']};
        }
      }
    }
  }
`;

const StyledMenuContainer = styled.div`
  padding: ${({ theme }) =>
    theme.pxToRem({
      top: 16,
      left: 0,
      right: 0,
      bottom: 16,
    })};
`;

const StyledOtherConnectionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.pxToRem(150)};
  text-align: center;
  text-transform: capitalize;

  & > p {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  & > .logo {
    position: relative;
    width: ${({ theme }) => theme.pxToRem(100)};
    height: ${({ theme }) => theme.pxToRem(100)};
    margin-bottom: ${({ theme }) => theme.pxToRem(16)};
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 12px 40px rgba(92, 153, 248, 0.2);

    & > img {
      width: ${({ theme }) => theme.pxToRem(48)};
      height: ${({ theme }) => theme.pxToRem(48)};
    }

    & > .selected-services {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: ${({ theme }) => theme.pxToRem(-12)};

      & > img {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        &:not(:first-child) {
          margin-left: -4px;
        }
      }
      & > .list-cta {
        width: ${({ theme }) => theme.pxToRem(24)};
        height: ${({ theme }) => theme.pxToRem(24)};
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.primary['BLUE']};
        border: none;
        cursor: pointer;

        & > svg {
          height: ${({ theme }) => theme.pxToRem(14)};
          height: ${({ theme }) => theme.pxToRem(14)};

          fill: ${({ theme }) => theme.colors.neutral[0]};
        }
      }
    }
  }
`;

const OtherConnectionsList: React.FC<OtherConnectionsListProps> = ({
  logo,
  options,
  customerJourneyId,
  ...props
}) => {
  const [selected, setSelected] = useState<
    OtherConnectionsListProps['options']
  >([]);
  const { setCanvasState, customerJourney } = useCanvas();
  console.log('CANVAS STATE ', customerJourney);
  return (
    <StyledOtherConnectionsList {...props}>
      <div className="logo">
        <img src={LogoImages[logo]} alt={`${logo} Logo Image`} />
        <div className="selected-services">
          {selected.map(({ value, logo }) => {
            return (
              <img
                key={value}
                src={LogoImages[logo as 'Intercom' | 'GoogleAnalytics']}
                alt={`${logo} logo image.`}
              />
            );
          })}
          {options?.length > 0 && (
            <Popover
              position={Position.BOTTOM}
              content={() => {
                return (
                  <Menu>
                    <StyledMenuContainer>
                      {Array.isArray(options) &&
                        options.map(({ label, value, logo }) => {
                          return (
                            <StyledMenuItem
                              key={value}
                              onClick={() => {
                                if (
                                  selected.find((item) => item.value === value)
                                ) {
                                  setSelected((prevState) =>
                                    prevState.filter(
                                      (item) => item.value !== value
                                    )
                                  );
                                  return;
                                }
                                setSelected((prevState) => [
                                  ...prevState,
                                  ...[{ label, value, logo }],
                                ]);
                                setCanvasState({
                                  type: 'ADD_OTHER_CONNECTIONS',
                                  customerJourneyId,
                                  storeFrontItemValue: { label, value, logo },
                                });
                              }}
                            >
                              <div className="menu-item-content">
                                <div className="service-label">
                                  <img
                                    src={
                                      LogoImages[
                                        logo as 'Intercom' | 'GoogleAnalytics'
                                      ]
                                    }
                                    alt={`${logo} logo image`}
                                  />
                                  <p className="large-text">{label}</p>
                                </div>
                                {selected.find(
                                  (item) => item.value === value
                                ) && (
                                  <div className="service-icon">
                                    <Icon icon="XIcon" />
                                  </div>
                                )}
                              </div>
                            </StyledMenuItem>
                          );
                        })}
                    </StyledMenuContainer>
                  </Menu>
                );
              }}
            >
              <button aria-label="Add Connections" className="list-cta">
                <Icon icon="PlusIcon" />
              </button>
            </Popover>
          )}
        </div>
      </div>
      <p className="small-text">Other Connections</p>
    </StyledOtherConnectionsList>
  );
};

export default OtherConnectionsList;
