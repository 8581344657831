import { IconProps } from 'components/atoms/icon/Icon';

export const templateData: {
  id: string;
  title: string;
  description: string;
  icon: IconProps['icon'];
  isShowSelections: boolean;
  options: { id: string; title: string; icon: IconProps['icon'] }[];
}[] = [
  {
    id: 'real_estate',
    title: 'Real Estate',
    description:
      'Segment syncs a list of users to these destinations and keeps it up to date.',
    icon: 'OfficeBuildingIcon',
    isShowSelections: false,
    options: [],
  },
  {
    id: 'hospitality',
    title: 'Hospitality',
    description:
      'Segment syncs a list of users to these destinations and keeps it up to date.',
    icon: 'PlusCircleIcon',
    isShowSelections: false,
    options: [],
  },
  {
    id: 'ecommerce',
    title: 'Ecommerce',
    description:
      'Segment syncs a list of users to these destinations and keeps it up to date.',
    icon: 'DesktopComputerIcon',
    isShowSelections: false,
    options: [
      { id: 'digital_goods', title: 'Digital Goods', icon: 'DatabaseIcon' },
      { id: 'physical_goods', title: 'Physical Goods', icon: 'CubeIcon' },
      { id: 'other_goods', title: 'Other Goods', icon: 'ViewGridIcon' },
    ],
  },
];
