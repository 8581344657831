import styled from 'styled-components';

import Lottie, { LottieComponentProps } from 'lottie-react';

import dotsScale from './data.json';

export type DotsScaleLoaderProps = {} & Omit<
  LottieComponentProps,
  'ref' | 'as' | 'animationData'
>;

const StyledDotsScaleLoader = styled(Lottie)`
  width: 100%;
  height: 100%;
  & > svg {
    & path {
      fill: ${({ theme }) => theme.colors.neutral[600]};
      stroke: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const DotsScaleLoader: React.FC<DotsScaleLoaderProps> = ({ ...props }) => {
  return <StyledDotsScaleLoader {...props} animationData={dotsScale} />;
};

export default DotsScaleLoader;
