import React, { useState } from 'react';

import theme, { Theme } from 'theme';

type ThemeContextType = {
  theme: Theme;
  setTheme?: React.Dispatch<React.SetStateAction<Theme>> | null;
};
export const ThemeContext = React.createContext<ThemeContextType>({
  theme,
});

export const useTheme = (): ThemeContextType => {
  const theme = React.useContext(ThemeContext);

  if (theme === null) {
    throw new Error(
      `Theme is null. This means that either we were unable to get the user data from the API,
       or "useTheme" is used outside "ThemeProvider"`
    );
  }

  return {
    ...theme,
  };
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [globalTheme, setTheme] = useState<Theme>(theme);
  return (
    <ThemeContext.Provider value={{ theme: globalTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
