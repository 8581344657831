import React from 'react';
import styled from 'styled-components';

import { motion, HTMLMotionProps } from 'framer-motion';

import { pxToRem } from 'theme/helpers';

export type SideBarContainerProps = {
  open?: boolean;
} & HTMLMotionProps<'div'>;

const StyledSideBarContainer = styled(motion.div)<
  Pick<SideBarContainerProps, 'open'>
>`
  position: fixed;
  width: 100%;
  height: ${({ theme }) => `calc(100vh - ${theme.pxToRem(40)})`};
  background-color: ${({ theme }) => theme.colors.neutral['50']};
  top: ${({ theme }) => theme.pxToRem(20)};
  right: ${({ theme }) => theme.pxToRem(6)};
  border: ${({ theme }) => `${theme.pxToRem(2)} solid ${theme.colors.white}`};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99;

  & .content {
    min-width: ${({ theme }) => theme.pxToRem(349)};
    padding: ${({ theme }) =>
      theme.pxToRem({
        top: 32,
        left: 24,
        right: 24,
        bottom: 32,
      })};
  }
`;

const variants = {
  closed: {
    maxWidth: pxToRem(0),
    transitionEnd: {
      display: 'none',
    },
  },
  opened: {
    display: 'initial',
    maxWidth: pxToRem(397),
  },
};

/**
 * SideBarContainer atom /to be used for the side panel main navigation
 */

const SideBarContainer: React.FC<SideBarContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledSideBarContainer
      {...props}
      variants={variants}
      animate={props.open ? 'opened' : 'closed'}
      initial={false}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 60,
        mass: 0.1,
        delay: 0,
      }}
    >
      <div className="content">{children}</div>
    </StyledSideBarContainer>
  );
};

export default SideBarContainer;
