import styled from 'styled-components';

import { Draggable, Droppable } from 'react-beautiful-dnd';

import BusinessModelDraggableItem from 'components/molecules/business-model-draggable-item';
import CanvasSidePanel from 'components/molecules/canvas-side-panel';
import { CanvasSidePanelProps } from '../canvas-side-panel/CanvasSidePanel';

import { BusinessModelOptionType } from 'pages/business-configuration/nested-routes/business-blueprint/config';

export type BusinessModelSidePanelProps = {
  children?: React.ReactNode;
  draggableItems: BusinessModelOptionType[] | [];
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
} & CanvasSidePanelProps;

const StyledBusinessModelSidePanel = styled(CanvasSidePanel)`
  & .items-container {
    display: grid;
    grid-template-columns: 120px 120px;
    grid-gap: 24px;
    align-items: center;
    justify-content: center;
    grid-template-rows: auto;
    position: relative;
    margin-top: ${({ theme }) => theme.pxToRem(24)};

    & > div {
      height: 120px;
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      border-radius: ${({ theme }) => theme.pxToRem(4)};
      border: ${({ theme }) => `1px dashed ${theme.colors.neutral[400]}`};
      overflow: hidden;
      z-index: 0;
    }
  }
`;

const BusinessModelSidePanel: React.FC<BusinessModelSidePanelProps> = ({
  draggableItems,
  onClose,
  ...props
}) => {
  return (
    <StyledBusinessModelSidePanel onClose={onClose} {...props}>
      <Droppable droppableId={`side-panel`}>
        {(provided, snapshot) => {
          return (
            <div
              className="items-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {draggableItems.map(({ label, icon }, index) => (
                <Draggable
                  key={index}
                  index={index}
                  draggableId={`drag-${index}-${label}`}
                >
                  {(provided, snapshot) => (
                    <BusinessModelDraggableItem
                      label={label}
                      icon={icon}
                      type="drag"
                      className="items-container__draggable"
                      isDraggingOver={snapshot.isDragging}
                      innerRef={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
              {snapshot.isUsingPlaceholder ? (
                <div className="items-container__placeholder">
                  {provided.placeholder}
                </div>
              ) : (
                provided.placeholder
              )}
            </div>
          );
        }}
      </Droppable>
    </StyledBusinessModelSidePanel>
  );
};

export default BusinessModelSidePanel;
