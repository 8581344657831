export const supplyChainChildrenNodes = ({
  parentNode,
}: {
  parentNode: string;
}) => [
  {
    id: 'delivery',
    type: 'FulfillmentList',
    data: {
      header: {
        title: 'Delivery',
        icon: 'ExpressDelivery',
      },
      options: [
        {
          id: 'delivery',
          menuGroupTitle: 'Delivery',
          selectedGroupTitle: 'Delivery',
          options: [
            {
              logo: 'RoyalMail',
              label: 'Royal Mail',
              value: 'royal-mail',
            },
            {
              logo: 'FedEx',
              label: 'FedEx',
              value: 'fedex',
            },
          ],
        },
      ],
    },
    position: { x: 32, y: 120 },
    parentNode,
  },
  {
    id: 'processing',
    type: 'FulfillmentList',
    data: {
      header: {
        title: 'Processing',
        icon: 'LoadingThree',
      },
      options: [],
    },
    position: { x: 32, y: 370 },
    parentNode,
  },
  {
    id: 'inventory',
    type: 'FulfillmentList',
    data: {
      header: {
        title: 'Inventory',
        icon: 'HomeTwo',
      },
      options: [],
    },
    position: { x: 32, y: 620 },
    parentNode,
  },
];
