import styled from 'styled-components';

import { Spinner } from 'evergreen-ui';

export type LoadingProps = {
  className?: string;
};

const StyledLoading = styled.div`
  svg {
    & circle {
      stroke: ${({ theme }) => theme.colors.neutral[600]};
    }
  }
`;

const Loading: React.FC<LoadingProps> = ({ className, ...props }) => {
  return (
    <StyledLoading className={className} {...props}>
      <Spinner />
    </StyledLoading>
  );
};

export default Loading;
