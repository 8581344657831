import styled from 'styled-components';

import PageContentContainer from 'components/atoms/page-content-container';
import BusinessMetricsList from 'components/molecules/business-metrics-list';
import BusinessResources from 'components/molecules/business-resources';

import { PageContentContainerProps } from 'components/atoms/page-content-container/PageContentContainer';

export type MetricsListProps = {
  loadingMetrics?: boolean;
  currentRevenue?: number;
  forecastedRevenue?: number;
  currentProfitability?: number;
  forecastedProfitability?: number;
  currentSolvency?: number;
  forecastedSolvency?: number;
  time: number;
  timeSpent: number;
  budget: number;
  budgetSpent: number;
} & PageContentContainerProps;

const StyledMetricsList = styled(PageContentContainer)`
  min-height: auto;
  padding: 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: ${({ theme }) => theme.pxToRem(14)};
  margin-bottom: ${({ theme }) => theme.pxToRem(0)};

  & > div {
    background-color: ${({ theme }) =>
      theme.mode === 'light'
        ? theme.colors.neutral[50]
        : theme.colors.neutral[950]};
    border: ${({ theme }) =>
      theme.mode === 'light'
        ? `${theme.pxToRem(2)} solid ${theme.colors.neutral[0]}`
        : 'none'};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
  }
`;

const MetricsList: React.FC<MetricsListProps> = ({
  loadingMetrics,
  currentRevenue,
  forecastedRevenue,
  currentProfitability,
  forecastedProfitability,
  currentSolvency,
  forecastedSolvency,
  time,
  timeSpent,
  budget,
  budgetSpent,
  ...props
}) => {
  return (
    <StyledMetricsList {...props} wrapper>
      <div>
        <BusinessMetricsList
          loading={loadingMetrics}
          currentRevenue={currentRevenue}
          forecastedRevenue={forecastedRevenue}
          currentProfitability={currentProfitability}
          forecastedProfitability={forecastedProfitability}
          currentSolvency={currentSolvency}
          forecastedSolvency={forecastedSolvency}
        />
      </div>
      <div>
        <BusinessResources
          time={time || 0}
          timeSpent={timeSpent || 0}
          budget={budget || 0}
          budgetSpent={budgetSpent || 0}
        />
      </div>
    </StyledMetricsList>
  );
};

export default MetricsList;
