import styled from 'styled-components';

import Icon from 'components/atoms/icon';

import BankIcon from './logos/bank.png';
import HSBC from './logos/hsbc.png';
import Tesco from './logos/tesco.png';
import CapitalOnTap from './logos/capital_on_tap.jpg';
import Iwoca from './logos/iwoca.png';
import Clearco from './logos/clearco.jpg';
import Uncapped from './logos/uncapped.png';
import Fundsquire from './logos/fundsquire.png';

export type FinanceOfferCardProps = {
  children?: React.ReactNode;
  id?: string;
  innerRef: any;
  provider?: string;
  type?: string;
  max?: number;
  duration?: number;
  interest?: number;
};

const StyledFinanceOfferCard = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.pxToRem(88)};
  background-color: ${({ theme }) =>
    theme.mode === 'light'
      ? theme.colors.neutral[0]
      : theme.colors.neutral[910]};
  border-radius: ${({ theme }) => theme.pxToRem(16)};
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;

  & div:nth-child(1) {
    text-align: center;
    & > span {
      & > img {
        width: ${({ theme }) => theme.pxToRem(32)};
        height: ${({ theme }) => theme.pxToRem(32)};
        border-radius: 50%;
      }
    }
  }

  & div:nth-child(2) {
    & > .title {
      display: flex;
      margin-bottom: ${({ theme }) => theme.pxToRem(8)};

      & > h5 {
        color: ${({ theme }) =>
          theme.mode === 'light'
            ? theme.colors.neutral[900]
            : theme.colors.neutral[0]};
      }

      & > span {
        display: flex;
        align-items: center;
        font-size: ${({ theme }) => theme.pxToRem(8)};
        text-transform: uppercase;
        padding: ${({ theme }) => theme.pxToRem(4)};
        border-radius: ${({ theme }) => theme.pxToRem(6)};
        margin-left: ${({ theme }) => theme.pxToRem(12)};
        background-color: ${({ theme }) => theme.colors.neutral[300]};
        color: ${({ theme }) => theme.colors.neutral[600]};
      }
    }

    & > .terms {
      display: flex;
      align-items: center;
      & > p {
        color: ${({ theme }) => theme.colors.neutral[500]};
        margin-right: ${({ theme }) => theme.pxToRem(12)};
        & > span {
          color: ${({ theme }) => theme.colors.neutral[600]};
        }
      }
    }
  }
  & div:nth-child(3) {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: ${({ theme }) => theme.pxToRem(24)};
      height: ${({ theme }) => theme.pxToRem(24)};
      fill: ${({ theme }) => theme.colors.neutral[500]};
    }
  }
`;

const FinanceOfferCard: React.FC<FinanceOfferCardProps> = ({
  id,
  innerRef,
  type,
  max,
  duration,
  interest,
  ...props
}) => {
  const getProviderLogo = (id?: string) => {
    if (id === 'HSBC') {
      return HSBC;
    }
    if (id === 'Tesco_Bank') {
      return Tesco;
    }
    if (id === 'Capital_on_Tap') {
      return CapitalOnTap;
    }
    if (id === 'Iwoca') {
      return Iwoca;
    }

    if (id === 'Clearco') {
      return Clearco;
    }
    if (id === 'Uncapped') {
      return Uncapped;
    }
    if (id === 'Fundsquire') {
      return Fundsquire;
    }
    return BankIcon;
  };
  return (
    <StyledFinanceOfferCard {...props} ref={innerRef} {...props}>
      <div>
        <span>
          <img src={getProviderLogo(id)} alt="Bank Icon" />
        </span>
      </div>
      <div>
        <div className="title">
          <h5 className="H-400">{`Borrow up to £${
            max && max > 1000 ? `${max / 1000}K` : max
          }`}</h5>
          <span>{type}</span>
        </div>
        <div className="terms">
          <p className="small-text">
            Term: <span>{`up to ${duration} months`}</span>
          </p>
          <p className="small-text">
            Rate (APR): <span>{`up to ${interest}%`}</span>
          </p>
        </div>
      </div>
      <div>
        <Icon icon="ViewListIcon" />
      </div>
    </StyledFinanceOfferCard>
  );
};

export default FinanceOfferCard;
