import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import DetailedProgressBar from 'components/atoms/detailed-progress-bar';
import Button from 'components/atoms/button';
import VerticalProgressBar from 'components/molecules/vertical-progress-bar';

export type UserBusinessInfoTemplateProps = {
  children?: React.ReactNode;
  totalSteps?: number;
  currentStep?: number;
  bodyBgColor?: string;
  progress?: {
    index: number;
    percent?: number;
  };
  title?: string;
  onSaveAndExit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const GlobalStyle = createGlobalStyle<{
  bodyBgColor: UserBusinessInfoTemplateProps['bodyBgColor'];
}>`
body {
  background-color: ${({ theme, bodyBgColor }) =>
    bodyBgColor ? bodyBgColor : theme.colors.neutral[0]} !important;
}
`;

const StyledUserBusinessInfoTemplate = styled.div`
  width: 100vw;
  position: relative;

  & .detailedProgressBar {
    position: absolute;
    left: ${({ theme }) => theme.pxToRem(24)};
    top: ${({ theme }) => theme.pxToRem(32)};
  }

  & .exitButton {
    position: absolute;
    right: ${({ theme }) => theme.pxToRem(24)};
    top: ${({ theme }) => theme.pxToRem(32)};
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 0px 4px 50px -10px rgba(193, 196, 214, 0.5);
    z-index: 99;
  }

  & .verticalProgressBar {
    position: absolute;
    top: ${({ theme }) => theme.pxToRem(376)};
    left: ${({ theme }) => theme.pxToRem(24)};
    transform: translateY(-30%);
  }
`;

const UserBusinessInfoTemplate: React.FC<UserBusinessInfoTemplateProps> = ({
  children,
  totalSteps,
  currentStep,
  progress,
  title,
  bodyBgColor,
  onSaveAndExit,
}) => {
  return (
    <>
      <StyledUserBusinessInfoTemplate>
        <GlobalStyle bodyBgColor={bodyBgColor} />
        <DetailedProgressBar
          className="detailedProgressBar"
          title={title || ''}
          totalSteps={totalSteps || 0}
          currentStep={currentStep || 0}
        />
        <Button
          onClick={(event) => {
            onSaveAndExit?.(event);
          }}
          iconStart="LogoutIcon"
          appearance="minimal"
          className="exitButton"
        >
          Save & exit
        </Button>
        <VerticalProgressBar
          steps={[
            { icon: 'ShoppingBagIcon', title: 'Business Blueprint' },
            {
              icon: 'PresentationChartLineIcon',
              title: 'Financial Information',
            },
            { icon: 'ThumbUpIcon', title: 'Personal Considerations' },
            { icon: 'ViewGridAddIcon', title: 'Connect Data Sources' },
          ]}
          currentStep={{
            // TODO fix TS issue
            //@ts-ignore
            index: progress?.index ? progress?.index : '',
            percent: progress?.percent,
          }}
          className="verticalProgressBar"
        />
        {children}
      </StyledUserBusinessInfoTemplate>
    </>
  );
};

export default UserBusinessInfoTemplate;
