import React, { useState } from 'react';
import styled from 'styled-components';
import SoftwareCard from 'components/atoms/software-card';
import AddSoftwareCard from 'components/atoms/add-software-card';
import SoftwarePickerPopover from 'components/molecules/software-picker-popover';
import OnboardingNav from '../../../../components/molecules/onboarding-nav';
import {
  SoftwareKeys,
  softwareMap,
} from 'pages/business-configuration/softwareData';
import Button from 'components/atoms/button';
import { ConnectionStatus } from 'components/atoms/software-card/SoftwareCard';
import SoftwareConnectionDialog from 'components/molecules/software-connection-dialog';
import SoftwareConnectedDialog from 'components/molecules/software-connected-dialog';

import CodatImg from './images/codat.png';
import { useNavigate } from 'react-router-dom';

const initialData: {
  codatServices: SoftwareKeys[];
  manualServices: SoftwareKeys[];
} = {
  codatServices: ['shopify', 'quickbooks', 'sage'],
  manualServices: ['facebookads', 'googleads', 'mailchimp'],
};

type SoftwareState = {
  id: SoftwareKeys;
  status: ConnectionStatus;
};

const LinkAccountsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & .container {
    max-width: ${({ theme }) => theme.pxToRem(740)};
    width: 100%;
    padding: ${({ theme }) =>
      theme.pxToRem({ top: 164, right: 0, bottom: 164, left: 0 })};

    & h2 {
      font-style: normal;
      font-weight: 600;
      font-size: ${({ theme }) => theme.pxToRem(18)};
      line-height: ${({ theme }) => theme.pxToRem(24)};
      color: ${({ theme }) => theme.colors.neutral[900]};
    }

    & .codatServicesHeader {
      display: flex;
      align-items: center;

      & > h2 {
        margin: ${({ theme }) =>
          theme.pxToRem({ top: 0, right: 4, bottom: 0, left: 0 })};
      }
      & > img {
        margin-right: ${({ theme }) => theme.pxToRem(12)};
      }
      & > button {
        margin-left: auto;
      }
    }

    & .softwareCardContainer {
      display: flex;
      flex-wrap: wrap;

      & > * {
        margin: ${({ theme }) =>
          theme.pxToRem({ top: 24, right: 24, bottom: 0, left: 0 })};
      }
      margin-bottom: ${({ theme }) => theme.pxToRem(18)};
    }
  }

  & .templateCard {
    margin: ${({ theme }) => theme.pxToRem(12)};
    & .templateOption {
      margin: ${({ theme }) => theme.pxToRem(12)};
    }
  }

  & .bottomNavigation {
    position: fixed;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
  }
`;

const LinkAccounts = () => {
  const navigate = useNavigate();
  const [manualServices, setManualServices] = useState<SoftwareState[]>([]);

  const [codatServices, setCodatServices] = useState<SoftwareState[]>([]);
  const [isSoftwareConnectionDialogOpen, setSoftwareConnectionDialogOpen] =
    useState(false);
  const [isSoftwareConnectedDialogOpen, setSoftwareConnectedDialogOpen] =
    useState(false);

  const notConnectedCodateServices = codatServices.filter(
    (service) => service.status === 'idle'
  );

  const handleConnect = () => {
    if (!notConnectedCodateServices.length) {
      return;
    }

    if (notConnectedCodateServices.length === 1) {
      setSoftwareConnectionDialogOpen(false);
      setSoftwareConnectedDialogOpen(true);
    }

    setCodatServices((prev) =>
      prev.map((service) =>
        service.id === notConnectedCodateServices[0].id
          ? { ...service, status: 'connecting' }
          : service
      )
    );
    setTimeout(
      () =>
        setCodatServices((prev) =>
          prev.map((service) =>
            service.id === notConnectedCodateServices[0].id
              ? { ...service, status: 'connected' }
              : service
          )
        ),
      10 * 1000
    );
  };

  const handleConnectService = (id: string) => {
    setManualServices((prev) =>
      prev.map((service) =>
        service.id === id ? { id, status: 'connecting' } : service
      )
    );
    setTimeout(() => {
      setManualServices((prev) =>
        prev.map((service) =>
          service.id === id ? { id, status: 'connected' } : service
        )
      );
    }, 2000);
  };

  const handleConnectAll = () => {
    if (notConnectedCodateServices.length) {
      setSoftwareConnectionDialogOpen(true);
    } else {
      setSoftwareConnectedDialogOpen(true);
    }
  };

  return (
    <LinkAccountsContainer>
      <div className="container">
        <div className="codatServicesHeader">
          <h2>Connect your Services with</h2>
          <img src={CodatImg} />
          <Button
            appearance="primary"
            onClick={handleConnectAll}
            disabled={
              !codatServices.some((service) => service.status !== 'connected')
            }
          >
            Connect all
          </Button>
        </div>
        <div className="softwareCardContainer">
          {codatServices.map(({ id, status }) => (
            <SoftwareCard
              status={status}
              key={id}
              data-testid="software-card"
              logo={softwareMap[id].logo}
              title={softwareMap[id].title}
              onClickX={() =>
                setCodatServices((prev) =>
                  prev.filter(({ id: currentId }) => currentId !== id)
                )
              }
            />
          ))}
          <SoftwarePickerPopover
            availableSoftwares={initialData.codatServices
              .filter((key) => !codatServices.some(({ id }) => key === id))
              .map((id) => softwareMap[id])}
            selectedSoftwares={codatServices.map(
              (service) => softwareMap[service.id]
            )}
            onSelect={(id) =>
              setCodatServices((prev) => [...prev, { id, status: 'idle' }])
            }
            onRemove={(id) =>
              setCodatServices((prev) =>
                prev.filter(({ id: currentId }) => currentId !== id)
              )
            }
          >
            <AddSoftwareCard />
          </SoftwarePickerPopover>
        </div>

        <h2>Connect Your Services Manually</h2>
        <div className="softwareCardContainer">
          {manualServices.map(({ id, status }, index) => (
            <SoftwareCard
              status={status}
              key={id}
              logo={softwareMap[id].logo}
              title={softwareMap[id].title}
              onClickX={() =>
                setManualServices((prev) =>
                  prev.filter(({ id: currentId }) => currentId !== id)
                )
              }
              connectButton={index === manualServices.length - 1}
              onClickConnect={() => handleConnectService(id)}
            />
          ))}
          <SoftwarePickerPopover
            availableSoftwares={initialData.manualServices
              .filter((key) => !manualServices.some(({ id }) => id === key))
              .map((id) => softwareMap[id])}
            selectedSoftwares={manualServices.map(({ id }) => softwareMap[id])}
            onSelect={(id) =>
              setManualServices((prev) => [...prev, { id, status: 'idle' }])
            }
            onRemove={(id) =>
              setManualServices((prev) =>
                prev.filter(({ id: currentId }) => currentId !== id)
              )
            }
          >
            <AddSoftwareCard />
          </SoftwarePickerPopover>
        </div>
      </div>

      <OnboardingNav
        className="bottomNavigation"
        timeSinceLastSave={600000}
        isNextDisabled={
          manualServices.length === 0 || codatServices.length === 0
        }
        onClickBack={() => {
          navigate('/account-settings/business-sophistication-info');
        }}
        onClickNext={() => {
          window.localStorage.setItem('onboarding', 'completed');
          navigate('/');
        }}
        nextButtonLabel="Finish"
      />

      <SoftwareConnectionDialog
        isShown={isSoftwareConnectionDialogOpen}
        onCloseComplete={() => setSoftwareConnectionDialogOpen(false)}
        title={
          notConnectedCodateServices.length
            ? `Connect to ${
                softwareMap[notConnectedCodateServices[0].id].title
              }`
            : ''
        }
        description={
          notConnectedCodateServices.length
            ? `You will be asked to connect your ${
                softwareMap[notConnectedCodateServices[0].id].title
              } account  and authorize Atlassian access to the following data:`
            : ''
        }
        accessList={[
          {
            icon: 'ShieldCheckIcon',
            title: 'Account receivable information',
            description:
              'By continnuining you are permitting Atlassian to access account information.',
          },
          {
            icon: 'ShieldCheckIcon',
            title: 'Account receivable information',
            description:
              'By continnuining you are permitting Atlassian to access account information.',
          },
          {
            icon: 'ShieldCheckIcon',
            title: 'Account receivable information',
            description:
              'By continnuining you are permitting Atlassian to access account information.',
          },
        ]}
        onConnect={handleConnect}
        logo={
          notConnectedCodateServices.length
            ? softwareMap[notConnectedCodateServices[0].id].logo
            : 'barclays'
        }
        footerText={`${
          codatServices.findIndex((service) => service.status === 'idle') + 1
        } / ${codatServices.length}
          `}
      />

      <SoftwareConnectedDialog
        isShown={isSoftwareConnectedDialogOpen}
        onCloseComplete={() => setSoftwareConnectedDialogOpen(false)}
        title="Accounts Linking is Underway"
        description="Connection in progress it may take up to 24h. You can close the tab and back to it later. The progress will be saved."
        submitButton={{
          title: 'Go to business cockpit',
          onClick: () => setSoftwareConnectedDialogOpen(false),
        }}
        softwareList={codatServices.map(
          (service) => softwareMap[service.id].logo
        )}
      />
    </LinkAccountsContainer>
  );
};

export default LinkAccounts;
