import React from 'react';

import styled from 'styled-components';

import Icon from 'components/atoms/icon';

export type MonthlyActionsDroppableProps = {
  children?: React.ReactNode;
  innerRef?: any;
} & Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'ref'
>;

const StyledMonthlyActionsDroppable = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.pxToRem(815)};
  height: 100%;
  min-width: ${({ theme }) => theme.pxToRem(390)};
  min-height: ${({ theme }) => theme.pxToRem(296)};
  padding: ${({ theme }) => theme.pxToRem(8)};
  background-color: ${({ theme }) => theme.colors.neutral[0]};
  border-radius: ${({ theme }) => theme.pxToRem(8)};

  & .droppable-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: ${({ theme }) => theme.pxToRem(280)};
    border: 1px solid ${({ theme }) => theme.colors.neutral[400]};
    border-style: dashed;
    border-radius: ${({ theme }) => theme.pxToRem(8)};
    padding-top: ${({ theme }) => theme.pxToRem(40)};
    padding-bottom: ${({ theme }) => theme.pxToRem(40)};

    &__heading {
      position: absolute;
      top: ${({ theme }) => theme.pxToRem(-1)};
      left: ${({ theme }) => theme.pxToRem(-1)};
      right: ${({ theme }) => theme.pxToRem(-1)};
      height: ${({ theme }) => theme.pxToRem(40)};
      background-color: ${({ theme }) => theme.colors.secondary['B400']};
      border-radius: ${({ theme }) => theme.pxToRem(8)};
      display: flex;
      justify-content: center;
      align-items: center;

      & > h5 {
        color: ${({ theme }) => theme.colors.neutral[0]};
      }
    }

    &__plus-icon {
      padding: ${({ theme }) => theme.pxToRem(10)};
      width: ${({ theme }) => theme.pxToRem(44)};
      height: ${({ theme }) => theme.pxToRem(44)};
      background: ${({ theme }) => theme.colors.blue50};
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;

      & div {
        width: 100%;
        height: 100%;
        padding: ${({ theme }) => theme.pxToRem(5)};
        box-sizing: border-box;

        display: flex;
        align-items: center;

        background: ${({ theme }) => theme.colors.tertiary['B200']};
        border-radius: 50%;

        & svg {
          width: 100%;
          height: auto;

          & path {
            stroke-width: 1.5;
            stroke: #60acff;
          }
          color: #60acff;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: ${({ theme }) => theme.pxToRem(740)};
      margin: 0 auto;
      z-index: 1;
    }
  }
`;

const MonthlyActionsDroppable: React.FC<MonthlyActionsDroppableProps> = ({
  children,
  innerRef,
  ...props
}) => {
  return (
    <StyledMonthlyActionsDroppable {...props} ref={innerRef}>
      <div className="droppable-container">
        <div className="droppable-container__heading">
          <h5 className="H-500">Actions To Perform</h5>
        </div>
        {React.Children.count(children) === 1 && (
          <div className="droppable-container__plus-icon">
            <div>
              <Icon icon="PlusIcon" />
            </div>
          </div>
        )}
        <div className="droppable-container__content">{children}</div>
      </div>
    </StyledMonthlyActionsDroppable>
  );
};

export default MonthlyActionsDroppable;
