import { useMutation } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'context';

export const useCreateDataSourceMutation = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  return useMutation(
    async ({
      companyId,
      sourceType,
      user,
    }: {
      companyId?: string;
      sourceType?: 'google' | 'facebook' | 'mailchimp' | 'codat_shopify';
      user: User;
    }): Promise<any> => {
      const token = window.Cypress
        ? 'cypress-mock-token'
        : await getAccessTokenSilently();

      if (!isAuthenticated && !window.Cypress) {
        throw new Error(`You need to login to create a user.`);
      }

      if (typeof companyId !== 'string' || typeof sourceType !== 'string') {
        throw new Error(`Invalid data source credentials.`);
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_GATEWAY}/${process.env.REACT_APP_API_GATEWAY_VERSION}/sources`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            user_id: user.id,
            source_type: sourceType,
            external_id: companyId,
          }),
        }
      );

      if (response.status !== 201) {
        throw new Error(`Failed to create a source.`);
      }

      const data = await response.json();
      return data;
    }
  );
};
