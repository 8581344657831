import React from 'react';

import styled from 'styled-components';

import ReactFlow from 'react-flow-renderer';

import BusinessModelEdge from 'components/atoms/business-model-edge';
import StoreFrontNode from 'components/organisms/store-front-node';
import CustomerJourneyNode from 'components/organisms/customer-journey-node';
import CustomerJourneyChildNode from 'components/molecules/customer-journey-child-node';
import FulfillmentMethodNode from 'components/organisms/fulfillment-method-node';
import FulfillmentList from 'components/molecules/fulfillment-list';
import SupplyChainNode from 'components/organisms/supply-chain-node/SupplyChainNode';
import OnboardingNav from 'components/molecules/onboarding-nav';

import { CanvasProvider } from 'pages/business-configuration/nested-routes/business-blueprint/context';
import {
  StyledMiniMap,
  StyledControls,
} from 'pages/business-configuration/nested-routes/business-blueprint/BusinessBlueprint';
import { nodes } from './config';

import { useNavigate } from 'react-router-dom';

const StyledPageContainer = styled.div`
  width: 100%;
  height: 100vh;

  & .bottomNavigation {
    position: absolute;
    bottom: ${({ theme }) => theme.pxToRem(36)};
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }
`;

export const PageContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <StyledPageContainer>
      {children}
      <OnboardingNav
        className="bottomNavigation"
        timeSinceLastSave={600000}
        onClickNext={() => {
          window.localStorage.setItem(
            'onboarding',
            '/business-configuration/financial-platforms'
          );
          navigate('/business-configuration/financial-platforms');
        }}
        onClickBack={() => {
          navigate('/business-configuration/business-blueprint');
        }}
      />
    </StyledPageContainer>
  );
};

const CustomerJourney = () => {
  return (
    <CanvasProvider>
      <PageContainer>
        <ReactFlow
          nodes={nodes}
          defaultZoom={1}
          nodeTypes={{
            StoreFrontNode,
            CustomerJourneyNode,
            CustomerJourneyChildNode,
            SupplyChainNode,
            FulfillmentMethodNode,
            FulfillmentList,
          }}
          edgeTypes={{
            BusinessModelEdge,
          }}
        >
          <StyledMiniMap />
          <StyledControls showInteractive={false} />
        </ReactFlow>
      </PageContainer>
    </CanvasProvider>
  );
};

export default CustomerJourney;
