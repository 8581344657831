import { useState } from 'react';

import styled from 'styled-components';

import Card from 'components/atoms/card';
import SelectMenu from 'components/molecules/select-menu';
import Button from 'components/atoms/button';

import Icon, { IconProps } from 'components/atoms/icon/Icon';

import { useNavigate } from 'react-router-dom';

export type OnboardingJourneyMenuProps = {
  children?: React.ReactNode;
};

const StyledOnboardingJourneyMenu = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 50%;
  right: ${({ show, theme }) =>
    show ? theme.pxToRem(16) : theme.pxToRem(-260)};
  transition: right 0.5s;
  z-index: 10;

  &:hover {
    right: ${({ show, theme }) =>
      show ? theme.pxToRem(16) : theme.pxToRem(-245)};
  }

  & .menu-card {
    width: ${({ theme }) => theme.pxToRem(230)};
    height: ${({ theme }) => theme.pxToRem(100)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    padding: ${({ theme }) => theme.pxToRem(16)};
    background-color: ${({ theme }) => theme.colors.tertiary['B50']};

    & > h5 {
      margin-bottom: ${({ theme }) => theme.pxToRem(8)};
    }

    & .menu-cta {
      width: ${({ theme }) => theme.pxToRem(32)};
      height: ${({ theme }) => theme.pxToRem(32)};
      position: absolute;
      top: ${({ theme }) => theme.pxToRem(-16)};
      left: ${({ theme }) => theme.pxToRem(-16)};
      border-radius: 50%;
      transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
      transition: transform 0.5s;

      & > svg {
        position: absolute;
      }
    }
  }
`;

const options: {
  label: string;
  value: string;
  icon?: IconProps['icon'];
}[] = [
  {
    label: 'Personal Info',
    value: '/account-settings/personal-info',
  },
  {
    label: 'Business Info',
    value: '/account-settings/business-info',
  },
  {
    label: 'Business Template',
    value: '/business-configuration/blueprint-template',
    icon: 'ShoppingBagIcon',
  },
  {
    label: 'Business Model',
    value: '/business-configuration/business-blueprint',
    icon: 'ShoppingBagIcon',
  },
  {
    label: 'Customer Journey',
    value: '/business-configuration/customer-journey',
    icon: 'ShoppingBagIcon',
  },
  {
    label: 'Financial platforms',
    value: '/business-configuration/financial-platforms',
    icon: 'ShoppingBagIcon',
  },
  {
    label: 'Financial Info',
    value: '/account-settings/financial-info',
    icon: 'PresentationChartLineIcon',
  },
  {
    label: 'Working Capital',
    value: '/account-settings/working-capital-info',
    icon: 'PresentationChartLineIcon',
  },
  {
    label: 'Acuity Info',
    value: '/account-settings/acuity-info',
    icon: 'ThumbUpIcon',
  },
  {
    label: 'Goals Info',
    value: '/account-settings/goals-info',
    icon: 'ThumbUpIcon',
  },
  {
    label: 'Risk Info',
    value: '/account-settings/risk-info',
    icon: 'ThumbUpIcon',
  },
  {
    label: 'Business Sophistication Info',
    value: '/account-settings/business-sophistication-info',
    icon: 'ThumbUpIcon',
  },
  {
    label: 'Link Accounts',
    value: '/business-configuration/link-accounts',
    icon: 'ViewGridAddIcon',
  },
];

const OnboardingJourneyMenu: React.FC<OnboardingJourneyMenuProps> = ({}) => {
  const navigate = useNavigate();
  const [selectedUrl, setSelectedUrl] = useState<string | undefined>(undefined);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StyledOnboardingJourneyMenu show={showMenu}>
      <Card className="menu-card">
        <Button
          className="menu-cta"
          aria-label="Show Onboarding Journey Menu"
          onClick={() => {
            setShowMenu((prevState) => !prevState);
          }}
        >
          <Icon icon="ChevronLeftIcon" />
        </Button>
        <h5 className="H-800">Onboarding Go To</h5>
        <SelectMenu
          placeholder="Select Onboarding Section"
          options={options}
          selected={selectedUrl}
          onSelect={(item) => {
            window.localStorage.setItem('onboarding', item.value as string);
            setSelectedUrl(item.value as string);
            navigate(item.value as string);
          }}
        />
      </Card>
    </StyledOnboardingJourneyMenu>
  );
};

export default OnboardingJourneyMenu;
