export const actions = [
  {
    id: 'action-1',
    title: 'Bring designer to improve visual, copy, CTA and other parameters',
    timeWeight: 2,
    budgetWeight: 1,
  },
  {
    id: 'action-2',
    title: 'Increase SEO budget to increase your search ranking',
    timeWeight: 2,
    budgetWeight: 0,
  },
  {
    id: 'action-3',
    title: 'Analyze your email subject lines to increase open rate',
    timeWeight: 1,
    budgetWeight: 1,
  },
  {
    id: 'action-4',
    title: 'Do A/B testing',
    timeWeight: 1,
    budgetWeight: 0,
  },
  {
    id: 'action-5',
    title: 'Changing images to high quality to increase time on site',
    timeWeight: 3,
    budgetWeight: 3,
  },
  {
    id: 'action-6',
    title: 'Put thumbnails of items baught in cart',
    timeWeight: 0,
    budgetWeight: 0,
  },
  {
    id: 'action-7',
    title: 'Provide multiple checkout options',
    timeWeight: 2,
    budgetWeight: 1,
  },
  {
    id: 'action-8',
    title: 'Make bundles to increase your upselling',
    timeWeight: 1,
    budgetWeight: 1,
  },
];
