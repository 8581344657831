import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import TextInput from 'components/molecules/text-input';
import Select from 'components/molecules/select';
import Button from 'components/atoms/button';
import InputWrapper from 'components/molecules/input-wrapper';
import { BusinessState } from 'pages/account-settings/StateMachine.types';
// import FilePicker from 'components/molecules/file-picker';
import ProgressBar from 'components/atoms/progress-bar';
import DatePicker from 'components/molecules/date-picker';

import { useStateMachine } from 'little-state-machine';
import { updateBusinessDetails } from 'pages/account-settings/actions';

import ArtisticAttire from './artistic-attire.png';

const companyTypes: {
  value: NonNullable<BusinessState['companyType']>;
  label: NonNullable<BusinessState['companyType']>;
}[] = [
  { value: 'Private Limited', label: 'Private Limited' },
  { value: 'Sole Trader', label: 'Sole Trader' },
  { value: 'Business Partnership', label: 'Business Partnership' },
  { value: 'Social Enterprise', label: 'Social Enterprise' },
  { value: 'Overseas Company', label: 'Overseas Company' },
  {
    value: 'Unincorporated Association',
    label: 'Unincorporated Association',
  },
];

const numberOfEmployeesTypes: {
  value: NonNullable<BusinessState['numberOfEmployees']>;
  label: NonNullable<BusinessState['numberOfEmployees']>;
}[] = [
  { value: '0 to 5', label: '0 to 5' },
  { value: '6 to 10', label: '6 to 10' },
  { value: '11 to 20', label: '11 to 20' },
  { value: 'above 21', label: 'above 21' },
];

const businessSchema = yup
  .object({
    logo: yup
      .string()
      .transform((val: File[]) => (!val?.length ? undefined : val))
      .nullable(),
    businessName: yup.string().required('Business name is required.'),
    companyNumber: yup.string().required('Company number is required'),
    companyWebsite: yup
      .string()
      .transform((value: string) =>
        !value.includes('www.') && !!value ? `www.${value}` : value
      )
      .required('Company website is required.')
      .matches(
        new RegExp(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
        ),
        'Company website must be a valid URL'
      ),
    companyType: yup.string().notOneOf(['placeholder'], 'Please select one'),
    dateOfIncorporation: yup.string().required('Please add a date'),
    numberOfEmployees: yup
      .string()
      .notOneOf(['placeholder'], 'Please select one'),
  })
  .required();

const StyledBusinessInfo = styled.div`
  & form {
    margin-top: ${({ theme }) => theme.pxToRem(32)};

    & .logo {
      width: 100%;
    }

    & .input {
      width: 100%;
    }

    & .footer {
      justify-content: space-between;
    }

    & .date-picker {
      & .react-datepicker__input-container {
        & > input {
          min-width: ${({ theme }) => theme.pxToRem(180)};
        }
      }
    }

    & .logo-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border: 1px solid ${({ theme }) => theme.colors.neutral[400]};
      border-style: dashed;
      border-radius: 8px;
    }
  }
`;

const BusinessInfo: React.FC = () => {
  const navigate = useNavigate();
  const {
    state: { businessStore },
    actions,
  } = useStateMachine({ updateBusinessDetails });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessState>({
    resolver: yupResolver<yup.AnyObjectSchema>(businessSchema),
    defaultValues: {
      ...businessStore,
      dateOfIncorporation: businessStore?.dateOfIncorporation
        ? new Date(businessStore?.dateOfIncorporation)
        : undefined,
    },
  });
  console.log('Business store', businessStore);

  return (
    <StyledBusinessInfo>
      <h3>Enter Your Business Info</h3>
      <ProgressBar currentStep={2} totalSteps={2} />
      <form
        onSubmit={handleSubmit((data) => {
          console.log('Data', data);
          actions.updateBusinessDetails(data);
          localStorage.setItem(
            'onboarding',
            '/business-configuration/blueprint-template'
          );
          navigate('/business-configuration/blueprint-template');
        })}
      >
        <InputWrapper label="Company Logo" className={`logo-wrapper`}>
          <img src={ArtisticAttire} className="logo-image" />
        </InputWrapper>
        <InputWrapper label="Business Name" className="businessName-wrapper">
          <TextInput
            data-testid="businessName-input"
            className="input"
            placeholder="Enter your business name"
            name="businessName"
            control={control}
            error={errors['businessName']}
          />
        </InputWrapper>
        <InputWrapper label="Company Number" className="companyNumber-wrapper">
          <TextInput
            data-testid="companyNumber-input"
            className="input"
            placeholder="Enter your company number"
            name="companyNumber"
            control={control}
            error={errors['companyNumber']}
          />
        </InputWrapper>

        <InputWrapper
          label="Company Website"
          className="companyWebsite-wrapper"
        >
          <TextInput
            data-testid="companyWebsite-input"
            className="input"
            placeholder="Enter your company website"
            name="companyWebsite"
            control={control}
            error={errors['companyWebsite']}
            prefix="https://"
          />
        </InputWrapper>

        <InputWrapper label="Company Type" className="companyType-wrapper">
          <Select
            data-testid="companyType-input"
            className="input"
            placeholder="Select One"
            name="companyType"
            control={control}
            error={errors['companyType']}
            errorMessage={errors['companyType']?.message}
            values={companyTypes}
          />
        </InputWrapper>

        <InputWrapper
          label="Date of Incorporation"
          className="dateOfIncorporation-wrapper"
        >
          <DatePicker
            control={control}
            data-testid="dateOfIncorporation-input"
            name="dateOfIncorporation"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="Click to select a date"
            error={errors['dateOfIncorporation']}
            className="date-picker"
          />
        </InputWrapper>

        <InputWrapper
          label="Numbert of Employees"
          className="numberOfEmployees-wrapper"
        >
          <Select
            data-testid="numberOfEmployees-input"
            className="input"
            placeholder="Select One"
            name="numberOfEmployees"
            control={control}
            error={errors['numberOfEmployees']}
            values={numberOfEmployeesTypes}
            errorMessage={errors['numberOfEmployees']?.message}
          />
        </InputWrapper>
        <div className="footer">
          <Button
            onClick={(event) => {
              event.preventDefault();
              navigate('/account-settings/personal-info');
            }}
          >
            Back
          </Button>
          <Button type="submit" appearance="primary">
            Next Step
          </Button>
        </div>
      </form>
    </StyledBusinessInfo>
  );
};

export default BusinessInfo;
