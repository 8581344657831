import colors from 'theme/colors';
import { pxToRem } from 'theme/helpers';

export const button: any = {
  Button: {
    baseStyle: {
      borderRadius: pxToRem(4),
      fontFamily: 'fontFamilies.ui',
      transition: 'box-shadow 80ms ease-in-out',
      _focus: {
        boxShadow: 'shadows.focusRing',
      },
      _disabled: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    appearances: {
      default: {
        background: colors.neutral[0],
        color: colors.neutral[700],
        border: `1px solid ${colors.neutral[400]}`,
        boxSizing: 'border-box',
        '& svg > path': {
          fill: colors.neutral[700],
        },
        _hover: {
          borderColor: colors.neutral[600],
          color: colors.neutral[800],
          '& svg > path': {
            fill: colors.neutral[800],
          },
        },
        _active: {
          background: colors.neutral[100],
          borderColor: colors.neutral[500],
          color: colors.neutral[800],
          '& svg > path': {
            fill: colors.neutral[800],
          },
        },
        _focus: {
          borderColor: colors.neutral[500],
          color: colors.neutral[800],
          '& svg > path': {
            fill: colors.neutral[800],
          },
        },
        _disabled: {
          borderColor: colors.neutral[300],
          color: colors.neutral[500],
          '& svg > path': {
            fill: colors.neutral[500],
          },
        },
      },
      primary: {
        background: colors.primary['B400'],
        color: colors.neutral[0],
        '& svg > path': {
          fill: colors.neutral[0],
        },
        _hover: {
          background: colors.primary['B500'],
        },
        _active: {
          background: colors.primary['B600'],
        },
        _focus: {
          background: colors.primary['B500'],
        },
        _disabled: {
          background: colors.tertiary['B200'],
        },
      },
      minimal: {
        backgroundColor: 'transparent',
        color: colors.neutral[700],
        '& svg > path': {
          fill: colors.neutral[700],
        },
        _hover: {
          backgroundColor: colors.neutral[100],
          color: colors.neutral[800],
          '& svg > path': {
            fill: colors.neutral[800],
          },
        },
        _active: {
          backgroundColor: colors.neutral[200],
          color: colors.neutral[800],
          '& svg > path': {
            fill: colors.neutral[800],
          },
        },
        _focus: {
          backgroundColor: colors.neutral[50],
        },
        _disabled: {
          color: colors.neutral[400],
          '& svg > path': {
            fill: colors.neutral[400],
          },
        },
      },
      error: {
        color: colors.neutral[0],
        '& svg > path': {
          fill: colors.neutral[0],
        },
        background: colors.secondary['R400'],
        _hover: {
          background: colors.tertiary['R500'],
        },
        _active: {
          background: colors.tertiary['R600'],
        },
        _focus: {
          background: colors.tertiary['R500'],
          boxShadow: `0 0 0 ${pxToRem(2)} ${colors.tertiary['R100']}`,
        },
        _disabled: {
          background: colors.tertiary['R100'],
        },
      },
      destructive: {
        backgroundColor: 'colors.red500',
        borderColor: 'colors.red500',
        color: 'white',
        '& svg > path': {
          fill: 'white',
        },
        _hover: {
          backgroundColor: 'colors.red600',
          borderColor: 'colors.red600',
        },
        _disabled: {
          backgroundColor: 'colors.red100',
          borderColor: 'colors.red100',
        },
        _focus: {
          backgroundColor: 'colors.red500',
          boxShadow: '0 0 0 2px undefined',
          borderColor: 'colors.red500',
        },
        _active: {
          backgroundColor: 'colors.red700',
          borderColor: 'colors.red700',
        },
      },
      link: {
        paddingX: 0,
        paddingY: 0,
        border: 'unset',
        '&:link': {
          color: colors.secondary['B400'],
        },
        '&:visited': {
          color: colors.tertiary['V400'],
        },
        _hover: {
          color: colors.secondary['B500'],
        },
        _active: {
          color: colors.secondary['B600'],
        },
        _focus: {
          color: colors.secondary['B500'],
          boxShadow: 'unset',
        },
        _disabled: {
          color: `${colors.neutral[500]} !important`,
        },
      },
    },
    sizes: {
      small: {
        height: 24,
        minWidth: 24,
        fontSize: 'fontSizes.1',
        lineHeight: '24px',
        paddingLeft: 12,
        paddingRight: 12,
      },
      medium: {
        paddingX: pxToRem(16),
        paddingY: pxToRem(8),
        fontSize: pxToRem(14),
        lineHeight: 1.71,
      },
      large: {
        height: 40,
        minWidth: 40,
        fontSize: 'fontSizes.2',
        lineHeight: '40px',
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  },
};
