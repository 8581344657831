import { InputHTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';

export type TemplateCardOptionProps = {
  children?: React.ReactNode;
  ref?:
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null
    | undefined;
} & Omit<
  React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'ref' | 'type'
>;

const StyledTemplateCardOption = styled.label`
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & > span {
    width: ${({ theme }) => theme.pxToRem(207)};
    height: ${({ theme }) => theme.pxToRem(55)};
    background: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: ${({ theme }) => theme.pxToRem(16)};
    border: 1px solid ${({ theme }) => theme.colors.neutral[0]};
    &:hover {
      box-shadow: 0px 4px 50px -10px rgba(193, 196, 214, 0.4);
    }

    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.pxToRem(16)};
    line-height: ${({ theme }) => theme.pxToRem(24)};
    color: ${({ theme }) => theme.colors.neutral[900]};

    & svg {
      width: ${({ theme }) => theme.pxToRem(15)};
      color: ${({ theme }) => theme.colors.neutral[600]};
      margin-right: ${({ theme }) => theme.pxToRem(9)};
      & path {
        stroke: ${({ theme }) => theme.colors.neutral[600]};
      }
    }
  }

  & > input:checked + span {
    border: 1px solid ${({ theme }) => theme.colors.primary['BLUE']};
    box-shadow: ${({ theme }) =>
      `0px ${theme.pxToRem(4)} ${theme.pxToRem(50)} ${theme.pxToRem(
        4
      )} rgba(96, 172, 255, 0.2)`};
    color: #60acff;
    & svg {
      color: #60acff;
      & path {
        stroke: #60acff;
      }
    }
  }
`;

const TemplateCardOption: React.FC<TemplateCardOptionProps> = ({
  children,
  className,
  style,
  ...props
}) => {
  return (
    <StyledTemplateCardOption className={className} style={style}>
      <input type="checkbox" {...props} />
      <span>{children}</span>
    </StyledTemplateCardOption>
  );
};

export default TemplateCardOption;
